import React from 'react';
import { Moment } from 'moment';

import LogoNormal from '../../../../assets/brand/LogoNormal';
import { transformDate } from '../../../../utils/DateTime';

import * as S from './styles';

interface Props {
  period?: {
    initial: Moment;
    final: Moment;
  };
  title: string;
}

export const PreviewHeader: React.FC<Props> = ({ period, title }) => {
  return (
    <S.PreviewHeader>
      <S.HeaderTitle>Relatório</S.HeaderTitle>
      <S.HeaderDate>
        {title}
        <br />
        {period ? transformDate(period.initial.toDate(), 'pt-BR') : 'Hoje'} {period ? '-' : ' '}
        {period ? transformDate(period.final.toDate(), 'pt-BR') : ''}
      </S.HeaderDate>
      <div>
        <LogoNormal />
      </div>
    </S.PreviewHeader>
  );
};
