import { Input } from 'antd';
import React from 'react';

import { ResultListItem } from '.';
import { useIsMounted } from '../../../hooks';
import Search from '../../../Icons/Search';

export type SearchInputProps = {
  value?: string;
  selectedItem?: ResultListItem;
  placeholder?: string;
  active?: boolean;
  customIcon?: React.ReactNode;
  onClick?: () => void;
  onClickSelectedItem?: () => void;
  onChange?: (value: string) => void;
};

export const SearchInput: React.FC<SearchInputProps> = (props: SearchInputProps) => {
  const {
    value,
    selectedItem,
    placeholder = 'Pesquisar',
    active = false,
    customIcon,
    onClick,
    onClickSelectedItem,
    onChange,
  } = props;
  const isMounted = useIsMounted();

  const handleOnChange = (changedValue: string) => {
    if (onChange) onChange(changedValue);
  };

  const handleOnClick = () => {
    if (onClick) onClick();
  };

  const handleClickOpenSearch = async () => {
    handleOnClick();

    if (active) {
      setTimeout(() => {
        if (!isMounted.current) return;
        handleOnChange('');
      }, 1000);
    }
  };

  const handleChangeInputSearch = (e: any) => {
    const { value } = e.target;
    handleOnChange(value);
  };

  return (
    <Input
      type="search"
      value={value}
      className="search-input"
      placeholder={placeholder}
      onChange={handleChangeInputSearch}
      prefix={
        <>
          <div className="search-prefix-container" onClick={handleClickOpenSearch}>
            {customIcon || <Search />}
          </div>
          {selectedItem && (
            <div className="search-selected-item-container">
              <div className="search-selected-item-label">
                <span className="search-selected-item">{selectedItem.label}</span>
                <div
                  className="search-selected-item-close-icon"
                  onClick={() => onClickSelectedItem && onClickSelectedItem()}
                >
                  <span title="Remover item selecionado">x</span>
                </div>
              </div>
            </div>
          )}
        </>
      }
    />
  );
};
