import React from 'react';
import { ToastContainer } from 'react-toastify';

import { useCreateNewCompany } from './hooks/useCreateCompany';
import { Modal } from '../../../../../components/Modal';
import ClientSelect from '../../../../filters/ClientSelect';
import IconClose from '../../../../../Icons/Close';
import IconUsers from '../../../../../Icons/Users';
import IconManager from '../../../../../Icons/Manager';

import { SimpleContainer } from '../../../../../styles/structure';
import * as S from './styles';

type AddCompanyContainerProps = {
  isActive: boolean;
  onClickToSave(): void;
  onClickToClose(): void;
};

export const AddCompanyContainer: React.FC<AddCompanyContainerProps> = (props: AddCompanyContainerProps) => {
  const { isActive, onClickToClose, onClickToSave } = props;

  const { isDefined, handleSaveCompany, createCompany, setNameSelected, setClientSelected } =
    useCreateNewCompany();

  return (
    <>
      <ToastContainer />
      <Modal visible={isActive}>
        <S.AddCompanyContainer>
          <S.CloseButton onClick={onClickToClose}>
            <IconClose />
          </S.CloseButton>

          <S.CompanyListHeaderTitleContainer>
            <S.IconUsersContainer>
              <IconUsers />
            </S.IconUsersContainer>
            <S.CompanyListHeaderLabel>Criar empresa nova</S.CompanyListHeaderLabel>
          </S.CompanyListHeaderTitleContainer>

          <S.AddCompanyFrontContent>
            <S.CompanyInfoContainer>
              <S.FilterInputContainer>
                <S.InputLabel>Cliente</S.InputLabel>
                <SimpleContainer>
                  <ClientSelect clientSelected={createCompany.client} setClientSelected={setClientSelected} />
                </SimpleContainer>
              </S.FilterInputContainer>
              <S.FilterInputContainer>
                <S.InputLabel>Empresa</S.InputLabel>
                <SimpleContainer>
                  <S.CompanyInpunt
                    placeholder="Insira o nome da empresa"
                    value={createCompany.name}
                    onChange={(event) => setNameSelected(event.target.value)}
                  />
                </SimpleContainer>
              </S.FilterInputContainer>
            </S.CompanyInfoContainer>

            <S.SaveButtonContainer>
              <S.SaveButton
                title="Criar empresa nova"
                icon={<IconManager />}
                disabled={!isDefined()}
                onClick={async () => {
                  const isSaved = await handleSaveCompany();
                  if (isSaved) onClickToSave();
                }}
              />
            </S.SaveButtonContainer>
          </S.AddCompanyFrontContent>
        </S.AddCompanyContainer>
      </Modal>
    </>
  );
};
