import React from 'react';
import { ToastContainer } from 'react-toastify';

import { IncidentsTable } from '../IncidentsTable';
import { useIncidetsList } from './hooks/useIncidentsList';
import IconUsers from '../../../../Icons/Users';
import { SolvingIcident } from '../SolveIncident';
import { IncidentDetails } from '../IncidentDetails';
import IncidentsOptions from '../IncidentsOptions';
import { useAuth } from '../../../../hooks';
import { Info } from '../../../../components/Info';

import * as S from './styles';
import 'react-toastify/dist/ReactToastify.css';

export const IncidentsList: React.FC = () => {
  const {
    isLoading,
    incidents,
    isSolving,
    selectedRow,
    handleCloseSolveIncident,
    handleCloseSolveIncidentsAndUpdateList,
    isDetails,
    handleCloseIncidentDetails,
    isEmpty,
    getIncidentsTooltipContent,
  } = useIncidetsList();
  const { getClientId } = useAuth();
  const clientId = getClientId();
  const tooltipContent = getIncidentsTooltipContent(clientId);

  return (
    <S.IncidentsListContainer>
      <ToastContainer />
      <S.IncidentsListHeader>
        <S.IncidentsListHeaderTitleContainer>
          <IconUsers />
          <S.IncidentsListHeaderLabel>Resumo dos Incidentes</S.IncidentsListHeaderLabel>
          <S.IconContainer>
            <Info content={tooltipContent} position={'right'} burndown={false} />
          </S.IconContainer>
        </S.IncidentsListHeaderTitleContainer>
      </S.IncidentsListHeader>
      <IncidentsOptions />
      <IncidentsTable
        detailData={incidents.map(({ id, solved, comment, solvedby, date, ...value }) => value)}
        loading={isLoading}
        empty={isEmpty}
        solved={incidents.map((item) => {
          if (item.solved == true) {
            return '#33B4694D';
          } else {
            return '';
          }
        })}
      />

      {isSolving && (
        <SolvingIcident
          isSolving={isSolving}
          onClickToClose={handleCloseSolveIncident}
          collaborator={selectedRow.collaborator}
          onClickToSolve={handleCloseSolveIncidentsAndUpdateList}
          place={selectedRow.place}
          timestamp={selectedRow.timestemp}
          reason={selectedRow.reason}
          details={selectedRow.details}
          id={selectedRow.id}
        />
      )}

      {isDetails && (
        <IncidentDetails
          isDetails={isDetails}
          onClickToClose={handleCloseIncidentDetails}
          collaborator={selectedRow.collaborator}
          place={selectedRow.place}
          timestamp={selectedRow.timestemp}
          reason={selectedRow.reason}
          id={selectedRow.id}
          date={selectedRow.date}
          solvedBy={selectedRow.solvedby}
          comment={selectedRow.comment}
          details={selectedRow.details}
        />
      )}
    </S.IncidentsListContainer>
  );
};
