import React from 'react';

import { Select } from '../../../components/Forms/Selects';
import { Option } from '../../../components/Forms/Selects/Select/Option';

import * as S from '../styles';

interface Props {
  placeholder?: string;
  options: { label: string; value: any; disabled?: boolean }[];
  id: string;
  label: string;
  onValueChange: (v: number) => void;
}

export const ReportSelect: React.FC<Props> = ({ label, id, options, placeholder, onValueChange }) => {
  return (
    <>
      <S.InputLabel htmlFor={id}>{label}</S.InputLabel>

      <Select
        id={id}
        bordered={false}
        style={{
          backgroundColor: '#ffffff',
          border: '1px solid #E6E6E6',
          borderRadius: '8px',
          marginBottom: '2.4rem',
        }}
        placeholder={placeholder}
        onChange={(v) => {
          onValueChange(v as number);
        }}
      >
        {options.map((v) => (
          <Option key={v.value} value={v.value} disabled={v.disabled}>
            {v.label || ''}
          </Option>
        ))}
      </Select>
    </>
  );
};
