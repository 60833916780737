import { TooltipContent } from "../../../../../components/Info/Tooltip";

export const getInOutTooltipContent = (clientId: number): TooltipContent[] => {
    if(clientId == 3)
        return [
            {
              content: 'Calcula a média de entrada e a média de saída dos colaboradores',
            },
            {
              content: 'Período de entrada: De 06:00 às 10:00'
            },
            {
              content: 'Período de saída: De 15:00 às 18:00'
            },
            {
              content: 'Os filtros “Semanal”, “Mensal” e “Anual”, localizados logo abaixo do gráfico, ignoram o filtro da pagina!'
            }
        ];

    if(clientId == 7)
        return [
            {
              content: 'Calcula a média de entrada e a média de saída dos colaboradores'
            },
            {
              content: 'Período de entrada: De 06:00 às 10:00'
            },
            {
              content: 'Período de saída: De 12:00 às 20:00'
            },
            {
              content: 'Os filtros “Semanal”, “Mensal” e “Anual”, localizados logo abaixo do gráfico, ignoram o filtro da pagina!'
            }
        ];

    return [
            {
              content: 'Calcula a média de entrada e a média de saída dos colaboradores',
            },
            {
              content: 'Período de entrada: De 06:00 às 10:00'
            },
            {
              content: 'Período de saída: De 16:00 às 18:00'
            },
            {
              content: 'Os filtros “Semanal”, “Mensal” e “Anual”, localizados logo abaixo do gráfico, ignoram o filtro da pagina!'
            }
        ];
};