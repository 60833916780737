import { useCallback, useState } from 'react';
import { useRegisterAreaContext } from '../../context/RegisterAreaContext';

export const useDrawArea = () => {
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const { setUpdateAreas, updateAreas } = useRegisterAreaContext();

  const handleCloseAddArea = useCallback(() => {
    setIsAdding(false);
  }, []);

  const handleCloseAddAreaAndUpdateMap = useCallback(() => {
    setUpdateAreas(!updateAreas);
    setIsAdding(false);
  }, []);

  return {
    isAdding,
    setIsAdding,
    handleCloseAddArea,
    handleCloseAddAreaAndUpdateMap,
  };
};
