import React from 'react';
import { DefaultIconProps } from './types';

export const CheckCircleIcon: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M36 66C52.5 66 66 52.5 66 36C66 19.5 52.5 6 36 6C19.5 6 6 19.5 6 36C6 52.5 19.5 66 36 66Z"
        stroke={fill || '#33B469'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.25 36.0056L31.74 44.4956L48.75 27.5156"
        stroke={fill || '#33B469'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};