import React, { createContext, ReactNode, useContext, useState } from 'react'; 
import { SelectOption } from '../../../components/Select/types';
  
type CompaniesContextData = {
  companySelected: SelectOption[];
  setCompanySelected: (company: SelectOption[]) => void;
};

type CompaniesProviderProps = {
  children: ReactNode;
};

const CompaniesContext = createContext({} as CompaniesContextData);
  
export function CompaniesContextProvider({ children }: CompaniesProviderProps) {
  const [companySelected, setCompanySelected] = useState<SelectOption[]>([]);
   
  const value = {
    companySelected,
    setCompanySelected,
  };
    
  return <CompaniesContext.Provider value={value}>{children}</CompaniesContext.Provider>;
}
  
export function useCompaniesContext() {
  const context = useContext(CompaniesContext);
  
  if (typeof context === 'undefined')
    throw new Error('CompaniesContext must be used within an useCompaniesContext');
    
  return context;
}
  