import { useCallback, useEffect, useState } from 'react';
import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { getCreatedCompany } from '../../../../../../services/api/TrackfyService';
import { CreatedCompany } from '../../../../types/ClientCompany';
import { useAuth } from '../../../../../../hooks';

export const useCompanyList = (isAdmin: boolean) => {
  const { getClientId } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [createdCompany, setCreatedCompany] = useState<CreatedCompany[]>([]);
  const [isAdding, setIsAdding] = useState<boolean>(false);

  const handleCloseAddCompany = useCallback(() => {
    setIsAdding(false);
  }, []);

  const requestCreatedCompany = (token: CancelToken, operation: number, clientId: number) => {
    getCreatedCompany(token, clientId)
      .then((data: CreatedCompany[]) => {
        setCreatedCompany(data);
        setIsLoading(false);

        if (operation === 1) setIsAdding(false);
      })
      .catch((err) => {
        console.log(err);
        setCreatedCompany([]);
        setIsLoading(false);
      });
  };

  const handleCreatedCompanyUpdate = (operation: number) => {
    const clientSelected = isAdmin ? 0 : getClientId();
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    requestCreatedCompany(currentRequest.token, operation, clientSelected);
  };

  const handleCloseAddCompanyAndUpdateList = useCallback(() => {
    handleCreatedCompanyUpdate(1);
  }, []);

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if (mounted) {
      setIsLoading(true);

      const clientSelected = isAdmin ? 0 : getClientId();
      requestCreatedCompany(currentRequest.token, 0, clientSelected);
    }

    return () => {
      mounted = false;
      currentRequest.cancel('request canceled by the user.');
    };
  }, []);

  return {
    createdCompany,
    isLoading,
    isAdding,
    setIsAdding,
    handleCloseAddCompany,
    handleCloseAddCompanyAndUpdateList,
  };
};
