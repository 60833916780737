import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';

import { TabTitle } from './TabTitle';

type Props = {
  children: ReactElement[];
};

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 98%;
  overflow: hidden;
`;

const Ul = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  margin: 0;
  padding: 0;
  overflow: auto hidden;
  touch-action: auto;
  position: relative;
`;

export const Tabs: React.FC<Props> = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <TabsContainer>
      <Ul>
        {children.map((item, index) => (
          <TabTitle
            key={`${index}-${item.props.title}`}
            screen={item.props.screen}
            title={item.props.title}
            index={index}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
        ))}
      </Ul>
      {children[selectedTab]}
    </TabsContainer>
  );
};
