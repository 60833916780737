import React from 'react';

import { useDisplacement } from './hooks/useDisplacement';
import { ProgressBarChart } from './components/ProgressBar';
import { DisplacementChartHeader } from './components/DisplacementChartHeader';
import { IconLoading } from '../../../components/IconLoading';

import * as S from './styles';

export const DisplacementChart: React.FC = () => {
  const { displacementChartData, isLoading } = useDisplacement();
  
  return (
    <>
      <DisplacementChartHeader />
      <S.DisplacementCardContainer>
        {isLoading && <IconLoading />}
        {!isLoading && <ProgressBarChart displacementChartData={displacementChartData} color={"#2f4cdd"} />}
      </S.DisplacementCardContainer>
    </>
  );
};
