import React from 'react';
import { DefaultIconProps } from './types';

export const MapTracking: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M12.145 19.1769L9.52004 17.8619C8.99004 17.5919 8.14004 17.6219 7.63004 17.9119L5.28004 19.2519C3.64004 20.1919 2.29004 19.4119 2.29004 17.5119V7.78193C2.29004 7.15193 2.74004 6.37193 3.30004 6.05193L7.63004 3.57193C8.14004 3.28193 8.99004 3.26193 9.52004 3.52193L14.77 6.14193C15.3 6.41193 16.15 6.38193 16.66 6.09193L19.01 4.75193C20.65 3.81193 22 4.59193 22 6.49193V13.002"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.55957 4V17"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7305 6.62109V13.001"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2 21.4C19.9673 21.4 21.4 19.9673 21.4 18.2C21.4 16.4327 19.9673 15 18.2 15C16.4327 15 15 16.4327 15 18.2C15 19.9673 16.4327 21.4 18.2 21.4Z"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 22L21 21"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
