import React from 'react';

import {
  BarChart,
  CartesianGrid,
  XAxis,
  ResponsiveContainer,
  LabelList,
  Legend,
  Bar,
  YAxis,
  Tooltip,
} from 'recharts';
import { VelocidadeMedia } from '../../../../types/Insights';

type VerticalBarChartProps = {
  velocidadeMediaChartData: VelocidadeMedia[] | undefined;
};

export const VerticalBarChart: React.FC<VerticalBarChartProps> = ({ velocidadeMediaChartData }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={700} height={250} data={velocidadeMediaChartData}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="metodo" />
        <Tooltip/>
        <Legend />
        <Bar dataKey="chegada" fill="#008AE5" radius={[5, 5, 0, 0]} barSize={40} name='Chegada'>
          <LabelList
            dataKey="chegadaLabel"
            position="insideBottom"
            offset={30}
            style={{ fontSize: 10 }}
          />
        </Bar>
        <Bar 
          dataKey="idaAlmoco" 
          fill="#E17D00" 
          radius={[5, 5, 0, 0]} 
          barSize={40} 
          name='Ida almoço'
        >
          <LabelList dataKey="idaAlmocoLabel" position="insideBottom" offset={30} />
        </Bar>
        <Bar
          dataKey="voltaAlmoco"
          fill="#00A0A9"
          radius={[5, 5, 0, 0]}
          barSize={40}
          name='Volta almoço'
        >
          <LabelList dataKey="voltaAlmocoLabel" position="insideBottom" offset={30} />
        </Bar>
        <Bar dataKey="saida" fill="#F8581D" radius={[5, 5, 0, 0]} barSize={40} name='Saída'>
          <LabelList dataKey="saidaLabel" position="insideBottom" offset={30} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
