import React, { useCallback, useEffect, useState } from 'react';
import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { getIncidents } from '../../../../../services/api/TrackfyService';
import { IncidentDetails, Incidents } from '../../../types/Incidents';
import { SolveActionsContainer, Details, Solve, DetailsActionsContainer } from '../styles';
import moment from 'moment';
import { useIncidentsContext } from '../../../context/IcidentsContext';
import { useAuth } from '../../../../../hooks';
import { TooltipContent } from '../../../../../components/Info/Tooltip';

export const useIncidetsList = () => {
  const { professionSelected, areaSelected, initialDate,finalDate } = useIncidentsContext();
  const { getClientId } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [incidents, setIncidents] = useState<Incidents[]>([]);
  const [isSolving, setIsSolving] = useState<boolean>(false);
  const [isDetails, setIsDetails] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const [selectedRow, setSelectedRow] = useState<IncidentDetails>({
    id: '',
    collaborator: '',
    place: '',
    timestemp: '',
    reason: '',
    comment: '',
    details: '',
    solvedby: '',
    date: ''
  });
  
  const getIncidentsTooltipContent = (clientId: number): TooltipContent[] => {
    return [
            {
              content: 'É um registro de todos os alertas que foram acionados em áreas sob monitoramento, informando a data, o horário, o motivo do alerta, o local e o colaborador que ativou o alerta',
            },
        ];
  };

 const handleCloseSolveIncident = useCallback(() => {
    setIsSolving(false);
  }, []);

  const handleCloseIncidentDetails = useCallback(() => {
    setIsDetails(false);
  }, []);

  const handleCloseSolveIncidentsAndUpdateList = () => {
    handleIncidentsUpdate(1);
  };

  const handleIncidentsUpdate = (operation: number) => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    requestIncidents(currentRequest.token, operation);
  };

   const requestIncidents = (token: CancelToken, operation: number) => {
    getIncidents(
      getClientId(),
      professionSelected, 
      moment(initialDate).startOf('day').valueOf(),
      moment(finalDate).valueOf(),
      areaSelected,
      token
    )
      .then((data:Incidents[]) => {
        data.map((item:Incidents) => {
          if(item.solved == true){
            item.solve = (
              <DetailsActionsContainer
                onClick={() => {
                  setSelectedRow({ 
                    collaborator: item.col_alias, 
                    place: item.place, 
                    timestemp: item.timestamp, 
                    reason: item.reason,
                    id: item.id,
                    comment: item.comment,
                    details: item.comment,
                    solvedby: item.solvedby,
                    date: item.date
                  });
                  setIsDetails(true);
                }}
              >
                <Details>
                  Detalhes
                </Details>
              </DetailsActionsContainer>
            )
          }else{
            item.solve = (
            <SolveActionsContainer
              onClick={() => {
                setSelectedRow({ 
                  collaborator: item.col_alias, 
                  place: item.place, 
                  timestemp: item.timestamp, 
                  details: item.comment,
                  reason: item.reason,
                  id: item.id,
                });
                setIsSolving(true);
              }}
            >
              <Solve>
                Resolver
              </Solve>
            </SolveActionsContainer>
          );
          }
        });

        setIncidents(data);
        setIsLoading(false);

        if (operation == 1) setIsSolving(false);
        if (data.length == 0) setIsEmpty(true);
      })
      .catch((err) => {
        console.log(err);
        setIncidents([]);
        setIsLoading(false);
        setIsEmpty(true);
      });
  };

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if (mounted) {
      setIsLoading(true);
      setIsEmpty(false);
      requestIncidents(currentRequest.token, 0);
    }

    return () => {
      mounted = false;
      currentRequest.cancel(' request canceled by the user.');
    };
  }, [professionSelected, areaSelected, initialDate, finalDate]);

  return {
    incidents,
    isLoading,
    isSolving,
    handleCloseSolveIncident,
    selectedRow,
    handleCloseSolveIncidentsAndUpdateList,
    isDetails,
    handleCloseIncidentDetails,
    setIsEmpty,
    isEmpty,
    getIncidentsTooltipContent,
  };
};
