import React from 'react';

import { Bar, BarChart, CartesianGrid, XAxis, ResponsiveContainer, LabelList, YAxis } from 'recharts';
import { InOut } from '../../../../indicators/types/Indicators';

type VerticalBarChartProps = {
  inOutChartData: InOut[] | undefined;
};

export const VerticalBarChart: React.FC<VerticalBarChartProps> = ({ inOutChartData }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={700} height={250} data={inOutChartData}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" />
        {/*<YAxis label={{ value: 'Minutos', angle: -90, position: 'insideLeft' }} />*/}
        <Bar dataKey="in" fill="rgba(217, 209, 255, 100)" radius={[5, 5, 0, 0]} barSize={40}>
          <LabelList dataKey="inLabel" position="insideBottom" offset={30} />
        </Bar>
        <Bar dataKey="out" fill="rgba(217, 209, 255, 100)" radius={[5, 5, 0, 0]} barSize={40}>
          <LabelList dataKey="outLabel" position="insideBottom" offset={30} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
