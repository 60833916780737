import { TableHeader } from '../../../../components/Table/Table';

export const headers: TableHeader[] = [
  {
    title: 'TAG',
    icon: undefined,
  },
  {
    title: 'Colaborador',
    icon: undefined,
  },
  {
    title: 'Função',
    icon: undefined,
  },
  {
    title: 'Empresa',
    icon: undefined,
  },
  {
    title: 'Data de vinculação',
    icon: undefined,
  },
  {
    title: 'Excluir',
    icon: undefined,
  }
];