
import styled from 'styled-components';

interface ModalButtonProps {
    color: string;
}

export const ModalDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.4rem 4rem;
  text-align: center;
  font-family: work sans;
`;

export const ContentRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 2rem;
  margin: 1rem 0;
`;

export const ContentButtonRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 2rem;
  margin: 1rem 0;
`;

export const ContentCell = styled.div`
  display: flex;
  gap: 0.4rem;
  min-width: 30rem;
  width: 100%;
  & div {
    display: flex;
    flex-direction: column;
    text-align: left;
    & span {
      margin-top: 0;
      white-space: nowrap;
    }
  }
`;

export const ModalButton = styled.button<ModalButtonProps>`
  all: unset;
  font-family: work sans;
  font-weight: 500;
  color: ${({ color }) => color};
  border: ${({ color }) => `2px solid ${color}`};
  font-size: 1.4rem;
  padding: 0.8rem 1.4rem;
  border-radius: 0.8rem;
  cursor: pointer;
`;

export const ModalTitle = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 3rem;
  gap: 1.6rem;
  & span {
    font-family: work sans;
    font-weight: 600;
    color: #0e1742;
    font-size: 1.6rem;
  }
`;