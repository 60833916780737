import { TableHeader } from '../../../../components/Table/Table';

export const headers: TableHeader[] = [
  {
    title: 'Colaborador',
    icon: undefined,
  },
  {
    title: 'Local',
    icon: undefined,
  },
  {
    title: 'Horário da Emergência ',
    icon: undefined,
  },
  {
    title: 'Motivo do Acionamento',
    icon: undefined,
  },
  {
    title: '',
    icon: undefined,
  },
];