import React, { ReactElement } from 'react';
import DynamicComponentsFactory from '../../components/DynamicComponents/DynamicComponentsFactory';
import { AverageTimeContextProvider } from './charts/AverageTimeChart/context/AverageTimeContext';
import AverageTimeChart from './charts/AverageTimeChart';
import { RankContextProvider } from './charts/RankChart/context/RankContext';
import RankChart from './charts/RankChart';
import { PeopleInAreaContextProvider } from './charts/PeopleInAreasChart/context/PeopleInAreasContext';
import PeopleInAreasChart from './charts/PeopleInAreasChart';
import PresenceAreaChart from './charts/PresenceAreaChart';

import * as S from './styles';

type ProductivityComponentsFactoryProps = {
    charts : string[] | undefined
};

export const ProductivityComponentsFactory: React.FC<ProductivityComponentsFactoryProps> = ({charts}: 
    ProductivityComponentsFactoryProps) => {
        const children: ReactElement[] = [];
        const factoryElements: ReactElement[] = [];

        const inOutChart = 
            <DynamicComponentsFactory charts={charts} component='inOutChart' key='dynamicInOutChart'/>;

        if(charts && charts.findIndex((chart:string) => chart == 'inOutChart') >= 0)
            children.push(inOutChart);

        const workingPermitChart = 
            <DynamicComponentsFactory charts={charts} component='workingPermitChart' key='dynamicWorkingPermitChart'/>;

            if(charts && charts.findIndex((chart:string) => chart == 'workingPermitChart') >= 0)
            children.push(workingPermitChart);

        children.push(
            <AverageTimeContextProvider key={'AverageTimeContextProvider'}>
                <AverageTimeChart key={'AverageTimeChart'}/>
          </AverageTimeContextProvider>
        );

        children.push(
            <RankContextProvider key={'RankContextProvider'}>
                <RankChart key={'RankChart'}/>
          </RankContextProvider>
        );

        children.push(
            <PeopleInAreaContextProvider key={'PeopleInAreaContextProvider'}>
                <PeopleInAreasChart key={'PeopleInAreasChart'}/>
            </PeopleInAreaContextProvider>
        );

        children.push(<PresenceAreaChart key={'PresenceAreaChart'}/>);
    
    if(children.length % 2 != 0){
        factoryElements.push( 
            <S.ChartSingleRow key={'ChartSingleRow'}>
                <S.Card key={'ChartSingleRowCard'}>{children[0]}</S.Card>
            </S.ChartSingleRow>
        );
    
        children.splice(0, 1);
    }


    for(let i = 0; i <= children.length - 2; i = i + 2){
        factoryElements.push( 
            <S.ChartRow key={'ChartRow' + i}>
                <S.Card key={'Card' + i}>{children[i]}</S.Card>
                <S.Card key={'Card' + (i+1)}>{children[i + 1]}</S.Card>
            </S.ChartRow>
        );
    }

    return <React.Fragment>{factoryElements}</React.Fragment>;
}