import { useEffect, useRef, useState } from 'react';
import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { getEmergencyNotifications } from '../../../services/api/TrackfyService';
import { useAuth, useIsMounted } from '../../../hooks';

export const useEmergencyNotifications = () => {
    const mountedRef = useIsMounted();
    const notificationsCache = useRef<any[]>([]);
    const notificationsUpdates = useRef(0);
    const { getClientId } = useAuth();
    const clientId = getClientId();
    const [notifications, setNotifications] = useState<any[]>([]);

    const requestEmergencyNotifications = (token: CancelToken) => {
        getEmergencyNotifications(clientId, token)
        .then((data:any[])=> {
          if ((JSON.stringify(notificationsCache.current[0]) != JSON.stringify(data[0]))
                        && (notificationsUpdates.current == 0 
                        || notificationsUpdates.current > 3)) {
            notificationsCache.current = data;
            setNotifications(notificationsCache.current);
            notificationsUpdates.current = 0;
          } else {
            notificationsUpdates.current = notificationsUpdates.current + 1;
          }
        })
        .catch((err) => {
          console.log(err);
        })
    }
  
    useEffect(() => {
        mountedRef.current = true;
        let interval: NodeJS.Timeout | undefined = undefined;
        const currentRequest: CancelTokenSource = Axios.CancelToken.source();

        if(mountedRef.current){
          requestEmergencyNotifications(currentRequest.token);

          const fetchData = () => requestEmergencyNotifications(currentRequest.token);
          interval = setInterval(fetchData, 20000);
        }

        return () => {
          if (interval) 
            clearInterval(interval);

          mountedRef.current = false;
          currentRequest.cancel('request canceled by the user.');
        };
    }, []);
    

    return { notifications, notificationsUpdates };
}