import React, { createContext, ReactNode, useState, useContext } from 'react';

import { PaginationControls } from '../../../../../components/Buttons/PaginationControls';
import { AverageTimeByPeople } from '../../../../indicators/types/Indicators';
import { SelectOption } from '../../../../../components/Select/types';

type ContextValue = {
  averageTimeChartData: AverageTimeByPeople[];
  areaSelected: SelectOption[];
  collaboratorsSelctValue: number;
  isLoading: boolean;
  averageValues: Array<AverageTimeByPeople[]>;
  setAverageTimeChartData: (value: AverageTimeByPeople[]) => void;
  setAreaSelected: (area: SelectOption[]) => void;
  setCollaboratorsSelctValue: (collaborator: number) => void;
  setAverageValues: (arrayAvarageValues: Array<AverageTimeByPeople[]>) => void;
  setIsLoading: (value: boolean) => void;
};

const AverageTimeContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function AverageTimeContextProvider(props: Props) {
  const { children } = props;
  const [areaSelected, setAreaSelected] = useState<SelectOption[]>([]);
  const [collaboratorsSelctValue, setCollaboratorsSelctValue] = useState<number>(0);
  const [averageValues, setAverageValues] = useState<Array<AverageTimeByPeople[]>>([]);
  const [averageTimeChartData, setAverageTimeChartData] = useState<AverageTimeByPeople[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const value = {
    averageTimeChartData,
    areaSelected,
    collaboratorsSelctValue,
    averageValues,
    isLoading,
    setAverageTimeChartData,
    setAreaSelected,
    setCollaboratorsSelctValue,
    setAverageValues,
    setIsLoading,
  };

  return (
    <AverageTimeContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={averageValues}
        setNewValue={setAverageTimeChartData}
        title="Tempo médio por recurso"
      />
    </AverageTimeContext.Provider>
  );
}

export function useAverageTimeContext() {
  const context = useContext(AverageTimeContext);

  if (typeof context === 'undefined') {
    throw new Error('AverageTimeContext must be used within an AverageTimeContext');
  }

  return context;
}
