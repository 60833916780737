import React from 'react';

import { XlsxExportButton } from '../../../components/Buttons/XlsxExportButton';
import { useCalendar } from './hooks/useCalendar';
import { useFullCalendarContext } from './context/FullCalendarContext';

export const FullCalendarExportButton: React.FC = () => {
    const { exportDataToXlsx } = useCalendar();
    const { events } = useFullCalendarContext();
    return <XlsxExportButton data={events} 
                             exportData={exportDataToXlsx} 
                             marginLeft='90%' 
                             marginBottom='5px' 
                             fileName='Movimentações'/>
};