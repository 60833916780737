import React from 'react';

import { useAuthContext } from '../../../../stores';
import DynamicComponentsFactory from '../../../../components/DynamicComponents/DynamicComponentsFactory';
import IconUsers from '../../../../Icons/Users';

import * as S from '../styles';

export const AreasTimeDivisionChartHeader: React.FC = () => {
  const { authData } = useAuthContext();
  const charts = authData ? authData.components.charts : [];
  
  return (
    <S.Header>
      <S.Titlecontainer>
        <S.TitleContent>
            <IconUsers />
            <S.Title>Divisão de tempo</S.Title>
          </S.TitleContent>
          <DynamicComponentsFactory charts={charts} component='areasTimeDivisionExport'/>
      </S.Titlecontainer>
    </S.Header>
  );
};
