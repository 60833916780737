import { QuestionsAndAnswers, UseSubjectProps } from "../types/Faq";

export const useSubject = (props: UseSubjectProps) => {
    const {searchQuestion, subjectFilter} = props;
    const keys = ['question', 'answer'];

    const search = (data: QuestionsAndAnswers[]): QuestionsAndAnswers[] => {
        return data.filter(
          (item: any) => keys.some(keys => item[keys].toLowerCase().includes(searchQuestion.toLowerCase()))
        )
    }
      
    const getFilters = (filters: string[]): boolean => {
        let filterCondition = false;
        filters.forEach((filter) => filterCondition = filterCondition || subjectFilter.includes(filter));
        return filterCondition;
    }

    return {
        search,
        getFilters
    }
}