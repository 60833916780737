import React from 'react';

const IconOutlineWhite: React.FC = () => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.14405 1H38.2825C40.5736 1 42.4266 2.85195 42.4266 5.13116V43.8535C42.4266 46.1327 40.5736 47.9846 38.2825 47.9846H9.14405C6.85299 47.9846 5 46.1327 5 43.8535V5.13116C5 2.85195 6.85299 1 9.14405 1Z"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M38.2828 11.3527C38.2828 12.5573 37.9247 13.7349 37.2537 14.7365C36.5828 15.7381 35.6292 16.5188 34.5135 16.9798C33.3977 17.4407 32.17 17.5614 30.9856 17.3263C29.8011 17.0913 28.7131 16.5113 27.8592 15.6595C27.0053 14.8077 26.4237 13.7224 26.1881 12.5409C25.9525 11.3595 26.0734 10.1348 26.5356 9.02189C26.9977 7.90896 27.7804 6.95773 28.7845 6.28847C29.7886 5.61922 30.9691 5.26201 32.1768 5.26201C32.9791 5.26032 33.7739 5.4167 34.5155 5.72219C35.257 6.02768 35.9308 6.47625 36.4982 7.04216C37.0655 7.60807 37.5152 8.28017 37.8214 9.01989C38.1277 9.75961 38.2845 10.5524 38.2828 11.3527Z"
      fill="#FBBC00"
    />
    <path
      d="M35.047 22.7593H28.6453C27.2248 22.7593 26.0732 23.9079 26.0732 25.3248V29.8043C26.0732 31.2213 27.2248 32.3699 28.6453 32.3699H35.047C36.4675 32.3699 37.6191 31.2213 37.6191 29.8043V25.3248C37.6191 23.9079 36.4675 22.7593 35.047 22.7593Z"
      fill="white"
    />
    <path
      d="M18.2028 5.26199H11.801C10.3805 5.26199 9.229 6.41064 9.229 7.82757V41.2879C9.229 42.7048 10.3805 43.8534 11.801 43.8534H18.2028C19.6233 43.8534 20.7748 42.7048 20.7748 41.2879V7.82757C20.7748 6.41064 19.6233 5.26199 18.2028 5.26199Z"
      fill="#00E0B4"
    />
  </svg>
);

export default IconOutlineWhite;
