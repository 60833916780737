import React from 'react';
import { XlsxExportButton } from '../../../../components/Buttons/XlsxExportButton';

import { useAreasTimeDivisionContext } from '../context/AreasTimeDivisionContext';
import { useAreasTimeDivision } from '../hooks/UseAreasTimeDivision';

export const AreasTimeDivisionExportButton: React.FC = () => {
    const { areasTimeDivisionValues } = useAreasTimeDivisionContext();
    const { exportDataToXlsx } = useAreasTimeDivision();
    return <XlsxExportButton data={areasTimeDivisionValues} exportData={exportDataToXlsx} fileName='Divisão_de_tempo'/>
};