import React from 'react';

import * as S from '../styles';
import IconUsers from '../../../../Icons/Users';

export const DisplacementChartHeader: React.FC = () => {

  return (
    <S.Header>
      <S.Titlecontainer>
        <IconUsers />
        <S.Title>Deslocamento</S.Title>
      </S.Titlecontainer>
    </S.Header>
  );
};
