import styled from 'styled-components';

export const CardContent = styled.div`
  max-height: 100%;
  height: 100%;
  padding-bottom: 4.5rem;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    max-height: 515px;
  }

  & svg {
    border-radius: 10px;

    & text {
      font-family: Work Sans, sans-serif !important;
      font-weight: 600;
      fill: #fff !important;
    }
  }
`;

export const TooltipContainer = styled.div`
  background-color: #ffffff80;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 2px rgb(85 85 85 / 8%);
  padding: 0.3rem;
`;

export const TooltipTitle = styled.h5`
  color: #4f5882;
  font: bold 500 1.2rem/1.5 Work Sans, sans-serif;
`;
