import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import moment from 'moment';

import { getDisplacementsAtoB } from '../../../../services/api/TrackfyService';
import { useMovimentContext } from '../../context/MovimentContext';
import { useDisplacementBtwnAreasContext } from '../context/DisplacementBtwnAreasContext';
import { parseSelectOptions } from '../../../../utils/Convert';
import { TooltipContent } from '../../../../components/Info/Tooltip';
import { DisplacementBtwnAreasMarker } from '../components/DisplacementBtwnAreasMarker';

type displacementIndicator = {
  loading: boolean;
  value: string;
};

export const useDisplacementBtwnAreas = () => {
  const [indicator, setIndicator] = useState<displacementIndicator>({ loading: true, value: 'Carregando...' });
  const { companySelected,professionSelected,collaboratorSelected,initialDate,finalDate } = useMovimentContext();
  const { initialAreaSelected, finalAreaSelected, areasMarkers } = useDisplacementBtwnAreasContext();
  const user = localStorage.getItem('@trackfy_user_id');
  const userId = user ? Number.parseInt(user) : 1;

  const getDisplacementAtoBTooltipContent = (clientId: number): TooltipContent[] => {
    return [
            {
              content: 'É um indicador que mostra a média de tempo em que um colaborador demora para se deslocar de uma área para outra',
            },
        ];
  };

  const getMarkers = ():React.JSX.Element[] => {
    return [
      <DisplacementBtwnAreasMarker key={`initialAreaMarker`}
                                   lat={areasMarkers.initialAreaOnMap.lat} 
                                   lng={areasMarkers.initialAreaOnMap.lng} 
                                   icon={areasMarkers.initialAreaOnMap.icon}
                                   contentName={areasMarkers.initialAreaOnMap.contentName}
                                   coordinates={areasMarkers.initialAreaOnMap.coordinates}
                                   color={areasMarkers.initialAreaOnMap.color}/>,
      <DisplacementBtwnAreasMarker key={`finalAreaMarker`}
                                   lat={areasMarkers.finalAreaOnMap.lat} 
                                   lng={areasMarkers.finalAreaOnMap.lng} 
                                   icon={areasMarkers.finalAreaOnMap.icon}
                                   contentName={areasMarkers.finalAreaOnMap.contentName}
                                   coordinates={areasMarkers.finalAreaOnMap.coordinates}
                                   color={areasMarkers.finalAreaOnMap.color}/>
    ]
  }

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if (mounted) {
      setIndicator({ loading: true, value: 'Carregando...' });

      getDisplacementsAtoB(
        parseSelectOptions(companySelected),
        parseSelectOptions(professionSelected),
        parseSelectOptions(collaboratorSelected),
        moment(initialDate).startOf('day').valueOf(),
        moment(finalDate).valueOf(),
        initialAreaSelected,
        finalAreaSelected,
        currentRequest.token
      )
        .then((response: AxiosResponse<any>) => {
          let displacement = '00:00';

          if (response.data !== '') displacement = response.data;

          const indicatorText = (userId == 42 || userId == 64) ?
            (Math.random() * (15.0 - 0.01) + 0.01).toFixed(2) : displacement;

          setIndicator({ loading: false, value: indicatorText });
        })
        .catch((err: any) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('DisplacementBtwnAreas request canceled by the user.');
    };
  }, [companySelected, professionSelected,collaboratorSelected,initialAreaSelected,finalAreaSelected,initialDate,finalDate]);

  return { indicator, getDisplacementAtoBTooltipContent, getMarkers };
};