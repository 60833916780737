import React from 'react';
import { Popup } from 'react-map-gl';

import { HistoryHeader, HistoryContent } from './components';
import { useHistory } from './hooks/useHistory';

import './styles.css';

export const History: React.FC = () => {
  const {
    lng,
    lat,
    historic,
    initHistory,
    collaborator,
    loading,
    currentLocation,
    handleClickToClose,
    setPeriodHistory,
  } = useHistory();

  return (
    <>
      {initHistory && (
        <Popup
          longitude={lng}
          latitude={lat}
          //onClose={handleClickToClose}
          //className="popup-container"
          //anchor="bottom"
        >
          <HistoryHeader
            title={collaborator}
            currentLocation={currentLocation}
            onClickToClose={handleClickToClose}
            closeIcon={false}
          />
          <HistoryContent
            historic={historic}
            onChangePeriodHistory={setPeriodHistory}
            loading={loading}
          />
        </Popup>
      )}
    </>
  );
};
