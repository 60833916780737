import styled from 'styled-components';
import { EvacuationSnapshot } from '../../types/Emergency';

export interface EvacuationButtonProps extends Pick<EvacuationSnapshot, 'mode'> {
  active: boolean;
  isMobile: boolean;
}

export interface ModalCardProps {
  success: boolean;
}

export interface EvacuationCardItemProps {
  color: string;
}

export const EvacuationButton = styled.button<EvacuationButtonProps>`
  position: absolute;
  width: fit-content;
  top: 2.5rem;
  right: ${({ isMobile }) => (isMobile ? '.5rem' : '4rem')};
  background-color: ${({ active, mode }) => (mode === 'evacuating' ? '#FFFFFF' : active ? '#F69C9C' : '#ed3a3a')};
  color: ${({ mode }) => (mode === 'evacuating' ? '#ed3a3a' : '#ffffff')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ isMobile }) => (isMobile ? '1.2rem 2rem' : '1.2rem 2rem')};
  border-radius: 0.8rem;
  gap: 1.4rem;
  cursor: ${({ active, mode }) => (mode === 'evacuating' ? 'pointer' : active ? 'not-allowed' : 'pointer')};
  font: normal 500 1.2rem/1.5 Work Sans, sans-serif;
  font-size: 2rem;
  transition: all 0.3s ease-in-out;
  border: ${({ mode }) => (mode === 'evacuating' ? '2px solid #ed3a3a' : '')};
`;

export const EvacuationSelectAreas = styled.div<EvacuationButtonProps>`
  background-color: #fff;
  position: absolute;
  width: fit-content;
  top: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
  color: #ed3a3a;
  opacity: ${({ active }) => (active ? '100%' : '0%')};
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  transition: all 0.3s ease-in-out;
  padding: 0.8rem 1.2rem;
  font: normal 500 1.2rem/1.5 Work Sans, sans-serif;
  font-size: 2rem;
  border-radius: 8px;
`;

export const IconBorder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  border: 1px solid #ed3a3a;
  border-radius: 8px;
`;

export const EvacuationCancelButton = styled.button<EvacuationButtonProps>`
  all: unset;
  cursor: pointer;
  position: absolute;
  width: fit-content;
  top: 10rem;
  right: 4rem;
  background-color: #fff;
  color: #ed3a3a;
  border: 2px solid #ed3a3a;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.2rem;
  opacity: ${({ active }) => (active ? '100%' : '0%')};
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  transition: all 0.3s ease-in-out;
  font: normal 500 1.2rem/1.5 Work Sans, sans-serif;
  font-size: 2rem;
`;

export const H1ModalCard = styled.h1<ModalCardProps>`
  color: ${({ success }) => (success ? '#33B469' : '#ED3A3A')};
  font: normal 600 1.8rem/1.5 Work Sans, sans-serif;
`;

export const TextModalCard = styled.p`
  color: #666666;
  max-width: 48rem;
  font: normal 400 1.3rem/1.5 Work Sans, sans-serif;
`;

export const ModalFooterButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  align-items: center;
`;

export const ModalCancelButton = styled.button<ModalCardProps>`
  background-color: ${({ success }) => (success ? 'transparent' : '#ED3A3A')};
  border: ${({ success }) => (success ? '2px solid #33b469' : 'none')};
  border-radius: 8px;
  color: ${({ success }) => (success ? '#33b469' : '#ffffff')};
  padding: ${({ success }) => (success ? '0.5rem 1.4rem' : '0.7rem 1.4rem')};
  box-sizing: border-box;
  cursor: pointer;
  font: normal 500 1.5rem/1.5 Work Sans, sans-serif;
`;

export const ModalConfirmButton = styled.button<ModalCardProps>`
  background-color: ${({ success }) => (success ? '#33b469' : 'transparent')};
  border: ${({ success }) => (success ? 'none' : '2px solid #ed3a3a')};
  border-radius: 8px;
  color: ${({ success }) => (success ? '#ffffff' : '#ed3a3a')};
  padding: ${({ success }) => (success ? '0.7rem 1.4rem' : '0.5rem 1.4rem')};
  box-sizing: border-box;
  cursor: pointer;
  font: normal 500 1.6rem/1.5 Work Sans, sans-serif;
`;

export const FixedEvacuationCard = styled.div`
  position: absolute;
  background-color: #ffffff;
  top: 0;
  left: 0;
  padding: 1.2rem;
  z-index: 9999;
  gap: 0.8rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;

    &::-webkit-scrollbar {
        width: 10px;
        height: 8px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f3f3f3;
        //background: #ffffff;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #cccccc;
        border-radius: 10px;
    }
`;

export const EvacuationCardClock = styled.div`
  width: 100%;
  padding: 0.8rem 6rem;
  display: flex;
  justify-content: center;
  border: 2px solid #b3b3b3;
  color: #666666;
  font: normal 500 1.6rem/1.5 Work Sans, sans-serif;
  border-radius: 8px;
  margin-bottom: 0.8rem;
`;

export const EvacuationCardItem = styled.div<EvacuationCardItemProps>`
  width: 100%;
  max-width: 25rem;
  border: ${({ color }) => `2px solid ${color}`};
  padding: 1.2rem 1.6rem;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  font: normal 500 1.6rem/1.5 Work Sans, sans-serif;
  & .topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${({ color }) => `1px solid ${color}`};
    font-style: normal;
    color: #4c4c4c;
  }
  font-style: italic;
  color: #666666;
`;

export const SafeAreaCardItem = styled.div<EvacuationCardItemProps>`
  width: 100%;
  max-width: 25rem;
  border: ${({ color }) => `2px solid ${color}`};
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  font: normal 500 1.6rem/1.5 Work Sans, sans-serif;
  & .topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${({ color }) => `1px solid ${color}`};
    font-style: normal;
    color: #4c4c4c;
  }
  font-style: italic;
  color: #666666;
`;
