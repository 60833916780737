import React, { useState } from 'react';
import { useFaQContext } from '../context/FaqContext';
import * as S from './styled'; 

type SubjectFilterProps = {
    children: string,
    filter: string
}

export const SubjectFilterItem: React.FC<SubjectFilterProps> = (props: SubjectFilterProps) => {
    const {children, filter} = props
    const {setSubjectFilter} = useFaQContext()
    const [background, setBackground] = useState<boolean>(false);

    const handleClickChangeBackground = (filter: string) => {
        setBackground((prevBackground) => !prevBackground );
        if(background == true){
            setSubjectFilter((prevFilters: string[]) => prevFilters.filter(existingFilter => existingFilter !== filter))
        } else {
            setSubjectFilter((prevFilters: string[]) => [...prevFilters, filter])
        }
    };

    return (
        <S.SubjectFilterItems onClick={() => handleClickChangeBackground(filter)} background={background}>
            {children}
        </S.SubjectFilterItems>
    );
};