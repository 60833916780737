import React, { createContext, ReactNode, useState, useContext } from 'react';

import { PaginationControls } from '../../../../../../components/Buttons/PaginationControls';
import { Economia } from '../../../../types/Insights';

type ContextValue = {
  economiaChartData: Economia[];
  isLoading: boolean;
  economiaValues: Array<Economia[]>;
  setEconomiaChartData: (value: Economia[]) => void;
  setEconomiaValues: (arrayAvarageValues: Array<Economia[]>) => void;
  setIsLoading: (value: boolean) => void;
};

const EconomiaContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function EconomiaContextProvider(props: Props) {
  const { children } = props;
  const [economiaValues, setEconomiaValues] = useState<Array<Economia[]>>([]);
  const [economiaChartData, setEconomiaChartData] = useState<Economia[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const value = {
    economiaChartData,
    economiaValues,
    isLoading,
    setEconomiaChartData,
    setEconomiaValues,
    setIsLoading,
  };

  return (
    <EconomiaContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={economiaValues}
        setNewValue={setEconomiaChartData}
        title="Economia"
      />
    </EconomiaContext.Provider>
  );
}

export function useEconomiaContext() {
  const context = useContext(EconomiaContext);

  if (typeof context === 'undefined') {
    throw new Error('EconomiaContext must be used within an EconomiaContext');
  }

  return context;
}
