import React from 'react';

import * as S from './styles'

export type TooltipTimelineProps = {
  visible: boolean;
  content: TooltipContent[];
  position: string;
  burndown: boolean;
};

export type TooltipContent = {
  title?: string;
  content: string;
  color?: string;
}

export const InfoTooltip: React.FC<TooltipTimelineProps> = (props: TooltipTimelineProps) => {
  const { visible, content, position, burndown } = props;

  return (
    <S.TooltipContainer visible={visible} position={position} burndown={burndown}>
        {content.map((item, index) => (
          <S.Topic key={index + "_" +item.title}>
            {item.color ? <S.Square color={item.color}/> : ''}
            <S.TopicContent>
              <S.TopicTitle>{item.title}</S.TopicTitle>
              <span>{item.content}</span>
            </S.TopicContent>
          </S.Topic>
        ))}
    </S.TooltipContainer>
  );
};