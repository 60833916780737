import styled from 'styled-components';
import { DeleteIcon } from '../../../../Icons/Delete';
import { sg } from '../../../../styles/style-guide';

export const IncidentsListContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 37px 40px 30px 47px;
  display: flex;
  flex-direction: column;
  gap: 3.1rem;
  background-color: ${sg.colors.white};
  box-shadow: 0px 2px 8px 2px rgba(85, 85, 85, 0.08);
  border-radius: 10px;
  overflow: hidden;
`;

export const IncidentsListHeader = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
`;

export const IncidentsListHeaderTitleContainer = styled.div`
  display: flex;
  gap: 1.6rem;
  
  & > svg{
    margin-top: 0.3rem;
  }
`;

export const IconContainer = styled.div`
  margin-top: 0.3rem;
`;

export const IncidentsListHeaderLabel = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.3rem;
  color: ${sg.colors.dark}
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 41px;
`;

export const Delete = styled(DeleteIcon)`
  fill: #0823ad !important;
  height: 24px;
  width: 24px;
`;

export const SolveActionsContainer = styled.div`
  cursor: pointer;
  display: inline-flex;
  //box-shadow: 0 0 2px -1px rgb(0, 0, 0, 0.8);
  vertical-align: middle;
  max-width: 100px;
  padding: 4px;
  border-radius: 5px;
  text-align: center;
`;

export const DetailsActionsContainer = styled.div`
  cursor: pointer;
  display: inline-flex;
  //box-shadow: 0 0 2px -1px rgb(0, 0, 0, 0.8);
  vertical-align: middle;
  max-width: 100px;
  padding: 4px;
  border-radius: 5px;
  text-align: center;
`;

export const Solve = styled.div`
  background-color: #2F4CDD;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center; 
  gap: 8px; 
  border-radius: 8px;
  width: 95px;
  height: 32px;

  color: white;
  font-size: 14px;
  font-family: Work Sans;
  font-weight: 500;
  line-height: 16px;
`

export const Details = styled.div`
border: solid 2px #2F4CDD;
display: flex;
padding: 8px 16px;
justify-content: center;
align-items: center; 
gap: 8px; 
border-radius: 8px;
width: 95px;
height: 32px;

color: #2F4CDD;
font-size: 14px;
font-family: Work Sans;
font-weight: 500;
line-height: 16px;
`