import styled from 'styled-components';
import { Card } from '../../styles';

export const Container = styled(Card)`
`;
export const TimeDataSection = styled.div`
 display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 100%;
  flex-wrap: wrap;
  @media (max-width: 1000px){
    justify-content: center;
  }
  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Work Sans;
    padding: 0 2rem;
    & span {
      color: #262424;
      font-weight: 500;
      font-size: 1rem;
    }
    & h1 {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 2.6rem;
    }
    & p {
      color: #999999;
      font-size: 1.4rem;
      font-weight: 400;
    }
  }
`;
