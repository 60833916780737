import React from "react";

import { useEvacuationContext } from "../../context/EvacuationContext";
import { useEmergencyNotifications } from "../../hooks/useEmergencyNotifications";
import IconFall from "../../../../Icons/Fall";
import IconAlert from "../../../../Icons/Alert";

import * as S from './styles';

export const NotificationBoard: React.FC = () => {
    const { data } = useEvacuationContext();
    const { notifications, notificationsUpdates } = useEmergencyNotifications();

    if(data.mode !== "notevacuating")
        return null;
    
    return (
        <S.Container>
            <S.TitleContainer>
                <S.Title>Alertas</S.Title>
            </S.TitleContainer>
            <S.ContentContainer>
            {notifications.filter((note: any) => note.event == "Botão de pânico disparado" )
             .map((notification:any, index:number)=> {
                const newNotification: boolean = index == 0 && notificationsUpdates.current < 3;
                return (
                    <S.Notification key={index + notification.timestamp + notification.area + notification.tag}
                        new={newNotification} panic={(notification.event == "Botão de pânico disparado")}>
                        <S.NotificationContentContainer>
                            <S.NotificationIcon>
                                { (notification.event == "Botão de pânico disparado") ? 
                                <IconAlert fill={"#fff"} height="30" width="30"/>
                                : <IconFall fill={"#fff"} /> }
                            </S.NotificationIcon>
                            <S.NotificationContent>
                                <S.NotificationTitle new={newNotification}>
                                    {notification.timestamp}    -    {notification.event}
                                </S.NotificationTitle>
                                <S.NotificationDetails>
                                    <S.NotificationDetailsContent>
                                        <S.NotificationLabel new={newNotification}>
                                            área:
                                        </S.NotificationLabel>
                                        <S.NotificationInfo new={newNotification}>
                                            {notification.area}
                                        </S.NotificationInfo>
                                    </S.NotificationDetailsContent>
                                    <S.NotificationDetailsContent>
                                        <S.NotificationLabel new={newNotification}>
                                            colaborador:
                                        </S.NotificationLabel>
                                        <S.NotificationInfo new={newNotification}>
                                            {notification.alias} ({notification.tag})
                                        </S.NotificationInfo>
                                    </S.NotificationDetailsContent>
                                </S.NotificationDetails>
                            </S.NotificationContent>
                        </S.NotificationContentContainer>
                    </S.Notification>
                )
            })}
            </S.ContentContainer>
        </S.Container>);
}