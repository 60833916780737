import React from 'react';

import { IconLoading } from '../../../../../components/IconLoading';

import { CardAvgcontent } from './styles';

import { VerticalBarChart } from './components/VerticalBarChart';
import { useVelocidadeMedia } from './hooks/UseVelocidadeMedia';
import { useVelocidadeMediaContext } from './context/VelocidadeMedia';
import { VelocidadeMediaChartHeader } from './components/VelocidadeMediaChartHeader';

const VelocidadeMediaChart: React.FC = () => {
  const { velocidadeMediaChartData } = useVelocidadeMediaContext();
  const { isLoading } = useVelocidadeMedia();

  return (
    <>
      <VelocidadeMediaChartHeader />
      <CardAvgcontent>
        {isLoading && <IconLoading />}
        {!isLoading && <VerticalBarChart velocidadeMediaChartData={velocidadeMediaChartData} />}
      </CardAvgcontent>
    </>
  );
};

export default VelocidadeMediaChart;
