import moment from 'moment';
import React, { createContext, ReactNode, useState, useContext } from 'react';
import { useAuthContext } from '../../../stores/AuthContext';
import { SelectOption } from '../../../components/Select/types';

type Props = {
  children: ReactNode;
};

type ProductivityContextProps = {
  companySelected: SelectOption[];
  professionSelected: SelectOption[];
  initialDate: any;
  finalDate: any;
  setCompanySelected: (company: SelectOption[]) => void;
  setProfessionSelected: (profession: SelectOption[]) => void;
  updatePeriod: (initial: any, final: any) => void;
};

const ProductivityContext = createContext<ProductivityContextProps>({} as ProductivityContextProps);

export function ProductivityContextProvider(props: Props) {
  const { children } = props;

  const {authData} = useAuthContext();
  const [initialDate, setInitialDate] = useState<any>(moment());
  const [finalDate, setFinalDate] = useState<any>(moment());
  const [professionSelected, setProfessionSelected] = useState<SelectOption[]>([]);
  const [companySelected, setCompanySelected] = useState<SelectOption[]>([]);
 
  /*const companyId = (authData && authData.grpCompanies != 'a' && (!authData.grpCompanies.includes(","))) ?
    authData.companyId : 0;
  const [companySelected, setCompanySelected] = useState<number>(companyId);*/

  const updatePeriod = (initial: any, final: any) => {
    setInitialDate(initial);
    setFinalDate(final);
  };

  //const professionId = 0;

  const value = {
    companySelected,
    professionSelected,
    initialDate,
    finalDate,
    setCompanySelected,
    setProfessionSelected,
    updatePeriod,
  };
  return <ProductivityContext.Provider value={value}>{children}</ProductivityContext.Provider>;
}

export function useProductivityContext() {
  const context = useContext(ProductivityContext);

  if (typeof context === 'undefined') {
    throw new Error('ProductivityContext must be used within an useProductivityContext');
  }

  return context;
}
