import React from 'react';

const IconSearch: React.FC<{}> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2825 14.2175L12.5001 11.4575C13.5801 10.1108 14.1032 8.40146 13.9617 6.68096C13.8201 4.96047 13.0248 3.35958 11.7392 2.20747C10.4536 1.05537 8.77541 0.439622 7.04974 0.486842C5.32408 0.534062 3.68209 1.24066 2.46141 2.46135C1.24072 3.68203 0.534124 5.32402 0.486903 7.04968C0.439683 8.77534 1.05543 10.4535 2.20753 11.7391C3.35964 13.0247 4.96053 13.8201 6.68103 13.9616C8.40152 14.1031 10.1109 13.5801 11.4576 12.5L14.2176 15.26C14.2873 15.3303 14.3702 15.3861 14.4616 15.4242C14.553 15.4622 14.651 15.4818 14.7501 15.4818C14.8491 15.4818 14.9471 15.4622 15.0385 15.4242C15.1299 15.3861 15.2128 15.3303 15.2825 15.26C15.4177 15.1201 15.4933 14.9332 15.4933 14.7387C15.4933 14.5442 15.4177 14.3573 15.2825 14.2175ZM7.25005 12.5C6.2117 12.5 5.19666 12.1921 4.33331 11.6152C3.46995 11.0383 2.79704 10.2184 2.39968 9.25908C2.00232 8.29977 1.89836 7.24417 2.10093 6.22577C2.3035 5.20737 2.80351 4.2719 3.53774 3.53768C4.27197 2.80345 5.20743 2.30344 6.22583 2.10087C7.24423 1.89829 8.29983 2.00226 9.25914 2.39962C10.2185 2.79698 11.0384 3.46989 11.6153 4.33325C12.1921 5.1966 12.5001 6.21164 12.5001 7.24999C12.5001 8.64238 11.9469 9.97773 10.9624 10.9623C9.97779 11.9469 8.64244 12.5 7.25005 12.5Z"
      fill="#2F4CDD"
    />
  </svg>
);

export default IconSearch;
