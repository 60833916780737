import React from 'react';

import { IconPath } from '../../../../Icons/Path';
import { Info } from '../../../../components/Info';
import { useAuth } from '../../../../hooks';
import { useDisplacementBtwnAreas } from '../hooks/useDisplacementBtwnAreas';

import * as S from '../styles';

export const DisplacementBtwnAreasHeader: React.FC = () => {
  const { getClientId } = useAuth();
  const clientId = getClientId();
  const { getDisplacementAtoBTooltipContent } = useDisplacementBtwnAreas();
  const tooltipContent = getDisplacementAtoBTooltipContent(clientId);

  return (
    <S.Header>
      <S.Titlecontainer>
        <IconPath />
        <S.Title>Deslocamento entre áreas</S.Title>
        <Info content={tooltipContent} position={'right'} burndown={false}/>
      </S.Titlecontainer>
    </S.Header>
  )
};
