import React from 'react';

const IconChartDown: React.FC<any> = () => (
  <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0002 7C19.735 7 19.4806 6.89464 19.2931 6.7071C19.1055 6.51957 19.0002 6.26521 19.0002 6V3.41L12.7102 9.71C12.6172 9.80373 12.5066 9.87812 12.3848 9.92889C12.2629 9.97966 12.1322 10.0058 12.0002 10.0058C11.8682 10.0058 11.7375 9.97966 11.6156 9.92889C11.4937 9.87812 11.3831 9.80373 11.2902 9.71L8.00019 6.41L2.71019 11.71C2.52188 11.8983 2.26649 12.0041 2.00019 12.0041C1.73388 12.0041 1.47849 11.8983 1.29019 11.71C1.10188 11.5217 0.996094 11.2663 0.996094 11C0.996094 10.7337 1.10188 10.4783 1.29019 10.29L7.29019 4.29C7.38315 4.19627 7.49375 4.12188 7.61561 4.07111C7.73747 4.02034 7.86817 3.9942 8.00019 3.9942C8.1322 3.9942 8.2629 4.02034 8.38476 4.07111C8.50662 4.12188 8.61722 4.19627 8.71019 4.29L12.0002 7.59L17.5902 2H15.0002C14.735 2 14.4806 1.89464 14.2931 1.70711C14.1055 1.51957 14.0002 1.26521 14.0002 0.999998C14.0002 0.734781 14.1055 0.480427 14.2931 0.292891C14.4806 0.105354 14.735 -1.90735e-06 15.0002 -1.90735e-06H20.0002C20.1309 0.00157833 20.26 0.0287571 20.3802 0.079998C20.6245 0.181473 20.8187 0.375649 20.9202 0.619999C20.9714 0.740221 20.9986 0.869321 21.0002 0.999998V6C21.0002 6.26521 20.8948 6.51957 20.7073 6.7071C20.5198 6.89464 20.2654 7 20.0002 7Z"
      fill="#86D655"
    />
  </svg>
);

export default IconChartDown;
