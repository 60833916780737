import styled from 'styled-components';

export interface FixedAraProps {
  color: string;
  top: string;
}

export interface IconCounterProps {
  bgColor: string;
}

export const MonitoredAreasContainer = styled.div`
  position: absolute;
  width: 20%;
  top: 18px;
  left: 40px;
`;

export const LoadingContainer = styled.div`
    width: '100%';
    height: '100vh';
    display: 'flex';
    justifyContent: 'center';
    alignItems: 'center';
`;

export const Badge = styled.div`
  /*position: absolute;*/
  /*width: 25px;
  height: 25px;
  left: 23px;
  top: 8px;*/
  width: 20px;
  height:20px;
  margin-left: 3px;
  margin-top: 3px;
  border-radius: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:rgba(27, 76, 221, 0.2);
  transform: rotate(-45deg);
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 59px;
  background: #f7f8fe;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 18px 20px 16px 21px;
`;
export const Title = styled.div`
  display: flex;
  height: auto;
  margin-bottom: 8px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  min-height: 100px;
  padding: 24px 2px 16px 21px;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
`;

export const ContentText = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

export const Pin = styled.div`
    position: relative;
    transform: rotate(45deg);
    width: 28px;
    height: 28px;
    border-radius: 60px 60px 0 60px;
    border: 1.5px solid #2F4CDD;
    background-color: white;
    justify-content: center;
    align-items:center;
  
`;

export const H1 = styled.h1`
  family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  
  margin-top: auto;
  color: #4f5882;
`

export const FixedAreaCard = styled.div<FixedAraProps>`
  position: absolute;
  width: 150px;
  height: 40px;
  top: ${(props) => (props.top)};
  left: 10px;
  background-color: #fff;
  
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #4f5882;
  text-align: center;
  line-height: 40px;

  border-color: ${(props) => (props.color)};
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
`;

export const CraneAreaCard= styled.div`
  position: fixed;
  width: 200px;
  height: 50px;
  top: 92%;
  left: 43%;
  background-color: #fff;
  border-radius: 5px;
`;

export const CraneAreaContent = styled.div`
  display: flex;
  width: 100%;
  heght: 100%;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #4f5882;
  text-align: center;
  line-height: 50px;
`;

export const StyledLegend = styled.div`
  color: #EAA800; 
  margin-right: 5px;
  margin-left: 10px
`;

export const EvacuationIconCounter = styled.div<IconCounterProps>`
  width: 3.2rem;
  border-radius: 50%;
  display: flex;
  aspect-ratio: 4/4;
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor }) => (bgColor.startsWith('#') ? bgColor : `#${bgColor}`)};
  color: #ffffff;
  font: normal 600 1.6rem/1.5 Work Sans, sans-serif;
`;

