import React from 'react';
import styled from 'styled-components';
import { Shadow } from '../../components';
import { PageHeader } from '../../components/PageStructure/PageHeader';

import { Container, ContentContainer } from '../../components/PageStructure/styles';
import { DisplacementContextProvider } from './context/DisplacementContext';
import { DisplacementChart } from './Displacement';
import { DisplacementOptions } from './DisplacementOptions';
import { ProductivityVSDisplacementChart } from './ProductivityVSDisplacement';
import { ProductivityVSDisplacementContextProvider } from './ProductivityVSDisplacement/context/ProductivityVSDisplacementContext';

const ChartRow = styled.div`
  min-height: 40vh;
  height: 100%;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  justify-content: space-between;
  gap: 3.5rem;
  @media (max-width: 1200px) {
    &&& {
      grid-template-columns: 1fr;
    }
  }
`;

const Card = styled(Shadow)`
  width: 100%;
  height: 100%;
  min-height: 590px;
  padding: 2.4rem;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const PageDisplacement: React.FC<{}> = () => {

  return (
  <Container>
    <PageHeader title='Deslocamentos'/>
    <ContentContainer value="">
      <DisplacementContextProvider>
        <DisplacementOptions />
        <ChartRow>
          <Card>
            <ProductivityVSDisplacementContextProvider>
              <ProductivityVSDisplacementChart/>
            </ProductivityVSDisplacementContextProvider>
          </Card>
          <Card>
            <DisplacementChart/>
          </Card>
        </ChartRow>
      </DisplacementContextProvider>
    </ContentContainer>
  </Container>
);
}

export default PageDisplacement;