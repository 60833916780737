import React from 'react';

import { IconLoading } from '../../../../../components/IconLoading';

import { CardAvgcontent } from './styles';

import { VerticalBarChart } from './components/VerticalBarChart';

import { RoiChartHeader } from './components/RoiChartHeader';
import { useRoiContext } from './context/RoiContext';
import { useRoi } from './hooks/UseRoi';

const RoiChart: React.FC = () => {
  const { roiChartData } = useRoiContext();
  const { isLoading } = useRoi();

  return (
    <>
      <RoiChartHeader />
      <CardAvgcontent>
        {isLoading && <IconLoading />}
        {!isLoading && <VerticalBarChart roiChartData={roiChartData} />}
      </CardAvgcontent>
    </>
  );
};

export default RoiChart;
