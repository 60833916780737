import React from 'react';

import { useAuth } from '../../../../../hooks';
import { User, UserAvatar, UserDescription } from './styles';

type PropsTypes = {
  image?: any;
  collapsed: boolean;
};

export const SideBarUserProfile: React.FC<PropsTypes> = ({ image, collapsed }: PropsTypes) => {
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);
  const user: string = userName || 'Usuario';

  return (
    <User className={collapsed ? 'collapsed' : ''}>
      <UserAvatar
        src={
          image ??
          'https://img2.gratispng.com/20180625/req/kisspng-computer-icons-avatar-business-computer-software-user-avatar-5b3097fcae25c3.3909949015299112927133.jpg'
        }
      />
      <UserDescription title={user}>{user}</UserDescription>
    </User>
  );
};
