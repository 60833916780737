import React, { useEffect } from 'react';

import { useAuthContext } from '../../stores/AuthContext';
import CompanySelect from '../filters/CompanySelect';
import ProfessionSelect from '../filters/ProfessionSelect';
import { DateRangePicker } from '../../components';
import { mixpanelTrack } from '../../services/SystemMonitor';
import { getFollowingFriday } from '../../utils/DateTime';
import { useAuth } from '../../hooks/useAuth';

import { OptionsContainer } from '../../components/PageStructure/styles';
import * as S from '../../styles/structure';
import { useDisplacementContext } from './context/DisplacementContext';

export const DisplacementOptions: React.FC = () => {
  const {
    companySelected,
    setCompanySelected,
    professionSelected,
    setProfessionSelected,
    updatePeriod,
  } = useDisplacementContext();
  const { authData, monitorAgent } = useAuthContext();
  const compIds: string = authData ? authData.grpCompanies : 't';
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  useEffect(
    () => mixpanelTrack(monitorAgent, 'Indicadores - Produtividade', userName, authData),
    []
  );

  return (
    <OptionsContainer>
      {compIds != 'a' && !compIds.includes(',') ? (
        <></>
      ) : (
        <S.SimpleContainer>
          <CompanySelect
            label={undefined}
            companySelected={companySelected}
            setCompanySelected={setCompanySelected}
          />
        </S.SimpleContainer>
      )}
      <S.SimpleContainer>
        <ProfessionSelect
          label={undefined}
          professionSelected={professionSelected}
          setProfessionSelected={setProfessionSelected}
        />
      </S.SimpleContainer>
      <S.SimpleContainer>
        <DateRangePicker
          updatePeriod={updatePeriod}
          screen="Indicadores"
          maxDate={getFollowingFriday(new Date())}
          component="Produtividade -"
          selectRange
          showToday
          loadDate={new Date()}
        />
      </S.SimpleContainer>
    </OptionsContainer>
  );
};