import React from 'react';

import { IconAddVuesax } from '../../../../Icons/Add-Vuesax';
import { useFaQContext } from './context/FaqContext';

import * as S from './styles';

interface Props {}

export const Questions: React.FC<Props> = ({}) => {
  const { questions } = useFaQContext();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: questions.length <= 3 ? '15%' : '',
      }}
    >
      {questions.map((item, index) => (
        <S.QuestionItem to="/suporte" key={`${item.question}-${index}`}>
          <IconAddVuesax />
          {item.question}
        </S.QuestionItem>
      ))}
    </div>
  );
};
