import styled from 'styled-components';
import * as Global from '../../styles';

export const Container = styled(Global.Card)`
  max-height: 643px;
`;

export interface MonitoredAreasItemProps {
  active: boolean;
}

export const MonitoredDataSection = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Work Sans;
    & span {
      color: #262424;
      font-weight: 500;
      font-size: 1.6rem;
    }
    & h1 {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 4.8rem;
    }
  }
`;

interface MonitoredAreasSectionProps {
  isLoading?: boolean;
};

export const MonitoredAreasSection = styled.div`
  width: 100%;
  border-radius: 0.8rem;
  border: 1px solid #e6e6e6;
  display: flex;
  justify-content: center;
  overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 10px;
        height: 8px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f3f3f3;
        //background: #ffffff;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #cccccc;
        border-radius: 10px;
    }
`;

export const MonitoredAreasNames = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  border-top-left-radius: 0.8rem;
  align-items: center;
`;

export const MonitoredAreasHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
  padding: 1.6rem;
  border: 1px solid #e6e6e6;
  border-top-left-radius: 0.8rem;
  & p {
    margin: 0;
    color: #666666;
    font-size: 1.6rem;
    font-weight: 500;
    font-family: Work Sans;
  }
`;
export const MonitoredAreasItem = styled.div<MonitoredAreasItemProps>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-family: Work Sans;
  font-size: 1.6rem;
  border: ${({ active }) => (active ? 'none' : '1px solid #e6e6e6')};
  padding: 1.2rem 2.4rem;
  cursor: pointer;
  gap: 0.8rem;
  & span {
    width: 3.8rem;
    height: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4c4c4c;
    background-color: #eaedfc;
    border-radius: 50%;
  }
  & p {
    margin: 0;
    color: #666666;
    flex-grow: 1;
    text-align: left;
  }
`;

export const MonitoredAreasCollaborators = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  align-items: center;
  text-align: left;
  & > svg {
    align-self: flex-end;
  }
`;

export const MonitoredCollaborator = styled.div`
  display: flex;
  text-align: left;
  justify-content: flex-start;
  width: 100%;
  padding: 0.4rem 1.6rem;
  gap: 1.6rem;
  font-family: Work Sans;
  & span {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #2f4cdd;
    font-size: 1.6rem;
  }
  & p {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #666666;
    font-size: 1.4rem;
  }
`;

export const CollaboratorsIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
`;