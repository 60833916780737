import React from 'react';

export const IconFinance: React.FC = () => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <title/>
        <g id="cheque">
            <path d="M23,3H1A1,1,0,0,0,0,4V20a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V4A1,1,0,0,0,23,3ZM22,19H2V5H22Z"/>
            <path d="M7.5,14H5a1,1,0,0,0,0,2H6A1,1,0,1,0,8,16,2.5,2.5,0,0,0,7.5,11h-1a.5.5,0,0,1,0-1H9A1,1,0,0,0,9,8H8a1,1,0,1,0-2,.05A2.5,2.5,0,0,0,6.5,13h1A.5.5,0,0,1,7.5,14Z"/>
            <path d="M13,9h6a1,1,0,0,0,0-2H13A1,1,0,0,0,13,9Z"/>
            <path d="M13,13h6a1,1,0,0,0,0-2H13A1,1,0,0,0,13,13Z"/>
            <path d="M13,17h6a1,1,0,0,0,0-2H13A1,1,0,0,0,13,17Z"/>
        </g>
    </svg>
);