import React from 'react';

import { useAbsenteeism } from './hooks/useAbsenteeism';

import * as S from './styles';

export const Absenteeism: React.FC = () => {
  const { indicator } = useAbsenteeism();

  return (
    <S.Card>
      <S.CardHeader>Absenteísmo: </S.CardHeader>
      <S.CardContent>{indicator.value}</S.CardContent>
    </S.Card>
  );
};