import React from 'react';

import { useAreaTimelineContext } from './context/TimelineContext';
import { useTimeline } from './hooks/useTimeline';
import { XlsxExportButton } from '../../../components/Buttons/XlsxExportButton';

export const AreaTimelineExportButton: React.FC = () => {
  const { areaTimelineValues } = useAreaTimelineContext();
  const { exportDataToXlsx } = useTimeline();
  
  return <XlsxExportButton data={areaTimelineValues} exportData={exportDataToXlsx} marginLeft='20px' fileName='Timeline_Areas'/>
};
