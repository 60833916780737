import React from 'react';
import styled from 'styled-components';

import { TCollaboratorTimelineLine } from '../../indicators/types/Indicators';

const Point = styled.span`
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background: ${({ background }: TCollaboratorTimelineLine) => background || '#C4C4C4'};
`;

const Line = styled.span`
  background: ${({ background }: TCollaboratorTimelineLine) => background || '#C4C4C4'};
  width: 100%;
  height: 4px;
  margin: 0 2px;
  display: block;
`;

const Container = styled.div`
  position: relative;
  width: ${({ width = 12 }: TCollaboratorTimelineLine) =>
    `calc((100% - 13px) / ${width})` || '100px'};
  flex: 0 0
    ${({ width = 12 }: TCollaboratorTimelineLine) => `calc((100% - 13px )/ ${width})` || '100px'};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 100px;
    flex: 100px;
  }

  & span {
    font: normal 500 1.2rem/1.5 Work Sans, sans-serif;
    font-weight: bolder;
    color: #4f5882bf;
  }

  & div:first-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1000px) {
      transform: rotate(90deg);
    }
  }

  & div:last-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 9px;

    @media (max-width: 1000px) {
      width: 100%;
      position: absolute;
      margin-right: 15px;
      margin-top: -75px;
    }
  }
`;

const CollaboratorTimelineLine: React.FC<TCollaboratorTimelineLine> = ({
  last,
  background,
  date,
  start,
  width,
}: TCollaboratorTimelineLine) => (
  <Container width={width}>
    <div>
      <Point background={background} />
      {last !== undefined &&
        (last === false ? <Line background={background} /> : <Line background="#FFFFFF" />)}
    </div>
    <div>
      {date && <span>{date}</span>}
      {start && <span>{start}</span>}
    </div>
  </Container>
);

export default CollaboratorTimelineLine;
