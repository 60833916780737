import React from 'react';

type Props = {
    stroke: string;
};

export const IconLocationVuesax: React.FC<Props> = ({stroke}) => (
    <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 22.3834C22.8719 22.3834 25.2 20.0553 25.2 17.1834C25.2 14.3115 22.8719 11.9834 20 11.9834C17.1282 11.9834 14.8 14.3115 14.8 17.1834C14.8 20.0553 17.1282 22.3834 20 22.3834Z" stroke={stroke} strokeWidth="2"/>
        <path d="M6.03338 14.1502C9.31671 -0.283167 30.7 -0.2665 33.9667 14.1668C35.8834 22.6335 30.6167 29.8002 26 34.2335C22.65 37.4668 17.35 37.4668 13.9834 34.2335C9.38338 29.8002 4.11671 22.6168 6.03338 14.1502Z" stroke={stroke} strokeWidth="2"/>
    </svg>
);