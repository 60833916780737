import React, { createContext, ReactNode, useState, useContext } from 'react';

type RankContextProps = {
  rankSelctValue: number;
  rankSearchValue: string;
  higherProductivity: number;
  lowerProductivity: number;
  average: number;
  isLoadingAverage: boolean;
  setRankSelctValue: (area: number) => void;
  setRankSearchValue: (collaborator: string) => void;
  setHigherProductivity: (higherProductivity: number) => void;
  setLowerProductivity: (lowerProductivity: number) => void;
  setAverage: (average: number) => void;
  setIsLoadingAverage: (isLoadingAverage: boolean) => void;
};

const RankContext = createContext<RankContextProps>({} as RankContextProps);

type Props = {
  children: ReactNode;
};

export function RankContextProvider(props: Props) {
  const { children } = props;

  const [rankSelctValue, setRankSelctValue] = useState<number>(0);
  const [rankSearchValue, setRankSearchValue] = useState<string>('');
  const [higherProductivity, setHigherProductivity] = useState<number>(0);
  const [lowerProductivity, setLowerProductivity] = useState<number>(0);
  const [average, setAverage] = useState<number>(0);
  const [isLoadingAverage, setIsLoadingAverage] = useState<boolean>(false);

  const value = {
    rankSelctValue,
    rankSearchValue,
    setRankSelctValue,
    setRankSearchValue,
    higherProductivity,
    setHigherProductivity,
    lowerProductivity,
    setLowerProductivity,
    average,
    setAverage,
    isLoadingAverage,
    setIsLoadingAverage,
  };

  return <RankContext.Provider value={value}>{children}</RankContext.Provider>;
}

export function useRankContext() {
  const context = useContext(RankContext);

  if (typeof context === 'undefined') {
    throw new Error('RankContext must be used within an RankContext');
  }

  return context;
}

/* const getRankChartDataByName = async (cancelToken: CancelToken, projValue?: number) => {
    let projectValue = projValue;
    setIsLoading(true);
    setIsLoadingAverage(true);

    if (projectSelctValue && projectSelctValue !== 0) projectValue = projectSelctValue;

    let compValue: number = 0;
    if (companiesSelctValue) compValue = companiesSelctValue;

    let funcValue: number = 0;
    if (professionsSelctValue) funcValue = professionsSelctValue;

    let areaValue: number = 0;
    if (rankSelctValue) areaValue = rankSelctValue;

    const initDate: number = moment(initialDate).startOf('day').valueOf();
    const fimDate: number = moment(finalDate).valueOf();

    return fetchData(
      token,
      `${baseUrl}/statistics/rankByName`,
      {
        project: projectValue,
        company: compValue,
        profession: funcValue,
        area: areaValue,
        collaborator: rankSearchValue,
        initialDate: initDate,
        finalDate:fimDate,
      },
      cancelToken
    );
  }; */
