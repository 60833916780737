import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Sucess from "../../../Icons/Sucess";
import Error from "../../../Icons/Error";
import { useIsMounted } from "../../../hooks";
import { useAuthContext } from "../../../stores";

interface IMessages {
    message: string;
    icon: JSX.Element;
}

interface TypeMessages {
    [key: string]: IMessages;
}

interface Props {
    userName: string;
}

export const useNewPassord = ({userName}: Props) => {
    const [user, setUser] = useState(userName);
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    
    const history = useHistory();
    const { changePassAndSignIn } = useAuthContext();
    const isMounted = useIsMounted();
    
    useEffect(() => {
        let waitLogin: ReturnType<typeof setTimeout>;

        if (message === 'sucess') {
          const auth = localStorage.getItem('authData');
          let parsedAuth = {profileData: { mainPage: '/monitoramento'}};
          if (auth) parsedAuth = JSON.parse(auth);
          const mainPage:string = parsedAuth.profileData.mainPage
          waitLogin = setTimeout(() => history.push('/' + mainPage), 1200);
        }

        return () => clearTimeout(waitLogin);
      }, [history, message]);
    
      const handlePasswordChange = async (e: any) => {
        e.preventDefault();
    
        if (!user || !password) {
            setMessage('warning');
            return;
        }

        if (password.length < 8) {
            setMessage('passsize');
            return;
        }

        if (!/[A-Z]/.test(password)) {
            setMessage('hasCapitalLetter');
            return;
        }
      
        if (!/[a-z]/.test(password)) {
            setMessage('hasLetter');
            return;
        }
      
        if (!/[0-9]/.test(password)) {
            setMessage('hasNumber');
            return;
        }
    
        setLoading(true);
        setMessage('');
    
        try {
          await changePassAndSignIn({ user, password });
    
          if (!isMounted.current) return history.push('/');
    
          setMessage('sucess');
          setLoading(false);
    
          history.push('/');
        } catch (error) {
          if (!isMounted.current) return;
          setMessage('error');
        }
    
        if (!isMounted.current) return;
        setLoading(false);
      };
    
      const typeMessages: TypeMessages = {
        sucess: {
          message: 'Alteração realizada com sucesso!',
          icon: <Sucess />,
        },
        error: {
          message: 'Erro na alteração. Tente novamente!',
          icon: <Error />,
        },
        warning: {
          message: 'Informe a nova senha para continuar!',
          icon: <Error />,
        },
        passsize: {
            message: 'A senha deve ter pelo menos 8 caracteres!',
            icon: <Error />,
        },
        hasCapitalLetter: {
            message: 'A senha deve conter pelo menos uma letra maiúscula!',
            icon: <Error />,
        },
        hasLetter: {
            message: 'A senha deve conter pelo menos uma letra minúscula!',
            icon: <Error />,
        },
        hasNumber: {
            message: 'A senha deve conter pelo menos um número!',
            icon: <Error />,
        },
      };

      return {
        user,
        password,
        loading,
        message,
        typeMessages,
        handlePasswordChange,
        setUser,
        setPassword,
      };
}