import React from 'react';
import { IconLoading } from '../../../../components/IconLoading';
import { useLtvCacMargin } from './hooks/UseOnePage';

import * as S from './styles'

export const LtvCacMarginContainer: React.FC = () => {;
  const {isLoading, ltvCacMargin} = useLtvCacMargin()

  return (
    <S.ContentContainer>
       {isLoading && <IconLoading />}
       {!isLoading &&
        ltvCacMargin.map((item) => (
          <S.ItemContainer>
            <S.Title>{item.name}:</S.Title>
            <S.Value>{item.valueLabel}</S.Value>
          </S.ItemContainer>
        ))
       }
    </S.ContentContainer>
  );
};