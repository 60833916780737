import React from 'react';
import { DefaultIconProps } from './types';

export const IconPath: React.FC<DefaultIconProps> = ({ width, height, fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width={width || "20"} 
         height={height || "20"} 
         viewBox="0 0 20 20" 
         fill={fill || "none"} >
        <path d="M4.58329 7.49984C6.19412 7.49984 7.49996 6.194 7.49996 4.58317C7.49996 2.97234 6.19412 1.6665 4.58329 1.6665C2.97246 1.6665 1.66663 2.97234 1.66663 4.58317C1.66663 6.194 2.97246 7.49984 4.58329 7.49984Z" stroke="#666666" strokeWidth="1.5"/>
        <path d="M10 4.1665H12.2334C13.775 4.1665 14.4917 6.07484 13.3334 7.0915L6.67504 12.9165C5.5167 13.9248 6.23337 15.8332 7.7667 15.8332H10" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.57185 4.58317H4.58148" stroke="#666666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.5583 13.8334C13.225 10.9501 17.5917 10.9501 18.2583 13.8334C18.65 15.5251 17.575 16.9584 16.6333 17.8501C15.95 18.5001 14.8667 18.4917 14.1833 17.8501C13.2417 16.9584 12.1667 15.5251 12.5583 13.8334Z" stroke="#666666" strokeWidth="1.5"/>
        <path d="M15.4052 14.5832H15.4148" stroke="#666666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};