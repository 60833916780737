import React, { createContext, ReactNode, useState, useContext } from 'react';
import { PaginationControls } from '../../../../../components';

import { OnePage } from '../../../types/OnePage';

type ContextValue = {
  ndrChartData: OnePage[];
  isLoading: boolean;
  ndrValues: Array<OnePage[]>;
  setNdrChartData: (value: OnePage[]) => void;
  setNdrValues: (arrayAvarageValues: Array<OnePage[]>) => void;
  setIsLoading: (value: boolean) => void;
};

const NdrContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function NdrContextProvider(props: Props) {
  const { children } = props;
  const [ndrValues, setNdrValues] = useState<Array<OnePage[]>>([]);
  const [ndrChartData, setNdrChartData] = useState<OnePage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const value = {
    ndrChartData,
    ndrValues,
    isLoading,
    setNdrChartData,
    setNdrValues,
    setIsLoading,
  };

  return (
    <NdrContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={ndrValues}
        setNewValue={setNdrChartData}
        title="Otimizações"
      />
    </NdrContext.Provider>
  );
}

export function useNdrContext() {
  const context = useContext(NdrContext);

  if (typeof context === 'undefined') {
    throw new Error('ndrContext must be used within an ndrContext');
  }

  return context;
}
