import moment from 'moment';
import React, { createContext, ReactNode, useState, useContext } from 'react';
import { useAuthContext } from '../../../stores/AuthContext';

type Props = {
  children: ReactNode;
};

type LinkCollaboratorsContextProps = {
  companySelected: number;
  professionSelected: number;
  searchTag: string;
  initialDate: any;
  finalDate: any;
  setCompanySelected: (companie: number) => void;
  setProfessionSelected: (profession: number) => void;
  setSearchTag(value: string): void;
};

const LinkCollaboratorsContext = createContext<LinkCollaboratorsContextProps>(
  {} as LinkCollaboratorsContextProps
);

export function LinkCollaboratorsContextProvider(props: Props) {
  const { children } = props;

  const { authData } = useAuthContext();
  const [initialDate, setInitialDate] = useState<any>(moment());
  const [finalDate, setFinalDate] = useState<any>(moment());
  const [professionSelected, setProfessionSelected] = useState(0);
  const [searchTag, setSearchTag] = useState('');

  const companyId =
    authData && authData.grpCompanies != 'a' && !authData.grpCompanies.includes(',')
      ? authData.companyId
      : 0;

  const [companySelected, setCompanySelected] = useState<number>(companyId);

  const value = {
    companySelected,
    professionSelected,
    initialDate,
    finalDate,
    searchTag,
    setCompanySelected,
    setProfessionSelected,
    setSearchTag,
    setInitialDate,
    setFinalDate,
  };

  return (
    <LinkCollaboratorsContext.Provider value={value}>{children}</LinkCollaboratorsContext.Provider>
  );
}

export function useLinkCollaboratorsContext() {
  const context = useContext(LinkCollaboratorsContext);

  if (typeof context === 'undefined') {
    throw new Error('LinkCollaboratorsContext must be used within an useLinkCollaboratorsContext');
  }

  return context;
}
