import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal } from 'antd';

import { MenuPermission } from '../../../../../types';
import { useAuthContext, usePermissionsContext } from '../../../../../stores';
import { useIsMounted } from '../../../../../hooks';
import { useLayoutMenuContext } from '../../../context/LayoutMenuContext';
import { iconConfig } from '../../../../../config';
import { IconLoading } from '../../../../../components/IconLoading';
import IconLogOut from '../../../../../Icons/LogOut';

import * as S from './styles';

export type MenuItem = {
  key: string;
  description: string;
  icon: React.ReactElement;
  path: string;
};

export const SideBarMenu: React.FC = () => {
  const isMounted = useIsMounted();
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  const { setCollapsed } = useLayoutMenuContext();
  const { pathname } = useLocation();
  const history = useHistory();
  const { signOut } = useAuthContext();

  const { permissions, loadingPermissions } = usePermissionsContext();

  const { confirm } = Modal;
  
  useEffect(() => {
    isMounted.current = true;
    if (!permissions || loadingPermissions) return;

    if(isMounted.current)
      setMenuItems(
        permissions.menu.map(
          (menu: MenuPermission): MenuItem => ({
            key: menu.key,
            description: menu.description,
            path: menu.path,
            icon: iconConfig[menu.icon],
          })
        )
      );

    return () => { isMounted.current = false };
  }, [permissions, loadingPermissions, isMounted]);

  const initialMenu = pathname.replace('/', '') || 'monitoramento';

  const handleClick = (...args: any[]) => {
    const url: string = args[0]?.item?.props?.['data-url'] || '/';

    if (url === '/') return;

    if(isMounted.current){
      history.push(`${url}`);
      setCollapsed(true);
    }
  };

  const showLogoutConfirm = () => {
    confirm({
      title: 'Deseja sair do Trackfy?',
      // icon: <IconLogOut />,
      okText: 'Sair',
      okType: 'primary',
      cancelText: 'Não',
      bodyStyle: {
        font: 'normal 500 2rem/1.5 Work Sans, sans-serif',
        display: 'flex',
        justifyContent: 'center',
      },
      className: 'modal-logout',
      onOk: () => {
        signOut();
        setTimeout(() => isMounted.current && history.push('/'), 1000);
      },
    });
  };

  if (loadingPermissions) {
    return (
      <S.LoadingContainer>
        <IconLoading customStyle={{ fontSize: '35px' }} />
      </S.LoadingContainer>
    );
  }

  return (
    <S.Menu defaultSelectedKeys={[initialMenu]} mode="inline" onClick={handleClick}>
      {menuItems.map((item: MenuItem) => (
        <S.MenuItem key={item.key} icon={item.icon} to={item.path}>
          {item.description}
        </S.MenuItem>
      ))}

      <S.MenuItem key="sair" icon={<IconLogOut />} to="/" onClick={showLogoutConfirm}>
        Sair
      </S.MenuItem>

      {/*       <SubMenu key="infraestrutura" icon={<IconMap />} title="Infraestrutura">
        <MenuItem icon={<IconManager />} to="/relatorios">
          Opção 1
        </MenuItem>
        <MenuItem icon={<IconManager />} to="/relatorios">
          Opção 2
        </MenuItem>
      </SubMenu> */}

      {/*       <SubMenu key="cadastros" icon={<IconUsersMenu />} title="Cadastros">
        <MenuItem icon={<IconManager />} to="/relatorios">
          Opção 1
        </MenuItem>
        <MenuItem icon={<IconManager />} to="/relatorios">
          Opção 2
        </MenuItem>
      </SubMenu> */}

      {/*       <SubMenu key="gerenciamento" icon={<IconManager />} title="Gerenciamento">
        <MenuItem icon={<IconManager />} to="/relatorios">
          Opção 1
        </MenuItem>
        <MenuItem icon={<IconManager />} to="/relatorios">
          Opção 2
        </MenuItem>
      </SubMenu> */}
    </S.Menu>
  );
};
