import { TableHeader } from '../../../../../components/Table/Table';

export const headers: TableHeader[] = [
  {
    title: 'Cliente',
    icon: undefined,
  },
  {
    title: 'Tipo',
    icon: undefined,
  },
];
