import React from 'react';

import { useBuildingAreas } from './hooks/useBuildingAreas';
import { MonitoredAreasData } from '../../../../types/MonitoredData';
import { PerspectiveBuildingFloors } from './PerspectiveBuildingFloors';
import { Detective } from '../../../monitoring/components/MonitoringIndicators/detective';
import { AreasCardsContainer } from './AreasCards';
import { useAuthContext } from '../../../../stores';
import DynamicComponentsFactory from '../../../../components/DynamicComponents/DynamicComponentsFactory';

import * as S from './styles';

export const MonitoredBuilding: React.FC = () => {
  const { authData } = useAuthContext();
  const { floorsData } = useBuildingAreas();
  
  return (
    <React.Fragment>
      <div>
        <S.BuildingPageContainer>
          <S.PerspectiveBuildingContainer>
            <S.PerspectiveRoof />
            <S.PerspectiveFloorsContainer>
              {(floorsData.length > 0) &&
                floorsData[0].areas
                  .filter((area: MonitoredAreasData) => area.nome != 'ÁREA NÃO MAPEADA')
                  .sort((a: MonitoredAreasData, b: MonitoredAreasData) => a.ordem - b.ordem)
                  .reverse()
                  .map((area: any, idx: number) => {
                    return (
                    <PerspectiveBuildingFloors
                      key={'floor_' + idx + '_' + area.name}
                      area={area}
                      isHall={idx === floorsData[0].areas.length - 2}
                    />
                  )
                  })}
            </S.PerspectiveFloorsContainer>
          </S.PerspectiveBuildingContainer>
          <Detective isManager={true} isMap={false} />
        </S.BuildingPageContainer>
      </div>
      <S.AreasCards>
        <AreasCardsContainer floorsData={(floorsData.length >0) ? floorsData[0].areas : [] } />
      </S.AreasCards>
      <DynamicComponentsFactory charts={authData?.components.charts} component='summary'/>
    </React.Fragment>
  );
};
