import React from 'react';

import { IconLoading } from '../../../components/IconLoading';

export const SiteLoading: React.FC<{}> = () => (
  <div
    style={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <IconLoading />
  </div>
);
