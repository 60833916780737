import axios, { CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';

import { getDashboardInOutData } from '../../../../../services/api/TrackfyService';
import { useIsMounted } from '../../../../../hooks';
import { DashboardInOutData } from '../../../../../types/MonitoredData';

export interface InOutData {
  loading: boolean;
  data: DashboardInOutData;
}

export const useGetInOutValues = () => {
  const mountedRef = useIsMounted();
  const [dashboardData, setDashboardData] = useState<InOutData>({
    loading: true,
    data: {
      arrivalAndDepartureAverage: 'hh:mm/hh:mm',
      arrivalImproved: false,
      departureImproved: false,
      earliestDeparture: 'hh:mm',
      earliestDepartureDate: 'dd/MM/YYYY',
      latestArrival: 'hh:mm',
      latestArrivalDate: 'dd/MM/YYYY',
    }
  });
  
  useEffect(() => {
    mountedRef.current = true;

    setDashboardData( (prev: InOutData) => ({ ...prev, loading: true }) );
    const currentRequest: CancelTokenSource = axios.CancelToken.source();
    const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 1);

    const initialDate = currentDate.valueOf();
    const finalDate = (new Date()).valueOf();

    getDashboardInOutData("", "", "", "", initialDate, finalDate, false, true, false, currentRequest.token)
      .then((data : DashboardInOutData) => setDashboardData({loading: false, data: data}))
      .catch((err) => console.log(err));

    return () => {
      mountedRef.current = false;
      currentRequest.cancel('request canceled by the user.');
    };
  }, []);


  return {
    dashboardData,
  };
};