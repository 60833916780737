import React, { createContext, ReactNode, useState, useContext, Dispatch, SetStateAction, useMemo } from 'react';
import { Question, allQuestions } from '../QuestionsAndAnswers';

type Props = {
  children: ReactNode;
};

type FaqContextProps = {
  subjectFilter: string;
  setSubjectFilter: Dispatch<SetStateAction<string>>;
  questions: Question[];
};

const FaqContext = createContext<FaqContextProps>({} as FaqContextProps);

export function FaQContextProvider(props: Props) {
  const { children } = props;

  const [subjectFilter, setSubjectFilter] = useState('Documentação');

  const questions = useMemo(
    () => allQuestions.filter((item) => item.filter.some((filter) => filter === subjectFilter)),
    [subjectFilter]
  );

  const value = {
    subjectFilter,
    setSubjectFilter,
    questions,
  };

  return <FaqContext.Provider value={value}>{children}</FaqContext.Provider>;
}

export function useFaQContext() {
  const context = useContext(FaqContext);

  if (typeof context === 'undefined') {
    throw new Error('FaqContext must be used within an useFaqContext');
  }

  return context;
}
