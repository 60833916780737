import moment from 'moment';
import React from 'react';

type Props = {
  title: string;
};

const ListHeader: React.FC<Props> = ({ title }) => (
  <div className="panel-collapse-title">{moment(title).utc().format('DD/MM/YYYY')}</div>
);

export default ListHeader;
