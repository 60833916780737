import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { mixpanelInitialize } from '../services/SystemMonitor';

import { useAuth, useIsMounted } from '../hooks';
import api from '../services/api';
import { IAuthRequest } from '../services/api/auth';
import { OverridedMixpanel } from 'mixpanel-browser';
import { AuthData, DynamicComponents } from '../types';

type AuthContextData = {
  authData: AuthData | undefined | null;
  changePassAndSignIn: (params: IAuthRequest) => Promise<void>;
  signIn: (params: IAuthRequest) => Promise<void>;
  signOut: () => void;
  monitorAgent: OverridedMixpanel|undefined;
};

type AuthContextProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext({} as AuthContextData);

function getParsedAuth(splitComponents: (components:string[]) => DynamicComponents): AuthData | null {
    const auth = localStorage.getItem('authData');

    if (!auth) return null;

    const jsonResponse: any = JSON.parse(auth) as AuthData;

    if (!jsonResponse) return null;
    
    const parsedAuth:AuthData = {
      isAdmin: jsonResponse.profileData.isTrackfy ? jsonResponse.profileData.isTrackfy : false,
      access_token: jsonResponse.access_token,
      refresh_token: jsonResponse.refresh_token,
      username: jsonResponse.profileData.name,
      email: jsonResponse.profileData.email,
      showSurvey: jsonResponse.profileData.showSurvey,
      persona: jsonResponse.profileData.persona,
      clientId: jsonResponse.profileData.clientId,
      companyId:jsonResponse.profileData.companyId,
      grpCompanies:jsonResponse.profileData.grpCompanies,
      hasMap: jsonResponse.profileData.hasMap,
      routes : jsonResponse.profileData.routes,
      menu : jsonResponse.profileData.menu,
      components: splitComponents(jsonResponse.profileData.components),
    };

    api.defaults.headers.common.Authorization = `Bearer ${parsedAuth.access_token}`;

    return parsedAuth;
}

export function AuthContextProvider({ children }: AuthContextProviderProps) {
  const { sigInProvider, signOutProvider, splitComponents, changePassAndSigInProvider } = useAuth();

  const [authData, setAuthData] = useState<AuthData | null>(getParsedAuth(splitComponents));

  const isMounted = useIsMounted();

  const signIn = useCallback(
    async (params: IAuthRequest) => {
      const response = await sigInProvider(params);

      if (isMounted.current) setAuthData(response);
    },
    [authData, isMounted]
  );

  const changePassAndSignIn = useCallback(
    async (params: IAuthRequest) => {
      const response = await changePassAndSigInProvider(params);

      if (isMounted.current) setAuthData(response);
    },
    [authData, isMounted]
  );

  const signOut = useCallback(() => {
    signOutProvider();

    if (isMounted.current) setAuthData(null);
  }, [authData, isMounted]);

  let monitorAgent:OverridedMixpanel|undefined =  undefined;

  if (process.env.NODE_ENV === 'production'){ 
    const mixpanelToken:string = process.env.REACT_APP_MIXPANEL_TOKEN || '';
    
    if(mixpanelToken.length > 0)
      monitorAgent = mixpanelInitialize(mixpanelToken, false);
  }

  const value = {
    authData,
    changePassAndSignIn,
    signIn,
    signOut,
    monitorAgent,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  const context = useContext(AuthContext);

  if (typeof context === 'undefined') {
    throw new Error('AuthContext must be used within an useAuthContext');
  }

  return context;
}
