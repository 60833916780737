import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { SelectOption } from '../../components/Select/types';
import { Select } from '../../components/Select';
import { getProfessions } from '../../services/api/TrackfyService';
import { Profession } from '../../types/Filters';
import { getLinkedCollaboratorsProfessions } from '../../services/api/TrackfyService/integration';
import { useAuth } from '../../hooks/useAuth';
import { useAuthContext } from '../../stores/AuthContext';
import { mixpanelTrack } from '../../services/SystemMonitor';

type ProfessionSelectProps = {
  label?: string;
  professionSelected: number;
  setProfessionSelected: (value: number) => void;
  demo?: boolean;
  screen?: string;
};

const ProfessionSelect: React.FC<ProfessionSelectProps> = (props: ProfessionSelectProps) => {
  const [professions, setProfessions] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();
  const { professionSelected, setProfessionSelected, label, demo, screen } = props;

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);

      if (demo && demo == true) {
        getLinkedCollaboratorsProfessions(currentRequest.token)
          .then((result: AxiosResponse<any>) => {
            setProfessions(
              result.data.map((Profession: Profession) => {
                return {
                  label: Profession.name,
                  value: Profession.id ? Profession.id.toString() : '0',
                };
              })
            );
            setIsLoading(false);
          })
          .catch((err) => console.log(err));
      } else {
        getProfessions(currentRequest.token)
          .then((result: AxiosResponse<any>) => {
            setProfessions(
              result.data.map((Profession: Profession) => {
                return {
                  label: Profession.name,
                  value: Profession.id ? Profession.id.toString() : '0',
                };
              })
            );
            setIsLoading(false);
          })
          .catch((err) => console.log(err));
      }
    }

    return () => {
      mounted = false;
      currentRequest.cancel('getProfessionsApi request canceled by the user.');
    };
  }, []);

  const onChanging = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setProfessionSelected(Number(event.target.value));

    const label = event.target.selectedOptions[0].label;

    if (label != 'Selecione uma função')
      mixpanelTrack(monitorAgent, `${screen} - Função ${label} selecionada`, userName, authData);
  };

  return (
    <Select
      id="avrgtime-Profession-select"
      label={label}
      placeholder="Selecione uma função"
      options={professions}
      value={professionSelected}
      loading={isLoading}
      onChanging={(event) => onChanging(event)}
    />
  );
};

export default ProfessionSelect;
