import { useState, useEffect } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { useDetectiveContext } from '../../../context/DetectiveContext';
import api from '../../../../../../../../services/api';
import { useAuth } from '../../../../../../../../hooks';
import { PersonHistory } from '../../../../../../../../types/MonitoredData';
import { initialHistory } from '../constants/history';
import { requestParams } from '../../../../../../../../types/Requests';

export const useHistory = () => {
  const [personHistory, setPersonHistory] = useState<PersonHistory>(initialHistory);
  const [periodHistory, setPeriodHistory] = useState<number>(5);
  const [loading, setLoading] = useState<boolean>(false);
  const { getClientId } = useAuth();
  const { selectedPerson, 
          updateSelectedPerson, 
          initHistory, 
          setInitHistory, 
          setSearchListActive } = useDetectiveContext();

  const { collaborator, currentLocation, historic } = personHistory;
  const { lat, lng } = personHistory.currentLocation;
  const clientId: number = getClientId();
  const user = localStorage.getItem('@trackfy_user_id');
  const userId = user ? Number.parseInt(user) : 1;

  const useV2 = (clientId == 11 || clientId == 1 || clientId == 6 || userId == 6);

  useEffect(() => {
    if (selectedPerson === 0) return;

    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted: boolean = true;
    const endpointPath = 'https://backend.trackfyapp.com/rest/v2/monitoring/collaborator';

    let params: requestParams = {
      collaboratorId: selectedPerson,
      currentDate: (new Date()).getTime(),
      period: periodHistory,
      clientId,
    }

    /*if(useV2) {
      endpointPath = 'https://backend.trackfyapp.com/rest/v2/monitoring/collaborator';
      //endpointPath = 'http://localhost:8083/rest/v2/monitoring/collaborator';
      params.currentDate = (new Date()).getTime();
    } else {
      endpointPath = `/monitoring/collaborator`;
    }*/
   
    setLoading(true);

    api.get(endpointPath, {
        params,
        cancelToken: currentRequest.token,
      })
      .then((res: AxiosResponse<any>) => {
        const { data } = res;

        if (!mounted) return;

        setPersonHistory(data);
        setInitHistory(true);
        setSearchListActive(false);
      })
      .catch((error: AxiosResponse<any>) => error)
      .finally(() => {
        if (!mounted) return;
        setLoading(false);
      });

    return () => {
      currentRequest.cancel('Operation canceled by the user.');
      mounted = false;
    };
  }, [periodHistory, selectedPerson]);

  useEffect(() => {
    if (!initHistory) setPeriodHistory(5);
  }, [initHistory]);

  const handleClickToClose = () => {
    setPersonHistory(initialHistory);
    updateSelectedPerson(0);
  };

  return {
    lng,
    lat,
    historic,
    initHistory,
    collaborator,
    loading,
    currentLocation,
    handleClickToClose,
    setPeriodHistory,
  };
};
