import { AlertIconVuesax } from '../../../../Icons/Alert-Vuesax';
import { PageOpenIcon } from '../../../../Icons/PageOpen';
import * as S from './styles';
import React from 'react';

interface Props {
  title: string;
  icon: React.ReactNode;
  subtitle?: string;
  style?: React.CSSProperties;
}
export const CardHeader: React.FC<Props> = ({ title, icon, subtitle, style }) => {
  return (
    <S.Header style={style}>
      {icon}
      <p>
        {title} {subtitle ? <span>{subtitle}</span> : <></>}
      </p>
      {/*<AlertIconVuesax style={{ marginRight: '-0.8rem' }} />
      <div style={{flexGrow: 1, display: 'flex', justifyContent: 'flex-end'}}>
        <PageOpenIcon />
      </div>*/}
    </S.Header>
  );
};
