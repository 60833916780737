import styled from 'styled-components';
import { Card } from '../../styles';

export const Container = styled(Card)``;

export const PagesContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 2.4rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const CardContainer = styled.div`
  min-width: 8.4rem;
  max-width: 16.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4.8rem;
  color: #666666;
  font-weight: 500;
  font-family: Work Sans;
  gap: 0.8rem;
`;

export const PageCard = styled.a`
  background-color: #e8e8e8;
  cursor: pointer;
  width: 100%;
  aspect-ratio: 4/4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25%;
  &:hover {
    background-color: #2f4cdd;
  }
`;

export const PageDescription = styled.p`
  width: 100%;
  text-align: left;
  font-weight: 600;
  font-family: Work Sans;
  color: #666666;
`;

export const PageControlButtons = styled.div`
  display: flex;
  width: 100%;
  gap: 6rem;
  justify-content: center;
`;

export const ControlButton = styled.button`
  all: unset;
  background-color: #2f4cdd;
  width: 5.6rem;
  height: 5.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 16px;
  &:disabled {
    cursor: not-allowed;
    background-color: #c1c9f5;
  }
`;

export const CarouselContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 2.4rem;
  position: relative;
`;
