import moment from 'moment';
import React, { createContext, ReactNode, useState, useContext } from 'react';

type Props = {
  children: ReactNode;
};

type SystemlogsContextProps = {
  logTypesSelected: number;
  initialDate: any;
  finalDate: any;
  setLogTypesSelected: (logTypesSelected: number) => void;
  updatePeriod: (initial: any, final: any) => void;
};

const SystemlogsContext = createContext<SystemlogsContextProps>({} as SystemlogsContextProps);

export function SystemlogsContextProvider(props: Props) {
  const { children } = props;

  const [initialDate, setInitialDate] = useState<any>(moment());
  const [finalDate, setFinalDate] = useState<any>(moment());
  const [logTypesSelected, setLogTypesSelected] = useState<number>(0)

  const updatePeriod = (initial: any, final: any) => {
    setInitialDate(initial);
    setFinalDate(final);
  };

  const value = {
    logTypesSelected,
    initialDate,
    finalDate,
    setLogTypesSelected,
    updatePeriod
  };

  return (
    <SystemlogsContext.Provider value={value}>{children}</SystemlogsContext.Provider>
  );
}

export function useSystemlogsContext() {
  const context = useContext(SystemlogsContext);

  if (typeof context === 'undefined') {
    throw new Error('SystemlogsContext must be used within an useSystemlogsContext');
  }

  return context;
}
