import { IconFaQ } from '../../../../Icons/Faq';
import { CardHeader } from '../CardHeader';
import { Questions } from './Questions';
import { ScrollChips } from './ScrollChips';
import { FaQContextProvider } from './context/FaqContext';
import * as S from './styles';
import React from 'react';

interface Props {}
export const HelpCard: React.FC<Props> = ({}) => {
  return (
    <div style={{ position: 'relative', alignItems: 'flex-start' }}>
      <CardHeader icon={<IconFaQ />} title="Ajuda" style={{ marginBottom: '9.6rem' }} />
      <FaQContextProvider>
        <ScrollChips />
        <Questions />
      </FaQContextProvider>
    </div>
  );
};
