import { Column, ColumnConfig } from '@ant-design/charts';
import React from 'react';

import { IconLoading } from '../../../../components/IconLoading';
import { useOnePage } from './hooks/UseValueExpansion';

export const ValueExpansionChart: React.FC = () => {
  const {valueExpansion, isLoading} = useOnePage()

  const config: ColumnConfig = {
    data: valueExpansion,
    xField: 'name',
    yField: 'value',
    color: ({ name }) => {    
      if (name === 'jul-set|23' || name === 'out-dez|23' || name === 'abr-jun|23'){
        return '#00A0A9'
      }
      else if (name === 'jan-mar|24' || name === 'abr-jun|24' || name === 'jan-mar|23'){
        return '#fa4444'
      }
      return '#008AE5'
    },
    yAxis: {
      label: {
        formatter: function formatter(v: any) { return ''.concat(v, '%'); }
      }
    },
    columnStyle: {
      radius: [5, 5, 0, 0], 
    },
    legend: false,
  };

  if (isLoading) return <IconLoading customDivStyle={{marginTop:"100px", marginLeft:"45%", marginRight:"45%"}}/>;

  return <Column {...config} />;
};
