import React from 'react';

import { PageHeader } from '../../components/PageStructure/PageHeader';
import { IncidentsList } from './components/IncidentsList';
import { IncidentsContextProvider } from './context/IcidentsContext';
import { Container, Content } from './styles';

export const IncidentsContainer: React.FC = () => {
  return (
    <Container>
      <PageHeader title="Log de Incidentes" />
      <Content>
        <IncidentsContextProvider>
          <IncidentsList/>
        </IncidentsContextProvider>
      </Content>
    </Container>
  );
};