import styled from 'styled-components';
import { Shadow } from '../../components';

export const ChartRow = styled.div`
  min-height: 40vh;
  height: 100%;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  justify-content: space-between;
  gap: 3.5rem;
  @media (max-width: 1200px) {
    &&& {
      grid-template-columns: 1fr;
    }
  }
`;

export const Card = styled(Shadow)`
  width: 100%;
  height: 100%;
  min-height: 590px;
  padding: 2.4rem;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const ExpansionValueCard = styled(Shadow)`
  //display: grid;
  //grid-template-columns: 1fr;
  //grid-template-rows: max-content auto;
  width: 100%;
  height: 100%;
  min-height: 400px;
  padding: 2.4rem 2.4rem 1.6rem;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
`;