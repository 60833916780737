import React from 'react';

import { SelectOption, SelectProps } from './types';

import * as S from './styles';

export const Select: React.FC<SelectProps> = ({
  id,
  options,
  label,
  value,
  onChanging,
  loading,
  className,
  ...rest
}) => {
  const { title, placeholder, disabled, style, secondary } = { ...rest };

  return (
    <S.SelectGroup className={className}>
      {label && <S.SelectLabel htmlFor={id}>{label}</S.SelectLabel>}
      <S.SelectField
        id={id}
        title={title}
        value={value}
        disabled={!!loading || !!disabled}
        onChange={onChanging}
        secondary={secondary}
      >
        {disabled && <option value="">{placeholder}</option>}
        {loading && <option value="">Carregando...</option>}
        {!loading && !placeholder && <option value="">Selecionar...</option>}
        {!loading && placeholder && <option value="">{placeholder}</option>}
        {!loading &&
          options.map((option: SelectOption) => {
            return (
              <S.Option key={option.value} value={option.value}>
                {option.label}
              </S.Option>
            );
          })}
      </S.SelectField>
    </S.SelectGroup>
  );
};
