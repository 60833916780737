import React from 'react';

import { CalibrationLists } from './CalibrationLists';
import { MapAreas } from '../../../components/MonitoredMap/hooks/useMapArea';

import * as S from '../styles';

interface CalibrationMapComponentsProps {
    mapAreas: MapAreas;
};

export const CalibrationMapComponents: React.FC<CalibrationMapComponentsProps> = ({mapAreas}) => {
    return (
        <>
            <S.MonitoredAreasContainer>
                {mapAreas.mapGeofences}
            </S.MonitoredAreasContainer>
            <CalibrationLists key="calibrationBoard" />
        </>
    );
}