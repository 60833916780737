import React, { useState } from 'react';
import { CancelToken } from 'axios';

import { Modal } from '../../components/Modal';
import { ModalInviteCancel } from './ModalInviteCancel';
import { ModalDetails } from './ModalDetails';
import { IconLoading } from '../../components/IconLoading';
import { User } from './types';
import Incident from '../../Icons/Incident';

import * as S from './styles';

interface Props {
  onSelectUser: (user: User) => void;
  onSelectAll: () => void;
  requestInvites: (token: CancelToken) => void;
  usersSelected: User[];
  userSearched: string;
  profileSelected: number;
  companySelected: number;
  users: User[];
  loading: boolean;
}

type ModalOpen = '' | 'details' | 'inviteCancel';

export const UsersTable: React.FC<Props> = (props: Props) => {
  const { onSelectAll, onSelectUser, users, loading, requestInvites } = props;
  const [modalOpen, setModalOpen] = useState<ModalOpen>('');
  const [userDetailed, setUserDetailed] = useState<User>();

  const EmptyTable = <tbody>
                        <tr>
                          <td width="100%" height="100%" colSpan={100}>
                            <S.EmptyContainer>
                              <Incident />
                              <span>{"Não há dados de usuários"}</span>
                            </S.EmptyContainer>
                          </td>
                      </tr>
                     </tbody>;

  const LoadingComponent = <tbody>
                              <tr>
                                <td width="100%" height="100%" colSpan={100}>
                                  <IconLoading />
                                </td>
                              </tr>
                           </tbody>;
  
  const closeModal = () => {
    setUserDetailed(undefined);
    setModalOpen('');
  };

  const openModal = (user: User): void => {
    setUserDetailed(user);
    
    if (user.isRegistered) 
      setModalOpen('details');
    else 
      setModalOpen('inviteCancel');
  }

  const actionButton = (user: User): React.ReactElement => {
    const action = user.isRegistered ? 'Detalhes' : 'Cancelar convite';
    return <S.ButtonRow isRegistered={user.isRegistered} onClick={() => openModal(user)} >{action}</S.ButtonRow>
  }

  return (
    <S.Table>
      <thead>
        <tr>
          <td>Usuário</td>
          <td>Empresa</td>
          <td>Perfil</td>
          <td>Data convite</td>
          <td></td>
        </tr>
      </thead>
      {loading ? LoadingComponent :(
        (users.length == 0) ? EmptyTable :
          <tbody>
            {users.map((user, index) => (
              <S.TableRow isRegistered={user.isRegistered} key={`${index}-${user.user}`}>
                  <React.Fragment>
                    <td style={{width:'25%'}}>{user.user}</td>
                    <td style={{width:'25%'}}>{user.company}</td>
                    <td style={{width:'13%'}}>{user.profile}</td>
                    <td style={{width:'12%'}}>{user.inviteDate}</td>
                    <td style={{width:'25%'}}>{actionButton(user)}</td>
                  </React.Fragment>
              </S.TableRow>
            ))}
          </tbody>
      )}
      <Modal visible={modalOpen === 'details'}>
        <ModalDetails closeModal={closeModal} user={userDetailed} requestInvites={requestInvites} />
      </Modal>
      <Modal visible={modalOpen === 'inviteCancel'}>
        <ModalInviteCancel closeModal={closeModal} inviteId={userDetailed ? userDetailed.id : 0} requestInvites={requestInvites} />
      </Modal>
    </S.Table>
  );
};


/*
   <S.Table>
      <thead>
        <tr>
          <td><td>
            <input type="checkbox" checked={usersSelected.length === users.length} onChange={onSelectAll} />
          </td>
          <td>Usuário</td>
          <td>Empresa</td>
          <td>Último acesso</td>
          <td>Número de acessos</td>
          <td>Tempo médio de sessão</td>
          <td>Perfil</td>
          <td>Data convite</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <S.TableRow isRegistered={user.isRegistered} key={`${index}-${user.user}`}>
            <td>
              <input
                type="checkbox"
                checked={usersSelected?.some((v) => v.user === user.user)}
                onChange={(e) => onSelectUser(user)}
              />
            </td>
            <td style={{width:'23%'}}>{user.user}</td>
            <td style={{width:'23%'}}>{user.company}</td>
            <td style={{width:'11%'}}>{user.lastLogin}</td>
            <td style={{width:'10%'}}>{user.sessionCount}</td>
            <td style={{width:'10%'}}>{user.sessionAverageDuration}</td>
            <td>{user.isRegistered ? user.user : '-'}</td>
            <td>{user.isRegistered ? user.company : '-'}</td>
            <td>{user.isRegistered ? user.lastLogin : '-'}</td>
            <td>{user.isRegistered ? user.sessionCount : '-'}</td>
        <td>{user.isRegistered ? user.sessionAverageDuration : '-'}</td> 
            <td style={{width:'23%'}}>{actionButton}</td>
          </S.TableRow>
        ))}
      </tbody>
      <Modal visible={modalOpen === 'details'}>
        <ModalDetails closeModal={closeModal} user={userDetailed} />
      </Modal>
      <Modal visible={modalOpen === 'inviteCancel'}>
        <ModalInviteCancel closeModal={closeModal} inviteId={userDetailed ? userDetailed.id : 0} />
      </Modal>
    </S.Table>
*/