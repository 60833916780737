/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import * as S from './styles';

type DeleteProps = {
  visible: boolean;
  children: JSX.Element;
  cardStyle?: React.CSSProperties;
};

export const Modal: React.FC<DeleteProps> = ({ visible, children, cardStyle }) => (
  <>
    {visible && (
      <S.Container>
        <S.Card style={cardStyle}>{children}</S.Card>
      </S.Container>
    )}
  </>
);