import React from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';

import { MonitoredMap } from '../../components/MonitoredMap';
import { CalibrationMapComponents } from './components/CalibrationMapComponents';
import { CalibrationLists } from './components/CalibrationLists';
import { useAuthContext } from '../../stores';
import { useMapArea } from '../../components/MonitoredMap/hooks/useMapArea';

export const CalibrationContainer: React.FC<{}> = () => {
  const { authData } = useAuthContext();
  const hasMap = authData && authData.hasMap ? authData.hasMap : false;
  const { mapAreas } = useMapArea(false, "notevacuating");

  if (hasMap)
    return (
      <MonitoredMap
        monitoredComponent="calibration"
        childComponents={[<CalibrationMapComponents key="CalibrationMapComponents" mapAreas={mapAreas}/>]}
      />
    );

  return <CalibrationLists key="calibrationBoard" />;
};
