import styled from 'styled-components';
import { colors } from '../../styles/colors';

export interface IsRegisteredProps {
  isRegistered: boolean;
}

export const UsersContainer = styled.div`
  background: '#f8f8f8';
  border-radius: 0.8rem;
  width: 100%;
  height: 100%;
`;

export const UsersHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  /*& div {
    & svg {
      cursor: pointer;
    }
  }*/
`;

export const UsersHeaderContent = styled.div`
    & svg:first-child {
      cursor: inherit;
    }
    display: flex;
    gap: 1.6rem;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
  }
`;

export const InviteButton = styled.button`
  all: unset;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 1rem;
  background-color: ${colors.blue};
  color: #ffffff;
  font: normal 500 1.2rem/1.5 Work Sans, sans-serif;
  border-radius: 0.8rem;
  padding: 0.8rem 1.6rem;
  white-space: nowrap;
`;

export const FilterSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;
  margin-bottom: 3rem;
  width: 100%;
  & :first-child {
    min-width: 4rem;
    cursor: pointer;
  }
  & :last-child {
    min-width: 2.4rem;
    cursor: pointer;
  }
`;

export const Table = styled.table`
  width: 100%;
  font-family: work sans;
  overflow-x: auto;
  min-width: 60rem;
  & input[type='checkbox'] {
    padding: 1.2rem;
    border: 1px solid #2f4cdd;
    border-radius: 4px;
    vertical-align: middle;
  }
  & td {
    box-sizing: border-box;
    padding: 1.2rem;
    text-align: center;
  }
  & thead {
    background-color: #21359b;
    width: 100%;
    color: #eaedfc;
    font-weight: 600;
  }
  & tbody {
    font-weight: 400;
    width: 100%;
    color: #262424;
    & tr:nth-child(odd) {
      background-color: #eaedfc;
    }
    & tr:nth-child(even) {
      background-color: #ffffff;
    }
  }
`;

export const TableRow = styled.tr<IsRegisteredProps>`
  background-color: ${({ isRegistered }) => (isRegistered ? 'current-color' : '#E6E6E6 !important')};
`;

export const ButtonRow = styled.button<IsRegisteredProps>`
  all: unset;
  border-width: 2px;
  border-style: solid;
  border-radius: 8px;
  border-color: ${({ isRegistered }) => (isRegistered ? '#2F4CDD' : '#ED3A3A')};
  color: ${({ isRegistered }) => (isRegistered ? '#2F4CDD' : '#ED3A3A')};
  white-space: nowrap;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  &:hover {
    color: #ffffff;
    background-color: ${({ isRegistered }) => (isRegistered ? '#2f4cdd' : '#ed3a3a')};
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & span {
    font: normal 500 1.5rem/1.5 Work Sans, sans-serif;
    color: #4f5882;
  }
`;