/* eslint camelcase: ["error", {allow: ["access_token", "refresh_token", "full_name"]}] */
import { Col, Row } from 'antd';
import React from 'react';

import { PageHeader } from '../../components/PageStructure/PageHeader';
import { Container, ContentContainer } from '../../components/PageStructure/styles';
import { IconLoading } from '../../components/IconLoading';
import { useNewPassord } from './hooks/useNewPassword';
import { useAuth } from '../../hooks';
import * as S from './styles';


export const NewPasswordContainer: React.FC = () => {
  const { getUserName } = useAuth();
  const { password, loading, message, typeMessages, setUser, setPassword, handlePasswordChange } =
    useNewPassord({userName: getUserName()});

  return (
    <Container>
    <PageHeader title="Primeiro acesso" />
    <ContentContainer value="">
      <S.Header>
          <S.HeaderTitle>Registre sua nova senha:</S.HeaderTitle>
      </S.Header>
      <S.FormContainer>
        <S.LoginForm onSubmit={handlePasswordChange}>
              <S.FormControl>
                <S.Label>
                  Usuário
                  <S.Input
                    autoFocus
                    disabled
                    value={getUserName()}
                    //placeholder="Digite seu usuário"
                    //onChange={(e) => setUser(e.target.value)}
                  />
                </S.Label>
              </S.FormControl>
              <S.FormControl>
                <S.Label>
                  Nova Senha
                  <S.PassInput
                    type="password"
                    value={password}
                    placeholder="Digite sua senha"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </S.Label>
              </S.FormControl>
              <S.FormControl>
                {loading && (
                  <S.IconLoadingContainer>
                    <IconLoading customStyle={{ fontSize: 24 }} />
                  </S.IconLoadingContainer>
                )}
                {message && (
                  <S.Message type={message}>
                    {typeMessages[message].icon}
                    {typeMessages[message].message}
                  </S.Message>
                )}
                {!loading && !message && <S.Space />}
              </S.FormControl>
              <S.FormControl>
                <S.Submit type="submit" disabled={loading}>
                  Alterar
                </S.Submit>
              </S.FormControl>
        </S.LoginForm>
      </S.FormContainer>
    </ContentContainer>
    </Container>
  );
};


/*
    <S.Container>
        <S.LoginContainer>
          <S.FormControl>
              <S.Title>Registre uma nova senha</S.Title>
          </S.FormControl>
          <S.LoginForm onSubmit={handleSignIn}>
            <S.FormControl>
              <S.Label>
                Usuário
                <S.Input
                  autoFocus
                  disabled
                  value={getUserName()}
                  //placeholder="Digite seu usuário"
                  //onChange={(e) => setUser(e.target.value)}
                />
              </S.Label>
            </S.FormControl>
            <S.FormControl>
              <S.Label>
                Nova Senha
                <S.Input
                  type="password"
                  value={password}
                  placeholder="Digite sua senha"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </S.Label>
            </S.FormControl>
            <S.FormControl>
              {loading && (
                <S.IconLoadingContainer>
                  <IconLoading customStyle={{ fontSize: 24 }} />
                </S.IconLoadingContainer>
              )}
              {message && (
                <S.Message type={message}>
                  {typeMessages[message].icon}
                  {typeMessages[message].message}
                </S.Message>
              )}
              {!loading && !message && <S.Space />}
            </S.FormControl>
            <S.FormControl>
              <S.Submit type="submit" disabled={loading}>
                Alterar
              </S.Submit>
            </S.FormControl>
          </S.LoginForm>
        </S.LoginContainer>
    </S.Container>
  */