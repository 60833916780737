import React from 'react';

import { SearchContent, SearchInput } from './components';
import { useSearch } from './hooks/useSearch';
import './styles.css';

export const Search: React.FC = () => {
  const { searchListActive, handleClickButtonSeacrh, getListMonitoredCollaborators } = useSearch();

  return (
    <>
      <div
        className={/* active */ searchListActive ? 'search-container active' : 'search-container'}
      >
        <SearchInput
          active={/* active */ searchListActive}
          onClick={handleClickButtonSeacrh}
          onSearch={getListMonitoredCollaborators}
        />
        <SearchContent
          active={/* active */ searchListActive}
          onSearch={getListMonitoredCollaborators}
        />
      </div>
    </>
  );
};
