import { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import moment from 'moment';

import { useProductivityContext } from '../../../context/PageProductivityContext';
import { useRankContext } from '../context/RankContext';
import { RankByProfession, RankByProfessionData } from '../../../../indicators/types/Indicators';
import { getRankChartData } from '../../../../../services/api/TrackfyService';
import { useAuth } from '../../../../../hooks';
import { parseSelectOptions } from '../../../../../utils/Convert';

export const useRank = () => {
  
  const { companySelected, professionSelected, initialDate, finalDate } = useProductivityContext();
  const { setLowerProductivity, setHigherProductivity, setAverage, setIsLoadingAverage } = useRankContext();
  const [rankChartData, setRankChartData] = useState<RankByProfessionData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { getClientId } = useAuth();
  const clientId: number = getClientId();

  const getColor = (value: number) => {
    if (value < 45) return '#e45a5a';
    if (value < 70) return '#2f4cdd';
    return '#86d655';
  };

  const handleChartData = (data: RankByProfession) => {
    if (data && data.data && data.data.length > 0) {
      setRankChartData(data.data);
      setLowerProductivity(data.data[0].value);
      setHigherProductivity(data.data[data.data.length - 1].value);
      setAverage(data.average);
    } else {
      setRankChartData([]);
      setLowerProductivity(0);
      setHigherProductivity(0);
      setAverage(0);
    }

    setIsLoadingAverage(false);
    setIsLoading(false);
  };

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);
      setIsLoadingAverage(true);

      getRankChartData(
        clientId,
        parseSelectOptions(companySelected),
        parseSelectOptions(professionSelected),
        moment(initialDate).startOf('day').valueOf(),
        moment(finalDate).valueOf(),
        currentRequest.token,
      )
        .then((result: AxiosResponse<any>) => handleChartData(result.data))
        .catch((err) => console.log(err));
    }

    return () => {
      currentRequest.cancel('RankChart request canceled by the user.');
      mounted = false;
    };
  }, [companySelected, professionSelected, initialDate, finalDate]);

  return {
    rankChartData,
    isLoading,
    getColor,
    setRankChartData,
    setIsLoading,
  };
};
