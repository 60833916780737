import React from 'react';
import { DefaultIconProps } from './types';

export const VerticalBarsIcon: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M6 66H66"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.25 12V66H42.75V12C42.75 8.7 41.4 6 37.35 6H34.65C30.6 6 29.25 8.7 29.25 12Z"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 30V66H21V30C21 26.7 19.8 24 16.2 24H13.8C10.2 24 9 26.7 9 30Z"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51 45V66H63V45C63 41.7 61.8 39 58.2 39H55.8C52.2 39 51 41.7 51 45Z"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
