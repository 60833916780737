import React, { ChangeEvent } from 'react';

import * as S from './styles';

interface TextareaProps {
  value?: string;
  autoFocus?: boolean;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  className?: string;
  onChange?: (value: string) => void;
}

export const Textarea: React.FC<TextareaProps> = ({
  value,
  autoFocus,
  placeholder,
  disabled,
  maxLength = 250,
  className,
  onChange,
}) => {
  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (onChange) onChange(value);
  };

  return (
    <S.Textarea
      className={className}
      value={value}
      maxLength={maxLength}
      onChange={handleOnChange}
      autoFocus={autoFocus}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};