import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import moment from 'moment';

import { useReportsContext } from '../../context/ReportsContext';
import { useAuth } from '../../../../hooks';
import { PreviewHeader } from './PreviewHeader';
import { ExampleLineChart } from './Chart';
import { DisplacementChart } from '../DisplacementChart';
import { StackedBarChart } from '../StackedBarChart';
import { Form } from '../ReportContent';
import { transformMinutesToHours } from '../../../../utils/DateTime';
import { random } from '../../../../utils/Random';
import { getArrivalAndDeparture } from '../../../../services/api/TrackfyService/integration';
import { IconLoading } from '../../../../components/IconLoading';

import * as S from './styles';

interface Props {
  form: Form;
}

export interface IArrivalOrDepartureTime {
  id: number;
  week: string;
  hours: number;
}

export interface IDisplacementData {
  area: string;
  arrived: string;
  exited: string;
  hours: string;
}

export interface ITimeDivision {
  label: string;
  value: number;
  color: string;
}

export interface IArrivalDepartureData {
  arrivalTimeMedia: string;
  departureTimeMedia: string;
  arrivalHistory: IArrivalOrDepartureTime[];
  departureHistory: IArrivalOrDepartureTime[];
  timeDivision: ITimeDivision[];
  displacement: IDisplacementData[];
}

export interface IPreviewInOut {
  report: IArrivalDepartureData;
  loading: boolean;
}

export const PreviewInOut: React.FC<Props> = ({ form }) => {
  const { areaSelected, professionSelected, collaboratorSelected, companySelected } = useReportsContext();
  const { getClientId } = useAuth();
  const { period } = form;
  const [arrivalDepartureData, setArrivalDepartureData] = useState<IPreviewInOut>({
    loading: true,
    report: {
      arrivalTimeMedia: '',
      departureTimeMedia: '',
      arrivalHistory: [],
      departureHistory: [],
      timeDivision: [],
      displacement: [],
    },
  });

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    setArrivalDepartureData(previousState => ({...previousState, loading: true}));
    
    getArrivalAndDeparture(
      getClientId(),
      companySelected ? companySelected.toString() : '',
      professionSelected ? professionSelected.toString() : '',
      areaSelected ? areaSelected.toString() : '',
      collaboratorSelected ? collaboratorSelected.toString() : '',
      form.period?.initial.valueOf() ? form.period?.initial.valueOf() : moment().valueOf(),
      form.period?.final.valueOf() ? form.period?.final.valueOf() : moment().valueOf(),
      currentRequest.token
    )
      .then((response: AxiosResponse<any>) => {
        if (response.data) setArrivalDepartureData({ report: response.data, loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    companySelected,
    professionSelected,
    areaSelected,
    collaboratorSelected,
    form.period?.initial,
    form.period?.final,
  ]);

  if (arrivalDepartureData.loading) return <IconLoading />;

  return (
    <>
      <PreviewHeader period={period} title="Chegadas e Saídas" />
      <S.PreviewContent>
        <S.TitleContainer marginTop="5rem">
          <S.PreviewInOutHeaderTitle>
            <h1>
              Horário médio de chegada: <span>{arrivalDepartureData.report.arrivalTimeMedia}</span>
            </h1>
            <h1>
              Horário médio de saída: <span>{arrivalDepartureData.report.departureTimeMedia}</span>
            </h1>
          </S.PreviewInOutHeaderTitle>
        </S.TitleContainer>

        <S.TitleContainer flexDirection="column" marginTop='0rem'>
          <S.ContentTitle>Histórico de chegadas e saídas</S.ContentTitle>
          <S.ContentSubtitle style={{ fontStyle: 'italic' }}>
            Média de chegadas e saídas de cada semana
          </S.ContentSubtitle>
        </S.TitleContainer>
        <ExampleLineChart
          height={240}
          unit=""
          data={arrivalDepartureData.report.arrivalHistory}
          tickFormatter={(v) => transformMinutesToHours(v)}
          showLineAverageValue
        />
        <ExampleLineChart
          unit=""
          height={240}
          data={arrivalDepartureData.report.departureHistory}
          tickFormatter={(v) => transformMinutesToHours(v)}
          showLineAverageValue
        />

        <S.TitleContainer flexDirection="column" className="pagebreak">
          <S.ContentTitle>Divisão de tempo</S.ContentTitle>
          <S.ContentSubtitle style={{ fontStyle: 'italic' }}>
            Tempo médio (% em relação ao tempo total)
          </S.ContentSubtitle>
        </S.TitleContainer>

        <StackedBarChart
          data={arrivalDepartureData.report.timeDivision}
          style={{ marginBottom: '0rem' }}
          labelTotal="Tempo médio total por recurso(hh:mm)"
        />

        {collaboratorSelected > 0 && (
          <>
            <S.TitleRow style={{ marginTop: '0rem' }}>
              <S.TitleContainer flexDirection="column" marginTop="5rem">
                <S.ContentTitle>Chegadas e saídas</S.ContentTitle>
                <S.ContentSubtitle style={{ fontStyle: 'italic' }}>
                  Horário médio de chegadas e saídas em cada área
                </S.ContentSubtitle>
              </S.TitleContainer>

              <S.TitleContainer flexDirection="column" marginTop="5rem">
                <S.ContentTitle>Deslocamento</S.ContentTitle>
                <S.ContentSubtitle style={{ fontStyle: 'italic' }}>
                  Tempo de deslocamento (% em relação ao total)
                </S.ContentSubtitle>
              </S.TitleContainer>
            </S.TitleRow>
            <DisplacementChart data={arrivalDepartureData.report.displacement} />
          </>
        )}
      </S.PreviewContent>
    </>
  );
};
