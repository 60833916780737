import React, { useState } from 'react';
import { IconLoading } from '../../../components/IconLoading';
import { AreasTimeDivisionChartHeader } from './components/AreasTimeDivisionChartHeader';
import { VerticalBarChart } from './components/VerticalBarChart';
import { useAreasTimeDivisionContext } from './context/AreasTimeDivisionContext';
import { useAreasTimeDivision } from './hooks/UseAreasTimeDivision';

import { CardAvgcontent, GroupingButton, OptionsCard } from './styles';


export const AreasTimeDivisionChartContent: React.FC = () => {
  const { areasTimeDivisionChartData } = useAreasTimeDivisionContext();
  const { isLoading } = useAreasTimeDivision();

  return (
    <>
      <AreasTimeDivisionChartHeader/>
      <CardAvgcontent>
        {isLoading && <IconLoading />}
        {!isLoading && <VerticalBarChart areasTimeDivisionChartData={areasTimeDivisionChartData} />}
      </CardAvgcontent>
    </>
  );
};
