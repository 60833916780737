import React, { useEffect, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';

import Table from '../../../components/Table/Table';
import { SummaryProps } from '../types/DailySummaryTypes';
import { SummaryContainer, SummaryTitle } from './styled';
import { useTrackfyApi } from '../../../hooks/useTrackfyApi';
import { getObjectToStringMatrix } from '../../../utils/Convert';
import Footer from './Footer';

const DailySummaryReport: React.FC<SummaryProps> = (props: SummaryProps) => {
  const { title, monthly, company, date, detailHeader, setData, detailData, footer } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const { generateSummaryApi } = useTrackfyApi();

  useEffect(() => {
    let mounted = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted)
      generateSummaryApi(
        company,
        date,
        currentRequest.token,
        monthly,
        setData,
        setIsLoading,
        setIsEmpty
      );

    return () => {
      mounted = false;
      currentRequest.cancel('DailySummary request canceled by the user.');
    };
  }, [company, date]);

  return (
    <SummaryContainer>
      <SummaryTitle>{title}</SummaryTitle>
      <Table
        tableHeaders={detailHeader}
        tableData={getObjectToStringMatrix(detailData)}
        loading={isLoading}
        isEmpty={isEmpty}
      />
      <Footer
        title={footer.title}
        company={company}
        date={date}
        data={footer.data}
        icon={footer.icon}
        monthly={footer.monthly}
        setData={footer.setData}
      />
    </SummaryContainer>
  );
};

export default DailySummaryReport;
