import React from 'react';

import { IconLoading } from '../../../../../components/IconLoading';

import { CardAvgcontent } from './styles';

import { VerticalBarChart } from './components/VerticalBarChart';
import { useDeslocamentosCarro } from './hooks/UseDeslocamentoCarro';
import { useDeslocamentosCarroContext } from './context/DeslocamentosPe';
import { DeslocamentosCarroChartHeader } from './components/DeslocamentosCarroChartHeader';

const DeslocamentosCarroChart: React.FC = () => {
  const { deslocamentosCarroChartData } = useDeslocamentosCarroContext();
  const { isLoading } = useDeslocamentosCarro();

  return (
    <>
      <DeslocamentosCarroChartHeader />
      <CardAvgcontent>
        {isLoading && <IconLoading />}
        {!isLoading && (
          <VerticalBarChart deslocamentosCarroChartData={deslocamentosCarroChartData} />
        )}
      </CardAvgcontent>
    </>
  );
};

export default DeslocamentosCarroChart;
