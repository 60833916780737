import styled from 'styled-components';

export interface LabelProps {
  checked?: boolean;
}

export const SwitchContainer = styled.div`
  display: flex;
  background: rgb(255, 255, 255);
  position: fixed;
  left: 80px;
  bottom: 40px;
  width: 210px;
  flex-direction: row;
  padding-top: 5px;
  border-radius: 20px;
  justify-content: space-around;
`;

export const SwitchLabel = styled.h3<LabelProps>`
  color: rgb(79, 88, 130);
  font-weight: ${(props) => (props.checked ? 'bold' : 'normal')};
  margin-left: 5px;
`;
