import React from 'react';

import { currentColabCountIndicator, useAreaCollaborators } from '../hooks/useAreaCollaborators';

type MonitoredAreasCollaboratorsProps = {
    individual: boolean;
    mode: string;
    setIndicator?: (indicator: currentColabCountIndicator) => void;
    dangerAreas?: string[], 
    safeArea?: string
}

export const MonitoredAreasCollaborators: React.FC<MonitoredAreasCollaboratorsProps> = 
    ({individual, mode, setIndicator, dangerAreas, safeArea}:MonitoredAreasCollaboratorsProps) => {

    const { areaCollaborators, renderMarkers, clientId } = 
        useAreaCollaborators(individual, mode, setIndicator, dangerAreas);
    
    return <React.Fragment>
            {renderMarkers(individual, areaCollaborators, clientId, mode, dangerAreas, safeArea)}
        </React.Fragment>
}