import React from 'react';

type Props = {
  fill: string;
};

const IconDot: React.FC<Props> = (props: Props) => (
  <svg
    width="11"
    height="11"
    style={{ minWidth: '11', minHeight: '11' }}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="11"
      height="11"
      style={{ minWidth: '11', minHeight: '11' }}
      rx="5.5"
      fill={props.fill}
    />
  </svg>
);

export default IconDot;
