import React from 'react';

import { Button } from '../../../components/Buttons/Button';
import { useConformityContext } from '../context/ConformityContext';
import { IconExport } from '../../../Icons/Export';
import * as XLSX from 'xlsx';

import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex
`
const ExportButton = styled(Button)`
  border: solid 2px #2F4CDD;
  background: #ffffff;
  color: #2F4CDD;
  height: 45px;
  gap: 14px;
  border-radius: 8px;
`
export const ResourcesBurnDownExportButtonContainer: React.FC = () => {
  const {resourcesPlanningData} = useConformityContext();
  
  const exportData = () => {
    let payValue: number[] = [];
    let prodValue: number[] = [];
    let date: string[] = [];
    
    resourcesPlanningData.map(i => {
      if(i.type === 'Quantidade de recursos disponíveis'){
        payValue.push(i.value);
        date.push(i.time);
      }
      else if (i.type === 'Quantidade de recursos registrados'){
        prodValue.push(i.value);
      }
    })

    const formattedData: any[] = [];
    
    formattedData.push(["Data", "Quantidade de recursos disponíveis", "Quantidade de recursos registrados", ]) ;
    
    date.forEach((date, index) => {
      const value1 = payValue[index];
      const value2 = prodValue[index];
      formattedData.push([date, value1, value2]);
    });

    return formattedData;
  }

  const handleExport = () => {
    var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(exportData());
    XLSX.utils.book_append_sheet(wb, ws, "Quantidade de recursos");
    XLSX.writeFile(wb, "Quantidade de recursos.xlsx");
  }

  return(
      <ButtonContainer>
        <ExportButton
          title='Exportar'
          onClick={() => handleExport()}
          icon={<IconExport/>}
        />
      </ButtonContainer>
  )
};