import styled from 'styled-components';

export const Container = styled.div`
  max-height: 250px;

  display: flex;
  flex-direction: column;
  gap: 10px 0;
  margin: 10px 0;

  overflow: auto;
`;

export const OccupationContainer = styled.div`
  width: 160px;
  max-height: 180px;

  display: flex;
  align-items: baseline;
  flex-direction: row;
  margin: 0 15px;
  gap: 10px;
`;

export const OccupationQtdy = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #3fc5ff;
`;

export const OccupationTitle = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #4f5882;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;