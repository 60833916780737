import React, { useEffect, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';

import { useAuth } from '../../hooks/useAuth';
import { useAuthContext } from '../../stores/AuthContext';
import { SelectOption } from '../../components/Select/types';
import { Select } from '../../components/Select';
import { mixpanelTrack } from '../../services/SystemMonitor';

type BraskCompanySelectProps = {
  label?: string;
  projectSelected: number;
  setProjectSelected: (value: number) => void;
  demo?: boolean;
  screen?: string;
  onChange?: (value: { id: number; name: string }) => void;
};

const BraskCompanySelect: React.FC<BraskCompanySelectProps> = (props: BraskCompanySelectProps) => {
  const [companies, setCompanies] = useState<SelectOption[]>([]);
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();
  const { projectSelected, setProjectSelected, label, screen, onChange } = props;

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted)
        setCompanies([{label: "Reframax-Tancagem", value: "1"}, {label: "Braskem-Aromaticos", value: "2"}]);

    return () => {
      mounted = false;
      currentRequest.cancel('getBraskCompanysApi request canceled by the user.');
    };
  }, []);

  const onChanging = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setProjectSelected(Number(event.target.value));

    const label = event.target.selectedOptions[0].label;

    if (onChange)
      onChange({ id: Number(event.target.value), name: label });

    if (label != 'Selecione um projeto')
      mixpanelTrack(monitorAgent, `${screen} - Projeto ${label} selecionado`, userName, authData);
  };

  return (
    <Select
      id="avrgtime-BraskCompany-select"
      label={label}
      placeholder="Selecione um projeto"
      options={companies}
      value={projectSelected}
      loading={false}
      onChanging={(event) => onChanging(event)}
    />
  );
};

export default BraskCompanySelect;
