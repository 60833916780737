import { SelectOption } from "../components/Select/types";

export const getObjectToStringMatrix = (obj: any): Array<string[]> => {
  const newArray: Array<string[]> = [];
  for (let element of obj) {
    newArray.push(Object.values(element));
  }

  return newArray;
};

export const parseDataToCoordinates = (coordinates: string) => {
  try {
    const coordinatesFormated = coordinates
      .replace('"', '')
      .split('],[')
      .map((coord: any) =>
        coord
          .replaceAll('[', '')
          .replaceAll(']', '')
          .split(',')
          .map((value: string) => Number(value))
      );

    return coordinatesFormated;
  } catch (_error) {
    return [];
  }
};

export const parseSelectOptions = (options: SelectOption[]): string => {
  if(options)
    return options.map((option:SelectOption) => `${option.value}`).join(';');

  return "";
}

export const parseElements = (options: any[]): string => {
  if(options)
    return options.map((option:any) => `${option.id}`).join(';');

  return "";
}