import styled from 'styled-components';
import { Button } from '../../../../components/Buttons/Button';
import { sg } from '../../../../styles/style-guide';

export const DeleteCollaboratorContainer = styled.div`
  position: relative;
  width: 862px;
  height: 404px;
  padding: 48px 51px 40px 34px;
  display: flex;
  flex-direction: column;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 20px;
  height: 16px;
  background-color: transparent;
  text-align: right;
  font: normal normal 300 1.6rem/1.9rem Poppins;
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
  border: none;
  cursor: pointer
`;

export const CollaboratorListHeaderTitleContainer = styled.div`
  display: flex;
  gap: 1.6rem;
`;

export const IconUsersContainer = styled.div``;

export const CollaboratorListHeaderLabel = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.3rem;
  color: ${sg.colors.dark};
`;

export const DeleteServiceFrontContent = styled.div`
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 4.3rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  text-align: center;
`;

export const Subtitle = styled.h3`
  text-align: center;
  color: #4f5882; ;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 50%;
`;

export const CancelButtonContainer = styled.div``;

export const CancelButton = styled(Button)`
  background-color: #0823ad;
  padding: 4px 4px;
  width: 150px;
  height: 50px;
  border-radius: 10px;
  color: #fefefe;
  font-size: large;
  font-weight: bold;
`;

export const DeleteButtonContainer = styled.div``;

export const DeleteButton = styled(Button)`
  background-color: red;
  padding: 4px 4px;
  width: 150px;
  height: 50px;
  border-radius: 10px;
  color: #fefefe;
  font-size: large;
  font-weight: bold;
`;