import { useEffect, useRef, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import Map from 'react-map-gl';

import { useAuth, useIsMounted } from '../../../../../hooks';
import { GeolocationPosition } from '../../../../../types/Postion';
import { useAuthContext } from '../../../../../stores';
import { mixpanelTrack } from '../../../../../services/SystemMonitor';
import { CoordinatesResponse, getMapCoordinates } from '../../../../../services/api/TrackfyService';

export const useMap = () => {
  const [initializeMap, setInitializeMap] = useState<boolean>(false);
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v11');

  const latitudeRef = useRef(0);
  const longitudeRef = useRef(0);
  const zoomRef = useRef(17);
  const sateliteRef = useRef(false);

  const isMounted = useIsMounted();
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();

  const changeMapTerrain = () => {
    if (mapStyle === 'mapbox://styles/mapbox/streets-v11') {
      setMapStyle('mapbox://styles/mapbox/satellite-streets-v11');
      sateliteRef.current = true;
    } else {
      setMapStyle('mapbox://styles/mapbox/streets-v11');
      sateliteRef.current = false;
    }
  };

  const mapGoToCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
      if (initializeMap) return;

      const latitude: number = Number(position.coords.latitude);
      const longitude: number = Number(position.coords.longitude);

      if (initializeMap) return;

      if (!isMounted.current) return;

      latitudeRef.current = latitude;
      longitudeRef.current = longitude;
      setInitializeMap(true);
    });
  };

  useEffect(() => {
    if (isMounted.current) {
      const currentRequest: CancelTokenSource = Axios.CancelToken.source();

      mixpanelTrack(monitorAgent, 'Monitoramento', localStorage.getItem(USER_NAME), authData);

      getMapCoordinates(currentRequest.token)
        .then((data: CoordinatesResponse) => {
          const { latitude, longitude, hasMap, zoom } = data;

          if ((!latitude || !longitude) && hasMap) return mapGoToCurrentLocation();
          if (initializeMap) return;

          latitudeRef.current = Number(latitude);
          longitudeRef.current = Number(longitude);
          zoomRef.current = zoom ? Number(zoom) : Number(17);
          setInitializeMap(true);
        })
        .catch((_error) => mapGoToCurrentLocation());
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    sateliteRef,
    initializeMap,
    mapStyle,
    changeMapTerrain,
    Map,
    latitudeRef,
    longitudeRef,
    zoomRef,
  };
};
