import React from 'react';

interface Props{
  fill?: string
}

const Sucess: React.FC<Props> = ({fill}) => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.08669 4.12746L5.58419 6.63579L4.62169 5.67329C4.5694 5.61223 4.50504 5.56263 4.43267 5.52762C4.3603 5.49261 4.28147 5.47293 4.20114 5.46983C4.1208 5.46672 4.04069 5.48026 3.96583 5.50959C3.89098 5.53891 3.82299 5.5834 3.76614 5.64025C3.70929 5.69709 3.66481 5.76508 3.63548 5.83994C3.60616 5.91479 3.59262 5.9949 3.59572 6.07524C3.59883 6.15558 3.6185 6.2344 3.65352 6.30678C3.68853 6.37915 3.73813 6.4435 3.79919 6.49579L5.17002 7.87246C5.22453 7.92652 5.28917 7.9693 5.36024 7.99833C5.43132 8.02735 5.50742 8.04207 5.58419 8.04163C5.73722 8.04098 5.88387 7.98023 5.99252 7.87246L8.90919 4.95579C8.96386 4.90156 9.00726 4.83705 9.03688 4.76596C9.06649 4.69488 9.08174 4.61863 9.08174 4.54163C9.08174 4.46462 9.06649 4.38837 9.03688 4.31729C9.00726 4.2462 8.96386 4.18169 8.90919 4.12746C8.79989 4.01881 8.65205 3.95783 8.49794 3.95783C8.34383 3.95783 8.19598 4.01881 8.08669 4.12746ZM6.50002 0.166626C5.3463 0.166626 4.21848 0.508745 3.2592 1.14972C2.29991 1.79069 1.55224 2.70174 1.11073 3.76764C0.669215 4.83354 0.553695 6.00643 0.778776 7.13799C1.00386 8.26954 1.55943 9.30894 2.37523 10.1247C3.19104 10.9406 4.23044 11.4961 5.362 11.7212C6.49355 11.9463 7.66644 11.8308 8.73234 11.3893C9.79825 10.9477 10.7093 10.2001 11.3503 9.24079C11.9912 8.2815 12.3334 7.15368 12.3334 5.99996C12.3334 5.23391 12.1825 4.47537 11.8893 3.76764C11.5962 3.05991 11.1665 2.41684 10.6248 1.87517C10.0831 1.33349 9.44008 0.903814 8.73234 0.610662C8.02461 0.31751 7.26607 0.166626 6.50002 0.166626ZM6.50002 10.6666C5.57704 10.6666 4.67479 10.3929 3.90736 9.88015C3.13993 9.36737 2.54179 8.63854 2.18859 7.78581C1.83538 6.93309 1.74296 5.99478 1.92303 5.08954C2.10309 4.18429 2.54755 3.35277 3.20019 2.70013C3.85284 2.04748 4.68436 1.60303 5.5896 1.42296C6.49485 1.2429 7.43316 1.33531 8.28588 1.68852C9.1386 2.04173 9.86743 2.63987 10.3802 3.4073C10.893 4.17473 11.1667 5.07698 11.1667 5.99996C11.1667 7.23764 10.675 8.42462 9.79985 9.29979C8.92468 10.175 7.7377 10.6666 6.50002 10.6666Z"
      fill={fill || "#076205"}
    />
  </svg>
);

export default Sucess;
