import React from 'react';

import { usePlanningCollaboratorsCount } from './hooks/usePlanningCollaboratorsCount';

import * as S from './styles';

export const PlanningCollaboratorsCount: React.FC = () => {
  const { indicator } = usePlanningCollaboratorsCount();

  return (
    <S.Card>
      <S.CardHeader> Colaboradores Planejados Hoje: </S.CardHeader>
      <S.CardContent>{indicator.value}</S.CardContent>
    </S.Card>
  );
};
