import React from 'react';

import * as S from './styles';

type TableRowProps = {
    even: boolean;
    item: string[];
    index: number;
    background: string;
    tableHeight: string;
};


const TableRow: React.FC<TableRowProps> = (props: TableRowProps) => {
    const {even, item, index, background, tableHeight} = props;

    if(even)
        return (
            <S.Trstyled key={`${index}-${item}`} height={tableHeight} backgroundColor={background}>
                {item.map((val, idx) => <td key={`${idx}-${val}`}>{val}</td> )}
            </S.Trstyled>
        );


    return ( 
        <S.TrstyledOdd key={`${index}-${item}`} height={tableHeight} backgroundColor={background}>
             {item.map((val, idx) => <td key={`${idx}-${val}`}>{val}</td> )}
         </S.TrstyledOdd>
    );
}

export default TableRow;