import React from 'react';
import { DefaultIconProps } from './types';

export const IconCloseVuesax: React.FC<DefaultIconProps> = ({ onClick, fill, style, width, height }) => (
    <svg
      width={width || '48'}
      height={height || '48'}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M18.3387 31.1587C17.9587 31.1587 17.5787 31.0187 17.2787 30.7187C16.6988 30.1387 16.6988 29.1787 17.2787 28.5987L28.5987 17.2787C29.1787 16.6988 30.1387 16.6988 30.7187 17.2787C31.2987 17.8587 31.2987 18.8187 30.7187 19.3987L19.3987 30.7187C19.1187 31.0187 18.7187 31.1587 18.3387 31.1587Z"
        fill={fill || '#292D32'}
      />
      <path
        d="M29.6587 31.1587C29.2787 31.1587 28.8987 31.0187 28.5987 30.7187L17.2787 19.3987C16.6988 18.8187 16.6988 17.8587 17.2787 17.2787C17.8587 16.6988 18.8187 16.6988 19.3987 17.2787L30.7187 28.5987C31.2987 29.1787 31.2987 30.1387 30.7187 30.7187C30.4187 31.0187 30.0387 31.1587 29.6587 31.1587Z"
        fill={fill || '#292D32'}
      />
    </svg>
  );