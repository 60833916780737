import React, { useEffect, useState } from "react";

export const Clock: React.FC = () => {
    const [date, setDate] = useState(new Date());

    const refreshClock= () => {
        setDate(new Date());
    }

    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        return function cleanup() {
          clearInterval(timerId);
        };
      }, []);
      
      return (
        <span>
          {date.toLocaleTimeString()}
        </span>
      );
}