import { GetUserGroupPermissions } from '.';
import { MenuIcon, RouteKey } from '../../../enum';
import { AuthData, UserGroupPermissions } from '../../../types';

export const getUserGroupPermissions3: GetUserGroupPermissions = async (authData: AuthData|undefined|null) => {
  return getUserGroupPermissions2(authData);
};

export function getUserGroupPermissions2(authData: AuthData|undefined|null): UserGroupPermissions {
  let routes = [
    {
      key: RouteKey.monitoramento,
      path: '/monitoramento',
    },
    {
      key: RouteKey.indicadores,
      path: '/indicadores',
    },
    {
      key: RouteKey.planejamento,
      path: '/planejamento',
    },
    {
      key: RouteKey.analises,
      path: '/analises',
    },
    {
      key: RouteKey.sumario,
      path: '/sumario',
    },
    {
      key: RouteKey.relatorios,
      path: '/relatorios',
    },
    {
      key: RouteKey.emergencia,
      path: '/emergencia',
    },
    {
      key: RouteKey.informes,
      path: '/informes',
    },
    {
      key: RouteKey.vinculacaodecolaboradores,
      path: '/vinculacao',
    },
    {
      key: RouteKey.temporeal,
      path: '/temporeal',
    },
    {
      key: RouteKey.calibracao,
      path: '/calibracao',
    },
    {
      key: RouteKey.onepage,
      path: '/onepage',
    },
    {
      key: RouteKey.usuarios,
      path: '/usuarios',
    },
    {
      key: RouteKey.novasenha,
      path: '/novasenha',
    },
  ];

  let menu = [
    {
      key: 'monitoramento',
      description: 'Monitoramento',
      icon: MenuIcon.dashboard,
      path: '/monitoramento',
    },
    {
      key: 'indicadores',
      description: 'Indicadores',
      icon: MenuIcon.indicators,
      path: '/indicadores',
    },
    {
      key: 'planejamento',
      description: 'Planejamento',
      icon: MenuIcon.agilean,
      path: '/planejamento',
    },
    {
      key: 'analises',
      description: 'Analises',
      icon: MenuIcon.insights,
      path: '/analises',
    },
    {
      key: 'sumario',
      description: 'Sumario',
      icon: MenuIcon.summary,
      path: '/sumario',
    },
    {
      key: 'relatorios',
      description: 'Relatórios',
      icon: MenuIcon.reports,
      path: '/relatorios',
    },
    {
      key: 'evacuacao',
      description: 'Evacuação',
      icon: MenuIcon.emergency,
      path: '/emergencia',
    },
    {
      key: 'informes',
      description: 'Informes',
      icon: MenuIcon.insights,
      path: '/informes',
    },
    {
      key: 'vinculacaodecolaboradores',
      description: 'Vinculação de Colaboradores',
      icon: MenuIcon.collaboratorsLink,
      path: '/vinculacao',
    },
    {
      key: 'temporeal',
      description: 'Localizacao',
      icon: MenuIcon.realtime,
      path: '/temporeal',
    },
    {
      key: 'calibracao',
      description: 'Calibracao',
      icon: MenuIcon.calibration,
      path: '/calibracao',
    },
    {
      key: 'onepage',
      description: 'OnePage',
      icon: MenuIcon.onePage,
      path: '/onepage',
    },
    {
      key: 'usuarios',
      description: 'Usuarios',
      icon: MenuIcon.users,
      path: '/usuarios',
    },
    {
      key: 'novasenha',
      description: 'NovaSenha',
      icon: MenuIcon.newpassword,
      path: '/novasenha',
    },
  ];

  /*const authDataString: any = localStorage.getItem('authData');

  if (authDataString) {
    const authData = JSON.parse(authDataString);
    routes = authData.profileData.routes;
    menu = authData.profileData.menu;
  }*/

  if(authData && authData.routes && authData.menu){
    routes = authData.routes;
    menu = authData.menu;
  }

  return {
    rules: ['ADMIN'],
    routes: routes,
    menu: menu,
  };
}

export const getUserGroupPermissions: GetUserGroupPermissions = async () => ({
  rules: ['ADMIN'],
  routes: [
    {
      key: RouteKey.monitoramento,
      path: '/monitoramento',
    },
    {
      key: RouteKey.indicadores,
      path: '/indicadores',
    },
    {
      key: RouteKey.planejamento,
      path: '/planejamento',
    },
    {
      key: RouteKey.analises,
      path: '/analises',
    },
    {
      key: RouteKey.sumario,
      path: '/sumario',
    },
    {
      key: RouteKey.relatorios,
      path: '/relatorios',
    },
    {
      key: RouteKey.informes,
      path: '/informes',
    },
    {
      key: RouteKey.vinculacaodecolaboradores,
      path: '/vinculacao',
    },
    {
      key: RouteKey.temporeal,
      path: '/temporeal',
    },
    {
      key: RouteKey.calibracao,
      path: '/calibracao',
    },
    {
      key: RouteKey.onepage,
      path: '/onepage',
    },
    {
      key: RouteKey.usuarios,
      path: '/usuarios',
    },
    {
      key: RouteKey.novasenha,
      path: '/novasenha',
    },
  ],
  menu: [
    {
      key: 'monitoramento',
      description: 'Monitoramento',
      icon: MenuIcon.dashboard,
      path: '/monitoramento',
    },
    {
      key: 'indicadores',
      description: 'Indicadores',
      icon: MenuIcon.indicators,
      path: '/indicadores',
    },
    {
      key: 'planejamento',
      description: 'Planejamento',
      icon: MenuIcon.agilean,
      path: '/planejamento',
    },
    {
      key: 'analises',
      description: 'Analises',
      icon: MenuIcon.insights,
      path: '/analises',
    },
    {
      key: 'sumario',
      description: 'Sumario diário',
      icon: MenuIcon.summary,
      path: '/sumario',
    },
    {
      key: 'relatorios',
      description: 'Relatórios',
      icon: MenuIcon.reports,
      path: '/relatorios',
    },
    {
      key: 'informes',
      description: 'Informes',
      icon: MenuIcon.insights,
      path: '/informes',
    },
    {
      key: 'vinculacaodecolaboradores',
      description: 'Vinculação de Colaboradores',
      icon: MenuIcon.collaboratorsLink,
      path: '/vinculacao',
    },
    {
      key: 'temporeal',
      description: 'Localizacao',
      icon: MenuIcon.realtime,
      path: '/temporeal',
    },
    {
      key: 'calibracao',
      description: 'Calibracao',
      icon: MenuIcon.calibration,
      path: '/calibracao',
    },
    {
      key: 'onepage',
      description: 'OnePage',
      icon: MenuIcon.onePage,
      path: '/onepage',
    },
    {
      key: 'usuarios',
      description: 'Usuarios',
      icon: MenuIcon.users,
      path: '/usuarios',
    },
    {
      key: 'novasenha',
      description: 'NovaSenha',
      icon: MenuIcon.newpassword,
      path: '/novasenha',
    },
  ],
});
