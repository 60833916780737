/**
 * Função para transformar as primeiras letras das palavras em caixa alta
 * @example const text = capitalize('oficina central') = 'Oficina Central'
 */
export const capitalize = (text: string) => {
  const capitalized = text
    .toLowerCase()
    .split(' ')
    .map((v) => `${v[0].toUpperCase()}${v.slice(1)}`);
  return capitalized.join(' ');
};

export const CapitalizeSingleWord = (str: string) => {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
};