import { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import moment from 'moment';

import { getAbsenteeism } from '../../../../../../services/api/TrackfyService/integration';
import { useAuth } from '../../../../../../hooks';
import { useCompaniesContext } from '../../../../context/CompaniesContext';
import { parseSelectOptions } from '../../../../../../utils/Convert';
import { useAreasContext } from '../../../../context/AreasContext';

type absenteeismIndicator = {
  loading: boolean;
  value: string;
};

export const useAbsenteeism = () => {
  const [indicator, setIndicator] = useState<absenteeismIndicator>({ loading: true, value: 'Carregando...' });
  const { companySelected } = useCompaniesContext();
  const { projectSelected } = useAreasContext();
  const { getClientId } = useAuth();
  const clientId: number = getClientId();

  const getCompaniesForBraskem = ():string => {
    if(projectSelected == 1)
      return "94";

    else if(projectSelected == 2)
      return "30;105;106;107;108;109";

    return "0";
  }

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if (mounted) {
      const companies: string = clientId == 12 ? getCompaniesForBraskem() : parseSelectOptions(companySelected);
      setIndicator({ loading: true, value: 'Carregando...' });

      getAbsenteeism(
        clientId,
        companies,
        moment().format('YYYY-MM-DD'),
        currentRequest.token
      )
        .then((response: AxiosResponse<any>) => {
          let absenteeism = '0%';

          if (response.data) absenteeism = response.data.absenteeism;

          const indicatorText = absenteeism;
          setIndicator({ loading: false, value: indicatorText });
        })
        .catch((err: any) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('Absenteeism request canceled by the user.');
    };
  }, [companySelected, projectSelected]);

  return { indicator };
};

//const { selectedCompany } = useCompanyFilterContext();
//const companyId = selectedCompany?.id ? selectedCompany?.id : '0';
