import { useCallback, useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import { useInsightsContext } from '../../../../context/InsightsContext';
import { DeslocamentosCarro } from '../../../../types/Insights';
import { getMovingByCarInsight } from '../../../../../../services/api/TrackfyService';
import { useDeslocamentosCarroContext } from '../context/DeslocamentosPe';

export const useDeslocamentosCarro = () => {
  const { companySelected } = useInsightsContext();
  const { setDeslocamentosCarroValues, setDeslocamentosCarroChartData } =
    useDeslocamentosCarroContext();
  const [isLoading, setIsLoading] = useState(true);

  const dividerPerOne = useCallback((base: DeslocamentosCarro[]): Array<DeslocamentosCarro[]> => {
    const divisor: number = 2;
    const newArray: Array<DeslocamentosCarro[]> = [];

    for (let i = 0; i < base.length; i += divisor) {
      const divider: DeslocamentosCarro[] = base.slice(i, i + divisor);
      newArray.push(divider);
    }
    return newArray;
  }, []);

  const handleChartData = (data: DeslocamentosCarro[]) => {
    if (data && data.length > 0) {
      batchedUpdates(() => {
        setDeslocamentosCarroValues(dividerPerOne(data));
        setDeslocamentosCarroChartData(dividerPerOne(data)[0]);
      });
    } else {
      setDeslocamentosCarroChartData([{ nome: 'Sem Registros' }]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);
      batchedUpdates(() => setDeslocamentosCarroValues([]));

      getMovingByCarInsight(currentRequest.token)
        .then((result: AxiosResponse<any>) => handleChartData(result.data))
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('DeslocamentoPeChart request canceled by the user.');
    };
  }, [companySelected]);

  return {
    dividerPerOne,
    handleChartData,
    isLoading,
    setIsLoading,
  };
};
