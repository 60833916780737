import { useState, useEffect, useDebugValue } from 'react';

export const useMatchMedia = (media: number | string) => {
  const [match, setMatch] = useState<boolean>(false);

  useEffect(() => {
    const mediaQuery = typeof media === 'number' ? `(min-width: ${Number(media)}px)` : media;
    function changeMatch() {
      const { matches } = window.matchMedia(mediaQuery);
      setMatch(matches);
    }
    changeMatch();
    window.addEventListener('resize', changeMatch);

    return () => {
      window.removeEventListener('resize', changeMatch);
    };
  }, [media]);

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDebugValue({ media, match });
  }

  return !match;
};
