import React from 'react';
import styled from 'styled-components';

import IconUsers from '../../../../Icons/Users';

const Header = styled.div`
  display: flex;
  align-items: center;
  //margin-top: 0.9rem;
  margin-bottom: 2rem;
`;

const Titlecontainer = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 1.6rem;
  }
`;

const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0;
`;

export const ValueExpansionChartHeader: React.FC = () => (
  <Header>
    <Titlecontainer>
      <IconUsers />
      <Title>Expansão de valor</Title>
    </Titlecontainer>
  </Header>
);
