import React from 'react';

import IconUsers from '../../../../../../Icons/Users';

import * as S from '../styles';

export const DeslocamentosCarroChartHeader: React.FC = () => (
  <S.Header>
    <S.Titlecontainer>
      <IconUsers />
      <S.Title>Deslocamento a Carro</S.Title>
    </S.Titlecontainer>
  </S.Header>
);
