import React, { useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';

import { Counter } from '../../../../components/Counter';
import { Modal } from '../../../../components/Modal';
import IconAlert from '../../../../Icons/Alert';
import IconClose from '../../../../Icons/Close';
import { CheckGuardIcon } from '../../../../Icons/CheckGuard';
import { CircleAlert } from '../../../../Icons/CircleAlert';
import { CircleCheck } from '../../../../Icons/CircleCheck';
import { EvacuationAreasBoard } from './EvacuationAreasBoard';
import { AreasInEvacuationSnapshot,
         evacuationButtonTexts, 
         EvacuationSnapshot} from '../../types/Emergency';
import { MapAreasData } from '../../../../types/MonitoredData';
import { useIsMounted, useMatchMedia } from '../../../../hooks';
import { useEvacuationContext } from '../../context/EvacuationContext';

import * as S from './styles';
import { IconLoading } from '../../../../components/IconLoading';

export interface EvacuationProps {
  isEvacuating: boolean;
  requestedAreas: MapAreasData[];
  userId: number;
}

export const Evacuation: React.FC<EvacuationProps> = ({isEvacuating, requestedAreas, userId}) => {
  const { current: map } = useMap();
  const isMounted = useIsMounted();
  //const [eventsAdded, setEventsAdded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isMobile = useMatchMedia(767);
  const { data, 
          setData,
          handleEvacuationCancel,
          handleEvacuationInit, 
          handleDangerAreasSelection, 
          handleEvacuationMonitoringStartOrFinish } = useEvacuationContext();
  
  useEffect(() => {
    isMounted.current = true;

    if (map) {
      const mapStyle = map.getCanvas().style;

      map.on('dragstart', () => mapStyle.cursor = 'grabbing');

      map.on('dragend', () => mapStyle.cursor = 'grab');

      requestedAreas.forEach((area, idx) => {
        map.on('mouseover', `id_fill_${area.nome}`, () => mapStyle.cursor = 'pointer');
        
        map.on('mouseleave', `id_fill_${area.nome}`, () => mapStyle.cursor = 'grab');

        map.on('click', `id_fill_${area.nome}`, () => {
          if(data.mode !== "standby") return;
          //if (eventsAdded) return;
          mapStyle.cursor = 'pointer';
          handleDangerAreasSelection(area, isMounted.current);
        });

        //if (idx === requestedAreas.length - 1 && isMounted.current) setEventsAdded(true);
      });
    }

    return () => { isMounted.current = false; }
  }, [map, isEvacuating, /*eventsAdded*/]);

  const handleCancelButton = () => {
    handleEvacuationCancel();
    setShowModal(false);
  }

  const handleEvacuationFinalizationCancel = () => setShowModal(false);

  const handleEmergencyButton = () => {
    if(data.mode == "notevacuating"){
      logInformation("Selecao de areas", data);
      handleEvacuationInit(requestedAreas);
    }

    if(data.mode === 'standby' && data.dangerAreas.length) {
      logInformation("Iniciando", data);
      handleEvacuationMonitoringStartOrFinish(true);
    } 
    
    else if (data.mode === "evacuating") {
      logInformation("Finalizando", data);
      setShowModal(true);
    }
  }

  const handleModalConfirmationButton = () => {
    handleEvacuationMonitoringStartOrFinish(false);
    logInformation("Finalizado", data);
    setShowModal(false);
  }

  const showTopCard = () => {
    if(data.loadingData)
      return <><IconLoading customStyle={{color: "#E24444"}} /> Carregando dados...</>;

    if(data.mode === "standby")
      return "Selecione a(s) área(s) para evacuação";
    else if(data.mode === "evacuating")
      return <Counter style={{ fontSize: '2rem', fontWeight: 700 }}
                getElapsedTime={(seconds) => { setData((prev: EvacuationSnapshot) => ({...prev, elapsedTime: seconds})) }} />
    else 
      return null;
  }

  const peopleIsInDanger = ():boolean => {
    if(data.collaboratorsInDangerAreas.length == 0)
      return false;

    console.log("condicao 1");

    const safeArea = data.currentSnapshot.find((area: AreasInEvacuationSnapshot) => area.area == data.safeArea);
    if(!safeArea) return true;

    console.log("condicao 2");

    const peopleInDanger = data.currentSnapshot.find((area: AreasInEvacuationSnapshot) => 
      data.dangerAreas.find((areaInDanger: string) => areaInDanger == area.area) && area.total > 0);

    if(peopleInDanger) return true;

    console.log("condicao 3");

    const collaboratorsSafe: boolean[] = data.collaboratorsInDangerAreas.map((colInDanger: string) => {
        const safeCollaborator = safeArea.collaborators.find((scolaborattor: string) => scolaborattor == colInDanger);  
        console.log(safeCollaborator);

        if(safeCollaborator)
          return true;
        console.log("condicao 4");

        return false;
    });

    console.log("condicao 5");

    if(collaboratorsSafe.find((result: boolean) => !result))
      return false;

    console.log("condicao 6");
    return true;
  }

  const logInformation = (message: string, object: any): void => {
    if(userId== 7 || userId== 8 || userId == 22 || userId == 42 || userId == 48 || userId == 51 || userId == 65 || userId == 68){ 
      console.log(`==========================${message}==========================`);
      console.log(object);
      console.log(`==========================${message}==========================`);
    }
  }
  
  return (
    <>
      <S.EvacuationButton mode={data.mode}
                          isMobile={isMobile}
                          active={(data.mode != "notevacuating") && !data.dangerAreas.length}
                          onClick={handleEmergencyButton} >
        { data.mode === "evacuating" ? <CheckGuardIcon /> : <IconAlert fill="#FFF" width="20" height="20" /> }
        { data.mode != "notevacuating" ? evacuationButtonTexts[data.mode] : "Modo de evacuação" }
      </S.EvacuationButton>
      
      { data.mode === "standby" ? (
          <S.EvacuationCancelButton mode={data.mode}
                                    isMobile={isMobile}
                                    active
                                    onClick={handleCancelButton}>
            <S.IconBorder><IconClose fill="#ed3a3a" /></S.IconBorder>
            Cancelar
          </S.EvacuationCancelButton> ) 
      : null }

      <S.EvacuationSelectAreas isMobile={isMobile} active={data.mode != "notevacuating"} mode={data.mode}>
        {showTopCard()}
      </S.EvacuationSelectAreas>

      <Modal visible={showModal} cardStyle={{ flexDirection: 'column', alignItems: 'center', padding: '3rem 4rem' }}>
        <>
          {true ? <CircleAlert /> : <CircleCheck />}
          <S.H1ModalCard success={false}>Finalizar modo de evacuação?</S.H1ModalCard>
          <S.TextModalCard>Certifique-se de que todas as pessoas saíram das áreas de risco.</S.TextModalCard>
          <S.ModalFooterButtons>
            <S.ModalCancelButton success={false} onClick={handleEvacuationFinalizationCancel}>
              Cancelar
            </S.ModalCancelButton>
            <S.ModalConfirmButton
              success={false}
              onClick={handleModalConfirmationButton}
            >
              Finalizar
            </S.ModalConfirmButton>
          </S.ModalFooterButtons>
        </>
      </Modal>

      { data.mode === "evacuating" ? (<EvacuationAreasBoard evacuationSnapshot={data} />) : (<></>) }
    </>
  );
};


{/*
     <Modal visible={showModal} cardStyle={{ flexDirection: 'column', alignItems: 'center', padding: '3rem 4rem' }}>
        <>
          {stillHavePeopleToEvacuate ? <CircleAlert /> : <CircleCheck />}
          <S.H1ModalCard success={!stillHavePeopleToEvacuate}>Finalizar modo de evacuação?</S.H1ModalCard>
          <S.TextModalCard>
            {stillHavePeopleToEvacuate
              ? 'Ainda existem pessoas nas áreas a serem evacuadas!'
              : 'Todas as pessoas que estavam na área de risco já estão na área segura!'}
          </S.TextModalCard>
          <S.ModalFooterButtons>
            <S.ModalCancelButton success={!stillHavePeopleToEvacuate} onClick={handleCancelButton}>
              Cancelar
            </S.ModalCancelButton>
            <S.ModalConfirmButton
              success={!stillHavePeopleToEvacuate}
              onClick={handleModalConfirmationButton}
            >
              Finalizar
            </S.ModalConfirmButton>
          </S.ModalFooterButtons>
        </>
      </Modal>  
*/}