import styled from 'styled-components';

type TableComponentsProps = {
    height?: string;
    width?: string;
    color?: string;
};

export const LoadingContainer = styled.div`
    width: '100%';
    height: '100vh';
    display: 'flex';
    justifyContent: 'center';
    alignItems: 'center';
`;

export const MonitoredAreasContainer = styled.div`
  position: absolute;
  width: 20%;
  top: 18px;
  left: 40px;
`;

export const ClientTagsContainer = styled.div`
    margin-left: 650px
`;

export const Container = styled.div`
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 100px;
    min-height: 100px;
    max-height: 98.5%;
    padding: 10px;
    gap: 10px;
    //background: #fff;
    background: #f3f3f3;
    border-radius: 5px;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 10px;
        height: 8px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f3f3f3;
        //background: #ffffff;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #cccccc;
        border-radius: 10px;
    }
`;

export const Area = styled.tr`
    border: solid 2px #4f5882;
    border-radius: 3px;
    padding: 1px;
`;

export const ClockContainer = styled.div`
    display: flex;
    justify-content: center;
    border: solid 3px #4f5882;
    border-radius: 5px;
    padding: 5px;
    font-size: x-large;
`;

export const Title = styled.div`
    border-bottom: solid 1px #0000ff;
    font-size: medium;
    font-weight: bold;
`;

export const LocationContainer = styled.div`
    display: flex;
    gap: 10px;
`;

export const Location = styled.div`
    padding: 2px;
    display: flex;
    gap: 10px;
`;

export const LocationItem = styled.div`
    font-size: medium;
`;

export const AreaPin = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 5px;
  top: 0;
  left: 0;
`;

export const Trstyled = styled.tr`
    height: ${(props: TableComponentsProps) => props.height};
    background: ${(props: TableComponentsProps) => props.color};
    vertical-align: middle;
`;

export const Tdstyled = styled.td`
    width: ${(props: TableComponentsProps) => props.width};
    font-weight: 600;
`;

