import React, { ReactElement } from 'react';

import { Tab } from '../../../components/Tabs/Tab';
import { Tabs } from '../../../components/Tabs';

import { ClientRegistrationContainer } from './Client';
import { CompanyRegistrationContainer } from './Company';
import { AreasRegistrationContainer } from './Area';

type PageTabsProps = {
  isAdmin: boolean;
}

export const PageTabs: React.FC<PageTabsProps> = ({isAdmin}) => {
  const children: ReactElement[] = [];
  
  if(isAdmin)
    children.push(<Tab title={"Clientes"} key={'Tab_Clientes'}><ClientRegistrationContainer /></Tab>);

  children.push(<Tab title={"Empresas"} key={'Tab_Empresas'}><CompanyRegistrationContainer isAdmin={isAdmin} /></Tab>);
  children.push(<Tab title={"Áreas"} key={'Tab_Áreas'}><AreasRegistrationContainer isAdmin={isAdmin} /></Tab>);

  return <Tabs children={children} />;
};
