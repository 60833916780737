import { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import moment from 'moment';

import { useProductivityContext } from '../../../context/PageProductivityContext';
import { usePeopleInAreaContext } from '../context/PeopleInAreasContext';
import { PeopleInAreaDate } from '../../../../indicators/types/Indicators';
import { getPeopleInAreasChartData } from '../../../../../services/api/TrackfyService';
import { useAuth } from '../../../../../hooks';
import { parseSelectOptions } from '../../../../../utils/Convert';

export const usePeopleInAreas = () => {
  const { companySelected, professionSelected, initialDate, finalDate } = useProductivityContext();
  const { setPeopleInAreasChartData, setPeopleValues, dividerPerFifteen } = usePeopleInAreaContext();
  const [isLoading, setIsLoading] = useState(true);
  const { getClientId } = useAuth();
  const clientId: number = getClientId();

  const handleChartData = (data: PeopleInAreaDate[]) => {
    if (data && data.length > 0) {
      batchedUpdates(() => {
        setPeopleValues(dividerPerFifteen(data));
        setPeopleInAreasChartData(dividerPerFifteen(data)[0]);
      });
    } else {
      setPeopleInAreasChartData([]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);
      batchedUpdates(() => setPeopleValues([]));

      getPeopleInAreasChartData(
        clientId,
        parseSelectOptions(companySelected),
        parseSelectOptions(professionSelected),
        moment(initialDate).startOf('day').valueOf(),
        moment(finalDate).valueOf(),
        currentRequest.token,
      )
        .then((result: AxiosResponse<any>) => handleChartData(result.data))
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('PeopleInAreasChart request canceled by the user.');
    };
  }, [companySelected, professionSelected, initialDate, finalDate]);

  return {
    handleChartData,
    isLoading,
    setIsLoading,
  };
};
