import React, { useEffect, useState } from 'react';
import Axios, { CancelToken, CancelTokenSource } from 'axios';

import { getLocationsByArea } from '../../../services/api/TrackfyService/integration';
import { useAuth, useIsMounted } from '../../../hooks';
import { AreaMarker } from '../components/AreaMarker';
import { parseSelectOptions } from '../../../utils/Convert';
import { useCompaniesContext } from '../../../containers/realTime/context/CompaniesContext';
import { useAreasContext } from '../../../containers/realTime/context/AreasContext';
import { MonitoredAreasInEmergencyData } from '../../../types/MonitoredData';

import * as S from '../styles';

export type currentColabCountIndicator = {
  loading: boolean;
  value: string;
};

export const useAreaCollaborators = (individual: boolean, 
                                     mode: string,
                                     setIndicator?: (indicator: currentColabCountIndicator) => void, 
                                     dangerAreas?: string[],
                                     timeout?: number) => {

  const [areaCollaborators, setAreaCollaborators] = useState<MonitoredAreasInEmergencyData[]>([]);
  const { getClientId, getUserName } = useAuth();
  const clientId = getClientId();
  const { companySelected } = useCompaniesContext();
  const { projectSelected } = useAreasContext();
  const currentRequest: CancelTokenSource = Axios.CancelToken.source();
  const mountedRef = useIsMounted();

   const defineCollaboratorsTotal = (isMounted: boolean,
                                    data:MonitoredAreasInEmergencyData[],
                                    setIndicator?: (indicator: currentColabCountIndicator) => void): void => {

    let currentActiveCollaborators = 0;
    
    data.forEach((value:MonitoredAreasInEmergencyData) => {
      if (value.nome != 'ÁREA NÃO MAPEADA' && value.nome != 'Sala capacetes') {
        if(clientId == 4){
          if (value.nome != 'Refeitorio' && value.nome != 'Gremio')
            currentActiveCollaborators += value.total;
        } else if (clientId == 14) {
          if (value.nome != 'Almoxarifado' && value.nome != 'Catraca' 
              && value.nome != 'Area de convivencia' && value.nome != 'Area Adm')
            currentActiveCollaborators += value.total;
        } else if (clientId == 13) {
          if (value.nome != 'PT')
            currentActiveCollaborators += value.total;
        } else if (clientId == 15) {
          if (value.nome != 'Almoxarifado' && value.nome != 'Casa de PT')
            currentActiveCollaborators += value.total;
        } else {
          currentActiveCollaborators += value.total;
        } 
      }
    });

    if(isMounted && setIndicator)
      setIndicator({loading: false, value: currentActiveCollaborators.toString()});
  }

  const getCompaniesForBraskem = ():string => {
    if(projectSelected == 1)
      return "94";

    else if(projectSelected == 2)
      return "30;105;106;107;108;109";

    return "0";
  }

  const requestCollaboratorsInAreas = (individual: boolean, 
                                      mounted: boolean, 
                                      setAreaCollaborators:(data:MonitoredAreasInEmergencyData[]) => void, 
                                      token: CancelToken, 
                                      clientId: number, 
                                      setIndicator?: (indicator: currentColabCountIndicator) => void) => {

    const evc =  individual ? individual : false;

    if(mounted) {
      const companies: string = clientId == 12 ? getCompaniesForBraskem() : parseSelectOptions(companySelected);

      getLocationsByArea(clientId, token, companies, evc)
      .then((data:MonitoredAreasInEmergencyData[])=> {      
          if(mounted) {
            const hasData = data.length > 0;
            const isAdm = (getUserName().startsWith("_") && getUserName().includes("admin"));
            const isAdminuser = hasData || isAdm;

            if(isAdminuser){
              defineCollaboratorsTotal(mounted, data, setIndicator);
              setAreaCollaborators(data);
            }
          }
      })
      .catch((err) => {
        console.log(err);
        setAreaCollaborators([]);
      });
    }
  }

  const renderMarkers = (individual: boolean, 
                         areaCollaboratorsData:MonitoredAreasInEmergencyData[], 
                         clientId:number, 
                         mode: string, 
                         dangerAreas?: string[], 
                         safeArea?: string): JSX.Element[] => {

    const markerComponents: JSX.Element[] = [];

    areaCollaboratorsData.map((i:MonitoredAreasInEmergencyData) => {
        if (i.nome != 'ÁREA NÃO MAPEADA') {
            if(clientId == 11 && i.nome == 'Sala capacetes') {
              markerComponents.push(
                <S.FixedAreaCard key={"helmetaarea"} color={"#ccc"} top="52px">
                  Sala capacetes: {i.total}
                </S.FixedAreaCard>);
            } else { 
                const notGroup = (clientId == 11 || individual) ? true : false;
                const grpByTeam = (clientId == 14 && !individual) ? true : false;

                let collaboratorsData: any[] = i.data;

                if(clientId == 11 || individual)
                  collaboratorsData = i.collaborators;
                else if(clientId == 14)
                  collaboratorsData = i.teams;

                if(clientId == 4) {
                  if(i.nome != 'Sala capacetes') 
                    markerComponents.push(
                      <AreaMarker
                          key={i.nome + "_" + i.total}
                          lng={i.lng}
                          lat={i.lat}
                          iconContent={i.total}
                          contentCollaborators={collaboratorsData}
                          contentName={i.nome}
                          notGroup={notGroup}
                          groupByTeam={grpByTeam}
                          color={i.issafearea ? "#33B469" : "#ED3A3A"}
                          mode={mode}
                          dangerAreas={dangerAreas}
                          safeArea={safeArea}
                      />);
                } else {
                  markerComponents.push(
                    <AreaMarker
                        key={i.nome + "_" + i.total}
                        lng={i.lng}
                        lat={i.lat}
                        iconContent={i.total}
                        contentCollaborators={collaboratorsData}
                        contentName={i.nome}
                        notGroup={notGroup}
                        groupByTeam={grpByTeam}
                        color={i.issafearea ? "#33B469" : "#ED3A3A"}
                        mode={mode}
                        dangerAreas={dangerAreas}
                        safeArea={safeArea}
                    />);
                }
            }
        } else {
            markerComponents.push(
              <S.FixedAreaCard key={"notfoundArea"} color='red' top="0px">
                Área Não Mapeada: {i.total}
              </S.FixedAreaCard>);
        }
      });

      return markerComponents;   
  }

  useEffect(() => {
    mountedRef.current = true;
    let interval: NodeJS.Timeout | undefined = undefined;

    if(mountedRef.current) {
      requestCollaboratorsInAreas(individual, 
                                 mountedRef.current, 
                                 setAreaCollaborators, 
                                 currentRequest.token, 
                                 clientId, 
                                 setIndicator);

      const fetchData = () => requestCollaboratorsInAreas(individual, 
                                                         mountedRef.current, 
                                                         setAreaCollaborators, 
                                                         currentRequest.token, 
                                                         clientId, 
                                                         setIndicator); 
        
      interval = setInterval(fetchData, timeout || 40000);
    }

    return () =>{ 
      if (interval) clearInterval(interval);
      currentRequest.cancel('useAreaCollaborators request canceled by the user.');
      mountedRef.current = false; 
    }

  }, [companySelected, projectSelected]);

  useEffect(() => {
    mountedRef.current = true;
    const evacuationIsOcurring = mountedRef.current 
                               && mode !== "notevacuating"
                               && dangerAreas 
                               && dangerAreas.length > 0;
  
    return () => { mountedRef.current = false }
    
  }, [areaCollaborators]);

  return { areaCollaborators, renderMarkers, clientId };
}