import { TooltipContent } from "../../../components/Info/Tooltip";

export const getLegend = (): any => {
    let legends: any[] = [
      {
        value: 'Quantidade de recursos registrados',
        name: 'Quantidade de recursos registrados',
        marker: {
          symbol: 'square',
          style: {
            fill: '#468FEA',
            r: 5,
          },
        },
      },
      {
        value: 'Quantidade de recursos disponíveis',
        name: 'Quantidade de recursos disponíveis',
        marker: {
          symbol: 'square',
          style: {
            fill: '#4F5882',
            r: 5,
          },
        },
      },
    ];

    return legends;
};

export const getTile = (clientId: number): String => {
  return 'Quantidade de recursos no período';
};

export const getTooltipContent = (clientId: number): TooltipContent[] => {
  return [
    {
      title: 'Quantidade de recursos - ',
      content: 'Quantidade de recursos (colaboradores) registrados em áreas produtivas durante o período.',
      color: '#4F5882'
    },
    {
      title: 'Quantidade de recursos - ',
      content: 'Quantidade de recursos (colaboradores) disponíveis durante o período.',
      color: '#468FEA'
    }
  ];
};