import Axios, { CancelTokenSource } from 'axios';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { IconLoading } from '../../../components/IconLoading';
import { useTrackfyApi } from '../../../hooks/useTrackfyApi';
import Incident from '../../../Icons/Incident';
import { useConformityContext } from '../context/ConformityContext';
import { useTimelineContext } from './context/TimelineContext';
import { useTimeline } from './hooks/useTimeline';
import { TimelineLine } from './TimelineLine';
import { useAuth } from '../../../hooks';
import { parseSelectOptions } from '../../../utils/Convert';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-center;
  justify-content: center;

  @media (max-width: 1000px) {
    & > div {
      padding-top: 2.5rem;
    }
  }

  & > div:first-child {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: auto;

    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
    }
  }

  & > div:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    margin-left: -1.5%;

    @media (max-width: 1000px) {
      //margin-top: -6rem;
    }
  }
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & span {
    font: normal 500 1.5rem/1.5 Work Sans, sans-serif;
    color: #4f5882;
  }
`;

const TimelineContent: React.FC<{}> = () => {
  const { areaSelected, companySelected, professionSelected, initialDate, finalDate } = useConformityContext();
  const { timelineData, setTimelineData, setTimelineValues } = useTimelineContext();
  const { getTimelineDataApi } = useTrackfyApi();
  const { dividerPerTwentyfour, isLoading, isEmpty, setIsLoading, setIsEmpty } = useTimeline();
  const { getClientId } = useAuth();

  useEffect(() => {
    let mounted = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted)
      getTimelineDataApi(
        getClientId(),
        parseSelectOptions(areaSelected),
        parseSelectOptions(companySelected),
        parseSelectOptions(professionSelected),
        initialDate,
        finalDate,
        currentRequest.token,
        setTimelineData,
        setTimelineValues,
        dividerPerTwentyfour,
        setIsLoading,
        setIsEmpty,
      );

    return () => {
      mounted = false;
      currentRequest.cancel('Timeline request canceled by the user.');
    };
  }, [companySelected, professionSelected, initialDate, finalDate, areaSelected]);

  if (isLoading) return <IconLoading customDivStyle={{marginTop:"20px", marginLeft:"45%", marginRight:"45%"}}/>;

  if (isEmpty)
    return (
      <EmptyContainer>
        <Incident />
        <span>Não há dados para o período selecinado</span>
      </EmptyContainer>
    );

  return (
    <Container>
      <div>
        {timelineData.map((i) => (
          <TimelineLine
            key={timelineData.indexOf(i)}
            blink={i.blink}
            background={i.background}
            color={i.color}
            count={i.count}
            time={i.time}
            width={timelineData.length}
            data={i.data}
          />
        ))}
      </div>
    </Container>
  );
};

export default TimelineContent;

/* useEffect(()=>{
  },[getTimelineData,
      setTimelineData,
      setTimelineValues
  ]); */

/* useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted: boolean = true;

    getTimelineData(currentRequest.token, projectSelctValue)
    .then((response: AxiosResponse<any>) => {
      if (mounted) {
        if(!response.data || response.data.length === 0){
            setTimelineData([]);
            setIsEmpty(true);
        } else {
          const dividerPerEighteen = (base: any[]): Array<any[]> => {
            const divisor: number = 18;
            const newArray: Array<any[]> = [];

            for (let i = 0; i < base.length; i += divisor) {
              const divider: any[] = base.slice(i, i + divisor);
              newArray.push(divider);
            }
            return newArray;
          };

          batchedUpdates(() => {
            setTimelineValues(dividerPerEighteen(response.data));
            setTimelineData(dividerPerEighteen(response.data)[0]);
          });
        }
        setIsLoading(false);
      }
    })
    .catch((error: AxiosResponse<any>) => console.log(error));

    return () => {
      currentRequest.cancel('Operation canceled by the user.');
      mounted = false;
    };
  }, [initialDate,
      finalDate,
      projectSelctValue,
      companiesSelctValue,
      professionsSelctValue,
      areasSelctValue
    ]); */

/* const getTimelineData= async (
    cancelToken: CancelToken,
    projValue?: number,
  ) => {
    const userId = localStorage.getItem(USER_ID) ? localStorage.getItem(USER_ID) : '1';
    const initDate: number = moment(initialDate).startOf('day').valueOf();
    const fimDate: number = moment(finalDate).valueOf();

    let projectoValue: number | undefined = projValue;
    if (projectSelctValue && projectSelctValue !== 0) projectoValue = projectSelctValue;

    let compValue: number = 0;
    if (companiesSelctValue) compValue = companiesSelctValue;

    let funcValue: number = 0;
    if (professionsSelctValue) funcValue = professionsSelctValue;

    let areaValue: number = 0;
    if (areasSelctValue) areaValue = areasSelctValue;

    setIsEmpty(false);
    setIsLoading(true);

    return fetchData(
      token,
      `${baseUrl}/statistics/v1/timeline`,
      {
        project: projectoValue,
        userId:userId,
        companyCod: compValue,
        professionCod:funcValue,
        areaCod:areaValue,
        initialDate: initDate,
        finalDate: fimDate,
        currentDate: moment().valueOf()
      },
      cancelToken
    );
  }; */
