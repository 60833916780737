import styled from 'styled-components';

export const Title = styled.div`
  color: #808080;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
`

export const Date = styled.div`
  color: #2F80ED;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  
  padding-left: 5px;
`

export const LastUpdateContainer = styled.div`
  display: flex
`