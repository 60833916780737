import React from 'react';
import styled from 'styled-components';

import HeatMapContent from './HeatMapContent';
import { Shadow } from '../../../components';
import Legend from './Legend';

const HeatMapCard = styled(Shadow)`
  flex-basis: 100%;
  width: 100%;
  height: 100%;
  min-height: 590px;
  max-height: 600px;
  /*padding: 2.4rem 2.4rem 1.6rem 3rem;*/
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const HeatMap: React.FC = () =>
    <HeatMapCard>
        <HeatMapContent />
        <Legend />
    </HeatMapCard>

export default HeatMap;