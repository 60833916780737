import moment from 'moment';
import React, { createContext, ReactNode, useState, useContext } from 'react';
import { useAuthContext } from '../../../stores/AuthContext';

import { getLasCommercialMommentDate } from '../../../utils/DateTime';

type ContextValue = {
  date: moment.Moment;
  companySelected: number;
  setCompanySelected: (companie: number) => void;
  updatePeriod: (initial: any, final: any) => void;
};

type Props = {
  children: ReactNode;
};

const DailySummaryContext = createContext({} as ContextValue);

export function DailySummaryContextProvider(props: Props) {
  const { children } = props;

  const {authData} = useAuthContext();

  const [date, setDate] = useState<moment.Moment>(getLasCommercialMommentDate());

  const companyId = (authData && authData.grpCompanies != 'a' && (!authData.grpCompanies.includes(","))) ?
    authData.companyId:0;
    
  const [companySelected, setCompanySelected] = useState<number>(companyId);

  const updatePeriod = (initial: any, final: any) => {
    console.log(final);
    setDate(initial);
  };

  const value = {
    date,
    companySelected,
    setCompanySelected,
    updatePeriod,
  };

  return <DailySummaryContext.Provider value={value}>{children}</DailySummaryContext.Provider>;
}

export function useDailySummaryContext() {
  const context = useContext(DailySummaryContext);

  if (typeof context === 'undefined') {
    throw new Error('DailySummaryContext must be used within an useDailySummaryContext');
  }

  return context;
}
