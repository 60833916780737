import { SignIn, IAuthRequest, IAuthResponse, GetRefreshToken } from '.';
import api from '../../api';

export const changePassAndSignIn: SignIn = async (request: IAuthRequest): Promise<IAuthResponse> => {
  const { user, password, recaptchaToken } = request;
  const body = { user, password, token: recaptchaToken };

  const endpoint = 'https://backend.trackfyapp.com/rest/auth/register';
  //const endpoint = 'http://localhost:8083/rest/auth/register';
  const response = await api.post<IAuthResponse>(endpoint, body);
  return response.data;
};

export const signIn: SignIn = async (request: IAuthRequest): Promise<IAuthResponse> => {
  const { user, password, recaptchaToken } = request;
  const body = { user, password, token: recaptchaToken };

  const endpoint = 'https://backend.trackfyapp.com/rest/auth/login';
  //const endpoint = 'http://localhost:8083/rest/auth/login';
  const response = await api.post<IAuthResponse>(endpoint, body);
  return response.data;
};

export const getRefreshToken: GetRefreshToken = async (refreshToken: string): Promise<string> => {
  const endpoint = 'https://backend.trackfyapp.com/rest/auth/refreshToken';
  const response = await api.post(endpoint, {
    refreshToken,
  });
  const { accessToken } = response.data;
  return accessToken;
};
