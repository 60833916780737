import React from 'react';

import ChartDown from '../../../../../Icons/ChartDown';
import { IconLoading } from '../../../../../components/IconLoading';

import * as S from '../styles';

type RankChartLabelProps = {
  value: any;
  isLoadingAverage: boolean;
  percentage?: boolean;
};

export const RankChartLabel: React.FC<RankChartLabelProps> = ({
  value = 0,
  isLoadingAverage = true,
  percentage = false,
}: RankChartLabelProps) => {
  if (isLoadingAverage) return <IconLoading />;
  return (
    <S.RankLabelContainer>
      <S.RankLabelValue>
        {value}
        {`${percentage ? '%' : ''}`}
      </S.RankLabelValue>
      <ChartDown />
    </S.RankLabelContainer>
  );
};
