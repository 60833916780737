export type Question = {
  question: string;
  link: string;
  filter: string[];
};

export const filters: string[] = [
  'Documentação',
  'Funcionamento',
  'Geral',
  'Monitoramento',
  'Indicadores',
  'Login',
  'Sistema web',
  'Trackfy',
];

export const AbouSystemQuestionsAndAnswer: Question[] = [
  {
    question: 'Qual o raio de alcance do scanner de vocês?',
    link: '',
    filter: [''],
  },
  {
    question: 'Qual a precisão do scanner?',
    link: '',
    filter: [''],
  },
  {
    question: 'Qual a voltagem de operação dos hardwares a serem instalados em campo?',
    link: '',
    filter: [''],
  },
];

export const CommonQuestionsAndAnswer: Question[] = [
  {
    question: 'Qual o raio de alcance do scanner de vocês?',
    link: '',
    filter: [''],
  },
  {
    question: 'Qual a precisão do scanner?',
    link: '',
    filter: [''],
  },
  {
    question: 'Qual a voltagem de operação dos hardwares a serem instalados em campo?',
    link: '',
    filter: [''],
  },
];

export const FirstStepsQuestionsAndAnswer: Question[] = [
  {
    question: 'Qual o raio de alcance do scanner de vocês?',
    link: '',
    filter: [''],
  },
  {
    question: 'Qual a precisão do scanner?',
    link: '',
    filter: [''],
  },
  {
    question: 'Qual a voltagem de operação dos hardwares a serem instalados em campo?',
    link: '',
    filter: [''],
  },
];

export const DocumentationSubjectQuestionsAndAnswers: Question[] = [
  {
    question: 'Como é a relação da Trackfy com a LGPD (Lei Geral de Proteção de Dados Pessoais)?',
    link: '',
    filter: ['Trackfy', 'Documentação'],
  },
  {
    question: 'Como fazer orçamento para um novo modelo?',
    link: '',
    filter: ['Trackfy', 'Documentação'],
  },
  {
    question: 'A Trackfy cobra por licença (Nº de Usuários)?',
    link: '',
    filter: ['Trackfy', 'Documentação'],
  },
];

export const GeneralSubjectQuestionsAndAnswers: Question[] = [
  {
    question: 'Como são divididas as áreas entre produtivas e improdutivas?',
    link: '',
    filter: ['Sistema web', 'Geral'],
  },
  {
    question: 'Como saber quem são as pessoas mostradas no sistema?',
    link: '',
    filter: ['Sistema web', 'Geral'],
  },
  {
    question: 'Quanto tempo leva para o que acontece em campo ser mostrado na tela? (latência)?',
    link: '',
    filter: ['Sistema web', 'Geral'],
  },
  {
    question: 'Como funciona o monitoramento em áreas não mapeadas?',
    link: '',
    filter: ['Sistema web', 'Geral'],
  },
];

export const IndicatorsSubjectQuestionsAndAnswers: Question[] = [
  {
    question: 'Qual a diferença entre o gráfico ‘Planejado x Realizado’ e a ‘Linha do tempo de presença’?',
    link: '',
    filter: ['Sistema web', 'Indicadores'],
  },
];

export const LoginQuestionsAndAnswers: Question[] = [
  {
    question: 'Esqueci minha senha, e agora?',
    link: '',
    filter: [''],
  },
];

export const MonitoringQuestionsAndAnswers: Question[] = [
  {
    question: 'De onde vem o HH monitorado?',
    link: '',
    filter: ['Sistema web', 'Monitoramento'],
  },
  {
    question: 'O que são colaboradores ativos?',
    link: '',
    filter: ['Sistema web', 'Monitoramento'],
  },
  {
    question: 'O mapa está desatualizado, o que fazer?',
    link: '',
    filter: ['Sistema web', 'Monitoramento'],
  },
];

export const OperationQuestionsAndAnswers: Question[] = [
  {
    question: 'Qual a base utilizada pra calcular os indicadores?',
    link: '',
    filter: ['Trackfy', 'Funcionamento'],
  },
  {
    question: 'O que acontece se a conectividade cair no local monitorado?',
    link: '',
    filter: ['Trackfy', 'Funcionamento'],
  },
  {
    question: 'Qual tipo de alimentação utilizada para os hardwares e como mudá-la?',
    link: '',
    filter: ['Trackfy', 'Funcionamento'],
  },
];

export const allQuestions: Question[] = [
  ...AbouSystemQuestionsAndAnswer,
  ...CommonQuestionsAndAnswer,
  ...FirstStepsQuestionsAndAnswer,
  ...DocumentationSubjectQuestionsAndAnswers,
  ...GeneralSubjectQuestionsAndAnswers,
  ...IndicatorsSubjectQuestionsAndAnswers,
  ...LoginQuestionsAndAnswers,
  ...MonitoringQuestionsAndAnswers,
  ...OperationQuestionsAndAnswers,
];
