import React from 'react';

import Sucess from '../../../../Icons/Sucess';
import * as S from './styles';

export type ResultListItem = {
  id: string;
  label: string;
};

type ResultListProps = {
  item: ResultListItem;
  selectedItems?: ResultListItem | ResultListItem[] | null;
  onSelect?: (value: ResultListItem) => void;
};

export const ResultList: React.FC<ResultListProps> = (props: ResultListProps) => {
  const { item, onSelect, selectedItems } = props;

  const handleSelectOption = (value: ResultListItem) => {
    if (onSelect) onSelect(value);
  };

  const SuccesIcon = () => {
    if (selectedItems instanceof Array) {
      const Items = selectedItems?.map((item: ResultListItem) => item.id).includes(item.id) && (
        <S.SuccessIconContainer>
          <Sucess />
        </S.SuccessIconContainer>
      );

      return Items;
    }
  };

  return (
    <>
      {item && (
        <S.Item id={item.id} onClick={() => handleSelectOption(item)}>
          <S.Label>{item.label}</S.Label>
          {selectedItems && SuccesIcon()}
        </S.Item>
      )}
    </>
  );
};
