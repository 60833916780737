import React from 'react';

import { Search } from '../../../../../components';
import { ResultListItem } from '../../../../../components/Search/components';
import IconFilter from '../../../../../Icons/Filter';
import { useCompanyFilter } from './useCompanyFilter';

export const CompanyFilter: React.FC = () => {
  const {
    isActive,
    value,
    resultList,
    setValue,
    handleActiveFilter,
    selectedItem,
    handleSetSelectedItem,
  } = useCompanyFilter();

  return (
    <Search
      value={value}
      placeholder="Pesquisar empresa"
      data={resultList}
      selectedItems={selectedItem}
      isActive={isActive}
      onChangeValue={(value: string) => setValue(value)}
      onToggle={() => handleActiveFilter(!isActive)}
      onSelectValue={(item: ResultListItem | null) => handleSetSelectedItem(item)}
      customIcon={<IconFilter />}
    />
  );
};
