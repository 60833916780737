import React from 'react';

import { useDetectiveContext } from '../context/DetectiveContext';
import { HistoryContent, HistoryHeader } from './History/components';
import { useHistory } from './History/hooks/useHistory';

import * as S from '../../../../styles';
import './History/styles.css';

export const BuildingHistory: React.FC = () => {
  const {
    historic,
    initHistory,
    collaborator,
    loading,
    currentLocation,
    handleClickToClose,
    setPeriodHistory,
  } = useHistory();
  const { setInitHistory } = useDetectiveContext();

  const closePopup = () => {
    handleClickToClose();
    setInitHistory(false);
  };

  return (
    <>
      {initHistory && (
        <S.BuildingHistoryContainer
        //onClose={handleClickToClose}
        //className="popup-container"
        //anchor="bottom"
        >
          <HistoryHeader
            title={collaborator}
            currentLocation={currentLocation}
            onClickToClose={closePopup}
            closeIcon={true}
          />
          <HistoryContent
            historic={historic}
            onChangePeriodHistory={setPeriodHistory}
            loading={loading}
          />
        </S.BuildingHistoryContainer>
      )}
    </>
  );
};
