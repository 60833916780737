import moment from 'moment';
import React, { createContext, ReactNode, useState, useContext } from 'react';
import { useAuthContext } from '../../../stores/AuthContext';
import { SelectOption } from '../../../components/Select/types';

import { Collaborator } from '../../../types/Filters';

type MovimentContextProps = {
  collaborators: Collaborator[];
  collaboratorsState: string;
  companySelected: SelectOption[];
  professionSelected: SelectOption[];
  collaboratorSelected: SelectOption[];
  initialDate: any;
  finalDate: any;
  setCollaborators: (collaborators: Collaborator[]) => void;
  setCollaboratorsState: (value: string) => void;
  setCompanySelected: (companie: SelectOption[]) => void;
  setProfessionSelected: (profession: SelectOption[]) => void;
  setCollaboratorSelected: (collaborator: SelectOption[]) => void;
  updatePeriod: (initial: any, final: any) => void;
};

type Props = {
  children: ReactNode;
};

const MovimentContext = createContext<MovimentContextProps>({} as MovimentContextProps);

export function MovimentContextProvider(props: Props) {
  const { children } = props;

  const {authData} = useAuthContext();
  const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
  const [collaboratorsState, setCollaboratorsState] = useState<string>('');
  const [initialDate, setInitialDate] = useState<any>(moment());
  const [finalDate, setFinalDate] = useState<any>(moment());
  const [collaboratorSelected, setCollaboratorSelected] = useState<SelectOption[]>([]);
  
  const professionId = 0; 

  /*const companyId = (authData && authData.grpCompanies != 'a' && (!authData.grpCompanies.includes(","))) ?
    authData.companyId:0;*/
    
  const [professionSelected, setProfessionSelected] = useState<SelectOption[]>([]);
  const [companySelected, setCompanySelected] = useState<SelectOption[]>([]);

  const updatePeriod = (initial: any, final: any) => {
    setInitialDate(initial);
    setFinalDate(final);
  };

  const value = {
    collaborators,
    collaboratorsState,
    collaboratorSelected,
    companySelected,
    professionSelected,
    initialDate,
    finalDate,
    setCollaborators,
    setCollaboratorsState,
    setCollaboratorSelected,
    setCompanySelected,
    setProfessionSelected,
    updatePeriod,
  };
  return <MovimentContext.Provider value={value}>{children}</MovimentContext.Provider>;
}

export function useMovimentContext() {
  const context = useContext(MovimentContext);

  if (typeof context === 'undefined') {
    throw new Error('MovimentContext must be used within an MovimentContext');
  }

  return context;
}
