import React from 'react';

import { PageHeaderProps } from './types';

import * as S from './styles';

export const PageHeader: React.FC<PageHeaderProps> = ({ title, children, style }) => (
  <S.HeaderContainer style={style}>
    <S.Title>{title}</S.Title>
    {children}
  </S.HeaderContainer>
);
