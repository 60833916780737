import React from 'react';
import { DefaultIconProps } from './types';

export const PresentionChartIcon: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '32'}
      height={height || '32'}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M7.86633 22.668H24.1197C26.653 22.668 27.9863 21.3346 27.9863 18.8013V2.66797H3.98633V18.8013C3.99966 21.3346 5.333 22.668 7.86633 22.668Z"
        stroke={fill || '#666666'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66699 2.66797H29.3337"
        stroke={fill || '#666666'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.667 29.3346L16.0003 26.668V22.668"
        stroke={fill || '#666666'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3333 29.3346L16 26.668"
        stroke={fill || '#666666'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33301 16.8328V12.5039"
        stroke={fill || '#666666'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.667 17.3346L22.667 6.66797"
        stroke={fill || '#666666'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 17.332L16 9.33203"
        stroke={fill || '#666666'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
