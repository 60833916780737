import React from 'react';

const IconArrowRight: React.FC<any> = ({ id }) => (
  <svg
    id={id}
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.72386 6.66666H0V5.33332H9.72386L5.5286 1.13806L6.4714 0.195251L12.2761 5.99999L6.4714 11.8047L5.5286 10.8619L9.72386 6.66666Z"
      fill="#2F4CDD"
    />
  </svg>
);

export default IconArrowRight;
