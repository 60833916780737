import React from 'react';

export interface IconCloseProps {
  fill?: string;
}

const IconClose: React.FC<IconCloseProps> = ({ fill }) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0416 2.07459L10.9253 0.958344L6.49992 5.38376L2.0745 0.958344L0.958252 2.07459L5.38367 6.50001L0.958252 10.9254L2.0745 12.0417L6.49992 7.61626L10.9253 12.0417L12.0416 10.9254L7.61617 6.50001L12.0416 2.07459Z"
      fill={fill || '#2E3A59'}
    />
  </svg>
);

export default IconClose;
