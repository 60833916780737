import React from 'react';
import styled from 'styled-components';

import { Shadow } from '../../components';
import { Page } from '../../components/PageStructure/Page';
import {ContentContainer} from '../../components/PageStructure/styles';
import CollaboratorsCountIndicator from './CollaboratorsCountIndicator';
import { AgileanContextProvider } from './context/AgileanContext';
import AgileanOptions from './AgileanOptions';
import IconUsers from '../../Icons/Users';
import AgileanHeader from './AgileanHeader';
import PlanningCurves from './PlanningCurves';

const ChartCard = styled(Shadow)`
  width: 100%;
  height: 100%;
  min-height: 400px;
  padding: 2.4rem 2.4rem 1.6rem;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
`;

export const AgileanContainer: React.FC = () => (
    <Page title="Planejamento" 
          components={
            <AgileanContextProvider>
              <ContentContainer value=""> 
                <AgileanOptions />
                <ChartCard>  
                  <AgileanHeader title="Nº de Colaboradores" hours={0}/>
                  <CollaboratorsCountIndicator hours={0}/>
                </ChartCard>
                <ChartCard>  
                  <AgileanHeader title="Horas" hours={1}/>
                  <CollaboratorsCountIndicator hours={1}/>
                </ChartCard>
              </ContentContainer>
            </AgileanContextProvider>
          } 
    />
  );