import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

type Props = {
  customStyle?: any;
  customDivStyle?: any;
};

export const IconLoading: React.FC<Props> = ({ customStyle, customDivStyle }) => (
  <Spin indicator={<LoadingOutlined style={customStyle} spin />} style={customDivStyle} />
);

IconLoading.defaultProps = {
  customStyle: { fontSize: 50 },
  customDivStyle: {placeSelf: "center"}
};

