import { useEffect, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import moment from 'moment';

import { getCollaboratorsCount } from '../../../../../../services/api/TrackfyService/integration';
import { CollaboratorsCount } from '../../../../../indicators/types/Indicators';
import { useAuth } from '../../../../../../hooks';
import { useCompaniesContext } from '../../../../context/CompaniesContext';
import { parseSelectOptions } from '../../../../../../utils/Convert';
import { useAreasContext } from '../../../../context/AreasContext';

type collaboratorCountIndicator = {
  loading: boolean;
  value: string;
};

export const useCollaboratorsCount = () => {
  const [indicator, setIndicator] = useState<collaboratorCountIndicator>({
    loading: true,
    value: 'Carregando...',
  });

  const { getClientId } = useAuth();
  const { companySelected } = useCompaniesContext();
  const { projectSelected } = useAreasContext();
  const clientId: number = getClientId();

  const getCompaniesForBraskem = ():string => {
    if(projectSelected == 1)
      return "94";

    else if(projectSelected == 2)
      return "30;105;106;107;108;109";

    return "0";
  }

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if (mounted) {
      const companies: string = clientId == 12 ? getCompaniesForBraskem() : parseSelectOptions(companySelected);
      setIndicator({ loading: true, value: 'Carregando...' });

      getCollaboratorsCount(
        clientId,
        companies,
        moment().format('YYYY-MM-DD'),
        currentRequest.token
      )
        .then((data: CollaboratorsCount[]) => {
          let indicatorText = '0';

          if (data && data.length > 0) indicatorText = data[0].value.toString();

          setIndicator({ loading: false, value: indicatorText });
        })
        .catch((err: any) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('CollaboratorCount request canceled by the user.');
    };
  }, [companySelected, projectSelected]);

  return { indicator };
};
