import React from 'react';
import { componentConfig } from '../../config/component.config';

type DynamicComponentsProps = {
    charts: string[]|undefined, 
    component: 'summary'|'burndown'|'timeline'|'areaTimeline'|'inOutChart'|'workingPermitChart'|'heatmap'
        |'movCalendar'|'movTimeline'|'areasTimeDivision'|'burndownExport'|'areaTimelineExport'
        |'timelineExport'|'movCalendarExport'|'inoutExport'|'displacementBtwnAreas'|'areasTimeDivisionExport'
		|'resourcesBurndown'|'resourcesBurndownExport'
}

const DynamicComponentsFactory: React.FC<DynamicComponentsProps> = (props: DynamicComponentsProps) => {
    const {charts, component} = props;

    if(charts && charts.findIndex((chart:string) => chart == component) >= 0)
        return componentConfig[component];

    return <></>;
}

export default DynamicComponentsFactory;