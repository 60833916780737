import React from 'react';
import Table from '../../../../../components/Table/Table';
import { getObjectToStringMatrix } from '../../../../../utils/Convert';
import { CreatedCompany } from '../../../types/ClientCompany';
import { headers } from './Header';

import * as S from './styles';

export type CompanyTableProps = {
  detailData: CreatedCompany[];
  loading: boolean;
  empty: boolean;
};

export const CompanyTable: React.FC<CompanyTableProps> = (props: CompanyTableProps) => {
  const { detailData, loading, empty } = props;

  return (
    <S.TableContainer>
      <Table
        tableHeaders={headers}
        tableData={getObjectToStringMatrix(detailData)}
        loading={loading}
        isEmpty={empty}
      />
    </S.TableContainer>
  );
};
