import React from 'react';

import { Bar, BarChart, CartesianGrid, XAxis, ResponsiveContainer, LabelList } from 'recharts';
import { AverageTimeByPeople } from '../../../../indicators/types/Indicators';

type VerticalBarChartProps = {
  averageTimeChartData: AverageTimeByPeople[] | undefined;
};

export const VerticalBarChart: React.FC<VerticalBarChartProps> = ({ averageTimeChartData }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={700} height={250} data={averageTimeChartData}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" />
        {/* <YAxis label={{ value: 'Qntd. de horas', angle: -90, position: 'insideLeft' }} /> */}
        <Bar dataKey="value" fill="rgba(217, 209, 255, 100)" radius={[5, 5, 0, 0]} barSize={40}>
          <LabelList dataKey="label" position="insideBottom" offset={30} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
