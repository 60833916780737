import styled from 'styled-components';
import Search from '../../assets/search.png';
import { sg } from '../../styles/style-guide';
import { InputStyleProps } from '../types';

export const Input = styled.input<InputStyleProps>`
  position: ${(InputStyleProps) =>
    InputStyleProps.position ? InputStyleProps.position : 'relative'};
  padding: ${(InputStyleProps) =>
    InputStyleProps.padding ? InputStyleProps.padding : '13px 30px 12px 16px'};
  height: ${(InputStyleProps) => (InputStyleProps.height ? InputStyleProps.height : '100%')};
  width: ${(InputStyleProps) => (InputStyleProps.width ? InputStyleProps.width : '100%')};
  background: url(${Search}) no-repeat right #ffffff;
  background-position-x: calc(100% - 12px);
  border: ${(InputStyleProps) =>
    InputStyleProps.border ? InputStyleProps.border : '1px solid #4f5882'};
  border-radius: ${(InputStyleProps) =>
    InputStyleProps.borderRadius ? InputStyleProps.borderRadius : '10px'};
  opacity: ${(InputStyleProps) => (InputStyleProps.opacity ? InputStyleProps.opacity : '1')};
  color: ${(InputStyleProps) => (InputStyleProps.color ? InputStyleProps.color : sg.colors.dark)};
  font: normal 500 1.4rem/1.5 Work Sans, sans-serif;
  &:disabled {
    color: ${(InputStyleProps) =>
      InputStyleProps.disabledStyle?.color ? InputStyleProps.disabledStyle?.color : '#afafaf'};
    background: url(${Search}) no-repeat right #f5f5f5;
    background-position-x: calc(100% - 12px);
  }
  &::placeholder {
    text-align: left;
    font: normal 500 1.4rem/1.5 Work Sans, sans-serif;
    letter-spacing: 0px;
    color: ${(InputStyleProps) =>
      InputStyleProps.placeholderColor ? InputStyleProps.placeholderColor : '#4f5882'};
  }
`;