import React from 'react';

import { FloorsProps } from '../../../../monitoring/types/Monitoring';

import * as S from '../styles';

export const PerspectiveFloor: React.FC<FloorsProps> = (props: FloorsProps) => {
  const { productive, color } = props;

  return (
    <>
      <S.FrontWall entrance={false} productive={productive} color={color}>
        <S.PerspectiveRowContainer>
          <S.FrontHeaderContainer productive={productive}>
            <S.FrontHeader productive={productive} isBig></S.FrontHeader>
            <S.FrontHeader productive={productive} isBig={false}></S.FrontHeader>
          </S.FrontHeaderContainer>
          <S.FrontHeaderContainer productive={productive}>
            <S.FrontHeader productive={productive} isBig={false}></S.FrontHeader>
            <S.FrontHeader productive={productive} isBig></S.FrontHeader>
          </S.FrontHeaderContainer>
        </S.PerspectiveRowContainer>
        <S.PerspectiveRowContainer>
          <S.FrontWindowsContainer productive={productive}>
            <S.FrontWindow productive={productive} isBig></S.FrontWindow>
            <S.FrontWindow productive={productive} isBig={false}></S.FrontWindow>
          </S.FrontWindowsContainer>
          <S.FrontWindowsContainer productive={productive}>
            <S.FrontWindow productive={productive} isBig={false}></S.FrontWindow>
            <S.FrontWindow productive={productive} isBig></S.FrontWindow>
          </S.FrontWindowsContainer>
        </S.PerspectiveRowContainer>
      </S.FrontWall>
      <S.LeftWall entrance={false} productive={productive} color={color}>
        <S.PerspectiveRowContainer>
          <S.LeftStrip productive={productive}></S.LeftStrip>
          <S.LeftWindow productive={productive}></S.LeftWindow>
          <S.LeftWindow productive={productive}></S.LeftWindow>
          <S.LeftStrip productive={productive}></S.LeftStrip>
        </S.PerspectiveRowContainer>
      </S.LeftWall>
    </>
  );
};
