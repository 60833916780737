import React from 'react';

import * as S from '../styles';

type TooltipProps = {
  tooltipProps: any
}

export const CustomTooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
  const {tooltipProps} = props;

  return (
    <S.TooltipContainer className="custom-tooltip">
      <S.TooltipTitle className="tooltipTitle">{tooltipProps}</S.TooltipTitle>
    </S.TooltipContainer>
  );
};
