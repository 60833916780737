import React from 'react';
import { DefaultIconProps } from './types';

const IconLocationSearch: React.FC<DefaultIconProps> = ({fill, width, height, style, onClick}) => (
<svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
 style={style} onClick={onClick}>
<path d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22" stroke={fill || "#c3c3c3"} strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.99998 3H8.99998C7.04998 8.84 7.04998 15.16 8.99998 21H7.99998" stroke={fill || "#c3c3c3"} strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15 3C15.97 5.92 16.46 8.96 16.46 12" stroke={fill || "#c3c3c3"} strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3 16V15C5.92 15.97 8.96 16.46 12 16.46" stroke={fill || "#c3c3c3"} strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3 8.99961C8.84 7.04961 15.16 7.04961 21 8.99961" stroke={fill || "#c3c3c3"} strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.2 21.4C19.9673 21.4 21.4 19.9673 21.4 18.2C21.4 16.4327 19.9673 15 18.2 15C16.4327 15 15 16.4327 15 18.2C15 19.9673 16.4327 21.4 18.2 21.4Z" stroke={fill || "#c3c3c3"} strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22 22L21 21" stroke={fill || "#c3c3c3"} strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round"/>
</svg>);

export default IconLocationSearch;