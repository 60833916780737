import styled from 'styled-components';

interface ActionButtonProps {
  color: string;
  disabled: boolean;
  bgColor: string;
}

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.4rem 3.2rem;
  text-align: center;
  font-family: work sans;
  gap: 1.2rem;
  max-height: 60vh;
  overflow-y: auto;
`;

export const ModalTitle = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 2.8rem;
  gap: 1.6rem;
  & span {
    font-family: work sans;
    font-weight: 600;
    color: #0e1742;
    font-size: 1.6rem;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  gap: 2rem;
  min-width: 115rem;
`;

export const ContentCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  padding: 0;
  & p {
    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;
    & svg {
      margin-right: 0.4rem;
    }
  }
`;

export const ContentInput = styled.input`
  all: unset;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding: 1.2rem;
  width: 100%;
  text-align: left;
`;

export const AddInviteButton = styled.button`
  all: unset;
  background-color: #e8e8e8;
  padding: 0.8rem 1.6rem;
  border-radius: 0.8rem;
  color: #717171;
  font-weight: 500;
  font-size: 1.4rem;
  cursor: pointer;
  align-self: flex-start;
`;

export const ActionButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
  margin-bottom: 1.6rem;
`;

export const ActionButton = styled.button<ActionButtonProps>`
  all: unset;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 1rem 1.6rem;
  color: ${({ color }) => color};
  border: ${({ color }) => `2px solid ${color}`};
  border-radius: 8px;
  background-color: ${({ bgColor }) => bgColor};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const IconRemove = styled.span`
  position: absolute;
  right: -3rem;
  height: 100%;
  display: flex;
  align-items: center;
  & svg {
    cursor: pointer;
  }
`;

export const CellValue = styled.p`
  margin-bottom: .4rem;
`;