import { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';

import {
  AverageTimeByPeople,
  Burndown,
  CalendarEvent,
  CollaboratorTimeline,
  PeopleInAreaDate,
  PresenceByArea,
  RankByProfession,
  Timeline,
} from '../containers/indicators/types/Indicators';
import { MonitoredData, MonitoringCollaboratorData } from '../types/MonitoredData';
import { DailySummaryMonitor, MediaData } from '../containers/dailySummary/types/DailySummaryTypes';
import { Area, Collaborator, Company, Profession } from '../types/Filters';
import api from './api';
import { requestParams } from '../types/Requests';
import { getEndpoint } from './api/TrackfyService/endpoints';

// Entities ==============================================================================================
export const getAreas = async (cancelToken: CancelToken, excludeImproductZone?: boolean): Promise<Area[]> => {
  try {
    const improductZone = excludeImproductZone ? excludeImproductZone.toString() : 'false';
    const result: AxiosResponse<any> = await api.get('v1/areas', {
      params: {
        excludeImproductZone: improductZone,
      },
      cancelToken,
    });
    return result.data as Area[];
  } catch (error) {
    return [] as Area[];
  }
};

export const getCollaborators = async (
  clientId: number,
  companyId: number,
  professionId: number,
  cancelToken: CancelToken
): Promise<Collaborator[]> => {
  try {
    const endpointPath = 'https://backend.trackfyapp.com/rest/v1/collaborators';
    const result: AxiosResponse<any> = await api.get(endpointPath, {
      params: {
        company: companyId,
        profession: professionId,
      },
      cancelToken,
    });
    return result.data as Collaborator[];
  } catch (error) {
    return [] as Collaborator[];
  }
};

export const getCompanies = async (operational: boolean, cancelToken: CancelToken): Promise<Company[]> => {
  try {
    const result: AxiosResponse<any> = await api.get(`v1/companies`, {
      params: {
        operational,
      },
      cancelToken,
    });
    return result.data as Company[];
  } catch (error) {
    return [] as Company[];
  }
};

export const getProfessions = async (cancelToken: CancelToken): Promise<Profession[]> => {
  try {
    const result: AxiosResponse<any> = await api.get('v1/professions', {
      cancelToken,
    });
    return result.data as Profession[];
  } catch (error) {
    return [] as Profession[];
  }
};

export const getCollaboratorsQuantity = async (clientId: number, cancelToken: CancelToken): Promise<any> => {
  try {
    const endpointPath = getEndpoint('statisticsCollaborators', false);
    const result: AxiosResponse<any> = await api.get(endpointPath, {
      cancelToken,
    });
    return result.data;
  } catch (error) {
    return [];
  }
};

// Monitoring ==============================================================================================
export const getMonitoredAreasData = async (
  clientId: number,
  companyId: number,
  cancelToken: CancelToken
): Promise<MonitoredData> => {
  try {
    const params: requestParams = { companyId };
    const endpointPath = getEndpoint('monitoringAreas', false);

    const result: AxiosResponse<any> = await api.get(endpointPath, {
      params,
      cancelToken,
    });

    return result.data as MonitoredData;
  } catch (error) {
    return { areas: [], collaborators: 0 } as MonitoredData;
  }
};

export const getMonitoredCollaborators = async (
  clientId: number,
  companyId: number,
  cancelToken: CancelToken
): Promise<MonitoringCollaboratorData[]> => {
  try {
    const endpointPath = getEndpoint('collaborators', false);
    const result: AxiosResponse<any> = await api.get(endpointPath, {
      params: {
        companyId,
      },
      cancelToken,
    });

    return result.data as MonitoringCollaboratorData[];
  } catch (error) {
    return [] as MonitoringCollaboratorData[];
  }
};

// Indicators ==============================================================================================
export const getAverageTimeChartData = async (
  clientId: number,
  companyId: number,
  professionId: number,
  areaId: number,
  collaboratorId: number,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<AverageTimeByPeople[]> => {
  try {
    const result: AxiosResponse<any> = await api.get('statistics/v1/averageTimeByPeople', {
      params: {
        company: companyId,
        profession: professionId,
        area: areaId,
        collaborator: collaboratorId,
        initialDate,
        finalDate,
      },
      cancelToken,
    });

    return result.data as AverageTimeByPeople[];
  } catch (error) {
    return [] as AverageTimeByPeople[];
  }
};

export const getRankChartData = async (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<RankByProfession> => {
  try {
    const result: AxiosResponse<any> = await api.get('v2/statistics/rankByProfession', {
      params: {
        company: companies,
        profession: professions,
        initialDate,
        finalDate,
      },
      cancelToken,
    });

    return result.data as RankByProfession;
  } catch (error) {
    return { average: 0, data: [] } as RankByProfession;
  }
};

export const getPeopleInAreasChartData = async (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<PeopleInAreaDate[]> => {
  try {
    const result: AxiosResponse<any> = await api.get('v2/statistics/peopleInAreas', {
      params: {
        company: companies,
        profession: professions,
        initialDate,
        finalDate,
      },
      cancelToken,
    });

    return result.data as PeopleInAreaDate[];
  } catch (error) {
    return [{ date: '', data: [] }] as PeopleInAreaDate[];
  }
};

export const getProductivityByArea = async (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<PresenceByArea[]> => {
  try {
    const result: AxiosResponse<any> = await api.get('v2/statistics/productivityByArea', {
      params: {
        company: companies,
        profession: professions,
        initialDate,
        finalDate,
      },
      cancelToken,
    });

    return result.data as PresenceByArea[];
  } catch (error) {
    return [] as PresenceByArea[];
  }
};

export const getBurndownChartData = async (
  clientId: number,
  companies: string,
  professions: string,
  areas: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<Burndown> => {
  try {
    const endpointPath = getEndpoint('burndown', false);
    const result: AxiosResponse<any> = await api.get(endpointPath, {
      params: {
        company: companies,
        profession: professions,
        area: areas,
        initialDate,
        finalDate,
      },
      cancelToken,
    });

    return result.data as Burndown;
  } catch (error) {
    return { series: [], benchmark: [] } as Burndown;
  }
};

export const getResourcesBurndownChartData = async (
  clientId: number,
  companies: string,
  professions: string,
  areas: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<Burndown> => {
  try {
    const endpointPath = getEndpoint('resourcesBurndown', false);
    const result: AxiosResponse<any> = await api.get(endpointPath, {
      params: {
        company: companies,
        profession: professions,
        area: areas,
        initialDate,
        finalDate,
      },
      cancelToken,
    });

    return result.data as Burndown;
  } catch (error) {
    return { series: [], benchmark: [] } as Burndown;
  }
};

export const getTimelineData = async (
  clientId: number,
  companies: string,
  professions: string,
  areas: string,
  initialDate: number,
  finalDate: number,
  currentDate: number,
  cancelToken: CancelToken
): Promise<Timeline[]> => {
  try {
    const result: AxiosResponse<any> = await api.get('v2/statistics/timeline', {
      params: {
        company: companies,
        profession: professions,
        area: areas,
        initialDate,
        finalDate,
        currentDate,
      },
      cancelToken,
    });

    return result.data as Timeline[];
  } catch (error) {
    return [];
  }
};

export const getCalendarData = async (
  clientId: number,
  collaboratorId: number,
  initialDate: number,
  finalDate: number,
  currentDate: number,
  cancelToken: CancelToken
): Promise<CalendarEvent[]> => {
  try {
    const endpoint = getEndpoint('calendar', false);
    const result: AxiosResponse<any> = await api.get(endpoint, {
      params: {
        collaborator: collaboratorId,
        initialDate,
        finalDate,
        currentDate,
      },
      cancelToken,
    });

    return result.data as CalendarEvent[];
  } catch (error) {
    return [] as CalendarEvent[];
  }
};

export const getTCollaboratorimelineData = async (
  clientId: number,
  collaboratorId: number,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<CollaboratorTimeline> => {
  try {
    const endpoint = getEndpoint('collaboratorTimeline', false);
    const result: AxiosResponse<any> = await api.get(endpoint, {
      params: {
        collaborator: collaboratorId,
        initialDate,
        finalDate,
      },
      cancelToken,
    });

    return result.data as CollaboratorTimeline;
  } catch (error) {
    return { timeline: [], labels: [] } as CollaboratorTimeline;
  }
};

// Reports ==============================================================================================
export const generateAccessManagement = async (
  companyId: number,
  professionId: number,
  collaboratorId: number,
  initialDate: string,
  finalDate: string
): Promise<string> => {
  const result: AxiosResponse<any> = await api.get(`reports/v1/accessManagment`, {
    params: {
      company: companyId,
      profession: professionId,
      collaborator: collaboratorId,
      initialDate,
      finalDate,
    },
  });
  return result.data;
};

export const generateAreaMonitoringHistory = async (
  companyId: number,
  professionId: number,
  collaboratorId: number,
  initialDate: string,
  finalDate: string
): Promise<string> => {
  const result: AxiosResponse<any> = await api.get(`reports/v1/areaMonitoringHistory`, {
    params: {
      company: companyId,
      profession: professionId,
      collaborator: collaboratorId,
      initialDate,
      finalDate,
    },
  });
  return result.data;
};

export const generateDailyReport = async (
  companyId: number,
  professionId: number,
  pessoaArea: boolean,
  tempoAreaDia: boolean,
  tempoAreaAcumulado: boolean,
  topProdDia: boolean,
  topProdProjeto: boolean,
  historicoCol: boolean
): Promise<string> => {
  const result: AxiosResponse<any> = await api.get(`reports/v1/dailyReport`, {
    params: {
      company: companyId,
      profession: professionId,
      pessoaArea,
      tempoAreaDia,
      tempoAreaAcumulado,
      topProdDia,
      topProdProjeto,
      historicoCol,
    },
  });
  return result.data;
};

export const getReport = async (id: string) => {
  const result: AxiosResponse<any> = await api.get(`reports/v1/getReport?id=${id}`);
  return result.data;
};

export const getSummary = async (
  companyId: number,
  date: number,
  cancelToken: CancelToken,
  monthly: boolean
): Promise<DailySummaryMonitor[]> => {
  let url: string = 'reports/v1/';
  const config: AxiosRequestConfig = {
    params: {
      company: companyId,
      date,
    },
    cancelToken,
  };

  try {
    if (monthly) url += 'monthlySummary';
    else url += 'dailySummary';

    const result: AxiosResponse<any> = await api.get(url, config);

    return result.data as DailySummaryMonitor[];
  } catch (error) {
    return [] as DailySummaryMonitor[];
  }
};

export const getSummaryMedia = async (
  companyId: number,
  date: number,
  cancelToken: CancelToken,
  monthly: boolean
): Promise<MediaData> => {
  let url: string = 'reports/v1/';
  const config: AxiosRequestConfig = {
    params: {
      company: companyId,
      date,
    },
    cancelToken,
  };

  try {
    if (monthly) url += 'monthlySummaryMedia';
    else url += 'dailySummaryMedia';

    const result: AxiosResponse<any> = await api.get(url, config);

    return result.data as MediaData;
  } catch (error) {
    return { media: '' } as MediaData;
  }
};
