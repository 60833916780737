import React from 'react';

import { IconLoading } from '../../../../../components/IconLoading';

import { CardAvgcontent } from './styles';

import { VerticalBarChart } from './components/VerticalBarChart';

import { useEconomiaDiariaContext } from './context/EconomiaDiaria';
import { useEconomiaDiaria } from './hooks/UseEconomiaDiaria';
import { EconomiaDiariaChartHeader } from './components/EconomiaDiariaChartHeader';

const EconomiaDiariaChart: React.FC = () => {
  const { economiaDiariaChartData } = useEconomiaDiariaContext();
  const { isLoading } = useEconomiaDiaria();

  return (
    <>
      <EconomiaDiariaChartHeader />
      <CardAvgcontent>
        {isLoading && <IconLoading />}
        {!isLoading && <VerticalBarChart economiaDiariaChartData={economiaDiariaChartData} />}
      </CardAvgcontent>
    </>
  );
};

export default EconomiaDiariaChart;
