import styled from 'styled-components';
import { sg } from '../../../styles/style-guide';

type ButtonProps = {
  loading?: boolean;
};

export const Button = styled.button<ButtonProps>`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 13px 16px;
  align-items: center;
  justify-content: center;
  gap: 0.9rem;
  background-color: ${({ loading }) => (loading ? '#f5f5f5' : sg.colors.darkBlue)};
  border-radius: 10px;
  border: none;
  color: ${({ loading }) => (loading ? '#afafaf' : sg.colors.white)};
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
  cursor: ${({ loading }) => (loading ? 'wait !important' : 'pointer')};
  &:disabled {
    color: #afafaf;
    background-color: #f5f5f5;
    cursor: auto;
  }
  & > svg {
    fill: ${sg.colors.white};
  }
`;