import React, { useEffect, useState } from 'react';

import './BarChart.css';

type Props = {
  label: string;
  value: number;
  valueTarget: number;
  color: string;
};

const BarChart: React.FC<Props> = ({ label, value, valueTarget, color }: Props) => {
  const [barValue, setBarValue] = useState('0%');

  useEffect(() => {
    let mount = false;

    if (!mount) {
      const parsedValue: number = Math.floor((value * 100) / valueTarget);
      setBarValue(`${parsedValue}%`);
    }

    return () => {
      mount = true;
    };
  }, [barValue]);

  return (
    <div className="bar-chart">
      <div className="header">
        <div className="label">
          <div style={{ backgroundColor: `${color}` }} />
          <span>{label}</span>
        </div>
        <div className="value">
          <span>{value}</span>
        </div>
      </div>
      <div className="container">
        <div className="bar" style={{ width: barValue, backgroundColor: `${color}` }} />
      </div>
    </div>
  );
};

export default BarChart;
