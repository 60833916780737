import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Titlecontainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;

  & > svg {
    margin-right: 1.6rem;
    margin-top: 0.3rem;
  }
`;

export const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0.6rem;
  line-height: 27px;
`;

export const Filtercontainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 2rem;

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
`;

export const CardAvgcontent = styled.div`
  height: 100%;
  padding: 0 1rem;
  max-height: 385px;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
  display: flex;
  flex-direction: column;

  & svg {
    border-radius: 10px;

    & text {
      font: normal 500 1.3rem/1 Work Sans, sans-serif !important;
      fill: #4f5882 !important;
    }
  }
`;

export const OptionsCard = styled.div`
display: flex;
flex-flow: row wrap;
justify-content: space-evenly;
width: 100%;
margin-top: 10px;
`

export const GroupingButton = styled.div`
  display: flex;
  background: #4f5882;
  padding: 0px 10px;
  color: white;
  border-radius: 6px;
  align-item: center;
  cursor: pointer
`
