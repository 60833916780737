import styled, { keyframes } from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const HeaderTitle = styled.div`
  color: #18266F;
  text-align: center;
  font-family: "Work Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const LoginForm = styled.form`
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;

  border: 1px solid;
  border-radius: 8px;
  padding: 25px;

  @media (min-width: 0) {
    max-width: 444px;
  }
`;

export const FormControl = styled.div`
  width: 100%;
  margin-bottom: 1.2rem;
  flex: 0 0 max-content;
`;

export const Title = styled.div`
  color: #18266F;
  text-align: center;
  font-family: "Work Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
`;

export const Label = styled.label`
  font: normal 500 1.6rem/1.5 Work Sans, sans-serif;
  color: #4f5882;
  text-transform: capitaliza;
`;

export const Input = styled.input`
  font: normal 500 1.6rem/1.5 Work Sans, sans-serif;
  width: 100%;
  min-height: 46px;
  margin-top: 0.5rem;
  padding: 1rem;
  background: #f0f0f0;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  color: #4f5882cc;
  font-size: 14px;
  outline: none;
  border: thin solid transparent;
  transition: box-shadow 0.2s ease-in-out, border 0.3s ease-in-out;

  &:hover {
    border: thin solid #2f4cddbe;
  }

  &:focus {
    border: thin solid #2f4cddbe;
    box-shadow: 0 0 1px 2.5px #2f4cdd44;
  }

  &::placeholder {
    color: #afafaf;
  }
`;

export const PassInput = styled.input`
  font: normal 500 1.6rem/1.5 Work Sans, sans-serif;
  width: 100%;
  min-height: 46px;
  margin-top: 0.5rem;
  padding: 1rem;
  background: #f0f0f0;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  color: #4f5882cc;
  font-size: 14px;
  outline: none;
  border: 1px solid #4f5882;
  transition: box-shadow 0.2s ease-in-out, border 0.3s ease-in-out;

  &:hover {
    border: thin solid #2f4cddbe;
  }

  &:focus {
    border: thin solid #2f4cddbe;
    box-shadow: 0 0 1px 2.5px #2f4cdd44;
  }

  &::placeholder {
    color: #afafaf;
  }
`;

export const Submit = styled.button`
  width: 100%;
  height: 50px;
  background: #2f4cdd;
  border-radius: 10px;
  border: thin solid transparent;
  font: normal 600 1.6rem/1.5 Work Sans, sans-serif;
  color: #f8f8f8;
  outline: none;
  cursor: pointer;

  transition: background-color 0.2s ease;

  &:hover {
    background-color: #2f4cdddd;
  }

  &:focus {
    border: thin solid #2f4cddbe;
    box-shadow: 0 0 1px 2.5px #2f4cdd44;
  }

  &:disabled {
    background-color: #2f4cdddd;
    opacity: 0.8;
    cursor: wait;
  }
`;

export const Text = styled.p`
  font: normal 1.3rem/1.5 Work Sans, sans-serif;
  color: #4f5882;
`;

export const IconLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0 4px;
`;

export const Space = styled.div`
  height: 34px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

interface IMessage {
  type: String;
}

export const Message = styled.div`
  font: normal 1.2rem/1.5 Work Sans, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  background: ${(props: IMessage) => (props.type === 'sucess' ? '#E3FFE8' : '#FFE3E3')};
  color: ${(props: IMessage) => (props.type === 'sucess' ? '#066204' : '#960000')};
  transition: opacity 1s ease-in;
  animation: ${fadeIn} 0.5s linear;

  & > svg {
    margin-right: 0.5rem;
  }
`;