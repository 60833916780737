import React, { useState } from 'react';

import { VerticalBarChart } from './components/VerticalBarChart';
import { IconLoading } from '../../../../components/IconLoading';

import { CardAvgcontent, GroupingButton, OptionsCard } from './styles';

import { useWorkingPermitContext } from './context/WorkingPermitContext';
import { useWorkingPermit } from './hooks/UseWorkingPermit';
import { WorkingPermitChartHeader } from './components/WorkingPermitChartHeader';
import { mixpanelTrack } from '../../../../services/SystemMonitor';
import { useAuthContext } from '../../../../stores/AuthContext';
import { useAuth } from '../../../../hooks/useAuth';

const WorkingPermitChart: React.FC = () => {
  const { workingPermitChartData, setTimeVisualization } = useWorkingPermitContext();
  const { isLoading } = useWorkingPermit();
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();

  const handleOnClick = (week: boolean, month: boolean, year: boolean, track: string) => {
    setTimeVisualization({ week, month, year });
    mixpanelTrack(monitorAgent, track, userName, authData);
  };

  return (
    <>
      <WorkingPermitChartHeader />
      <CardAvgcontent>
        {isLoading && <IconLoading />}
        {!isLoading && <VerticalBarChart workingPermitChartData={workingPermitChartData} />}
      </CardAvgcontent>
      <OptionsCard>
        <GroupingButton
          onClick={() =>
            handleOnClick(false, false, false, 'Conformidade - Retirada de PT - Diário')
          }
        >
          Diário
        </GroupingButton>
        <GroupingButton
          onClick={() =>
            handleOnClick(true, false, false, 'Conformidade - Retirada de PT - Semanas')
          }
        >
          Semanas
        </GroupingButton>
        <GroupingButton
          onClick={() => handleOnClick(false, true, false, 'Conformidade - Retirada de PT - Meses')}
        >
          Meses
        </GroupingButton>
        <GroupingButton
          onClick={() => handleOnClick(false, false, true, 'Conformidade - Retirada de PT - Anos')}
        >
          Anos
        </GroupingButton>
      </OptionsCard>
    </>
  );
};

export default WorkingPermitChart;
