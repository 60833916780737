import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  gap: 3.5rem;
`;

export const SummaryContainer = styled.div`
  width: 100%;
  min-height: 250px;

  background: #ffffff;
  border: 0.5px solid #ebebf9;
  box-sizing: border-box;
  //box-shadow: 8px 8px 16px 4px rgba(80, 94, 165, 0.13);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  & .input[type='date' i]::-webkit-calendar-picker-indicator {
    background-image: none;
  }
`;

export const Title = styled.h1`
  font: normal 500 2.8rem/1.25 Work Sans, sansserif;
  color: #f8f8f8;

  &:after {
    content: '';
    display: block;
    border-radius: 8px;
    border: 1px solid #42dbb6;
  }
`;

export const SummaryFooter = styled.div`
  width: 100%;
  //background: #4f5882;
  background: #273dab;

  color: #ffffff;
  font-size: 12px;

  border: 0.5px solid #ebebf9;
  box-sizing: border-box;
  box-shadow: 8px 8px 16px 4px rgba(80, 94, 165, 0.13);

  display: flex;
  justify-content: end;
`;

export const SummaryFooterData = styled.div`
  margin-right: 10px;
`;

export const SummaryFooterLabel = styled.div`
  font-weight: 500;
  margin-right: 3px;
`;

export const SummaryTitle = styled.h3`
  font: normal 700 1.9rem/1 Work Sans, sans-serif;
  //color: #4f5882;
  color: #000000;
  text-align: center;
  margin-top: 10px;
`;
