import React from 'react';

import { IconCloseVuesax } from '../../../../Icons/Close-Vuesax';
import { MonitoredAreasInEmergencyData } from '../../../../types/MonitoredData';
import { capitalize } from '../../../../utils/Capitalize';
import { useMonitoringContext } from './context/MonitoringContext';

import * as S from './styles';

interface Props {}

const showCollaborators = (activeArea: MonitoredAreasInEmergencyData) => {
  return activeArea.collaborators.map((item, index) => (
    <S.MonitoredCollaborator key={`${item.alias}-${index}`}>
      <p>{capitalize(item.alias)}</p>
    </S.MonitoredCollaborator>
  ))
}

const showTeams = (activeArea: MonitoredAreasInEmergencyData) => {
  return activeArea.teams?.map((team) => (
    team.details.map((item, index) => (
      <S.MonitoredCollaborator key={`${item.occupation}-${index}`}>
        <span>{item.quantity}</span>
        <p>{item.occupation}</p>
      </S.MonitoredCollaborator>
    ))
  ))
}

const showProfessions = (activeArea: MonitoredAreasInEmergencyData) => {
  if(activeArea.data && activeArea.data.length)
    return activeArea.data.map((item, index) => (
      <S.MonitoredCollaborator key={`${item.occupation}-${index}`}>
        <span>{item.quantity}</span>
        <p>{item.occupation}</p>
      </S.MonitoredCollaborator>
    ))
}

export const MonitoredAreaCollaborators: React.FC<Props> = ({}) => {
  const { activeArea, setActiveArea } = useMonitoringContext();

  return (
    <S.MonitoredAreasCollaborators>
      {activeArea.nome ? (
          <IconCloseVuesax style={{ cursor: 'pointer', marginBottom: '-1.6rem' }}
            onClick={() => setActiveArea({} as MonitoredAreasInEmergencyData)}
          />
        ) : (<></>)
      }
      {activeArea.nome && activeArea.collaborators.length ? showCollaborators(activeArea)
        : activeArea.teams && activeArea.teams.length ? showTeams(activeArea)
        : showProfessions(activeArea)   
      }
    </S.MonitoredAreasCollaborators>
  );
};


/*
    <S.MonitoredAreasCollaborators>
      {activeArea.nome ? (
          <IconCloseVuesax style={{ cursor: 'pointer', marginBottom: '-1.6rem' }}
            onClick={() => setActiveArea({} as MonitoredAreasInEmergencyData)}
          />
        ) : (<></>)
      }
      {activeArea.nome && activeArea.collaborators.length
        ? activeArea.collaborators.map((item, index) => (
            <S.MonitoredCollaborator key={`${item.alias}-${index}`}>
              <p>{capitalize(item.alias)}</p>
            </S.MonitoredCollaborator>
          ))
        : activeArea.teams.length ? 
          activeArea.teams?.map((team) => (
              team.details.map((item, index) => (
                <S.MonitoredCollaborator key={`${item.occupation}-${index}`}>
                  <span>{item.quantity}</span>
                  <p>{item.occupation}</p>
                </S.MonitoredCollaborator>
              ))
          ))
          : activeArea.data.map((item, index) => (
            <S.MonitoredCollaborator key={`${item.occupation}-${index}`}>
              <span>{item.quantity}</span>
              <p>{item.occupation}</p>
            </S.MonitoredCollaborator>
          ))
        }
    </S.MonitoredAreasCollaborators>
  */