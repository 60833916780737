import Axios, { CancelTokenSource } from 'axios';
import React, { useEffect } from 'react';

import { IconLoading } from '../../../components/IconLoading';
import { useTrackfyApi } from '../../../hooks/useTrackfyApi';
import Incident from '../../../Icons/Incident';
import { useConformityContext } from '../context/ConformityContext';
import { useAreaTimelineContext } from './context/TimelineContext';
import { useTimeline } from './hooks/useTimeline';
import { TimelineLine } from './AreaTimelineLine';
import { useAuth, useMatchMedia } from '../../../hooks';

import styled from 'styled-components';
import * as S from './styles';
import { parseSelectOptions } from '../../../utils/Convert';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-center;
  justify-content: center;
  @media (max-width: 1000px) {
    & > div {
      padding-top: 2.5rem;
    }
  }
  & > div:first-child {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: auto;
    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
    }
  }
  & > div:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    margin-left: -1.5%;
    @media (max-width: 1000px) {
      //margin-top: -6rem;
    }
  }
`;

export const AreaTimelineContent: React.FC<{}> = () => {
  const { areaSelected, companySelected, professionSelected, initialDate, finalDate } = useConformityContext();
  const { areaTimelineData,
          setAreaTimelineData,
          setAreaTimelineValues,
          setAreaTimelineSelected,
          areaTimelineSelected,
  } = useAreaTimelineContext();
  const { getAreaTimelineDataApi } = useTrackfyApi();
  const { isLoading, isEmpty, setIsLoading, setIsEmpty, dividePerTwentyfourAreas } = useTimeline();
  const { getClientId } = useAuth();
  const isMobile = useMatchMedia(1100);

  useEffect(() => {
    let mounted = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted){
      getAreaTimelineDataApi(
        getClientId(),
        parseSelectOptions(areaSelected),
        parseSelectOptions(companySelected),
        parseSelectOptions(professionSelected),
        initialDate,
        finalDate,
        currentRequest.token,
        setAreaTimelineData,
        setAreaTimelineValues,
        dividePerTwentyfourAreas,
        setIsLoading,
        setIsEmpty
      );
    }
    return () => {
      mounted = false;
      currentRequest.cancel('Timeline request canceled by the user.');
    };
  }, [companySelected, professionSelected, initialDate, finalDate, areaSelected]);

  if (isLoading)
    return <IconLoading customDivStyle={{ marginTop: '20px', marginLeft: '45%', marginRight: '45%' }} />;

  if (isEmpty)
    return (
      <S.EmptyContainer>
        <Incident />
        <span>Não há dados para o período selecinado</span>
      </S.EmptyContainer>
    );

  return (
    <S.Container isMobile={isMobile}>
      <S.LinesContainer isMobile={isMobile}>
        {areaTimelineData.map((area: any) => (
          <S.LineRow isMobile={isMobile} key={`LineRow_${area.area}`}>
            <S.AreaName>{area.area}</S.AreaName>
            {area.timeline.map((i: any) => (
              <TimelineLine
                key={area.timeline.indexOf(i)}
                blink={i.blink}
                background={i.background}
                color={i.color}
                count={i.count}
                time={i.time}
                width={area.timeline.length}
                data={i.data}
              />
            ))}
          </S.LineRow>
        ))}
      </S.LinesContainer>
    </S.Container>
  );
};