import React, { useEffect, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';

import { IconLoading } from '../../../components/IconLoading';
import { SummaryFooterProps } from '../types/DailySummaryTypes';
import { SummaryFooter, SummaryFooterData, SummaryFooterLabel } from './styled';
import { useTrackfyApi } from '../../../hooks/useTrackfyApi';

const Footer: React.FC<SummaryFooterProps> = (props: SummaryFooterProps) => {
  const { title, company, date, data, icon, monthly, setData } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { generateSummaryMediaApi } = useTrackfyApi();

  useEffect(() => {
    let mounted = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted)
      generateSummaryMediaApi(company, date, currentRequest.token, monthly, setData, setIsLoading);

    return () => {
      mounted = false;
      currentRequest.cancel('DailySummary request canceled by the user.');
    };
  }, [company, date]);

  if (isLoading)
    return (
      <SummaryFooter>
        <IconLoading customStyle={{ fontSize: 18, color: 'white', marginRight: '15px' }} />
      </SummaryFooter>
    );

  return (
    <SummaryFooter>
      {icon}
      <SummaryFooterLabel>{title}</SummaryFooterLabel>
      <SummaryFooterData>{data.media}</SummaryFooterData>
    </SummaryFooter>
  );
};

export default Footer;
