import moment from 'moment';

export const getCurrentDateBrazilianFormat = () => {
  const currentDate: Date = new Date();
  const dt: string =
    currentDate.getDate() > 9
      ? currentDate.getDate().toString()
      : `0${currentDate.getDate().toString()}`;
  const month: string = (currentDate.getMonth() + 1).toString();
  const year: string = currentDate.getFullYear().toString().substring(2, 4);
  return `${dt}/${month}/${year}`;
};

export const parseToDateFormatted = (initialDate: Date, finalDate: Date) => {
  let parsedDate: any = '';
  if (moment(initialDate).format('DD/MM/YYYY') === moment(finalDate).format('DD/MM/YYYY')) {
    parsedDate =
      moment(initialDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
        ? 'Hoje'
        : `${moment(initialDate).locale('pt-br').format('DD/MM/YY')}`;
  } else if (moment(initialDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
    parsedDate = `Hoje ~ ${moment(finalDate).locale('pt-br').format('DD/MM/YY')}`;
  } else if (moment(finalDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
    parsedDate = `${moment(initialDate).locale('pt-br').format('DD/MM/YY')} ~ Hoje`;
  } else {
    parsedDate =
      `${moment(initialDate).locale('pt-br').format('DD/MM/YY')} ~ ` +
      `${moment(finalDate).locale('pt-br').format('DD/MM/YY')}`;
  }
  return parsedDate;
};

export const getFollowingFriday = (currentDate: Date): Date => {
  const friday: number = 5;

  if (currentDate.getDay() > 5) {
    currentDate.setDate(currentDate.getDate() + 6);
  } else {
    const dayDiff: number = friday - currentDate.getDay();
    currentDate.setDate(currentDate.getDate() + dayDiff);
  }

  return currentDate;
};

export const getLastCommercialDateBrazilianFormat = (): string => {
  const currentDate: Date = new Date();
  const weekDay: number = currentDate.getDay();

  if (weekDay === 1) {
    currentDate.setDate(currentDate.getDate() - 3);
  } else if (weekDay === 7) currentDate.setDate(currentDate.getDate() - 2);
  else currentDate.setDate(currentDate.getDate() - 1);

  const dt: string =
    currentDate.getDate() > 9
      ? currentDate.getDate().toString()
      : `0${currentDate.getDate().toString()}`;
  const month: string = (currentDate.getMonth() + 1).toString();
  const year: string = currentDate.getFullYear().toString().substring(2, 4);
  return `${dt}/${month}/${year}`;
};

export const getDateBrFormat = (currentDate: moment.Moment): string => {
  // if(!currentDate)
  // return getLastCommercialDateBrazilianFormat();
  const dateObj: Date = currentDate.toDate();
  const dt: string =
    dateObj.getDate() > 9 ? dateObj.getDate().toString() : `0${dateObj.getDate().toString()}`;
  const month: string =
    dateObj.getMonth() > 9
      ? (dateObj.getMonth() + 1).toString()
      : `0${(dateObj.getMonth() + 1).toString()}`;
  const year: string = dateObj.getFullYear().toString().substring(2, 4);
  return `${dt}/${month}/${year}`;
};

export const getLastCommercialDateUSFormat = (): string => {
  const currentDate: Date = new Date();
  const weekDay: number = currentDate.getDay();

  if (weekDay === 1) {
    currentDate.setDate(currentDate.getDate() - 3);
  } else if (weekDay === 7) currentDate.setDate(currentDate.getDate() - 2);
  else currentDate.setDate(currentDate.getDate() - 1);

  const dt: string =
    currentDate.getDate() > 9
      ? currentDate.getDate().toString()
      : `0${currentDate.getDate().toString()}`;
  const month: string = (currentDate.getMonth() + 1).toString();
  const year: string = currentDate.getFullYear().toString();
  return `${year}-${month}-${dt}`;
};

export const createDateObjtWithTmezoffset = (currentDate: Date): Date => {
  const offset: number = currentDate.getTimezoneOffset();
  currentDate.setHours(currentDate.getHours() + offset / 60);
  return currentDate;
};

export const getLasCommercialMommentDate = (): moment.Moment => {
  const commercialDate: string = getLastCommercialDateUSFormat();
  const dtParts: string[] = commercialDate.split('-');
  const dt: number = Number.parseInt(dtParts[2]);
  const mnth: number = Number.parseInt(dtParts[1]) - 1;
  //const date: moment.Moment = moment().set('date', dt).set('month', mnth);
  const date: moment.Moment = moment(
    dtParts[0] + '-' + dtParts[1] + '-' + dtParts[2],
    'YYYY-MM-DD'
  );
  return date;
};

export const transformDate = (date: Date, format: string) =>
  new Intl.DateTimeFormat(format, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date);

export const transformMinutesToHours = (minutes: number) => {
  const hours = (String(Math.floor(minutes / 60))).padStart(2,'0');
  const remainingMinutes = (String(Math.floor(minutes % 60))).padStart(2,'0');
  return `${hours}:${remainingMinutes}`;
};

export const transformHoursToMinutes = (value: number) => {
  const values = String(value).split('.');
  let [hours, minutes] = values.map(Number);
  hours = hours * 60;
  if (minutes < 10) {
    minutes = minutes * 10;
  }
  return hours + minutes;
};