import React, { createContext, ReactNode, useState, useContext } from 'react';

type Props = {
  children: ReactNode;
};

type FaqContextProps = {
  searchQuestion: string;
  subjectFilter: string[];
  setSearchQuestion(value: string): void;
  setSubjectFilter(value: any): void;
};

const FaqContext = createContext<FaqContextProps>({} as FaqContextProps);

export function FaQContextProvider(props: Props) {
  const { children } = props;

  const [searchQuestion, setSearchQuestion] = useState('');
  const [subjectFilter, setSubjectFilter] = useState<string[]>([]);

  const value = {
    searchQuestion,
    setSearchQuestion,
    subjectFilter,
    setSubjectFilter
  };

  return (
    <FaqContext.Provider value={value}>{children}</FaqContext.Provider>
  );
}

export function useFaQContext() {
  const context = useContext(FaqContext);

  if (typeof context === 'undefined') {
    throw new Error('FaqContext must be used within an useFaqContext');
  }

  return context;
}