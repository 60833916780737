import React, { createContext, ReactNode, useState, useContext } from 'react';

import { PaginationControls } from '../../../../../../components/Buttons/PaginationControls';
import { DeslocamentosPe } from '../../../../types/Insights';

type ContextValue = {
  deslocamentosPeChartData: DeslocamentosPe[];
  isLoading: boolean;
  deslocamentosPeValues: Array<DeslocamentosPe[]>;
  setDeslocamentosPeChartData: (value: DeslocamentosPe[]) => void;
  setDeslocamentosPeValues: (arrayAvarageValues: Array<DeslocamentosPe[]>) => void;
  setIsLoading: (value: boolean) => void;
};

const DeslocamentosPeContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function DeslocamentosPeContextProvider(props: Props) {
  const { children } = props;
  const [deslocamentosPeValues, setDeslocamentosPeValues] = useState<Array<DeslocamentosPe[]>>([]);
  const [deslocamentosPeChartData, setDeslocamentosPeChartData] = useState<DeslocamentosPe[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const value = {
    deslocamentosPeChartData,
    deslocamentosPeValues,
    isLoading,
    setDeslocamentosPeChartData,
    setDeslocamentosPeValues,
    setIsLoading,
  };

  return (
    <DeslocamentosPeContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={deslocamentosPeValues}
        setNewValue={setDeslocamentosPeChartData}
        title="Tempo Médio por Área VS Função"
      />
    </DeslocamentosPeContext.Provider>
  );
}

export function useDeslocamentosPeContext() {
  const context = useContext(DeslocamentosPeContext);

  if (typeof context === 'undefined') {
    throw new Error('deslocamentosPeContext must be used within an deslocamentosPeContext');
  }

  return context;
}
