import styled from 'styled-components';

export const ModalInviteCancelContent = styled.div`-
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.4rem 4rem;
  text-align: center;
  font-family: work sans;
  & strong {
    color: #ed3a3a;
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
    margin-top: 0.4rem;
  }
  & p {
    color: #666666;
    font-weight: 400;
    font-size: 1.4rem;
    margin-bottom: 2.4rem;
  }
`;

export const ModalInviteCancelButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 0.8rem;
  & button {
    all: unset;
    padding: 0.6rem 1rem;
    border-radius: 0.8rem;
    cursor: pointer;
  }
  & :first-child {
    border: 2px solid #ed3a3a;
    color: #ed3a3a;
    background-color: #fff;
  }
  & :last-child {
    background-color: #ed3a3a;
    color: #fff;
  }
`;