import React from 'react';



import { CardAvgcontent } from './styles';

import { VerticalBarChart } from './components/VerticalBarChart';


import { useRevenueFTEContext } from './context/RevenueFTEContext';
import { useRevenueFTE } from './hooks/UseRevenueFTE';
import { IconLoading } from '../../../../components/IconLoading';
import { RevenueFTEChartHeader } from './components/RevenueFTEChartHeader';

const RevenueFTEChart: React.FC = () => {
  const { revenueFTEChartData } = useRevenueFTEContext();
  const { isLoading } = useRevenueFTE();

  return (
    <>
      <RevenueFTEChartHeader />
      <CardAvgcontent>
        {isLoading && <IconLoading />}
        {!isLoading && <VerticalBarChart revenueFTEChartData={revenueFTEChartData} />}
      </CardAvgcontent>
    </>
  );
};

export default RevenueFTEChart;
