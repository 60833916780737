import React from 'react';
import { ContentText } from "../styles";
import { OccupationData, TeamData } from '../../../types/MonitoredData';

export const useAreaPopup = () => {
    const getGroupedData = (contentCollaborators: OccupationData[]) => {
        return (
            <ContentText>
                {contentCollaborators.map((collaborators: OccupationData, index:number) => {
                          return (
                            <p key={index + "_" + collaborators.occupation + "_" + collaborators.quantity}>
                              {collaborators.occupation} - {collaborators.quantity}
                            </p>
                          );
                        })}
            </ContentText>
        );
    }

    const getIndividualData = (contentCollaborators: OccupationData[]) => {
        return (
            <ContentText>
                {contentCollaborators.map((collaborators: any, index:number) => {
                    return (
                        <p key={index + "_" + collaborators.alias}>
                            {collaborators.alias}
                        </p>
                    )
                })}
            </ContentText>
        );
    }

    const getTeamData = (contentCollaborators: TeamData[]) => {
        return (
            <ContentText>
                {contentCollaborators.map((team: TeamData, index:number) => {
                    const occupations: React.JSX.Element[] = [];
                        
                    team.details.forEach((collaborators: OccupationData, index:number) => {
                        occupations.push(
                            <p key={index + "_" + collaborators.occupation + "_" + collaborators.quantity}
                                style={{fontSize:10, marginLeft:8}}>
                            {collaborators.occupation} - {collaborators.quantity}
                            </p>
                        )
                    });

                    return (
                        <React.Fragment>
                            <p key={index + "_" + team.team + "_" + team.quantity} style={{fontWeight:"bold"}} >
                                {team.team} - {team.quantity}
                            </p>
                            <React.Fragment>{occupations}</React.Fragment>
                        </React.Fragment>
                    );
                })}
            </ContentText>
        );
    }

    return { getGroupedData, getIndividualData, getTeamData };
}