import React, { createContext, ReactNode, useState, useContext } from 'react';

import { PaginationControls } from '../../../../../components/Buttons/PaginationControls';
import { TimeVisualization, WorkingPermit } from '../../../../indicators/types/Indicators';

type ContextValue = {
  workingPermitChartData: WorkingPermit[];
  isLoading: boolean;
  workingPermitValues: Array<WorkingPermit[]>;
  timeVisualization: TimeVisualization;
  setTimeVisualization: (timeVisualization: TimeVisualization) => void;
  setWorkingPermitChartData: (value:WorkingPermit[]) => void;
  setWorkingPermitValues: (arrayAvarageValues: Array<WorkingPermit[]>) => void;
  setIsLoading: (value: boolean) => void;
};

const WorkingPermitContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function   WorkingPermitContextProvider(props: Props) {
  const { children } = props;
  const [ workingPermitValues, setWorkingPermitValues] = useState<Array<WorkingPermit[]>>([]);
  const [ workingPermitChartData, setWorkingPermitChartData] = useState<WorkingPermit[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [timeVisualization, setTimeVisualization] = 
    useState<TimeVisualization>({ week:false, month:false, year: false });

  const value = {
    workingPermitChartData,
    workingPermitValues,
    isLoading,
    setWorkingPermitChartData,
    setWorkingPermitValues,
    setIsLoading,
    timeVisualization,
    setTimeVisualization,
  };

  return (
    <WorkingPermitContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={ workingPermitValues}
        setNewValue={setWorkingPermitChartData}
        title="Retirada de PT"
      />
    </WorkingPermitContext.Provider>
  );
}

export function useWorkingPermitContext() {
  const context = useContext( WorkingPermitContext);

  if (typeof context === 'undefined') {
    throw new Error('WorkingPermitContext must be used within an  WorkingPermitContext');
  }

  return context;
}