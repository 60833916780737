import React from 'react';

import PageConformity from "../containers/conformity/PageConformity";
import Moviment from "../containers/moviment/Moviment";
import PageProductivity from "../containers/productivity/PageProductivity";
import { HHIndicators } from '../containers/realTime/components/summary/components/HHIndicators';
import { CollaboratorsCount } from '../containers/realTime/components/summary/components/CollaboratorsCount';
import { CurrentCollaboratorsCount } from '../containers/realTime/components/summary/components/CurrentCollaboratorsCount';
import { Absenteeism } from '../containers/realTime/components/summary/components/Absenteeism';
import { PlanningCollaboratorsCount } from '../containers/realTime/components/summary/components/PlanningCollaboratorsCount';
import BurndownChart from '../containers/conformity/BurnDownChart';
import { ResourcesBurndownChart } from '../containers/conformity/ResourcesBurnDownChart';
import { InOutContextProvider } from '../containers/productivity/charts/InOut/context/InOutContext';
import InOutChart from '../containers/productivity/charts/InOut';
import { WorkingPermitContextProvider } from '../containers/productivity/charts/WorkingPermit/context/WorkingPermitContext';
import WorkingPermitChart from '../containers/productivity/charts/WorkingPermit';
import { FullCallendar } from '../containers/moviment/FullCallendar';
import HeatMap from '../containers/moviment/HeatMap';
import CollaboratorTimeline from '../containers/moviment/collaboratorTimeline';
import { IndicatorsCard } from '../containers/realTime/components/summary';
import { AreaTimelineComponent } from '../containers/conformity/AreaTimeline';
import { Timeline } from '../containers/conformity/Timeline';
import { AreasTimeDivisionChart } from '../containers/moviment/AreasTimeDivision';
import { ExportButtonContainer } from '../containers/conformity/BurnDownChart/ExportButton';
import { ResourcesBurnDownExportButtonContainer } from '../containers/conformity/ResourcesBurnDownChart/ExportButton';
import { AreaTimelineExportButton } from '../containers/conformity/AreaTimeline/AreaTimelineExportButton';
import { FullCalendarExportButton } from '../containers/moviment/FullCallendar/FullCalendarExportButton';
import { InOutExportButton } from '../containers/productivity/charts/InOut/components/InOutChartExportButton';
import { TimelineExportButton } from '../containers/conformity/Timeline/TimelineExportButton';
import { DisplacementBtwnAreasContextProvider } from '../containers/moviment/DiplacementBetweenAreas/context/DisplacementBtwnAreasContext';
import { DiplacementBetweenAreas } from '../containers/moviment/DiplacementBetweenAreas';
import { AreasTimeDivisionExportButton } from '../containers/moviment/AreasTimeDivision/components/AreasTimeDivisionChartExportButton';

export const componentConfig = {
    'conformity': <PageConformity key={'PageConformity'}/>,
    'productivity': <PageProductivity key={'PageProductivity'}/>,
    'moviment': <Moviment key={'Moviment'}/>,

    'summary': <IndicatorsCard titulo="Resumo" civil={false}/>,
    'hhindicator': <HHIndicators />,
    'colaborattorsCount': <CollaboratorsCount />,
    'currentColaborattorsCount': <CurrentCollaboratorsCount />,
    'absenteeism': <Absenteeism />,  
    'planningCollaboratorsCount': <PlanningCollaboratorsCount />,  

    'burndown': <BurndownChart key={'burndown'} />,
    'burndownExport': <ExportButtonContainer key={'burndownExportButton'} />,
	'resourcesBurndown': <ResourcesBurndownChart key={'resourcesBurndown'}/>,
    'resourcesBurndownExport': <ResourcesBurnDownExportButtonContainer key={'resourcesBurndownExportButton'}/>,
    
	'timeline': <Timeline key={'confTimeline'}/>,
    'timelineExport': <TimelineExportButton key={'timelineExport'}/>,
    'areaTimeline': <AreaTimelineComponent key={'confAreaTimeline'}/>,
    'areaTimelineExport': <AreaTimelineExportButton key={'timelineExport'}/>,

    'heatmap': <HeatMap key={'heatmap'}/>,
    'movCalendar': <FullCallendar key={'fullCallendar'}/>,
    'movCalendarExport': <FullCalendarExportButton key={'fullCallendarExport'}/>,
    'movTimeline': <CollaboratorTimeline key={'CollaboratorTimeline'}/>,

    'inOutChart': <InOutContextProvider key={'InOutContextProvider'}>
                    <InOutChart key={'inOutChart'}/>
                  </InOutContextProvider>,
    'inoutExport': <InOutExportButton />,

    'workingPermitChart': <WorkingPermitContextProvider key={'WorkingPermitContextProvider'}>
                            <WorkingPermitChart key={'WorkingPermitChart'}/>
                          </WorkingPermitContextProvider>,

    'areasTimeDivision': <AreasTimeDivisionChart key={'AreasTimeDivision'}/>,

    'areasTimeDivisionExport': <AreasTimeDivisionExportButton key={'areasTimeDivisionExport'}/>,

    'displacementBtwnAreas':  <DisplacementBtwnAreasContextProvider>
                                <DiplacementBetweenAreas />
                              </DisplacementBtwnAreasContextProvider>,
};