import React from 'react';

import { Clock } from '../MonitoringBoard/Clock';
import { CheckGuardIcon } from '../../../../Icons/CheckGuard';
import { DangerOutlinedIcon } from '../../../../Icons/DangerOutline';
import { AreasInEvacuationSnapshot, 
         EvacuationAreaPorfessionsDataProps, 
         EvacuationAreaTitleProps, 
         EvacuationSnapshot } from '../../types/Emergency';

import * as S from './styles';

export interface EvacuationAreasProps {
    evacuationSnapshot: EvacuationSnapshot;
}

const EvacuationAreaTitle: React.FC<EvacuationAreaTitleProps> = ({ title, icon }) => (
    <div className="topbar">
        <strong>{title}</strong>
        {icon}
    </div>
);

const EvacuationAreaPorfessionsData: React.FC<EvacuationAreaPorfessionsDataProps> = ({ emptyScenarioMessage, monitoredAreas } ) => {
    if(monitoredAreas && monitoredAreas.collaborators.length > 0) 
        return <> 
            { monitoredAreas.collaborators.map( (alias) => (<p key={`evacuationCardItem_${alias}`}>{alias}</p>) ) }
        </>;

    return  ( <p key={`evacuationCardItem_semOcupacao}`}>{emptyScenarioMessage}</p> );
};

export const EvacuationAreasBoard: React.FC<EvacuationAreasProps> =  ({evacuationSnapshot}) => {
    let safeAreas: AreasInEvacuationSnapshot[] = [];
    
    evacuationSnapshot.currentSnapshot.forEach((area: AreasInEvacuationSnapshot) => {
        if(area.issafearea) 
            safeAreas.push(area);
    });
    
    return (
            <S.FixedEvacuationCard>

                    <S.EvacuationCardClock>
                    <Clock />
                    </S.EvacuationCardClock>

                    <S.SafeAreaCardItem color="#208048">
                        <p style={{ fontWeight: 'bold', fontSize: '1.4rem', color: '#4c4c4c' }}>
                            Nº colaboradores que já passaram por áreas seguras: <span style={{ fontWeight: 'bold', color: '#33B469', fontSize: '1.8rem' }}>
                                    {evacuationSnapshot.collaboratorsSeenInSafeAreas.length}
                                </span>
                        </p>
                    </S.SafeAreaCardItem>

                    {safeAreas.map((safeArea) => {
                        if(safeArea){
                           return <S.EvacuationCardItem key={`evct_item_${safeArea.area}`} color="#33B469">
                                        <EvacuationAreaTitle title={safeArea.area}
                                                            icon={<CheckGuardIcon fill="#33b469" />} />

                                        <EvacuationAreaPorfessionsData 
                                            emptyScenarioMessage = {"Nenhum colaborador nesta área segura atualmente"}
                                            monitoredAreas={safeArea}  
                                        />
                                  </S.EvacuationCardItem>
                        }
                    })}
    
                    {evacuationSnapshot.dangerAreas.map((dangerArea) => (
                        <S.EvacuationCardItem key={`evct_item_${dangerArea}`} color="#ED3A3A">
    
                            <EvacuationAreaTitle title={dangerArea} icon={<DangerOutlinedIcon fill="#ED3A3A" />} />

                            <EvacuationAreaPorfessionsData 
                                emptyScenarioMessage = {"Sem colaboradores nesta área de risco atualmente"}
                                monitoredAreas={ evacuationSnapshot.currentSnapshot.find((area) => area.area == dangerArea) } 
                            />
                        </S.EvacuationCardItem>
                    ))}
                    
          </S.FixedEvacuationCard>
    );
}