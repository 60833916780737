import { useCallback, useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import { useRevenueFTEContext } from '../context/RevenueFTEContext';
import { OnePage } from '../../../types/OnePage';
import { getRevenueFTE } from '../../../../../services/api/TrackfyService/integration';


export const useRevenueFTE = () => {
  const { setRevenueFTEValues, setRevenueFTEChartData } = useRevenueFTEContext();
  const [isLoading, setIsLoading] = useState(true);

  const dividerPerOne = useCallback((base: OnePage[]): Array<OnePage[]> => {
    const divisor: number = 2;
    const newArray: Array<OnePage[]> = [];

    for (let i = 0; i < base.length; i += divisor) {
      const divider: OnePage[] = base.slice(i, i + divisor);
      newArray.push(divider);
    }
    return newArray;
  }, []);

  const handleChartData = (data: OnePage[]) => {
    if (data && data.length > 0) {
      batchedUpdates(() => {
        setRevenueFTEValues(dividerPerOne(data));
        setRevenueFTEChartData(dividerPerOne(data)[0]);
      });
    } else {
      setRevenueFTEChartData([{ name: 'Sem Registros', value: 0, valueLabel: '0'}]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);
      batchedUpdates(() => setRevenueFTEValues([]));

      getRevenueFTE(currentRequest.token)
        .then((result: AxiosResponse<any>) => handleChartData(result.data))
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('RevenueFTE request canceled by the user.');
    };
  }, []);

  return {
    dividerPerOne,
    handleChartData,
    isLoading,
    setIsLoading,
  };
};
