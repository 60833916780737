import React from 'react';
import { DefaultIconProps } from './types';

export const IconStatus: React.FC<DefaultIconProps> = ({ height, width, style, fill }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M2.45 14.9697C3.52 18.4097 6.4 21.0597 9.98 21.7897"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.05 10.98C2.56 5.93 6.82 2 12 2C17.18 2 21.44 5.94 21.95 10.98"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.01 21.7995C17.58 21.0695 20.45 18.4495 21.54 15.0195"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};