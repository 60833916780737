import React from 'react';
import styled from 'styled-components';

import IconUsers from '../../../Icons/Users';
import { useAuth } from '../../../hooks';
import { Info } from '../../../components/Info';
import { getFullCalendarTooltipContent } from '../FullCallendar/FullCalendarTooltip';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const Titlecontainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;

  /*& > svg {
    margin-right: 1.6rem;
  }*/
`;

const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0;
`;

const CollaboratorTimelineHeader: React.FC = () => {
  const { getClientId } = useAuth();
  const clientId = getClientId();
  const tooltipContent = getFullCalendarTooltipContent(clientId);

  return(
    <Header>
      <Titlecontainer>
        <IconUsers />
        <Title>Linha do Tempo de produtividade</Title>
        <Info content={tooltipContent} position={'right'} burndown={false}/>
      </Titlecontainer>
    </Header>
  )
};

export default CollaboratorTimelineHeader;
