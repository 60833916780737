import { TooltipContent } from "../../../components/Info/Tooltip";
export const getTimelinesTooltipContent = (clientId: number): TooltipContent[] => {
    if(clientId == 11)
        return [
            {
              content: 'Cada linha do tempo mostra a quantidade de colaboradores que estiveram presentes em máquinas a cada hora (o trabalhador só é registrado se passar mais de 10 minutos seguidos na máquina).',
            }
        ];

    if(clientId == 4)
        return [
            {
              content: 'Cada linha do tempo mostra a quantidade de colaboradores que estiveram presentes em áreas a cada hora.',
            }
        ];

    return [
            {
              content: 'Mostra a quantidade de colaboradores que estiveram presentes em áreas a cada hora.',
            },
        ];
};