import React from 'react';

import { CompanyFilterContextProvider } from '../../../../stores';
import { CompanyFilter } from './companyFilter';
import { MonitoredAreasContainer } from './monitoredAreas';
import { IndicatorsCard } from './summary';
import { SatisfactionSurvey } from './satisfactionSurvey';

import * as S from '../../../monitoring/styles';

type MonitoringIndicatorsProps = {
  isManager: boolean;
  showSurvey: boolean;
  civil: boolean;
};

export const MonitoringIndicators: React.FC<MonitoringIndicatorsProps> = (
  props: MonitoringIndicatorsProps
) => {
  const { isManager, showSurvey, civil } = props;

  return (
    <CompanyFilterContextProvider>
      {
        <S.MonitoredAreas>
          <MonitoredAreasContainer />
        </S.MonitoredAreas>
      }

      {isManager && (
        <React.Fragment>
          <S.FilterContainer>
            <CompanyFilter />
          </S.FilterContainer>
          <S.MonitoringIndicatorsContainer>
            <IndicatorsCard titulo="Resumo" civil={civil} />
          </S.MonitoringIndicatorsContainer>
        </React.Fragment>
      )}

      {showSurvey && <SatisfactionSurvey clientId="0" />}
    </CompanyFilterContextProvider>
  );
};
