import { useState } from 'react';
import { AreaTimeline } from '../../../indicators/types/Indicators';

export const useTimeline = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const exportDataToXlsx = (timelineValues: Array<AreaTimeline[]>): any[] => {
    let count: string[] = []
    let time: string[] = []
    let areaValues: string[] = []
    let date: string[] = []
    timelineValues.forEach((timelineValue: AreaTimeline[]) => {
      timelineValue.map((area: AreaTimeline) => (
        area.timeline.map(i => (
          areaValues.push(area.area),
          count.push(i.count),
          date.push(i.date),
          time.push(i.time)
        ))
      ))
    });

    const formattedData: any[] = [];
    formattedData.push(["Área", "Data", "Horário", "Quantidade"])

    date.forEach((date, index) => {
        const value1 = time[index];
        const value2 = count[index];
        const value3 = areaValues[index];
        formattedData.push([value3,date, value1, value2]);
    });

    return formattedData
  }

  const formatTimelineDate = (timelineData: any[]): string => {
    if (timelineData[0] && timelineData[0].length > 0) {
      if (timelineData[0][0].date) {
        return `${timelineData[0][0].date.substring(8, 10)}/${timelineData[0][0].date.substring(
          5,
          7
        )}/${timelineData[0][0].date.substring(0, 4)}`;
      }
    }

    return '';
  };

  const dividerPerEighteen = (base: any[]): Array<any[]> => {
    const divisor: number = 18;
    const newArray: Array<any[]> = [];

    for (let i = 0; i < base.length; i += divisor) {
      const divider: any[] = base.slice(i, i + divisor);
      newArray.push(divider);
    }
    return newArray;
  };

  const dividePerTwentyfourAreas = (arr: any[]) => {
    const divider = 24;
    let newArray: any[] = [];
    arr.forEach(({ timeline, area }) => {
      const days = timeline.length / divider;
      for (let day = 0; day < days; day++) {
        const initialSlice = divider * day;
        const finalSlice = divider * (day + 1);
        if (newArray[day]) {
          newArray[day].push({ area, timeline: timeline.slice(initialSlice, finalSlice) });
        } else {
          newArray.push([{ area, timeline: timeline.slice(initialSlice, finalSlice) }]);
        }
      }
    });
    return newArray;
  };

  return {
    isLoading,
    isEmpty,
    setIsLoading,
    setIsEmpty,
    formatTimelineDate,
    dividerPerEighteen,
    dividePerTwentyfourAreas,
    exportDataToXlsx,
  };
};