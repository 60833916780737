import React from 'react';

interface PropsTypes {
    fill?: string;
}

const IconHome: React.FC<PropsTypes> = ({ fill }: PropsTypes) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill ?? "#fff"} xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0693 2.81984L3.13929 8.36983C2.35929 8.98983 1.85929 10.2999 2.02929 11.2799L3.35929 19.2398C3.59929 20.6598 4.95928 21.8098 6.39928 21.8098H17.5993C19.0293 21.8098 20.3993 20.6498 20.6393 19.2398L21.9693 11.2799C22.1293 10.2999 21.6293 8.98983 20.8593 8.36983L13.9293 2.82985C12.8593 1.96985 11.1293 1.96984 10.0693 2.81984Z" 
              stroke={fill ?? "#fff"} 
              strokeWidth="1.5" 
              strokeLinecap="round" 
              strokeLinejoin="round"/>
        <path opacity="0.4" d="M12 15.5C13.3807 15.5 14.5 14.3807 14.5 13C14.5 11.6193 13.3807 10.5 12 10.5C10.6193 10.5 9.5 11.6193 9.5 13C9.5 14.3807 10.6193 15.5 12 15.5Z" 
              stroke={fill ?? "#2f4cdd"} 
              strokeWidth="1.5" 
              strokeLinecap="round" 
              strokeLinejoin="round"/>
    </svg>
);

export default IconHome;

/*<path d="M20.83 8.01002L14.28 2.77002C13 1.75002 11 1.74002 9.72996 2.76002L3.17996 8.01002C2.23996 8.76002 1.66996 10.26 1.86996 11.44L3.12996 18.98C3.41996 20.67 4.98996 22 6.69996 22H17.3C18.99 22 20.59 20.64 20.88 18.97L22.14 11.43C22.32 10.26 21.75 8.76002 20.83 8.01002ZM12.75 18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18Z" 
    fill={fill ?? "#fff"}
/>*/