import React from 'react';

import { SubjectFilterItem } from './SubjectFilterItem';
import { Subject } from './Subject';
import { DisplacementAtoBQuentionsAndAnswer, DocumentationSubjectQuestionsAndAnswers, 
         EmergencyQuentionsAndAnswer, 
         GeneralSubjectQuestionsAndAnswers,
         IncidentsQuentionsAndAnswer,
         IndicatorsSubjectQuestionsAndAnswers,
         LinkCollaboratorsQuestionsAndAnswers,
         LoginQuestionsAndAnswers,
         MonitoringQuestionsAndAnswers,
         OperationQuestionsAndAnswers, 
         ReportsQuentionsAndAnswer,
         UsersQuentionsAndAnswer} from './QuestionsAndAnswers';

import * as S from './styled'

export const FaqContent: React.FC = () => 
    <React.Fragment>
      <S.SubjectFilter>
        <SubjectFilterItem filter='Documentação'>Documentação</SubjectFilterItem>
        <SubjectFilterItem filter='Funcionamento'>Funcionamento</SubjectFilterItem>
        <SubjectFilterItem filter='Geral'>Geral</SubjectFilterItem>
        <SubjectFilterItem filter='Monitoramento'>Monitoramento</SubjectFilterItem>
        <SubjectFilterItem filter='Indicadores'>Indicadores</SubjectFilterItem>
        <SubjectFilterItem filter='Login'>Login</SubjectFilterItem>
        <SubjectFilterItem filter='Sistema web'>Sistema web</SubjectFilterItem>
        <SubjectFilterItem filter='Trackfy'>Trackfy</SubjectFilterItem>
        <SubjectFilterItem filter='Vinculação'>Vinculação</SubjectFilterItem>
        <SubjectFilterItem filter='Emergência'>Emergência</SubjectFilterItem>
        <SubjectFilterItem filter='Incidentes'>Incidentes</SubjectFilterItem>
        <SubjectFilterItem filter='Relatórios'>Relatórios</SubjectFilterItem>
        <SubjectFilterItem filter='Usuários'>Usuários</SubjectFilterItem>
        <SubjectFilterItem filter='Home'>Home</SubjectFilterItem>
        <SubjectFilterItem filter='Deslocamento'>Deslocamento</SubjectFilterItem>
      </S.SubjectFilter>
      <Subject title='Documentação'
               questionsAndAnswers={DocumentationSubjectQuestionsAndAnswers}
               filters={['Trackfy', 'Documentação']}
      />
      <Subject title='Login'
               questionsAndAnswers={LoginQuestionsAndAnswers}
               filters={['Sistema web', 'Login']}
      />
      <Subject title='Geral'
               questionsAndAnswers={GeneralSubjectQuestionsAndAnswers}
               filters={['Sistema web', 'Geral']}
      />
      <Subject title='Funcionamento'
               questionsAndAnswers={OperationQuestionsAndAnswers}
               filters={['Trackfy', 'Funcionamento']}
      />
      <Subject title='Monitoramento'
               questionsAndAnswers={MonitoringQuestionsAndAnswers}
               filters={['Sistema web', 'Monitoramento']}
      />
      <Subject title='Indicadores'
               questionsAndAnswers={IndicatorsSubjectQuestionsAndAnswers}
               filters={['Sistema web', 'Indicadores']}
      />
            <Subject title='Vinculação'
               questionsAndAnswers={LinkCollaboratorsQuestionsAndAnswers}
               filters={['Sistema web', 'Vinculação']}
      />
      <Subject title='Emergência'
               questionsAndAnswers={EmergencyQuentionsAndAnswer}
               filters={['Sistema web', 'Emergência']}
      />
      <Subject title='Incidentes'
               questionsAndAnswers={IncidentsQuentionsAndAnswer}
               filters={['Sistema web', 'Incidentes']}
      />
      <Subject title='Relatórios'
               questionsAndAnswers={ReportsQuentionsAndAnswer}
               filters={['Sistema web', 'Relatórios']}
      />
      <Subject title='Usuários'
               questionsAndAnswers={UsersQuentionsAndAnswer}
               filters={['Sistema web', 'Usuários']}
      />
      <Subject title='Deslocamento'
               questionsAndAnswers={DisplacementAtoBQuentionsAndAnswer}
               filters={['Sistema web', 'Deslocamento']}
      />
    </React.Fragment>
;