import React, { createContext, ReactNode, useState, useContext } from 'react';

import { PaginationControls } from '../../../../../../components/Buttons/PaginationControls';
import { Roi } from '../../../../types/Insights';

type ContextValue = {
  roiChartData: Roi[];
  isLoading: boolean;
  roiValues: Array<Roi[]>;
  setRoiChartData: (value: Roi[]) => void;
  setRoiValues: (arrayAvarageValues: Array<Roi[]>) => void;
  setIsLoading: (value: boolean) => void;
};

const RoiContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function RoiContextProvider(props: Props) {
  const { children } = props;
  const [roiValues, setRoiValues] = useState<Array<Roi[]>>([]);
  const [roiChartData, setRoiChartData] = useState<Roi[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const value = {
    roiChartData,
    roiValues,
    isLoading,
    setRoiChartData,
    setRoiValues,
    setIsLoading,
  };

  return (
    <RoiContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={roiValues}
        setNewValue={setRoiChartData}
        title="Otimizações"
      />
    </RoiContext.Provider>
  );
}

export function useRoiContext() {
  const context = useContext(RoiContext);

  if (typeof context === 'undefined') {
    throw new Error('RoiContext must be used within an RoiContext');
  }

  return context;
}
