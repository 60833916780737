import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { Layer, Source } from 'react-map-gl';

import { useIsMounted } from '../../../../../hooks';
import cookieService from '../../../../../services/CookieService';
import { fetchData } from '../../../../../services/requests';
import { MapAreasData } from '../../../../../types/MonitoredData';
import { useAreasContext } from '../../../context/AreasContext';

export const useArea = () => {
  const mountedRef = useIsMounted();
  const { mapAreas, updateMapAreas } = useAreasContext();
  const token: any = cookieService.get('token');

  /*useSwr(
    mounted ? 'monitoring/simpleareas' : null,
    async (url: string) => {
      //const clientId = localStorage.getItem(CLIENT_ID) ? localStorage.getItem(CLIENT_ID) : '2';

      await fetchData(token, url, {})
        .then((res: AxiosResponse<any>) => {
          if (finished) return;
          if (!res.data || res.data.length === 0) return;

          if (!isMounted.current) return;

          updateMapAreas(res.data);
        })
        // eslint-disable-next-line no-console
        .catch((error: any) => console.error(error));
    },
    {
      focusThrottleInterval: 3600001,
      refreshInterval: 3600000,
    }
  ); */

  /*  useEffect(() => {
    setMounted(true);

    return () => {
      setFinished(true);
    };
  }, [mapAreas]); */

  useEffect(() => {
    const requestAreas = async (url: string) => {
      await fetchData(token, url, {})
        .then((res: AxiosResponse<any>) => {
          if (!res.data || res.data.length === 0) return;
          if (!mountedRef.current) return;
          updateMapAreas(res.data);
        })
        // eslint-disable-next-line no-console
        .catch((error: any) => console.error(error));
    };

    if (mountedRef.current) requestAreas('monitoring/simpleareas');
    //setInterval(() => requestAreas('monitoring/simpleareas'), 3600000);

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const parseDataToCoordinates = (coordinates: string) => {
    try {
      const coordinatesFormated = coordinates
        .replace('"', '')
        .split('],[')
        .map((coord: any) =>
          coord
            .replaceAll('[', '')
            .replaceAll(']', '')
            .split(',')
            .map((value: string) => Number(value))
        );

      return coordinatesFormated;
    } catch (_error) {
      return [];
    }
  };

  function createArea() {
    const areas = mapAreas
      .filter((area: MapAreasData) => area.coordenadas)
      .map((area: MapAreasData, idx: number) => {
        const { nome, cor, coordenadas } = area;

        const coordinates = parseDataToCoordinates(coordenadas);

        const geojson: FeatureCollection<Geometry, GeoJsonProperties> = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                title: nome,
              },
              geometry: {
                type: 'Polygon',
                coordinates: [coordinates],
              },
            },
          ],
        };

        const paint: mapboxgl.FillPaint = {
          'fill-opacity': 0.4,
          'fill-color': cor,
        };

        const paintBorder: mapboxgl.LinePaint = {
          'line-width': 1,
          'line-color': cor,
        };

        return (
          <Source id={idx + nome + 'src'} type="geojson" data={geojson} key={idx}>
            <Layer id={idx + nome} type="fill" paint={paint} />
            <Layer id={idx + nome + 'border'} type="line" paint={paintBorder} />
          </Source>
        );
      });

    return areas;
  }

  return { mapAreas, createArea };
};
