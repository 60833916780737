import styled from 'styled-components';
import { Shadow } from '../../../components';

export interface FixedAraProps {
  color: string;
}

export const DisplacementBtwnAreasContainer = styled(Shadow)`
    width: 100%;
    height: 100%;
    min-height: 270px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 32px;
`;

export const SelectAreasContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Titlecontainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.6rem;

  & > svg {
    //margin-right: 1.6rem;
    margin-top: 0.3rem;
  }
  
  & > div {
    margin-top: 0.3rem;
  }
`;

export const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0.6rem;
  line-height: 27px;
`;

export const Card = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 500px;
  height: 50px;
  display: flex;
  background: #ffffff;
  border: 0.5px solid #ebebf9;
  box-sizing: border-box;
  box-shadow: 8px 8px 16px 4px rgba(80, 94, 165, 0.13);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const CardHeader = styled.div`
    font-family: Work Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #808080;
`;

export const CardContent = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #2F4CDD;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ToggleContainer = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 14px;
  border: solid 2px #2F4CDD;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`

export const IconMarkerContainer = styled.div<FixedAraProps>`
display: flex;
border-radius: 28px;
padding: 4px;
width: 30px;
height: 30px;
background: ${(props) => props.color};
align-items: center;
justify-content: center
`

export const Tooltip = styled.div`
display: flex;
align-items: center;
justify-content: center;
background: #ffffff;
padding: 4px;
border-radius: 5px;
margin-top: 18px;
font-family: Work Sans;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
color: #4f5882;
`