import React from 'react';

import * as S from './styles';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;
  className?: string;
  icon?: JSX.Element;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Button: React.FC<IButtonProps> = ({
  title,
  className,
  icon,
  onClick,
  loading,
  ...rest
}) => {
  const { style, disabled } = rest;

  return (
    <S.Button
      title={title}
      className={className}
      style={style}
      onClick={onClick}
      disabled={disabled}
      loading={loading || undefined}
    >
      {icon}
      {title}
    </S.Button>
  );
};
