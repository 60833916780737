import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { MonitoredAreasData, MapAreasData, MonitoredData } from '../../../types/MonitoredData';

type currentColabCountIndicator = {
  loading: boolean;
  value: string;
};

type AreasContextData = {
  monitoredAreas: MonitoredAreasData[];
  mapAreas: MapAreasData[];
  indicator: currentColabCountIndicator;
  updateMonitoredAreas: (areas: MonitoredData) => void;
  updateMapAreas: (areas: MapAreasData[]) => void;
  setIndicator: (indicator: currentColabCountIndicator) => void;
};

type AreasProviderProps = { children: ReactNode };

const AreasContext = createContext({} as AreasContextData);

export function AreasContextProvider({ children }: AreasProviderProps) {
  const mounted = useRef(false);
  const [mapAreas, setMapAreas] = useState<MapAreasData[]>([]);
  const [monitoredAreas, setMonitoredAreas] = useState<MonitoredAreasData[]>([]); 
  const [indicator, setIndicator] = useState<currentColabCountIndicator>({ loading: true, value: 'Carregando...' });

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => { mounted.current = false }; // ... and to false on unmount
  }, []);

  const updateMonitoredAreas = useCallback(
    (data: MonitoredData) => {
      if (mounted.current) {
        setIndicator({ loading: false, value: data.collaborators.toString() });
        setMonitoredAreas(data.areas);
      }
    }, [setMonitoredAreas]
  );

  const updateMapAreas = useCallback(
    (areas: MapAreasData[]) => {
      if (mounted.current) setMapAreas(areas);
    },[setMonitoredAreas]
  );

  const value = {
    monitoredAreas,
    mapAreas,
    updateMonitoredAreas,
    updateMapAreas,
    indicator,
    setIndicator,
  };

  return <AreasContext.Provider value={value}>{children}</AreasContext.Provider>;
}

export function useAreasContext() {
  const context = useContext(AreasContext);

  if (typeof context === 'undefined') {
    throw new Error('AreasContext must be used within an useAreasContext');
  }

  return context;
}
