import { GetUserGroupPermissions } from '.';
import { MenuIcon, RouteKey } from '../../../enum';

export const getUserGroupPermissions: GetUserGroupPermissions = async () => ({
  rules: ['ADMIN', 'DASHBOARD'],
  routes: [
    {
      key: RouteKey.monitoramento,
      path: '/monitoramento',
    },
    {
      key: RouteKey.indicadores,
      path: '/indicadores',
    },
    {
      key: RouteKey.analises,
      path: '/analises',
    },
    {
      key: RouteKey.sumario,
      path: '/sumario',
    },
    {
      key: RouteKey.relatorios,
      path: '/relatorios',
    },
    {
      key: RouteKey.informes,
      path: '/informes',
    },
    {
      key: RouteKey.vinculacaodecolaboradores,
      path: '/vinculacao',
    },
  ],
  menu: [
    {
      key: 'monitoramento',
      description: 'Monitoramento',
      icon: MenuIcon.dashboard,
      path: '/monitoramento',
    },
    {
      key: 'indicadores',
      description: 'Indicadores',
      icon: MenuIcon.indicators,
      path: '/indicadores',
    },
    {
      key: 'analises',
      description: 'Previsoes',
      icon: MenuIcon.insights,
      path: '/analises',
    },
    {
      key: 'sumario',
      description: 'Sumario diário',
      icon: MenuIcon.summary,
      path: '/sumario',
    },
    {
      key: 'relatorios',
      description: 'Relatórios',
      icon: MenuIcon.reports,
      path: '/relatorios',
    },
    {
      key: 'informes',
      description: 'Informes',
      icon: MenuIcon.insights,
      path: '/informes',
    },
    {
      key: 'vinculacaodecolaboradores',
      description: 'Vinculação de Colaboradores',
      icon: MenuIcon.collaboratorsLink,
      path: '/vinculacao',
    },
  ],
});

export const getUserGroupPermissions3: GetUserGroupPermissions = async () => ({
  rules: ['ADMIN', 'DASHBOARD'],
  routes: [
    {
      key: RouteKey.monitoramento,
      path: '/monitoramento',
    },
    {
      key: RouteKey.indicadores,
      path: '/indicadores',
    },
    {
      key: RouteKey.planejamento,
      path: '/planejamento',
    },
    {
      key: RouteKey.analises,
      path: '/analises',
    },
    {
      key: RouteKey.sumario,
      path: '/sumario',
    },
    {
      key: RouteKey.relatorios,
      path: '/relatorios',
    },
    {
      key: RouteKey.informes,
      path: '/informes',
    },
    {
      key: RouteKey.vinculacaodecolaboradores,
      path: '/vinculacao',
    },
  ],
  menu: [
    {
      key: 'monitoramento',
      description: 'Monitoramento',
      icon: MenuIcon.dashboard,
      path: '/monitoramento',
    },
    {
      key: 'indicadores',
      description: 'Indicadores',
      icon: MenuIcon.indicators,
      path: '/indicadores',
    },
    {
      key: 'indicadorestrackfy',
      description: 'Indicadores Trackfy',
      icon: MenuIcon.indicators,
      path: '/indicadorestrackfy',
    },
    {
      key: 'analises',
      description: 'Previsoes',
      icon: MenuIcon.insights,
      path: '/analises',
    },
    {
      key: 'sumario',
      description: 'Sumario diário',
      icon: MenuIcon.summary,
      path: '/sumario',
    },
    {
      key: 'relatorios',
      description: 'Relatórios',
      icon: MenuIcon.reports,
      path: '/relatorios',
    },
    {
      key: 'informes',
      description: 'Informes',
      icon: MenuIcon.insights,
      path: '/informes',
    },
    {
      key: 'vinculacaodecolaboradores',
      description: 'Vinculação de Colaboradores',
      icon: MenuIcon.collaboratorsLink,
      path: '/vinculacao',
    },
  ],
});
