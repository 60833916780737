import React from 'react';
import { DefaultIconProps } from './types';

// enable-background="new 0 0 60 60"
const IconCalendar: React.FC<DefaultIconProps> = ({width, height, fill, style, onClick}) => (
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" style={style} onClick={onClick}>
    <title />
    <g data-name="Layer 2" id="Layer_2">
      <path fill={fill || undefined} d="M24,29H8a5,5,0,0,1-5-5V10A5,5,0,0,1,8,5H24a5,5,0,0,1,5,5V24A5,5,0,0,1,24,29ZM8,7a3,3,0,0,0-3,3V24a3,3,0,0,0,3,3H24a3,3,0,0,0,3-3V10a3,3,0,0,0-3-3Z" />
      <path fill={fill || undefined} d="M24,25H20a1,1,0,0,1-1-1V20a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,24,25Zm-3-2h2V21H21Z" />
      <path fill={fill || undefined} d="M28,13H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z" />
      <path fill={fill || undefined} d="M11,9a1,1,0,0,1-1-1V4a1,1,0,0,1,2,0V8A1,1,0,0,1,11,9Z" />
      <path fill={fill || undefined} d="M21,9a1,1,0,0,1-1-1V4a1,1,0,0,1,2,0V8A1,1,0,0,1,21,9Z" />
    </g>
    <g id="frame">
      <rect fill="none" height={height || "32"} width={width || "32"} />
    </g>
  </svg>
);

export default IconCalendar;
