import React, { createContext, ReactNode, useState, useContext } from 'react';

import { PaginationControls } from '../../../../../components/Buttons/PaginationControls';
import { PeopleInAreaDate } from '../../../../indicators/types/Indicators';

type ContextValue = {
  peopleValues: Array<PeopleInAreaDate[]>;
  peopleInAreasChartData: PeopleInAreaDate[];
  setPeopleValues: (arrayAvarageValues: Array<PeopleInAreaDate[]>) => void;
  setPeopleInAreasChartData: (values: PeopleInAreaDate[]) => void;
  dividerPerFifteen: (base: PeopleInAreaDate[]) => Array<PeopleInAreaDate[]>;
};

const PeopleInAreaContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function PeopleInAreaContextProvider(props: Props) {
  const { children } = props;
  const [peopleValues, setPeopleValues] = useState<Array<PeopleInAreaDate[]>>([]);
  const [peopleInAreasChartData, setPeopleInAreasChartData] = useState<PeopleInAreaDate[]>([]);

  const dividerPerFifteen = (base: PeopleInAreaDate[]): Array<PeopleInAreaDate[]> => {
    const divisor: number = 15;
    const newArray: Array<PeopleInAreaDate[]> = [];

    for (let i = 0; i < base.length; i += divisor) {
      const divider: PeopleInAreaDate[] = base.slice(i, i + divisor);
      newArray.push(divider);
    }
    return newArray;
  };

  const value = {
    peopleValues,
    setPeopleValues,
    peopleInAreasChartData,
    setPeopleInAreasChartData,
    dividerPerFifteen,
  };

  return (
    <PeopleInAreaContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={peopleValues}
        setNewValue={setPeopleInAreasChartData}
        title="Pessoas por áreas"
      />
    </PeopleInAreaContext.Provider>
  );
}

export function usePeopleInAreaContext() {
  const context = useContext(PeopleInAreaContext);

  if (typeof context === 'undefined') {
    throw new Error('PeopleInAreaContext must be used within an PeopleInAreaContext');
  }

  return context;
}
