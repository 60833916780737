import React from 'react';

import { PageHeader } from '../../components/PageStructure/PageHeader';
import { Container } from '../../components/PageStructure/styles';
import { PageTabs } from './components/PageTabs';

export const IndicatorsContainer: React.FC<{}> = () => {
  return (
    <Container>
      <PageHeader title="Indicadores" />
      <PageTabs />
    </Container>
  );
}
