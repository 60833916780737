import React, { createContext, ReactNode, useCallback, useContext, useRef, useState } from 'react';
import { useAuth } from '../../../../../hooks';
import { MapAreasData, MonitoredAreasData } from '../../../../../types/MonitoredData';

type CurrentColabCountIndicator = {
  loading: boolean;
  value: string;
};

type AreasContextData = {
  monitoredAreas: MonitoredAreasData[];
  mapAreas: MapAreasData[];
  currentCollaborators: CurrentColabCountIndicator;
  //updateMonitoredAreas: (areas: MonitoredData) => void;
  updateMapAreas: (areas: MapAreasData[]) => void;
  setCurrentCollaborators: (indicator: CurrentColabCountIndicator) => void;
};

type AreasProviderProps = { children: ReactNode };

const AreasContext = createContext({} as AreasContextData);

export function AreasContextProvider({ children }: AreasProviderProps) {
  const { getClientId } = useAuth();
  const mounted = useRef(false);
  const [mapAreas, setMapAreas] = useState<MapAreasData[]>([]);
  const [monitoredAreas, setMonitoredAreas] = useState<MonitoredAreasData[]>([]);
  const [currentCollaborators, setCurrentCollaborators] = useState<CurrentColabCountIndicator>({value : '', loading: true});

  /*useEffect(() => {
    mounted.current = true;
    setCurrentCollaborators((prev: CurrentColabCountIndicator) => ({...prev, loading: true}))
    const currentRequest = axios.CancelToken.source();

    getMonitoredAreasData(getClientId(), 0, currentRequest.token).then((v) =>
      setCurrentCollaborators({value: v.collaborators.toString(), loading: false})
    );

    return () => {
      mounted.current = false;
    }; 
  }, []);*/

  /*const updateMonitoredAreas = useCallback(
    (data: MonitoredData) => {
      if (mounted.current) {
        setCurrentCollaborators({value: data.collaborators.toString(), loading: false});
        setMonitoredAreas(data.areas);
      }
    },
    [setMonitoredAreas]
  );*/

  const updateMapAreas = useCallback(
    (areas: MapAreasData[]) => {
      if (mounted.current) setMapAreas(areas);
    },
    [setMonitoredAreas]
  );

  const value = {
    monitoredAreas,
    mapAreas,
    //updateMonitoredAreas,
    updateMapAreas,
    currentCollaborators,
    setCurrentCollaborators,
  };

  return <AreasContext.Provider value={value}>{children}</AreasContext.Provider>;
}

export function useAreasContext() {
  const context = useContext(AreasContext);

  if (typeof context === 'undefined') {
    throw new Error('AreasContext must be used within an useAreasContext');
  }

  return context;
}
