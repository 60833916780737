import { Line, LineConfig } from '@ant-design/charts';
import Axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { IconLoading } from '../../components/IconLoading';
import { Burndown, Planning } from '../indicators/types/Indicators';
import { getAgileanCurves } from '../../services/api/TrackfyService';
import { useAgileanContext } from './context/AgileanContext';

type PlanningCurvesProps = {
  hours: number;
  plan: number;
}

const PlanningCurves: React.FC<PlanningCurvesProps> = 
  ({hours, plan}: PlanningCurvesProps) => {
  const { areaSelected, companySelected, professionSelected, activitySelected, initialDate, finalDate } = 
    useAgileanContext();
  const [planningData, setPlanningData] = useState<Planning[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const color = ['#4F5882', '#00E0B4', '#468FEA'];

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    setIsLoading(true);

    getAgileanCurves(
      companySelected,
      professionSelected,
      areaSelected,
      activitySelected,
      moment(initialDate).valueOf(),
      moment(finalDate).valueOf(),
      hours,
      plan,
      currentRequest.token,
    )
    .then((result:Burndown) => {
      const data = result.series;
      
      data.map((item) => {
        if(item.type == 'programado') item.type = 'planejado'
      });

      setPlanningData(data);
    })
    .catch((err) => console.log(err))
    .finally(() => setIsLoading(false))

    return () => {
      currentRequest.cancel('CollaboratorCountIndicator request canceled by the user.');
    };
  }, [areaSelected, companySelected, professionSelected, initialDate, finalDate, activitySelected]);

  const config: LineConfig = {
    data: planningData,
    xField: 'time',
    yField: 'value',
    color: color,
    seriesField: 'type',
    point: {
      size: 5,
      shape: 'diamond | circule',
    },
    yAxis: {
      label: {
        formatter: function formatter(v: any) { 
          if(hours == 1)
            return ''.concat(v, 'hrs'); 
          else  
            return ''.concat(v, ''); 
        }
      }
    },
    legend: {
      custom: true,
      position: 'bottom',
      items: [
        {
          value: 'planejado',
          name: 'Curva recente',
          marker: {
            symbol: 'square',
            style: {
              fill: '#4F5882',
              r: 5,
            },
          },
        },
        {
          value: 'inputado',
          name: 'Curva intermediária',
          marker: {
            symbol: 'square',
            style: {
              fill: '#00E0B4',
              r: 5,
            },
          },
        },
        {
          value: 'trackfy',
          name: 'Curva inicial',
          marker: {
            symbol: 'square',
            style: {
              fill: '	#468FEA',
              r: 5,
            },
          },
        },
      ],
    },
  };

  if (isLoading) return <IconLoading customDivStyle={{marginTop:"100px", marginLeft:"45%", marginRight:"45%"}}/>;

  return <Line {...config} />;
};

export default PlanningCurves;