import React, { useEffect } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import FullCalendar from '@fullcalendar/react';
import ptBr from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

import { useFullCalendarContext } from './context/FullCalendarContext';
import { useAuthContext } from '../../../stores';
import { useMovimentContext } from '../context/MovimentContext';
import { useCalendar } from './hooks/useCalendar';
import { useAuth } from '../../../hooks';
import { useTrackfyApi } from '../../../hooks/useTrackfyApi';
import { IconLoading } from '../../../components/IconLoading';
import QuestionMark from '../../../Icons/QuestionMark';
import DynamicComponentsFactory from '../../../components/DynamicComponents/DynamicComponentsFactory';
import { getFullCalendarTooltipContent } from './FullCalendarTooltip';
import { Info } from '../../../components/Info';

import styled from 'styled-components';
import { EmptyContainer } from '../../../components/Table/styles';
import { Shadow } from '../../../components';
import './FullCallendar.css';

const CalendarCard = styled(Shadow)`
  flex-basis: 100%;
  width: 100%;
  height: 100%;
  //min-height: 590px;
  max-height: 600px;
  padding: 2.4rem 2.4rem 1.6rem;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  & .fc-media-screen {
    height: 100%;
  }
`;

const IconContent = styled.div`
  display: flex;
  position: absolute;
`;

export const FullCalendarContent: React.FC = () => {
  const {authData} = useAuthContext();
  const { initialDate, finalDate, collaboratorSelected } = useMovimentContext();
  const { events, setEvents } = useFullCalendarContext();
  const { getCalendarDataApi } = useTrackfyApi();
  const { getClientId } = useAuth();
  const { isLoading, setIsLoading, showEventDetails, logData, initDate, setInitDate } = useCalendar();
  const clientId = getClientId();
  const tooltipContent = getFullCalendarTooltipContent(clientId);

  const charts = authData ? authData.components.charts : [];
  const optionsView = ['timeGridWeek', 'timeGridDay', 'dayGridMonth'];
  const collaboratorSelectedFormated =  collaboratorSelected.length > 0 ? 
    parseInt(collaboratorSelected.map(item => item.value)[0]) : 0;
    
  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted)
      getCalendarDataApi(
        getClientId(),
        collaboratorSelectedFormated,
        initialDate,
        finalDate,
        currentRequest.token,
        setEvents,
        setInitDate,
        setIsLoading
      );

    return () => {
      mounted = false;
      currentRequest.cancel('FullCallendar request canceled by the user.');
    };
  }, [collaboratorSelected, initialDate, finalDate]);

  if (isLoading) return <IconLoading />;

  if(collaboratorSelected.length == 0 )
    return (
      <CalendarCard>
        <EmptyContainer>
          <QuestionMark />
          <span>Para visualizar os dados, selecione um recurso</span>
        </EmptyContainer>
      </CalendarCard>
    );
  
  if(collaboratorSelected.length > 1 )
    return (
      <CalendarCard>
        <EmptyContainer>
          <QuestionMark />
          <span>Para visualizar os dados, selecione apenas um único recurso</span>
        </EmptyContainer>
      </CalendarCard>
    );
    
  return (
    <CalendarCard>
      <DynamicComponentsFactory charts={charts} component='movCalendarExport'/>
      <IconContent>
        <Info content={tooltipContent} position={'right'} burndown={false}/>
      </IconContent>
      <FullCalendar
        viewClassNames="callendar"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        locale={ptBr}
        headerToolbar={{
          left: '',
          center: '',
          // right: '',
        }}
        initialView={optionsView[0]}
        initialDate={initDate}
        selectable
        weekends
        dayMaxEvents
        slotLabelFormat={[{ hour: '2-digit', minute: '2-digit', hour12: false }]}
        eventBorderColor="transparent"
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          // second: '2-digit',
          hour12: false,
          omitZeroMinute: false,
        }}
        initialEvents={events}
        slotMinTime="07:00:00"
        slotMaxTime="24:00:00"
        slotDuration="00:02:00"
        eventClick={logData}
        eventMouseEnter={showEventDetails}
        eventOverlap={false}
        slotEventOverlap={false}
      />
    </CalendarCard>
  );
};