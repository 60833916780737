export const b64toBlob = (b64Data: any, contentType: string, sliceSize?: number): Blob => {
  const contentTypeParsed = contentType || '';
  const sliceSizeParsed = sliceSize || 512;

  const byteCharacters: string = atob(b64Data);
  const byteArrays: Array<any> = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSizeParsed) {
    const slice: string = byteCharacters.slice(offset, offset + sliceSizeParsed);

    const byteNumbers: any[] = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray: Uint8Array = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob: Blob = new Blob(byteArrays, { type: contentTypeParsed });
  return blob;
};

export const downloadPdf = (filename: string, text: string): void => {
  const element: HTMLAnchorElement = document.createElement('a');
  // @ts-ignore
  if (window.navigator.msSaveOrOpenBlob) {
    const fileData: string[] = [text];
    const blobObject: Blob = b64toBlob(fileData, 'application/pdf');
    element.addEventListener('click', () => {
      // @ts-ignore
      window.navigator.msSaveOrOpenBlob(blobObject, filename);
    });
  } else {
    element.setAttribute('href', `data:application/pdf;charset=utf-8;base64,${text}`);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
  }
  element.click();
  document.body.removeChild(element);
};
