import React, { createContext, ReactNode, useState, useContext } from 'react';
import { PaginationControls } from '../../../../components';
import { AreasTimeDivision } from '../../types/types';

type ContextValue = {
  areasTimeDivisionChartData: AreasTimeDivision[];
  isLoading: boolean;
  areasTimeDivisionValues: Array<AreasTimeDivision[]>;
  setAreasTimeDivisionChartData: (value:AreasTimeDivision[]) => void;
  setAreasTimeDivisionValues: (arrayAvarageValues: Array<AreasTimeDivision[]>) => void;
  setIsLoading: (value: boolean) => void;
};

const AreasTimeDivisionContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function AreasTimeDivisionContextProvider(props: Props) {
  const { children } = props;
  const [ areasTimeDivisionValues, setAreasTimeDivisionValues] = useState<Array<AreasTimeDivision[]>>([]);
  const [ areasTimeDivisionChartData, setAreasTimeDivisionChartData] = useState<AreasTimeDivision[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const value = {
    areasTimeDivisionChartData,
    areasTimeDivisionValues,
    isLoading,
    setAreasTimeDivisionChartData,
    setAreasTimeDivisionValues,
    setIsLoading
  };

  return (
    <AreasTimeDivisionContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={areasTimeDivisionValues}
        setNewValue={setAreasTimeDivisionChartData}
        title="Tempo em máquinas"
      />
    </AreasTimeDivisionContext.Provider>
  );
}

export function useAreasTimeDivisionContext() {
  const context = useContext( AreasTimeDivisionContext);

  if (typeof context === 'undefined') {
    throw new Error('AreasTimeDivisionContext must be used within an AreasTimeDivisionContext');
  }

  return context;
}