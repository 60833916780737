import { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import moment from 'moment';

import { useCompanyFilterContext } from '../../../../../../../stores/CompanyFilterContext';
import { getAbsenteeism } from '../../../../../../../services/api/TrackfyService';
import { useAuth } from '../../../../../../../hooks';

type absenteeismIndicator = {
  loading: boolean;
  value: string;
};

export const useAbsenteeism = () => {
  const [indicator, setIndicator] = useState<absenteeismIndicator>({ loading: true, value: 'Carregando...' });
  const { selectedCompany } = useCompanyFilterContext();
  const { getClientId } = useAuth();

  const companyId = selectedCompany?.id ? selectedCompany?.id : '0';
  const clientId: number = getClientId();
  const useV2 = clientId == 1 || clientId == 6 || clientId == 11;

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if (mounted) {
      //setIndicator({loading: true, value: "Carregando..."});

      getAbsenteeism(clientId, companyId, moment().format('YYYY-MM-DD'), currentRequest.token)
        .then((response: AxiosResponse<any>) => {
          let absenteeism = '0%';

          if (response.data) absenteeism = useV2 ? response.data.absenteeism : response.data;

          const indicatorText = absenteeism;
          setIndicator({ loading: false, value: indicatorText });
        })
        .catch((err: any) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('Absenteeism request canceled by the user.');
    };
  }, [companyId]);

  return { indicator };
};
