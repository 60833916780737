import { useEffect, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';

import { TextInsightsMessages } from '../../../types/TextInsights';
import { useTextInsightsContext } from '../../../context/TextInsightsContext';
import { getTextInsightsMessages } from '../../../../../services/api/TrackfyService';

export const useTextInsights = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [informes, setTextInsights] = useState<TextInsightsMessages[]>([]);
  const { companySelected, clientSelected } = useTextInsightsContext();

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if(mounted) {
      setIsLoading(true)

      getTextInsightsMessages(companySelected,0,0,0,clientSelected,currentRequest.token)
        .then((data) => {
          if(data.length > 0){
            setTextInsights(data)
            setIsLoading(false)
          }
        })
        .catch((err) => {
          console.log(err)
          setTextInsights([])
          setIsLoading(false)
        })
    }

    return () => {
      mounted = false;
      currentRequest.cancel(' request canceled by the user.');
    };
  }, [companySelected, clientSelected]);

  return {
    informes,
    isLoading,
    companySelected,
    clientSelected
  };
};