import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { SelectOption } from '../../components/Select/types';
import { Select } from '../../components/Select';
import { getClients } from '../../services/api/TrackfyService';
import { Client } from '../../types/Filters';

type ClientSelectProps = {
  label?: string;
  omitCivil?: boolean;
  clientSelected: number;
  setClientSelected: (value: number) => void;
};

const ClientSelect: React.FC<ClientSelectProps> = (props: ClientSelectProps) => {
  const [clients, setClients] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { clientSelected, setClientSelected, label, omitCivil } = props;

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);

      getClients(currentRequest.token, omitCivil)
        .then((result: AxiosResponse<any>) => {
          setClients(
            result.data.map((Client: Client) => {
              return {
                label: Client.name,
                value: Client.id ? Client.id.toString() : '0',
              };
            })
          );
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('getCompanysApi request canceled by the user.');
    };
  }, []);

  return (
    <Select
      id="avrgtime-Client-select"
      label={label}
      placeholder="Selecione um cliente"
      options={clients}
      value={clientSelected}
      loading={isLoading}
      onChanging={(event) => setClientSelected(Number(event.target.value))}
    />
  );
};

export default ClientSelect;
