import api from './api';
import CookieService from './CookieService';

export const ACCESS_TOKEN_KEY = '@trackfy_access_token';
export const REFRESH_ACCESS_TOKEN_KEY = '@trackfy_refresh_access_token';
export const USER_ID = '@trackfy_user_id';
export const CLIENT_ID = '@trackfy_client_id';
export const USER_NAME = '@trackfy_user_name';
export const isAuthenticated = () => localStorage.getItem(ACCESS_TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);
export const getRefreshToken = () => CookieService.get(REFRESH_ACCESS_TOKEN_KEY);
export const login = (
  accessToken: string,
  refreshToken: string,
  userId: string,
  clientId: string,
  userName: string
) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  localStorage.setItem(USER_ID, userId);
  localStorage.setItem(CLIENT_ID, clientId);
  localStorage.setItem(USER_NAME, userName);
  CookieService.set(REFRESH_ACCESS_TOKEN_KEY, refreshToken, {
    path: '/',
  });
};
export const logout = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(CLIENT_ID);
  CookieService.remove(REFRESH_ACCESS_TOKEN_KEY);
};

export const refreshAccessToken = async (refreshToken: string) => {
  if (!refreshToken) {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }
  const response = await api.post('auth/refreshToken', {
    refreshToken,
  });
  const { accessToken } = response.data;
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  return accessToken;
};
