import React from 'react';

import { PageHeader } from '../../components/PageStructure/PageHeader';
import { Container, ContentContainer } from '../../components/PageStructure/styles';
import TextInsightsOptions from './FaqOptions';
import { FaqContent } from './components/FaqContent';
import { FaQContextProvider } from './context/FaqContext';
import { Header } from './components/Header';

export const FaqContainer: React.FC = () => (
  <Container>
    <PageHeader title="Suporte" />
    <ContentContainer value="">
      <Header/>
      <FaQContextProvider>
        <TextInsightsOptions />
        <FaqContent/>
      </FaQContextProvider>
    </ContentContainer>
  </Container>
);