import React from 'react';

import { EvacuationContextProvider } from './context/EvacuationContext';
import { EmergencyMapContainer } from './components/EmergencyMapContainer';

export const EmergencyContainer: React.FC = () => (
  <EvacuationContextProvider>
    <EmergencyMapContainer />
  </EvacuationContextProvider>
);
