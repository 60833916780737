import React from 'react';

import { ArrowRightVuesax } from '../../../../Icons/ArrowRight-Vuesax';
import { IconFinishVuesax } from '../../../../Icons/Finish-Vuesax';
import { IconLocationVuesax } from '../../../../Icons/Location-Vuesax';
import { IconRepeatVuesax } from '../../../../Icons/Repeat-Vuesax';
import AreaSelect from '../../../filters/AreaSelect';
import { useDisplacementBtwnAreasContext } from '../context/DisplacementBtwnAreasContext';

import * as S from '../styles'

export const SelectInitialAndFinalAreas: React.FC = () => {
    let {
        setInitialAreaSelected, 
        finalAreaSelected, 
        initialAreaSelected, 
        setFinalAreaSelected,
        setInitialAreaLabelSelected,
        initialAreaLabelSelected,
        finalAreaLabelSelected,
        setFinalAreaLabelSelected,
        setAreasMarkers
    } = useDisplacementBtwnAreasContext();

    const handleSwap = () => {
        setInitialAreaSelected(finalAreaSelected)
        setInitialAreaLabelSelected(finalAreaLabelSelected)
        setFinalAreaSelected(initialAreaSelected)
        setFinalAreaLabelSelected(initialAreaLabelSelected)
    }

    return(
        <S.SelectAreasContainer>
            <S.SelectContainer>
                <S.IconContainer>
                    <IconLocationVuesax stroke='#666666'/>
                </S.IconContainer>
                <AreaSelect 
                    areaSelected={initialAreaSelected}
                    areaLabelSelected={initialAreaLabelSelected}
                    setAreaSelected={setInitialAreaSelected}
                    setAreaLabelSelected={setInitialAreaLabelSelected}
                    screen={'Produtividade - Movimentação - Deslocamentos entre áreas'}
                    setAreasMarkers={setAreasMarkers}
                    initial={true}
                />
            </S.SelectContainer>

            <S.IconContainer>
                <ArrowRightVuesax
                    width={30}
                    height={30}
                />
            </S.IconContainer>

            <S.SelectContainer>
                <S.IconContainer>
                    <IconFinishVuesax stroke='#666666'/>
                </S.IconContainer>
                <AreaSelect
                    areaSelected={finalAreaSelected}
                    setAreaSelected={setFinalAreaSelected}
                    areaLabelSelected={finalAreaLabelSelected}
                    setAreaLabelSelected={setFinalAreaLabelSelected}
                    setAreasMarkers={setAreasMarkers}
                    initial={false}
                />
            </S.SelectContainer>

            <S.ToggleContainer onClick={handleSwap}>
                <IconRepeatVuesax/>
            </S.ToggleContainer>
        </S.SelectAreasContainer>
    )
};