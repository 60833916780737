import React from 'react';

type Props = {
  stroke: string;
  height?: string;
  width?: string;
};

const IconReason: React.FC<Props> = ({ stroke, height, width }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 15.7174H14.3667C13.7 15.7174 13.0667 15.9758 12.6 16.4424L11.175 17.8508C10.525 18.4925 9.46667 18.4925 8.81667 17.8508L7.39166 16.4424C6.925 15.9758 6.28333 15.7174 5.625 15.7174H5C3.61667 15.7174 2.5 14.6091 2.5 13.2425V4.15076C2.5 2.78409 3.61667 1.67578 5 1.67578H15C16.3833 1.67578 17.5 2.78409 17.5 4.15076V13.2425C17.5 14.6008 16.3833 15.7174 15 15.7174Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.99952 9.4668V9.29183C9.99952 8.72516 10.3495 8.42515 10.6995 8.18348C11.0412 7.95015 11.3828 7.65016 11.3828 7.10016C11.3828 6.33349 10.7662 5.7168 9.99952 5.7168C9.23286 5.7168 8.61621 6.33349 8.61621 7.10016" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.99592 11.4577H10.0034" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default IconReason;