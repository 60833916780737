import React, { createContext, ReactNode, useState, useContext, Dispatch, SetStateAction } from 'react';

import { PaginationControls } from '../../../../components/Buttons/PaginationControls';
import { AreaTimeline, Timeline } from '../../../indicators/types/Indicators';

type ContextValue = {
  areaTimelineData: AreaTimeline[];
  areaTimelineValues: Array<AreaTimeline[]>;
  setAreaTimelineData: (value: AreaTimeline[]) => void;
  setAreaTimelineValues: (arrayAvarageValues: Array<AreaTimeline[]>) => void;
  areaTimelineSelected: Timeline[];
  setAreaTimelineSelected: Dispatch<SetStateAction<Timeline[]>>;
};

const AreaTimelineContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function AreaTimelineContextProvider(props: Props) {
  const { children } = props;

  const [areaTimelineData, setAreaTimelineData] = useState<AreaTimeline[]>([]);
  const [areaTimelineValues, setAreaTimelineValues] = useState<Array<AreaTimeline[]>>([]);
  const [areaTimelineSelected, setAreaTimelineSelected] = useState<Timeline[]>([]);

  const value = {
    areaTimelineData,
    setAreaTimelineData,
    areaTimelineValues,
    setAreaTimelineValues,
    areaTimelineSelected,
    setAreaTimelineSelected,
  };

  return (
    <AreaTimelineContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={areaTimelineValues}
        setNewValue={setAreaTimelineData}
        title="Timeline de conformidade"
      />
    </AreaTimelineContext.Provider>
  );
}

export function useAreaTimelineContext() {
  const context = useContext(AreaTimelineContext);

  if (typeof context === 'undefined') {
    throw new Error('TimelineContext must be used within an TimelineContext');
  }

  return context;
}