import React from 'react';

import IconControll from '../../../../../../../Icons/Controll';

type Props = {
  titulo: string;
  onClick: any;
  expanded: boolean;
};

export const CardHeader: React.FC<Props> = (props: Props) => {
  const { titulo, onClick, expanded } = props;

  return (
    <div className="card-monitor-header">
      <span
        className="card-expandable"
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex={0}
      >
        {expanded ? '-' : '+'}
      </span>
      <div className="card-title">{titulo}</div>
      {false && (
        <div className="card-controll">
          <IconControll />
        </div>
      )}
    </div>
  );
};
