import React from 'react';

import { ContentContainer } from '../../components/PageStructure/styles';
import { ProductivityContextProvider } from './context/PageProductivityContext';
import ProductivityOptions from './ProductivityOptions';
import { useAuthContext } from '../../stores';
import { ProductivityComponentsFactory } from './ProductivityComponentsFactory';

const PageProductivity: React.FC<{}> = () => {
  const { authData } = useAuthContext();
  const charts = authData ? authData.components.charts : [];

  return (
    <ContentContainer value="">
      <ProductivityContextProvider>
        <ProductivityOptions />
        <ProductivityComponentsFactory charts={charts} />
      </ProductivityContextProvider>
    </ContentContainer>
  );
};

export default PageProductivity;