import React from 'react';

import { useArea } from './hooks/useArea';

export const Area: React.FC = () => {
  const { mapAreas, createArea } = useArea();

  if (!mapAreas) return <></>;
  return <>{createArea()}</>;
};
