import { useEffect, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import moment from 'moment';

import { useCompanyFilterContext } from '../../../../../../../stores';
import { useAgileanContext } from '../../../../../../agilean/context/AgileanContext';
import { CollaboratorsCount } from '../../../../../../indicators/types/Indicators';
import { getPlanningCollaboratorsCount } from '../../../../../../../services/api/TrackfyService';

type PlanningCollaboratorCountIndicator = {
  loading: boolean;
  value: string;
};

export const usePlanningCollaboratorsCount = () => {
  const { initialDate, finalDate } = useAgileanContext();
  const [indicator, setIndicator] = useState<PlanningCollaboratorCountIndicator>({
    loading: true,
    value: 'Carregando...',
  });
  //const [planningCount, setPlanningCount] = useState<CollaboratorsCount[]>([])
  const { selectedCompany } = useCompanyFilterContext();
  const companyId = selectedCompany?.id ? selectedCompany?.id : '0';

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    setIndicator({ loading: true, value: 'Carregando...' });

    getPlanningCollaboratorsCount(
      Number.parseInt(companyId),
      moment(initialDate).valueOf(),
      moment(finalDate).valueOf(),
      currentRequest.token
    )
      .then((data: CollaboratorsCount[]) => {
        let indicatorText = '0';

        if (data && data.length > 0) indicatorText = data[0].value.toString();

        setIndicator({ loading: false, value: indicatorText });
      })
      .catch((err) => console.log(err));

    return () => {
      currentRequest.cancel('PLanningCollaboratorCountIndicator request canceled by the user.');
    };
  }, [companyId]);

  return {
    indicator,
  };
};
