import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: rgb(85 85 85 / 8%) 0px 2px 8px 2px;
  padding: 1.2em;

`;

export const Title = styled.div`
  color: #808080;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
`

export const Value = styled.div`
  color: #2F80ED;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;

  padding-left: 5px
`
export const ItemContainer = styled.div`
  display: flex;
`