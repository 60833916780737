import { useEffect, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import { useSystemlogsContext } from '../context/SystemlogsContext';
import { getLogs } from '../../../services/api/TrackfyService/integration';

export type Logs = {
    user: string,
    message: string,
    date: string
}

export const useSystemLogs = () => {
    const { initialDate, finalDate, logTypesSelected} = useSystemlogsContext();
    const [logs, setLogs] = useState<Logs[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    const requestLogs = () => {
        const currentRequest: CancelTokenSource = Axios.CancelToken.source();
        setIsLoading(true);

        getLogs(logTypesSelected,
            moment(initialDate).startOf('day').valueOf(),
            moment(finalDate).valueOf(),    
            currentRequest.token
        ).then((data:any) => {   
                const logs: Logs[] = data.data;
                setLogs(logs);
                setIsLoading(false);
                if (data.length == 0) setIsEmpty(true);
            })
            .catch((err: any) => {
                console.log(err);
                setLogs([]);
                setIsLoading(false);
                setIsEmpty(true);
            });
    };

    useEffect(() => {
        const currentRequest: CancelTokenSource = Axios.CancelToken.source();
        let mounted = true;
    
        if (mounted) {
          setIsLoading(true);
          setIsEmpty(false);
          requestLogs();
        }
    
        return () => {
          mounted = false;
          currentRequest.cancel(' request canceled by the user.');
        };
    }, [logTypesSelected, initialDate, finalDate]);
    
    return {
        logs,
        isLoading,
        isEmpty
    };
};