import React from 'react';

import { useAuth } from '../../hooks';
import { PageHeader } from '../../components/PageStructure/PageHeader';
import { PageTabs } from './components/PageTabs';

import * as S from './components/styles';

export const ClientCompanyRegistrationContainer: React.FC<{}> = () => {
  const { getIsAdmin } = useAuth();

  return (
    <S.Container>
      <PageHeader title="Página de cadastro" />
      <PageTabs isAdmin={getIsAdmin()} />
    </S.Container>
  )
};
