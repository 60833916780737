export const getEndpoint = (method: string, local: boolean): string => {
  let serverHost = `https://backend.trackfyapp.com/rest/v2`;

  if (local) serverHost = `http://localhost:8083/rest/v2`;

  switch (method) {
    case `absenteeism`:
      return `${serverHost}/monitoring/absenteeism`;

    case `averageTimeByPeople`:
      return `${serverHost}/statistics/averageTimeByPeople`;

    case `areaTimeline`:
      return `${serverHost}/statistics/areatimeline`;

    case `arrivalAndDeparture`:
      return `${serverHost}/statistics/arrivalanddeparture`;

    case `burndown`:
      return `${serverHost}/statistics/burndown`;

    case `cancelinvite`:
      if (local) return `http://localhost:8083/rest/user/invite/cancel`;
      return `https://backend.trackfyapp.com/rest/user/invite/cancel`;

    case `calendar`:
      return `${serverHost}/statistics/calendar`;

    case `collaborators`:
      return `${serverHost}/monitoring/collaborators`;

    case `collaboratorsCount`:
      return `${serverHost}/monitoring/collaboratorsCount`;

    case `collaboratorTimeline`:
      return `${serverHost}/statistics/collaboratorTimeline`;

    case `createdclients`:
      if (local) return `http://localhost:8083/rest/v1/clients/created`;
      return `https://backend.trackfyapp.com/rest/v1/clients/created`;

    case `createdcompanies`:
      if (local) return `http://localhost:8083/rest/v1/companies/created`;
      return `https://backend.trackfyapp.com/rest/v1/companies/created`;

    case `sitecoordinates`:
      if (local) return `http://localhost:8083/rest/monitoring/sitecoordinates`;
      return `https://backend.trackfyapp.com/rest/monitoring/sitecoordinates`;

    case `createarea`:
      if (local) return `http://localhost:8083/rest/v1/area/create`;
      return `https://backend.trackfyapp.com/rest/v1/area/create`;

    case `createclient`:
      if (local) return `http://localhost:8083/rest/v1/client/create`;
      return `https://backend.trackfyapp.com/rest/v1/client/create`;

    case `createcompany`:
      if (local) return `http://localhost:8083/rest/v1/company/create`;
      return `https://backend.trackfyapp.com/rest/v1/company/create`;

    case `currentlocation`:
      return `${serverHost}/engine/currentlocation`;

    case `displacementsAtoB`:
      return `${serverHost}/statistics/displacementsAtoB`

    case `emergencyNotifications`:
      return `${serverHost}/monitoring/emergency/notifications`;

    case `emergencyEvacuation`:
      return `${serverHost}/monitoring/emergency/evacuationnew`;

    case `emergencyEvacuationList`:
      return `${serverHost}/monitoring/emergency/evacuations`;

    case `floors`:
      return `${serverHost}/monitoring/floors`;

    case `heatmaps`:
      return `${serverHost}/statistics/heatmaps`;

    case `hhquantity`:
      return `${serverHost}/monitoring/hhquantity`;

    case `homePageInOut`:
      return `${serverHost}/statistics/homePageInOut`;

    case `homePageWorkingPermit`:
      return `${serverHost}/statistics/homePageWorkingPermit`;

    case `incidents`:
      return `${serverHost}/monitoring/emergency/incidents`;

    case `inOut`:
      return `${serverHost}/statistics/inOut`;

    case `invites`:
      if (local) return `http://localhost:8083/rest/user/invites`;
      return `https://backend.trackfyapp.com/rest/user/invites`;

    case `inviteusers`:
      if (local) return `http://localhost:8083/rest/user/invite`;
      return `https://backend.trackfyapp.com/rest/user/invite`;

    case `locationsByArea`:
      return `${serverHost}/monitoring/locationsbyarea`;

    case `logtypes`:
      if (local) return `http://localhost:8083/rest/v1/logtypes`;
      return `https://backend.trackfyapp.com/rest/v1/logtypes`;

    case `logs`:
      if (local) return `http://localhost:8083/rest/v1/logs`;
      return `https://backend.trackfyapp.com/rest/v1/logs`;

    case `monitoringAreas`:
      return `${serverHost}/monitoring/areas`;

    case `monitoredhh`:
      return `${serverHost}/statistics/monitoredhh`;

    case `peopleInAreas`:
      return `${serverHost}/statistics/peopleInAreas`;

    case `profiles`:
      if (local) return `http://localhost:8083/rest/user/profile`;
      return `https://backend.trackfyapp.com/rest/user/profile`;

    case `productivityByArea`:
      return `${serverHost}/statistics/productivityByArea`;

    case 'quantityResources':
      return `${serverHost}/statistics/quantityresources`;

    case `rankByProfession`:
      return `${serverHost}/statistics/rankByProfession`;
	  
	case `resourcesBurndown`:
      return `${serverHost}/statistics/resourcesburndown`;

    case `revokeuser`:
      if (local) return `http://localhost:8083/rest/user/revoke`;
      return `https://backend.trackfyapp.com/rest/user/revoke`;

    case `statisticsCollaborators`:
      return `${serverHost}/statistics/collaborators`;

    case `scannersStatus`:
      return `${serverHost}/resourcesmonitoring/scanners/status`;

    case `simpleareas`:
      if (local) return `http://localhost:8083/rest/monitoring/simpleareas`;
      return `https://backend.trackfyapp.com/rest/monitoring/simpleareas`;

    case `solveincident`:
      return `${serverHost}/monitoring/emergency/solveincident`;

    case `timeline`:
      return `${serverHost}/statistics/timeline`;

    case `areasTimeDivision`:
      return `${serverHost}/statistics/areasTimeDivision`;

    case `workingPermit`:
      return `${serverHost}/statistics/workingPermit`;

    default:
      return serverHost;
  }
};
