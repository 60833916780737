import React from 'react';

import IconUsers from '../../../Icons/Users';
import { useAreaTimelineContext } from './context/TimelineContext';
import { useTimeline } from './hooks/useTimeline';
import { useAuthContext } from '../../../stores';
import DynamicComponentsFactory from '../../../components/DynamicComponents/DynamicComponentsFactory';
import { Info } from '../../../components/Info';
import { useAuth } from '../../../hooks';
import { getTimelinesTooltipContent } from '../Timeline/TimelineDynamicContent';

import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;
const Headercontainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > svg {
    margin-right: 1.6rem;
  }
`;
const Titlecontainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  /*& > svg {
    margin-right: 1.6rem;
  }*/
`;
const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0;
`;
const Date = styled.span`
  background-color: #4f5882;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  //margin-left: 20px;
  padding: 0px 8px;
`;

export const TimelineHeader: React.FC = () => {
  const {authData} = useAuthContext();
  const charts = authData ? authData.components.charts : [];
  const { areaTimelineData } = useAreaTimelineContext();
  const { formatTimelineDate } = useTimeline();
  const { getClientId } = useAuth();
  const clientId = getClientId();
  const tooltipContent = getTimelinesTooltipContent(clientId);

  return (
    <Header>
      <Headercontainer>
        <Titlecontainer>
          <IconUsers />
          <Title>Linha do Tempo de presença</Title>
          <Date>{formatTimelineDate(areaTimelineData.map((i)=>i.timeline))}</Date>
          <Info content={tooltipContent} position='right' burndown={false}/>
        </Titlecontainer>
        <DynamicComponentsFactory charts={charts} component='areaTimelineExport'/>
      </Headercontainer>
    </Header>
  );
};
