import React from 'react';
import { DefaultIconProps } from './types';

export const ClipboardDangerIcon: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M30 18H42C48 18 48 15 48 12C48 6 45 6 42 6H30C27 6 24 6 24 12C24 18 27 18 30 18Z"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48 12.0586C57.99 12.5986 63 16.2886 63 29.9986V47.9986C63 59.9986 60 65.9986 45 65.9986H27C12 65.9986 9 59.9986 9 47.9986V29.9986C9 16.3186 14.01 12.5986 24 12.0586"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.001 36.6133L35.9996 42.001"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.0013 55.2302H26.91C21.7043 55.2302 19.529 51.5097 22.0493 46.9641L26.73 38.5329L31.1406 30.6118C33.811 25.7961 38.1916 25.7961 40.862 30.6118L45.2726 38.5479L49.9533 46.9791C52.4736 51.5247 50.2833 55.2452 45.0926 55.2452H36.0013V55.2302Z"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36 48H36.0135"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
