import styled from 'styled-components';
import { sg } from '../../styles/style-guide';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.6rem 1.6rem 1.6rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #0823ad;
  @media (max-width: 576px) {
    padding: 2.6rem 0 1.6rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 3.5rem;
  padding: 3.5rem 2.4rem;
  overflow: hidden;
  background: #f3f3f3;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  @media (max-width: 576px) {
    border-top-right-radius: 0;
    padding: 2.5rem 1.5rem;
  }
`;

export const SysLogsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 37px 40px 30px 47px;
  display: flex;
  flex-direction: column;
  gap: 3.1rem;
  background-color: ${sg.colors.white};
  box-shadow: 0px 2px 8px 2px rgba(85, 85, 85, 0.08);
  border-radius: 10px;

    &::-webkit-scrollbar {
        width: 10px;
        height: 8px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f3f3f3;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #cccccc;
        border-radius: 10px;
    }
`;