import { useCallback, useEffect, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import { useAuth } from '../../../../../../hooks/useAuth';
import { useAuthContext } from '../../../../../../stores/AuthContext';
import { mixpanelTrack } from '../../../../../../services/SystemMonitor';
import { useIsMounted } from '../../../../../../hooks/useIsMounted';
import { notification } from '../../../../../../utils/Notification';
import { createNewArea } from '../../../../../../services/api/TrackfyService';
import { CreateArea } from '../../../../types/ClientCompany';
import { useRegisterAreaContext } from '../../context/RegisterAreaContext';

export const useCreateNewArea = (isAdmin: boolean) => {
  const { getClientId } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { clientSelected, coordinates } = useRegisterAreaContext();
  const [addingCompany, setAddingCompany] = useState<boolean>(false);
  const [centerLat, setCenterLat] = useState<string>('');
  const [centerLng, setCenterLng] = useState<string>('');
  const [createArea, setCreateArea] = useState<CreateArea>({
    name: '',
    client: 0,
    color: '',
    centerLat: '',
    centerLng: '',
    polygonCoords: '',
  });
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();

  const setNameSelected = useCallback((value: string) => {
    setCreateArea((prevState) => ({
      ...prevState,
      name: value,
    }));
  }, []);

  const setColorSelected = useCallback((value: string) => {
    setCreateArea((prevState) => ({
      ...prevState,
      color: value,
    }));
  }, []);

  const isMounted = useIsMounted();

  useEffect(() => {
    const polygonCenter = async () => {
      if (coordinates !== '') {
        const poligono = JSON.parse(coordinates);

        // Encontrar os valores mínimos e máximos de longitude e latitude
        let minLongitude = Infinity;
        let maxLongitude = -Infinity;
        let minLatitude = Infinity;
        let maxLatitude = -Infinity;

        for (const ponto of poligono) {
          minLongitude = Math.min(minLongitude, ponto[0]);
          maxLongitude = Math.max(maxLongitude, ponto[0]);
          minLatitude = Math.min(minLatitude, ponto[1]);
          maxLatitude = Math.max(maxLatitude, ponto[1]);
        }

        // Calcular o ponto médio
        const centroLongitude = (minLongitude + maxLongitude) / 2;
        const centroLatitude = (minLatitude + maxLatitude) / 2;

        return {
          lat: centroLatitude.toString(),
          lng: centroLongitude.toString(),
        };
      }

      return null;
    };

    polygonCenter()
      .then((centro) => {
        if (centro) {
          setCenterLat(centro.lat);
          setCenterLng(centro.lng);
        }
      })
      .catch((error) => {
        console.error('Erro ao calcular centro do polígono:', error);
      });
  }, [coordinates]);

  const isDefined = (): boolean => {
    if (
      createArea.name &&
      (clientSelected || (!isAdmin && getClientId()) ) &&
      centerLat !== '' &&
      createArea.color &&
      centerLng !== '' &&
      coordinates !== ''
    ) {
      return true;
    }

    return false;
  };

  console.log(isDefined());

  const handleSaveClient = async (): Promise<boolean> => {
    const isValidFields = isDefined();

    if (!isValidFields) return false;

    setAddingCompany(true);

    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    const addNewAreaRequest: CreateArea = {
      name: createArea.name,
      client: isAdmin ? clientSelected : getClientId(),
      color: createArea.color,
      centerLat,
      centerLng,
      polygonCoords: coordinates,
    };

    await createNewArea(addNewAreaRequest, currentRequest.token)
      .then(() => {
        notification('success', `${createArea.name} cadastrada com sucesso!`);
        mixpanelTrack(monitorAgent, `Área ${createArea.name} foi cadastrada`, userName, authData);
      })
      .catch((err) => {
        console.log(err);
        notification('error', 'Ocorreu um erro ao vincular o colaborador');
      });

    if (isMounted.current) setAddingCompany(false);
    return true;
  };

  return {
    isLoading,
    isDefined,
    handleSaveClient,
    addingCompany,
    createArea,
    setCreateArea,
    setNameSelected,
    setColorSelected,
    setIsLoading,
    centerLat,
    centerLng,
  };
};
