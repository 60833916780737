import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useIsMounted } from '../../../hooks';
import Error from '../../../Icons/Error';
import Sucess from '../../../Icons/Sucess';
import { useAuthContext } from '../../../stores';

interface IMessages {
  message: string;
  icon: JSX.Element;
}

interface TypeMessages {
  [key: string]: IMessages;
}

interface Credentials {
  user: string;
  password: string;
  confirmPassword: string;
}

export const useLogin = () => {
  const [message, setMessage] = useState('');
  const [credentials, setCredentials] = useState<Credentials>({user: '', password:'', confirmPassword:''});
  const [newpass, setNewPass] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { signIn, changePassAndSignIn } = useAuthContext();
  const isMounted = useIsMounted();

  useEffect(() => {
    let waitLogin: ReturnType<typeof setTimeout>;

    if (message === 'sucess' || message === 'registersucess') {
      const auth = localStorage.getItem('authData');
      let parsedAuth = {profileData: { mainPage: '/temporeal', name: '', registered: true}};
      if (auth) parsedAuth = JSON.parse(auth);
      const mainPage:string = parsedAuth.profileData.mainPage;

      if(parsedAuth.profileData.registered) {
        waitLogin = setTimeout(() => history.push('/' + mainPage), 1200);
      } else {
        setCredentials(prevState => ({...prevState, user: parsedAuth.profileData.name}));
        setNewPass(true);
      }
    }
    return () => clearTimeout(waitLogin);
  }, [history, message]);

  const handleSignIn = async (e: any) => {
    e.preventDefault();

    if (!credentials.user || !credentials.password) {
      setMessage('warning');
      return;
    }

    setLoading(true);
    setMessage('');

    try {
      await signIn({ user: credentials.user, password: credentials.password });

      if (!isMounted.current) return history.push('/');

      setMessage('sucess');
      setLoading(false);

      history.push('/');
    } catch (error) {
      if (!isMounted.current) return;
      setMessage('error');
    }

    if (!isMounted.current) return;
    setLoading(false);
  };

  const handlePasswordChange = async (e: any, user: string) => {
    e.preventDefault();

    if (credentials.password != credentials.confirmPassword) {
      setMessage('differentpass');
      return;
    }

    if (!credentials.password || !credentials.confirmPassword) {
        setMessage('registerwarning');
        return;
    }

    if (credentials.password.length < 8) {
        setMessage('passsize');
        return;
    }

    if (!/[A-Z]/.test(credentials.password)) {
        setMessage('hasCapitalLetter');
        return;
    }
  
    if (!/[a-z]/.test(credentials.password)) {
        setMessage('hasLetter');
        return;
    }
  
    if (!/[0-9]/.test(credentials.password)) {
        setMessage('hasNumber');
        return;
    }

    setLoading(true);
    setMessage('');

    try {
      await changePassAndSignIn({ user: user, password: credentials.password });

      if (!isMounted.current) return history.push('/');

      setMessage('registersucess');
      setLoading(false);

      history.push('/');
    } catch (error) {
      if (!isMounted.current) return;
      setMessage('registererror');
    }

    if (!isMounted.current) return;
    setLoading(false);
  };

  const handleUserUpdate = (login: any) => setCredentials(prevState => ({...prevState, user: login}));

  const handlePassUpdate = (pass: any) => setCredentials(prevState => ({...prevState, password: pass}));

  const handleConfirmPassUpdate = (pass: any) => setCredentials(prevState => ({...prevState, confirmPassword: pass}));

  const typeMessages: TypeMessages = {
    sucess: {
      message: 'Login realizado com sucesso!',
      icon: <Sucess />,
    },
    error: {
      message: 'Ops! Senha ou usuário errados. Tente novamente!',
      icon: <Error />,
    },
    warning: {
      message: 'Informe o a senha e a confirmação de senha!',
      icon: <Error />,
    },
    differentpass: {
      message: 'Valores diferentes de senha!',
      icon: <Error />,
    },
    registersucess: {
      message: 'Alteração realizada com sucesso!',
      icon: <Sucess />,
    },
    registererror: {
      message: 'Erro na alteração. Tente novamente!',
      icon: <Error />,
    },
    registerwarning: {
      message: 'Informe a nova senha para continuar!',
      icon: <Error />,
    },
    passsize: {
        message: 'A senha deve ter pelo menos 8 caracteres!',
        icon: <Error />,
    },
    hasCapitalLetter: {
        message: 'A senha deve conter pelo menos uma letra maiúscula!',
        icon: <Error />,
    },
    hasLetter: {
        message: 'A senha deve conter pelo menos uma letra minúscula!',
        icon: <Error />,
    },
    hasNumber: {
        message: 'A senha deve conter pelo menos um número!',
        icon: <Error />,
    },
  };

  return {
    credentials,
    loading,
    message,
    typeMessages,
    handleSignIn,
    handlePasswordChange,
    newpass,
    setCredentials,
    handleUserUpdate,
    handlePassUpdate,
    handleConfirmPassUpdate,
    setNewPass
  };
};