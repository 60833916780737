import React from 'react';

interface PropsTypes {
  className?: string;
  onClick: any;
}

const IconMenu: React.FC<PropsTypes> = ({ className, onClick }: PropsTypes) => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    className={className}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 2V0H12V2H0Z" fill="#5E85E8" />
    <path d="M0 7H18V5H0V7Z" fill="#5E85E8" />
    <path d="M0 12H12V10H0V12Z" fill="#5E85E8" />
  </svg>
);

export default IconMenu;
