import { useCallback, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import { useAuth } from '../../../../../../hooks/useAuth';
import { useAuthContext } from '../../../../../../stores/AuthContext';
import { mixpanelTrack } from '../../../../../../services/SystemMonitor';
import { useIsMounted } from '../../../../../../hooks/useIsMounted';
import { notification } from '../../../../../../utils/Notification';
import { createNewCompany } from '../../../../../../services/api/TrackfyService';
import { CreatedCompany } from '../../../../types/ClientCompany';

export const useCreateNewCompany = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [addingCompany, setAddingCompany] = useState<boolean>(false);
  const [createCompany, setCreateCompany] = useState<CreatedCompany>({
    name: '',
    client: 0,
  });
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();

  const setNameSelected = useCallback((value: string) => {
    setCreateCompany((prevState: CreatedCompany) => ({
      ...prevState,
      name: value,
    }));
  }, []);

  const setClientSelected = useCallback((value: number) => {
    setCreateCompany((prevState: CreatedCompany) => ({
      ...prevState,
      client: value,
    }));
  }, []);

  const isMounted = useIsMounted();

  const isDefined = (): boolean => {
    if (createCompany.name && createCompany.client) return true;

    return false;
  };

  const handleSaveCompany = async (): Promise<boolean> => {
    const isValidFields = isDefined();

    if (!isValidFields) return false;

    setAddingCompany(true);

    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    const addNewCompanyRequest: CreatedCompany = {
      name: createCompany.name,
      client: createCompany.client,
    };

    await createNewCompany(addNewCompanyRequest, currentRequest.token)
      .then((response) => {
        notification('success', createCompany.name + ' cadastrada com sucesso!');
        mixpanelTrack(monitorAgent, `Empresa ${createCompany.name} foi criada`, userName, authData);
      })
      .catch((err) => {
        console.log(err);
        notification('error', 'Ocorreu um erro ao criar a empresa');
      });

    if (isMounted.current) setAddingCompany(false);
    return true;
  };

  return {
    isLoading,
    isDefined,
    handleSaveCompany,
    addingCompany,
    createCompany,
    setCreateCompany,
    setNameSelected,
    setClientSelected,
  };
};
