import React from 'react';

import { useAuthContext } from '../../stores/AuthContext';
import { useAgileanContext } from './context/AgileanContext';
import { DateRangePicker } from '../../components';
import { getFollowingFriday } from '../../utils/DateTime';
import CompanySelect from '../filters/CompanySelect';
import ProfessionSelect from '../filters/ProfessionSelect';
import AreaSelect from '../filters/AreaSelect';

//import { OptionsContainer } from '../../components/PageStructure/styles';
import * as S from '../../styles/structure';
import styled from 'styled-components';
import ActivitySelect from '../filters/ActivitySelect';

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: rgb(85 85 85 / 8%) 0px 2px 8px 2px;
  padding: 1.2em;
  gap: 10px;
`;

export const OptionsSubContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 100%;
  background: #ffffff;
`;

const AgileanOptions: React.FC = () => {
  const {
    areaSelected,
    setAreaSelected,
    companySelected,
    setCompanySelected,
    professionSelected,
    setProfessionSelected,
    updatePeriod,
    activitySelected,
    setActivitySelected,
  } = useAgileanContext();
  const { authData } = useAuthContext();
  const compIds: string = authData ? authData.grpCompanies : 't';
  
  return (
    <OptionsContainer>
      <OptionsSubContainer>
        {compIds != 'a' && !compIds.includes(',') ? (
          <></>
        ) : (
          <S.SimpleContainer>
            <CompanySelect
              label={undefined}
              companySelected={companySelected}
              setCompanySelected={setCompanySelected}
            />
          </S.SimpleContainer>
        )}
        <S.SimpleContainer>
          <ProfessionSelect
            label={undefined}
            professionSelected={professionSelected}
            setProfessionSelected={setProfessionSelected}
          />
        </S.SimpleContainer>
        <S.SimpleContainer>
          <AreaSelect
            label={undefined}
            areaSelected={areaSelected}
            setAreaSelected={setAreaSelected}
          />
        </S.SimpleContainer>
        <S.SimpleContainer>
          <DateRangePicker
            updatePeriod={updatePeriod}
            screen="Indicadores"
            maxDate={getFollowingFriday(new Date())}
            component="PLanejamento - "
            selectRange
            showToday
            loadDate={new Date()}
          />
        </S.SimpleContainer>
      </OptionsSubContainer>
      <OptionsSubContainer>
        <S.SimpleContainer>
            <ActivitySelect
              label={undefined}
              activitySelected={activitySelected}
              setActivitySelected={setActivitySelected}
              areaId={areaSelected}
            />
          </S.SimpleContainer>
      </OptionsSubContainer>
    </OptionsContainer>
  );
};

export default AgileanOptions;
