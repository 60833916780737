import React from 'react';

import { useAreasContext } from '../../realTime/context/AreasContext';
import { MapAreas } from '../../../components/MonitoredMap/hooks/useMapArea';
import { MonitoredAreasCollaborators } from '../../../components/MonitoredMap/components/MonitoredAreasCollaborators';

import * as S from '../styles';

interface EmergencyMapComponentsProps {
    mapAreas: MapAreas
    mode: string;
    showEvacuation: boolean;
    dangerAreas?: string[], 
    safeArea?: string
}

export const EmergencyMapComponents: React.FC<EmergencyMapComponentsProps> = (props: EmergencyMapComponentsProps) => {
  const { mapAreas, mode, dangerAreas, safeArea, showEvacuation } = props
  const { setIndicator } = useAreasContext();
  
  return (
      <>
        {mapAreas.mapGeofences}
        <S.MonitoredAreasContainer>
            {<MonitoredAreasCollaborators setIndicator={setIndicator} 
                                          individual={showEvacuation}
                                          mode={mode}
                                          dangerAreas={dangerAreas}
                                          safeArea={safeArea} />}
        </S.MonitoredAreasContainer>
     </>
    );
};