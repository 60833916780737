export const colors = Object.freeze({
  white: '#FEFEFE',
  blue: '#2F4CDD',
  lightBlue: '#B7BEDB',
  darkBlue: '#0823AD',
  oceanBlue: '#42DBB6',
  green: '#11C197',
  dark: '#242324',
  gray: '#757575',
  lightGray: '#D3D3D3',
  red: '#E45A5A',
  royalBlue: '#273DAB',
});
