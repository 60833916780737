import React from 'react';
import { useCollaboratorsCount } from './hooks/useCollaboratorsCount';
import { Info } from '../../../../../components/Info';
import { getCollaboratorsCountTooltipContent } from './SummaryIndicatorsTooltip';
import { useAuth } from '../../../../../hooks';

import * as S from './styles';

export const CollaboratorsCount: React.FC = () => {
  const { indicator } = useCollaboratorsCount();
  const { getClientId } = useAuth();
  const clientId = getClientId();
  const tooltipContent = getCollaboratorsCountTooltipContent(clientId);

  return (
    <S.Card>
      <S.CardHeader>Colaboradores Hoje: </S.CardHeader>
      <S.CardContent>{indicator.value}</S.CardContent>
      <S.IconContent>
        <Info content={tooltipContent} position={'left'} burndown={false}/>
      </S.IconContent>
    </S.Card>
  );
};
