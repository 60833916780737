import { GroupBase, StylesConfig } from 'react-select';

export const MultiSelectStyles: StylesConfig<unknown, true, GroupBase<unknown>> = {
    container: (styles) => ({ ...styles, width: '100%', height:'100%' }),
    control: (styles) => ({
      ...styles,
      border: '1px solid #4f5882',
      borderRadius: '10px',
      height: '100%',
      '&:hover': { border: '1px solid #2F4CDD' },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: '#4f5882',
      font: 'normal 500 1.4rem/1.5 Work Sans,sans-serif',
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
    menuList: (styles) => ({
      ...styles,
      '&::-webkit-scrollbar': { width: '8px' },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 0, 0, 0.2)',
        width: '8px',
        border: '1px solid #f3f3f3',
        borderRadius: '2px',
      },
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#4f5882',
      font: 'normal 500 1.4rem/1.5 Work Sans,sans-serif',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: '#4f5882',
      font: 'normal 500 1.4rem/1.5 Work Sans,sans-serif',
    }),
    option: (styles) => ({
      ...styles,
      padding: '0.5px 0.5px',
      color: '#4f5882',
      font: 'normal normal normal 1.3rem/2.1rem Work Sans,sans-serif',
      '&:hover': { backgroundColor: '#1890ff', color: 'white' },
      '::selection': { backgroundColor: '#1890ff' },
    }),
};