import React, { useState } from 'react';

import { InfoTooltip, TooltipContent } from './Tooltip';
import { AlertIconVuesax } from '../../Icons/Alert-Vuesax';

import * as S from './styles';

type InfoProps = {
  content: TooltipContent[];
  position: string;
  burndown: boolean;
};

export const Info: React.FC<InfoProps> = (props: InfoProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return(
    <S.InfoContainer onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
      <AlertIconVuesax/>
      <InfoTooltip visible={showTooltip} content={props.content} position={props.position} burndown={props.burndown}/>
    </S.InfoContainer>
  )
};