import Axios, { AxiosResponse, CancelToken, CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';
import useSwr from 'swr';

import { useAuth, useIsMounted } from "../../../../../hooks";
import cookieService from '../../../../../services/CookieService';
import { useAreasContext } from "../../../context/AreasContext";
import { getLocationsByFloors } from '../../../../../services/api/TrackfyService/integration';
import { MonitoredData } from '../../../../../types/MonitoredData';
import { fetchData } from '../../../../../services/requests';

export const useBuildingAreas = () => {
    const mountedRef = useIsMounted();
    const { getClientId } = useAuth();
    const { mapAreas, updateMapAreas, setIndicator } = useAreasContext();
      
    const [floorsData, setFloorsData] = useState<MonitoredData[]>([]);
      
    const token: any = cookieService.get('token');
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    
    const requestAreas = async(url: string) => {
        await fetchData(token, url, {})
            .then((res: AxiosResponse<any>) => {
                if (!res.data || res.data.length === 0) return;
                if (!mountedRef.current) return;
                updateMapAreas(res.data);
            })
            // eslint-disable-next-line no-console
            .catch((error: any) => console.error(error));
    }

    const requestMonitoredAreas = (token: CancelToken) => {
      getLocationsByFloors(getClientId(), token)
        .then((data:MonitoredData[])=> {
            setFloorsData(data);

            if(data.length > 0)
              setIndicator({loading: false, value: data[0].collaborators.toString()}) 
        })
        .catch((err) => {
            console.log(err);
            setFloorsData([]);
            setIndicator({loading: false, value: "0"}) 
        })
    }

    useSwr(
        null,
        async () => { 
          requestMonitoredAreas(currentRequest.token);
        },
        {
          focusThrottleInterval: 30001,
          refreshInterval: 30000,
        }
      );

    useEffect(() => {
        const currentSimpleAreasRequest: CancelTokenSource = Axios.CancelToken.source();

        if(mountedRef.current)
          requestAreas('monitoring/simpleareas');
          
        return () =>{ 
          mountedRef.current = false; 
          currentSimpleAreasRequest.cancel('request canceled by the user.');
        }
    }, []);

    useEffect(() => {
        if(mountedRef.current)
            requestMonitoredAreas(currentRequest.token);

        return () =>{ 
            mountedRef.current = false; 
            currentRequest.cancel('request canceled by the user.');
        }
    }, []);

    return { mapAreas, floorsData }
}