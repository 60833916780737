import React from 'react';
import moment from 'moment';

import { useAddCollaborator } from './hooks/useAddCollaborator';
import { useAuthContext } from '../../../../stores/AuthContext';
import TagSelect from '../../../filters/TagSelect';
import ProfessionSelect from '../../../filters/ProfessionSelect';
import CompanySelect from '../../../filters/CompanySelect';
import { Modal } from '../../../../components/Modal';
import IconClose from '../../../../Icons/Close';
import IconUsers from '../../../../Icons/Users';
import IconManager from '../../../../Icons/Manager';
import { ToastContainer } from 'react-toastify';

import { SimpleContainer } from '../../../../styles/structure';
import * as S from './styles';

type AddCollaboratorContainerProps = {
  isActive: boolean;
  setIsAdding(value: boolean): void;
  onClickToSave(): void;
  onClickToClose(): void;
};

export const AddCollaboratorContainer: React.FC<AddCollaboratorContainerProps> = (
  props: AddCollaboratorContainerProps
) => {
  const { isActive, onClickToClose, onClickToSave } = props;
  const { authData } = useAuthContext();
  const {
    isDefined,
    handleSaveCollaborator,
    addCollaborator,
    setCompanySelected,
    setProfessionSelected,
    setTagSelected,
  } = useAddCollaborator();
  const compIds: string = authData ? authData.grpCompanies : 't';

  return (
    <>
      <ToastContainer />
      <Modal visible={isActive}>
        <S.AddCollaboratorContainer>
          <S.CloseButton onClick={onClickToClose}>
            <IconClose />
          </S.CloseButton>

          <S.CollaboratorsListHeaderTitleContainer>
            <S.IconUsersContainer>
              <IconUsers />
            </S.IconUsersContainer>
            <S.CollaboratorsListHeaderLabel>
              Vincular novo colaborador
            </S.CollaboratorsListHeaderLabel>
          </S.CollaboratorsListHeaderTitleContainer>

          <S.AddCollaboratorsFrontContent>
            <S.CollaboratorInfoContainer>
              <S.FilterInputContainer>
                <S.InputLabel>Empresa</S.InputLabel>
                {compIds != 'a' && !compIds.includes(',') ? (
                  <></>
                ) : (
                  <SimpleContainer>
                    <CompanySelect
                      companySelected={addCollaborator.company}
                      setCompanySelected={setCompanySelected}
                      demo={true}
                    />
                  </SimpleContainer>
                )}
              </S.FilterInputContainer>
              <S.DateFieldContainer>
                <S.CreatedAtLabel>Criado em: {moment().format('DD/MM/YYYY')}</S.CreatedAtLabel>
              </S.DateFieldContainer>
            </S.CollaboratorInfoContainer>

            <S.CollaboratorInfoContainer>
              <S.FilterInputContainer>
                <S.InputLabel>Função</S.InputLabel>
                <SimpleContainer>
                  <ProfessionSelect
                    label={undefined}
                    professionSelected={addCollaborator.profession}
                    setProfessionSelected={setProfessionSelected}
                    demo={true}
                  />
                </SimpleContainer>
              </S.FilterInputContainer>
              <S.FilterInputContainer>
                <S.InputLabel>Tag</S.InputLabel>
                <SimpleContainer>
                  <TagSelect
                    label={undefined}
                    tagSelected={addCollaborator.tag}
                    setTagSelected={setTagSelected}
                  />
                </SimpleContainer>
              </S.FilterInputContainer>
            </S.CollaboratorInfoContainer>

            <S.SaveButtonContainer>
              <S.SaveButton
                title="Vincular novo colaborador"
                icon={<IconManager />}
                disabled={!isDefined()}
                //loading={addingCollaborator}
                onClick={async () => {
                  const isSaved = await handleSaveCollaborator();
                  if (isSaved) onClickToSave();
                }}
              />
            </S.SaveButtonContainer>
          </S.AddCollaboratorsFrontContent>
        </S.AddCollaboratorContainer>
      </Modal>
    </>
  );
};
