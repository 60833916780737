import { useCallback, useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import { useAreasTimeDivisionContext } from '../context/AreasTimeDivisionContext';
import moment from 'moment';
import { useMovimentContext } from '../../context/MovimentContext';
import { useAuth } from '../../../../hooks';
import { AreasTimeDivision } from '../../types/types';
import { getAreasTimeDivision } from '../../../../services/api/TrackfyService';
import { parseSelectOptions } from '../../../../utils/Convert';

export const useAreasTimeDivision = () => {
  const { 
    companySelected, 
    professionSelected, 
    collaboratorSelected,
    initialDate, 
    finalDate
  } = useMovimentContext();
  const { setAreasTimeDivisionValues, setAreasTimeDivisionChartData } = useAreasTimeDivisionContext();
  const [isLoading, setIsLoading] = useState(true);
  const { getClientId } = useAuth();
  const clientId = getClientId();

  const dividerPerFive = useCallback(
    (base: AreasTimeDivision[]): Array<AreasTimeDivision[]> => {
      const divisor: number = 4;
      const newArray: Array<AreasTimeDivision[]> = [];

      for (let i = 0; i < base.length; i += divisor) {
        const divider: AreasTimeDivision[] = base.slice(i, i + divisor);
        newArray.push(divider);
      }
      return newArray;
    },
    []
  );

  const exportDataToXlsx = (areasTimeDivisionValues: Array<AreasTimeDivision[]>): any[] => {
    let date: string[] = []
    let item1Value: string[] = []
    let item2Value: string[] = []
    let othersValue: string[] = []

    areasTimeDivisionValues.forEach((data: AreasTimeDivision[]) => {
      data.map((i: { date: string; item1Label: string; item2Label: string; othersLabel: string }) => (
        date.push(i.date),
        item1Value.push(i.item1Label),
        item2Value.push(i.item2Label),
        othersValue.push(i.othersLabel)
      ))
    })

    const formattedData: any[] = [];

    if(clientId == 11) {
      formattedData.push(["Data", "Máquinas", "Amarração", "Outros"]);
    } else {
      formattedData.push(["Data", "Áreas produtivas", "Oficina", "Outros"])
    }
      
    
    date.forEach((date, index) => {
        const value1 = item1Value[index];
        const value2 = item2Value[index];
        const value3 = othersValue[index];
        formattedData.push([date, value1, value2, value3]);
    });

    return formattedData
  }

  const handleChartData = (data: AreasTimeDivision[]) => {
    if (data && data.length > 0) {
      console.log(data);
      console.log(dividerPerFive(data)[0]);
      batchedUpdates(() => {
        setAreasTimeDivisionValues(dividerPerFive(data));
        setAreasTimeDivisionChartData(dividerPerFive(data)[0]);
      });
    } else {
      setAreasTimeDivisionChartData([{
        date: "Sem registros",
        item1: 0,
        item1Label: "0%",
        item2: 0,
        item2Label: "0%",
        others: 0,
        othersLabel: "0%"
      }]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);
      batchedUpdates(() => setAreasTimeDivisionValues([]));

      getAreasTimeDivision(
        clientId,
        parseSelectOptions(companySelected),
        parseSelectOptions(professionSelected),
        parseSelectOptions(collaboratorSelected),
        moment(initialDate).startOf('day').valueOf(),
        moment(finalDate).valueOf(),
        currentRequest.token
      )
        .then((result: AxiosResponse<any>) => handleChartData(result.data))
        .catch((err) => console.log(err));
    }
    return () => {
      mounted = false;
      currentRequest.cancel('AreasTimeDivisionChart request canceled by the user.');
    };
  }, [companySelected, professionSelected,collaboratorSelected,initialDate,finalDate]);

  return {
    dividerPerFive,
    handleChartData,
    isLoading,
    setIsLoading,
    exportDataToXlsx,
  };
};
