import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Shadow } from '../../components';
import { PageHeader } from '../../components/PageStructure/PageHeader';
import { Container, ContentContainer } from '../../components/PageStructure/styles';
import { useAuth } from '../../hooks';
import { mixpanelTrack } from '../../services/SystemMonitor';

import { useAuthContext } from '../../stores';
import { LastUpdateContainer } from './charts/LastUpdate/LastUpdate';
import { LtvCacMarginContainer } from './charts/LtvCacMargin/LtvCacMargin';
import { NDRChart } from './charts/NDR';
import { NdrContextProvider } from './charts/NDR/context/NDRContext';
import RevenueFTEChart from './charts/RevenueFTE';
import { RevenueFTEContextProvider } from './charts/RevenueFTE/context/RevenueFTEContext';
import { ValueExpansionChart } from './charts/ValueExpansion/ValueExpansionChart';
import { ValueExpansionChartHeader } from './charts/ValueExpansion/ValueExpansionChartHeader';
import * as S from './styles'

export const PageOnePage: React.FC<{}> = () =>{
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  useEffect(
    () => mixpanelTrack(monitorAgent, 'One page - Tração', userName, authData),
    []
  );
  return (
    <Container>
    <PageHeader title="One Page - Tração" />
    <ContentContainer value="">
      <LastUpdateContainer/>
      <S.ExpansionValueCard>
        <ValueExpansionChartHeader />
        <ValueExpansionChart />
      </S.ExpansionValueCard>
      <S.ChartRow>
        <S.Card>
          <RevenueFTEContextProvider>
            <RevenueFTEChart />
        </RevenueFTEContextProvider>
        </S.Card>
        <S.Card>
          <NdrContextProvider>
            <NDRChart/>
          </NdrContextProvider>
        </S.Card>
      </S.ChartRow>
      <LtvCacMarginContainer/>
    </ContentContainer> 
    </Container>
  );
}
