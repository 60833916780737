import React, { createContext, ReactNode, useState, useContext } from 'react';

import { PaginationControls } from '../../../../../../components/Buttons/PaginationControls';
import { DeslocamentosCarro } from '../../../../types/Insights';

type ContextValue = {
  deslocamentosCarroChartData: DeslocamentosCarro[];
  isLoading: boolean;
  deslocamentosCarroValues: Array<DeslocamentosCarro[]>;
  setDeslocamentosCarroChartData: (value: DeslocamentosCarro[]) => void;
  setDeslocamentosCarroValues: (arrayAvarageValues: Array<DeslocamentosCarro[]>) => void;
  setIsLoading: (value: boolean) => void;
};

const DeslocamentosCarroContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function DeslocamentosCarroContextProvider(props: Props) {
  const { children } = props;
  const [deslocamentosCarroValues, setDeslocamentosCarroValues] = useState<
    Array<DeslocamentosCarro[]>
  >([]);
  const [deslocamentosCarroChartData, setDeslocamentosCarroChartData] = useState<
    DeslocamentosCarro[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const value = {
    deslocamentosCarroChartData,
    deslocamentosCarroValues,
    isLoading,
    setDeslocamentosCarroChartData,
    setDeslocamentosCarroValues,
    setIsLoading,
  };

  return (
    <DeslocamentosCarroContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={deslocamentosCarroValues}
        setNewValue={setDeslocamentosCarroChartData}
        title="Deslocamentos a Carro"
      />
    </DeslocamentosCarroContext.Provider>
  );
}

export function useDeslocamentosCarroContext() {
  const context = useContext(DeslocamentosCarroContext);

  if (typeof context === 'undefined') {
    throw new Error('deslocamentosCarroContext must be used within an deslocamentosCarroContext');
  }

  return context;
}
