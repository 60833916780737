import React from 'react';

import { useRank } from './hooks/useRank';
import { RankChartHeader } from './components/RankChartHeader';
import { ProgressBarChart } from './components/ProgressBar';
import { IconLoading } from '../../../../components/IconLoading';

import * as S from './styles';

const RankChart: React.FC = () => {
  const { rankChartData, isLoading, getColor } = useRank();

  return (
    <>
      <RankChartHeader />
      <S.RankCardContainer>
        {isLoading && <IconLoading />}
        {!isLoading && <ProgressBarChart rankChartData={rankChartData} getColor={getColor} />}
      </S.RankCardContainer>
    </>
  );
};

export default RankChart;
