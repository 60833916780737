import React, { createContext, ReactNode, useState, useContext, useEffect } from 'react';

import { useMatchMedia } from '../../../hooks/useMatchMedia';
import { useIsMounted } from '../../../hooks';

type ContextValue = {
  isMobile: boolean;
  isTableT: boolean;
  collapsed: boolean;

  setCollapsed: (collapsed: boolean) => void;
  onCollapse: () => void;
};

const LayoutMenuContext = createContext<ContextValue>({} as ContextValue);

type Props = {
  children: ReactNode;
};

export function LayoutMenuContextProvider({ children }: Props) {
  const isMounted = useIsMounted();
  const isMobile: boolean = useMatchMedia(767);
  const isTableT: boolean = useMatchMedia(1200);

  const [collapsed, setCollapsed] = useState<boolean>(true);

  useEffect(() => {
    isMounted.current = true;

    if (isMobile && isMounted.current) setCollapsed(true);
  }, [isMobile]);

  const onCollapse = () => {
    setCollapsed(!collapsed);
    isMounted.current = false;
  };

  const value = {
    isMobile,
    isTableT,
    collapsed,
    setCollapsed,
    onCollapse,
  };

  return <LayoutMenuContext.Provider value={value}>{children}</LayoutMenuContext.Provider>;
}

export function useLayoutMenuContext() {
  const context = useContext(LayoutMenuContext);

  if (typeof context === 'undefined') {
    throw new Error('LayoutMenuContext must be used within an LayoutMenuContext');
  }

  return context;
}
