import React from 'react';

import AreaMultiSelect from '../../../../filters/AreaMultiSelect';
import { SelectOption } from '../../../../../components/Select/types';
import IconUsers from '../../../../../Icons/Users';
import { useAuth } from '../../../../../hooks';
import { IconContainer } from '../../../styles';
import { Info } from '../../../../../components/Info';
import { TooltipContent } from '../../../../../components/Info/Tooltip';

import * as S from '../styles';

type AverageTimeChartHeaderProps = {
  areaSelected: SelectOption[];
  setAreaSelected: (value: SelectOption[]) => void;
};

export const AverageTimeChartHeader: React.FC<AverageTimeChartHeaderProps> = (
  props: AverageTimeChartHeaderProps
) => {
  const { getClientId } = useAuth();
  const { areaSelected, setAreaSelected } = props;
  const titlePart = getClientId() === 4 ? 'total' : 'médio';
  const clientId = getClientId();

  const getAverageTimeTooltipContent = (clientId: number): TooltipContent[] => {
    if(clientId == 4)
      return [
        { content: 'É calculado pela soma do tempo / numero de recursos.' },
        { content: 'Média apresentada em horas' },
      ];
      
      return [
            { content: 'É calculado pela soma do tempo / numero de recursos.' },
            { content: 'Média apresentada em minutos.' }
      ];
  };

  return (
    <S.Header>
      <S.Titlecontainer>
        <IconUsers />
        <S.Title>Tempo {titlePart} por recurso</S.Title>
        <IconContainer>
          <Info content={getAverageTimeTooltipContent(clientId)} position={'right'} burndown={false}/>
        </IconContainer>
      </S.Titlecontainer>
      <S.Filtercontainer>
        <AreaMultiSelect
          itemsSelected={areaSelected}
          setItemsSelected={setAreaSelected}
          screen="Indicadores - Produtividade - Tempo Médio/Total por Recurso"
        />
      </S.Filtercontainer>
    </S.Header>
  );
};
