import React, { createContext, ReactNode, useState, useContext, Dispatch, SetStateAction } from 'react';
import { MonitoredAreasInEmergencyData } from '../../../../../types/MonitoredData';

type Props = {
  children: ReactNode;
};

type MonitoringContextProps = {
  activeArea: MonitoredAreasInEmergencyData;
  setActiveArea: Dispatch<SetStateAction<MonitoredAreasInEmergencyData>>;
};

const MonitoringContext = createContext<MonitoringContextProps>({} as MonitoringContextProps);

export function MonitoringContextProvider(props: Props) {
  const { children } = props;
  const [activeArea, setActiveArea] = useState<MonitoredAreasInEmergencyData>({} as MonitoredAreasInEmergencyData);

  const value = {
    activeArea,
    setActiveArea,
  };
  return <MonitoringContext.Provider value={value}>{children}</MonitoringContext.Provider>;
}

export function useMonitoringContext() {
  const context = useContext(MonitoringContext);

  if (typeof context === 'undefined') {
    throw new Error('MonitoringContext must be used within an useMonitoringContext');
  }

  return context;
}
