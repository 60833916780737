import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto auto 0;
  width: 100%;
  //max-width: 170px;
  max-width: 70px;
  padding-top: 1.4rem;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4f5882;
  border: 1px solid #f8f8f8;
  box-sizing: border-box;
  border-radius: 8px;
  border: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: background-color 0.2s ease, opcacity 0.2 ease-ease-in-out;

  &:hover {
    background-color: #4f5882dd;
  }

  &:focus {
    border: thin solid #4f5882be;
    box-shadow: 0 0 1px 2.5px #4f588244;
  }

  &:disabled {
    background: #bbbbbb;
    //opacity: 0;
    cursor: auto;
  }

  & > svg {
    fill: #fff;
  }

  &:last-child > svg {
    transform: rotate(180deg);
  }
`;

/*
type TypeDots = {
  dotActive?: boolean;
};

const Dots = styled.span`
  background: ${({ dotActive }: TypeDots) => (dotActive ? '#4f5882 ' : '#E4E8FB')};
  width: 10px;
  height: 10px;
  flex: 0 0 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
`; */
