import styled from 'styled-components';
import { sg } from '../../styles/style-guide';

export const Textarea = styled.textarea`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 13px 16px 12px 16px;
  background-color: #ffffff;
  border: 1px solid #4f5882;
  border-radius: 10px;
  font: normal 500 1.4rem/1.5 Work Sans, sans-serif;
  color: ${sg.colors.dark};
  opacity: 1;
  &:disabled {
    color: #afafaf;
    background-color: #f5f5f5;
  }
  &::placeholder {
    text-align: left;
    font: normal 500 1.4rem/1.5 Work Sans, sans-serif;
    letter-spacing: 0px;
    color: #4f5882;
  }
`;