import React from 'react';
import { DefaultIconProps } from './types';

export const SummaryIcon: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M63 21V51C63 60 58.5 66 48 66H24C13.5 66 9 60 9 51V21C9 12 13.5 6 24 6H48C58.5 6 63 12 63 21Z"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.5 13.5V19.5C43.5 22.8 46.2 25.5 49.5 25.5H55.5"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 39H36"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 51H48"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
