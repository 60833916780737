import { List } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ScrollBar from 'react-custom-scrollbars';

import { ResultListItem, ResultList } from '.';
import { IconLoading } from '../../IconLoading';

export type SearchContentProps = {
  active?: boolean;
  data?: ResultListItem[];
  selectedItems?: ResultListItem | ResultListItem[] | null;
  onSelect?: (value: ResultListItem) => void;
};

export const SearchContent: React.FC<SearchContentProps> = (props: SearchContentProps) => {
  const { data, onSelect, selectedItems } = props;
  const [list, setList] = useState<ResultListItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const ScrollbarsRef: React.MutableRefObject<any> = useRef<any>(null);

  useEffect(() => {
    setLoading(true);

    if (!data) return;

    if (selectedItems instanceof Array) {
      const includedListItem: ResultListItem[] = data
        .filter((resultList: ResultListItem) =>
          selectedItems?.map((item: ResultListItem) => item.id).includes(resultList.id)
        )
        .sort((a: ResultListItem, b: ResultListItem) => {
          if (a.label > b.label) {
            return 1;
          }

          if (a.label < b.label) {
            return -1;
          }

          return 0;
        });

      const notIncludedListItem: ResultListItem[] = data
        .filter(
          (resultList: ResultListItem) =>
            !selectedItems?.map((item: ResultListItem) => item.id).includes(resultList.id)
        )
        .sort((a: ResultListItem, b: ResultListItem) => {
          if (a.label > b.label) {
            return 1;
          }

          if (a.label < b.label) {
            return -1;
          }

          return 0;
        });

      const resultListItem: ResultListItem[] = [...includedListItem, ...notIncludedListItem];

      setList(resultListItem);

      setLoading(false);
      return;
    }

    const items: ResultListItem[] = data
      .filter((resultList: ResultListItem) => selectedItems?.id !== resultList.id)
      .sort((a: ResultListItem, b: ResultListItem) => {
        if (a.label > b.label) {
          return 1;
        }

        if (a.label < b.label) {
          return -1;
        }

        return 0;
      });

    setList(items);
    setLoading(false);
  }, [data, selectedItems]);

  return (
    <>
      {list && (
        <ScrollBar ref={ScrollbarsRef} className="search-content">
          {loading ? (
            <IconLoading />
          ) : (
            <List>
              {list.map((item: ResultListItem) => (
                <ResultList
                  key={item.id}
                  item={item}
                  onSelect={onSelect}
                  selectedItems={selectedItems}
                />
              ))}
            </List>
          )}
        </ScrollBar>
      )}
    </>
  );
};
