import React from 'react';
import { ToastContainer } from 'react-toastify';

import { AddClientContainer } from '../CreateClients';
import { ClientTable } from '../ClientsTable';
import { useClientList } from './hooks/useClientList';

import * as S from './styles';
import 'react-toastify/dist/ReactToastify.css';
import IconUsers from '../../../../../Icons/Users';
import { Button } from '../../../../../components/Buttons/Button';
import IconAdd from '../../../../../Icons/Add';

export const ClientList: React.FC = () => {
  const {
    isLoading,
    createdClients,
    isAdding,
    setIsAdding,
    handleCloseAddClients,
    handleCloseAddClientsAndUpdateList,
  } = useClientList();

  return (
    <S.ClientListContainer>
      <ToastContainer />
      <S.ClientListHeader>
        <S.ClientListHeaderTitleContainer>
          <S.IconUsersContainer>
            <IconUsers />
          </S.IconUsersContainer>
          <S.ClientListHeaderLabel>Resumo dos clientes existentes</S.ClientListHeaderLabel>
        </S.ClientListHeaderTitleContainer>
        <S.NewClientButtonContainer>
          <Button
            title="Novo cliente"
            icon={<IconAdd fill="#FFF" height="20px" width="20px" />}
            onClick={() => setIsAdding(true)}
          />
        </S.NewClientButtonContainer>
      </S.ClientListHeader>
      <ClientTable detailData={createdClients} loading={isLoading} empty={false} />

      {isAdding && (
        <AddClientContainer
          isActive={isAdding}
          setIsAdding={setIsAdding}
          onClickToClose={handleCloseAddClients}
          onClickToSave={handleCloseAddClientsAndUpdateList}
        />
      )}
    </S.ClientListContainer>
  );
};
