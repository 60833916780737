import React from 'react';
import { DefaultIconProps } from './types';

export const FileIconVuesax: React.FC<DefaultIconProps> = ({ fill, width, height, style }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M18.3334 8.33268V12.4993C18.3334 16.666 16.6667 18.3327 12.5001 18.3327H7.50008C3.33341 18.3327 1.66675 16.666 1.66675 12.4993V7.49935C1.66675 3.33268 3.33341 1.66602 7.50008 1.66602H11.6667"
        stroke={fill || '#808080'}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3334 8.33268H15.0001C12.5001 8.33268 11.6667 7.49935 11.6667 4.99935V1.66602L18.3334 8.33268Z"
        stroke={fill || '#808080'}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.83325 10.834H10.8333"
        stroke={fill || '#808080'}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.83325 14.166H9.16659"
        stroke={fill || '#808080'}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};