import { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { getLtvCacMargin } from '../../../../../services/api/TrackfyService/integration';
import { OnePage } from '../../../types/OnePage';

export const useLtvCacMargin = () => {
  const [ltvCacMargin, setLtvCacMargin] = useState<OnePage[]>([])
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);
      
      getLtvCacMargin(currentRequest.token)
        .then((result: AxiosResponse<any>) => {
          setLtvCacMargin(result.data)
          setIsLoading(false)
        })
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('LtvCacMargin request canceled by the user.');
    };
  }, []);

  return {
    isLoading,
    setIsLoading,
    ltvCacMargin
  };
};
