import React from 'react';

import { useAreasContext } from '../../context/AreasContext';
import { PerspectiveBuildingFloors } from './PerspectiveBuildingFloors';
import { Detective } from '../MonitoringIndicators/detective';
import { ClientTypeProps, MonitoredAreasData } from '../../../../types/MonitoredData';

import * as S from './styles';

export const PespectiveMonitBuilding: React.FC<ClientTypeProps> = (props: ClientTypeProps) => {
  const { isManager, isMap } = props;
  const { monitoredAreas, indicator } = useAreasContext();

  return (
    <div>
      <S.BuildingPageContainer>
        <S.PerspectiveBuildingContainer>
          <S.PerspectiveRoof />
          <S.PerspectiveFloorsContainer>
            {!indicator.loading &&
              monitoredAreas
                .filter((area: MonitoredAreasData) => area.nome != 'ÁREA NÃO MAPEADA')
                .sort((a: MonitoredAreasData, b: MonitoredAreasData) => a.ordem - b.ordem)
                .reverse()
                .map((area: any, idx: number) => (
                  <PerspectiveBuildingFloors
                    key={'floor_' + idx + '_' + area.name}
                    area={area}
                    isHall={idx === monitoredAreas.length - 2}
                  />
                ))}
          </S.PerspectiveFloorsContainer>
        </S.PerspectiveBuildingContainer>
        <Detective isManager={isManager} isMap={isMap} />
      </S.BuildingPageContainer>
    </div>
  );
};
