import { useCallback, useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import { useProductivityContext } from '../../../context/PageProductivityContext';
import { useWorkingPermitContext } from '../context/WorkingPermitContext';
import { WorkingPermit } from '../../../../indicators/types/Indicators';
import { getWorkingPermitChartData } from '../../../../../services/api/TrackfyService';
import moment from 'moment';
import { useAuth } from '../../../../../hooks';
import { parseSelectOptions } from '../../../../../utils/Convert';

export const useWorkingPermit = () => {
  const { companySelected, professionSelected, initialDate, finalDate } = useProductivityContext();
  const { setWorkingPermitValues, setWorkingPermitChartData, timeVisualization } = useWorkingPermitContext();
  const [isLoading, setIsLoading] = useState(true);
  const { getClientId } = useAuth();

  const dividerPerFive = useCallback(
    (base: WorkingPermit[]): Array<WorkingPermit[]> => {
      const divisor: number = 5;
      const newArray: Array<WorkingPermit[]> = [];

      for (let i = 0; i < base.length; i += divisor) {
        const divider: WorkingPermit[] = base.slice(i, i + divisor);
        newArray.push(divider);
      }
      return newArray;
    },
    []
  );

  const handleChartData = (data: WorkingPermit[]) => {
    if (data && data.length > 0) {
      batchedUpdates(() => {
        setWorkingPermitValues(dividerPerFive(data));
        setWorkingPermitChartData(dividerPerFive(data)[0]);
      });
    } else {
      setWorkingPermitChartData([{ value: 0, label: '0hrs', name: 'Sem Registros' }]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);
      batchedUpdates(() => setWorkingPermitValues([]));

      getWorkingPermitChartData(
        getClientId(),
        parseSelectOptions(companySelected),
        parseSelectOptions(professionSelected),
        moment(initialDate).startOf('day').valueOf(),
        moment(finalDate).valueOf(),
        timeVisualization.week,
        timeVisualization.month,
        timeVisualization.year,
        currentRequest.token
      )
        .then((result: AxiosResponse<any>) => handleChartData(result.data))
        .catch((err: any) => console.log(err));
    }
    return () => {
      mounted = false;
      currentRequest.cancel('WorkingPermitChart request canceled by the user.');
    };
  }, [companySelected, professionSelected,initialDate,finalDate, timeVisualization]);

  return {
    dividerPerFive,
    handleChartData,
    isLoading,
    setIsLoading
  };
};