import styled from 'styled-components';

export type TooltipContainerProps = {
  visible?: boolean;
  position?: string;
  color?: string;
  burndown?: boolean;
};

export const InfoContainer = styled.div`
  position: relative;
  display: flex;
`

export const TooltipContainer = styled.div<TooltipContainerProps>`
  position: absolute;
  top: 20px;
  //right: 30px;
  ${({ position }) => (position === 'right' ? 'left: 25px;' : position === 'left' ? 'right: 25px;' : '')}
  width: 572px;
  text-align: justify;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  padding: 12px 24px 12px 24px;
  background: #E6E6E6;
  //border-radius: 10px 0px 10px 10px;
  ${({ position }) => (position === 'right' ? 'border-radius: 0px 10px 10px 10px;' : position === 'left' ? 'border-radius: 10px 0px 10px 10px;' : '')}
  ${({burndown}) => (burndown ? 'min-width: 572px;' : 'min-width: 400px;')}
  box-shadow: 0px 4px 4px 0px #00000040;
  color: #808080;
  z-index: 999;
`;

export const TopicContent = styled.div`
`

export const Topic = styled.div`
  display: flex;
`

export const TopicTitle = styled.div`
  display: inline;  
  font-weight: 600;
`

export const Square = styled.div<TooltipContainerProps>`
  padding: 8px;
  height: 16px;
  background-color: ${({ color }) => color};
  margin-right: 8px;
  margin-top: 3px;
  border-radius: 2px;
`;