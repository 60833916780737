import React from 'react';
import { Card } from './Card';
import { MonitoredAreasData } from '../../../../../types/MonitoredData';

export type AreasCardsContainerProps = {
    floorsData: MonitoredAreasData[];
}

export const AreasCardsContainer: React.FC<AreasCardsContainerProps> = ({ floorsData }: AreasCardsContainerProps) =>
    <Card titulo="Áreas Monitoradas" floorsData={floorsData} />;
