import React, { useMemo } from 'react';

import * as S from './styles';
import { transformMinutesToHours } from '../../../../utils/DateTime';

interface Props {
  data: {
    label: string;
    value: number;
    color: string;
  }[];
  style?: React.CSSProperties;
  labelTotal?: string;
}

function getTotalMinutes(values: number[]): number {
  let totalMinutes = 0;
  values.forEach((value) => {
    const finalValue = value; //transformHoursToMinutes(value);
    totalMinutes += finalValue;
  });
  return totalMinutes;
}

export const StackedBarChart: React.FC<Props> = ({ data, style }) => {
  const totalMinutes = useMemo(() => getTotalMinutes(data.map((v) => v.value)), [data]);

  const totalValue = useMemo(() => {
    const values = String(totalMinutes / 60).split('.');
    const [hours, minutes] = values.map(Number);
    const finalMinutes = minutes ? String(minutes * 60).slice(0, 2) : '0';
    return Number(`${hours}.${finalMinutes}`);
  }, [data]);

  const totalHoursValue = useMemo(() => data.reduce((acc, val) => acc + val.value, 0), [data]);

  const formatItemPercentLabel = (value: number) => {
    const percentValue = totalMinutes > 0 ? (value * 100) / totalMinutes : 0;
    const label = transformMinutesToHours(value);
    return `${label} (${Math.round(percentValue)}%)`;
  };

  return (
    <S.Container style={style}>
      <S.ChartRow>
        {data.map((item, index) => {
          const percentWidth = Number(((item.value * 100) / totalHoursValue).toFixed(2));
          const label = formatItemPercentLabel(item.value);
          return (
            <S.BarSection size={percentWidth} key={`${item.value}-${index}`}>
              <S.BarSectionLabel color={item.color}>{label}</S.BarSectionLabel>
              <S.BarSectionItem
                color={item.color}
                key={`${index}-${item.label}`}
                firstItem={index === 0}
                lastItem={index === data.length - 1}
              />
            </S.BarSection>
          );
        })}
      </S.ChartRow>
      <S.BarTotal />
      <S.BarTotalLabel>{(!totalValue || totalValue == 0) ? '00:00 (100%)' :
        `${String(totalValue)
        .split('.')
        .map((v) => v.padStart(2, '0'))
        .join(':')} (100%)`}</S.BarTotalLabel>
      <S.LabelSection>
        {data.map((item, index) => (
          <div key={`${item.label}-${index}`}>
            <S.LabelSquare color={item.color} />
            {item.label}
          </div>
        ))}
      </S.LabelSection>
    </S.Container>
  );
};
