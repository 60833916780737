import { useEffect, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import moment from 'moment';

import { useCompanyFilterContext } from '../../../../../../../stores/CompanyFilterContext';
import { getCollaboratorsCount } from '../../../../../../../services/api/TrackfyService';
import { CollaboratorsCount } from '../../../../../../indicators/types/Indicators';
import { useAuth } from '../../../../../../../hooks';

type collaboratorCountIndicator = {
  loading: boolean;
  value: string;
};

export const useCollaboratorsCount = () => {
  const [indicator, setIndicator] = useState<collaboratorCountIndicator>({
    loading: true,
    value: 'Carregando...',
  });
  const { selectedCompany } = useCompanyFilterContext();
  const { getClientId } = useAuth();
  const companyId = selectedCompany?.id ? selectedCompany?.id : '0';
  const clientId: number = getClientId();

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if (mounted) {
      setIndicator({ loading: true, value: 'Carregando...' });

      getCollaboratorsCount(clientId, companyId, moment().format('YYYY-MM-DD'), currentRequest.token)
        .then((data: CollaboratorsCount[]) => {
          let indicatorText = '0';

          if (data && data.length > 0) indicatorText = data[0].value.toString();

          setIndicator({ loading: false, value: indicatorText });
        })
        .catch((err: any) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('CollaboratorCount request canceled by the user.');
    };
  }, [companyId]);

  return { indicator };
};
