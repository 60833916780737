import React from 'react';
import { ReportsContextProvider } from './context/ReportsContext';

import { ReportContent } from './components/ReportContent';

export const ReportsContainer: React.FC = () => {
  return (
    <ReportsContextProvider>
      <ReportContent />
    </ReportsContextProvider>
  );
};
