import React from 'react';
import { DefaultIconProps } from './types';

export const UsersIconVuesax: React.FC<DefaultIconProps> = ({ fill, width, height, style }) => {
  return (
    <svg
      style={style}
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.63314 9.05964C7.5498 9.0513 7.4498 9.0513 7.35814 9.05964C5.3748 8.99297 3.7998 7.36797 3.7998 5.36797C3.7998 3.3263 5.4498 1.66797 7.4998 1.66797C9.54147 1.66797 11.1998 3.3263 11.1998 5.36797C11.1915 7.36797 9.61647 8.99297 7.63314 9.05964Z"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6747 3.33203C15.2914 3.33203 16.5914 4.64036 16.5914 6.2487C16.5914 7.8237 15.3414 9.10703 13.7831 9.16537C13.7164 9.15703 13.6414 9.15703 13.5664 9.16537"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.4666 12.132C1.44993 13.482 1.44993 15.682 3.4666 17.0237C5.75827 18.557 9.5166 18.557 11.8083 17.0237C13.8249 15.6737 13.8249 13.4737 11.8083 12.132C9.52494 10.607 5.7666 10.607 3.4666 12.132Z"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2832 16.668C15.8832 16.543 16.4499 16.3013 16.9165 15.943C18.2165 14.968 18.2165 13.3596 16.9165 12.3846C16.4582 12.0346 15.8999 11.8013 15.3082 11.668"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};