import { TooltipContent } from "../../../components/Info/Tooltip";

export const getLegend = (clientId: number, color: String[]): any => {
    if (clientId === 7 || clientId === 1)
      return [
        {
          value: 'programado',
          name: 'Programado (prévia)',
          marker: {
            symbol: 'square',
            style: {
              fill: '#4F5882',
              r: 5,
            },
          },
        },
        {
          value: 'trackfy',
          name: 'Realizado (Trackfy)',
          marker: {
            symbol: 'square',
            style: {
              fill: '#468FEA',
              r: 5,
            },
          },
        },
        {
          value: 'executado',
          name: 'Executado (informado)',
          marker: {
            symbol: 'square',
            style: {
              fill: '#00E0B4',
              r: 5,
            },
          },
        },
      ];
    
  if (clientId === 14)
        return [
          {
            value: 'programado',
            name: 'Programado (prévia)',
            marker: {
              symbol: 'square',
              style: {
                fill: '#4F5882',
                r: 5,
              },
            },
          },
          {
            value: 'trackfy',
            name: 'Realizado (Trackfy)',
            marker: {
              symbol: 'square',
              style: {
                fill: '#468FEA',
                r: 5,
              },
            },
          }
    ];

    if (clientId === 15){
      color.splice(0);
      color.splice(1);

      return [
        {
          value: 'Quantidade de recursos',
          name: 'Quantidade de recursos',
          marker: {
            symbol: 'square',
            style: {
              fill: '#468FEA',
              r: 5,
            },
          },
        },
      ];
    }

    let legends: any[] = [
      {
        value: 'HH Pago',
        name: 'HH Pago',
        marker: {
          symbol: 'square',
          style: {
            fill: '#4F5882',
            r: 5,
          },
        },
      },
      {
        value: 'HH Produtivo',
        name: 'HH Produtivo',
        marker: {
          symbol: 'square',
          style: {
            fill: '	#468FEA',
            r: 5,
          },
        },
      },
    ];

    if(clientId == 4)
      legends.push({
        value: 'HH Geral',
        name: 'HH Geral',
        marker: {
          symbol: 'square',
          style: {
            fill: '	#00E0B4',
            r: 5,
          },
        },
      },);

    return legends;
};

export const getTile = (clientId: number): String => {
    if (clientId == 7 || clientId == 1 || clientId == 14) return 'Planejado x Realizado';
    else if (clientId == 15) return 'Quantidade de recursos no período';
    return 'HH Pago x HH Produtivo';
};

export const getTooltipContent = (clientId: number): TooltipContent[] => {
    if(clientId == 7 || clientId == 1)
        return [
            {
                title: 'Programado (prévia) - ',
                content: 'Valores previstos e disponibilizados como prévias via integração com planejado.',
                color: '#4F5882'
              },
              {
                title: 'Realizado (Trackfy) - ',
                content: 'É o tempo registrado em áreas produtivas (áreas com serviços produtivos).',
                color: 	'#468FEA'
              },
              {
                title: 'Executado (informado) - ',
                content: 'Valores informados pelo planejamento após execução e consolidação da informação.',
                color: '#00E0B4'
              }
        ];
    
    if(clientId == 14)
      return [
              {
                  title: 'Programado (prévia) - ',
                  content: 'Valores previstos e disponibilizados como prévias via integração com planejado.',
                  color: '#4F5882'
                },
                {
                  title: 'Realizado (Trackfy) - ',
                  content: 'É o tempo registrado em áreas produtivas (áreas com serviços produtivos).',
                  color: 	'#468FEA'
                },
      ];

    if(clientId == 15)
        return [
            {
                title: 'Quantidade de recursos - ',
                content: 'Quantidade de recursos (colaboradores) registrados em áreas produtivas durante o período.',
                color: '#468FEA'
            }
        ];

    return [
            {
              title: 'HH Pago - ',
              content: 'É calculado com base na quantidade de trabalhadores que adentraram a unidade, vezes 8 (O parâmetro de horas pode ser alterado).',
              color: '#4F5882'
            },
            {
              title: 'HH Produtivo - ',
              content: 'É o tempo registrado em áreas produtivas (áreas com serviços produtivos).',
              color: 	'#468FEA'
            },
            {
              title: 'HH Geral - ',
              content: 'É calculado com base no horário de saída, subtraído do horário de chegada na unidade para cada trabalhador.',
              color: '#00E0B4'
            }
        ];
};
