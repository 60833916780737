import React from 'react';

import { TextInsightsContextProvider } from './context/TextInsightsContext';
import { PageHeader } from '../../components/PageStructure/PageHeader';
import { Container, ContentContainer } from '../../components/PageStructure/styles';
import TextInsightsOptions from './components/TextInsightsOptions';
import { TextInsightsContent } from './components/modal';

export const TextInsightsContainer: React.FC = () => (
        <Container>
          <PageHeader title="Informes" />
          <ContentContainer value="">
            <TextInsightsContextProvider>
              <TextInsightsOptions />
              <TextInsightsContent/>
            </TextInsightsContextProvider>
          </ContentContainer>
        </Container>
);