import React, { useEffect } from 'react';

import { useAuthContext } from '../../stores/AuthContext';
import { useMovimentContext } from './context/MovimentContext';
import { DateRangePicker } from '../../components';
import { getFollowingFriday } from '../../utils/DateTime';
import { mixpanelTrack } from '../../services/SystemMonitor';
import { useAuth } from '../../hooks/useAuth';
import CompanyMultiSelect from '../filters/CompanyMultiSelect';
import ProfessionMultiSelect from '../filters/ProfessionMultiSelect';
import { MonitoredResourcesMultiSelect } from '../filters/MonitoredResourcesMultiSelect';
import { OptionsContainer } from '../../components/PageStructure/styles';

import * as S from '../../styles/structure';

const MovimentOptions: React.FC = () => {
  const {
    companySelected,
    professionSelected,
    collaboratorSelected,
    setCollaboratorSelected,
    setCompanySelected,
    setProfessionSelected,
    updatePeriod,
  } = useMovimentContext();
  const { authData, monitorAgent } = useAuthContext();
  const compIds: string = authData ? authData.grpCompanies : 't';
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  useEffect(
    () => mixpanelTrack(monitorAgent, 'Indicadores - Movimentação', userName, authData),
    []
  );

  return (
    <OptionsContainer>
      {compIds != 'a' && !compIds.includes(',') ? (
        <></>
      ) : (
        <S.SimpleContainer>
           <CompanyMultiSelect
            itemsSelected={companySelected}
            setItemsSelected={setCompanySelected}
            screen={'Indicadores - Movimentação'}
          />
        </S.SimpleContainer>
      )}
      <S.SimpleContainer>
      <ProfessionMultiSelect
          itemsSelected={professionSelected}
          setItemsSelected={setProfessionSelected}
          screen={'Indicadores - Movimentação'}
      />
      </S.SimpleContainer>
      <S.SimpleContainer>
        <MonitoredResourcesMultiSelect
          companySelected={companySelected}
          professionSelected={professionSelected}
          monitoredResourcesSelected={collaboratorSelected}
          setMonitoredResourcesSelected={setCollaboratorSelected}
          screen={'Indicadores - Movimentação'}
        />
      </S.SimpleContainer>
      <S.SimpleContainer>
        <DateRangePicker
          updatePeriod={updatePeriod}
          screen="Indicadores"
          maxDate={getFollowingFriday(new Date())}
          component="Movimentação - "
          selectRange
          showToday
          loadDate={new Date()}
        />
      </S.SimpleContainer>
      {/* <div><GeneratePdf /></div> */}
    </OptionsContainer>
  );
};

export default MovimentOptions;
