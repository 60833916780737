import React from 'react';

import { TrendUpIcon } from '../../../../Icons/TrendUp';
import { useAreasContext } from './context/AreasContext';
import { useHhindicator } from './hooks/useHhindicator';
import { useCollaboratorsCount } from './hooks/useCollaboratorsCount';

import * as S from './styles';
import { IconLoading } from '../../../../components/IconLoading';

interface Props {}

export const MonitoredData: React.FC<Props> = () => {
  const { HHIndicator } = useHhindicator();
  const { collaboratorsIndicator } = useCollaboratorsCount();
  const { currentCollaborators } = useAreasContext();

  return (
    <S.MonitoredDataSection>
      <div>
        <span>HH Monitorado</span>
        {HHIndicator.loading ? <IconLoading customStyle={{color: "#1890ff"}} /> : <h1>{`${HHIndicator.indicator}h`}</h1> }
      </div>
      <div>
        <span>Colaboradores hoje</span>
        {collaboratorsIndicator.loading ? <IconLoading customStyle={{color: "#1890ff"}} /> : 
          <S.CollaboratorsIndicatorContainer>
            <h1>{collaboratorsIndicator.indicator}</h1> <TrendUpIcon style={{"marginTop": -20}} />
          </S.CollaboratorsIndicatorContainer> 
        }
      </div>
      <div>
        <span>Colaboradores ativos</span>
          {currentCollaborators.loading ? <IconLoading customStyle={{color: "#1890ff"}} /> : 
            <S.CollaboratorsIndicatorContainer>
              <h1>{currentCollaborators.value}</h1> <TrendUpIcon style={{"marginTop": -20}} />
            </S.CollaboratorsIndicatorContainer>
          }
      </div>
    </S.MonitoredDataSection>
  );
};
