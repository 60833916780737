import React, { useState } from 'react';

import { Modal } from '../../components/Modal';
import { ModalInviteUser } from './ModalInviteUser';
import { Container, ContentContainer } from '../../components/PageStructure/styles';
import { PageHeader } from '../../components/PageStructure/PageHeader';
import { UsersIconVuesax } from '../../Icons/Users-Vuesax';
import { PlusIconVuesax } from '../../Icons/Plus-Vuesax';
import { SearchInput } from '../../components/SearchInput';
import ProfileSelect from '../filters/ProfileSelect';
import CompanySelect from '../filters/CompanySelect';
import { UsersTable } from './UsersTable';
import { User } from './types';
import { useUsers } from './hooks/useUsers';
import { Info } from '../../components/Info';
import { useAuth } from '../../hooks';
import { TooltipContent } from '../../components/Info/Tooltip';

import * as S from './styles';

export const UsersContainer: React.FC = () => {
  const [profileSelected, setProfileSelected] = useState(0);
  const [companySelected, setCompanySelected] = useState(0);
  const [userSearched, setUserSearched] = useState('');
  const [usersSelected, setUsersSelected] = useState<User[]>([]);
  const [showModal, setShowModal] = useState(false);
  const { users, isLoading, requestInvites } = useUsers(userSearched, profileSelected, companySelected);
  const { getClientId } = useAuth();
  const clientId = getClientId();
  
  const getUsersTooltipContent = (clientId: number): TooltipContent[] => {
    return [
      {
        content: 'É uma página direcionada aos focal points dos contratos, onde é possível convidar novos funcionários para se cadastrarem no Sistema Trackfy, excluir usuários e verificar detalhes como a frequência de uso e tempo médio por sessão dos usuários de sua empresa!',
      }
    ];
  };

  const onSelectUser = (user: User) => {
    const haveUser = usersSelected.some((v) => v.user === user.user);
    if (haveUser) {
      let localUsersSelected = [...usersSelected];
      localUsersSelected = localUsersSelected.filter((v) => v.user !== user.user);
      setUsersSelected(localUsersSelected);
    } else {
      setUsersSelected([...usersSelected, user]);
    }
  };

  return (
    <Container>
      <PageHeader title="Usuários" />
      <ContentContainer value="" style={{ padding: '2.4rem', background: '#eeeeee' }}>
        <S.UsersContainer>
          <S.UsersHeader>
            <S.UsersHeaderContent>
              <UsersIconVuesax />
              <strong>Usuários cadastrados</strong>
              <Info content={getUsersTooltipContent(clientId)} position={'right'} burndown={false}/>
            </S.UsersHeaderContent>
            <S.InviteButton onClick={() => setShowModal(true)}>
              <PlusIconVuesax />
              Convidar usuário
            </S.InviteButton>
          </S.UsersHeader>
          <S.FilterSection>
            <SearchInput placeholder="Pesquise por um usuário" 
              onChanging={(value) => setUserSearched(value)}
              id="input"
              style={{ filter: 'grayscale(100%)', padding: '1.6rem 1rem', fontSize: '1.6rem' }} />
            <ProfileSelect profileSelected={profileSelected} setProfileSelected={setProfileSelected} />
            <CompanySelect companySelected={companySelected} setCompanySelected={setCompanySelected} />
          </S.FilterSection>
          <UsersTable
            onSelectUser={onSelectUser}
            onSelectAll={() => (usersSelected.length === users.length) ? setUsersSelected([]) : setUsersSelected(users)} 
            usersSelected={usersSelected}
            profileSelected={profileSelected}
            companySelected={companySelected}
            userSearched={userSearched}
            users={users}
            loading={isLoading}
            requestInvites={requestInvites}
          />
        </S.UsersContainer>
      </ContentContainer>
      <Modal visible={showModal}>
        <ModalInviteUser closeModal={() => setShowModal(false)} 
                         setProfileSelected={setProfileSelected} 
                         setCompanySelected={setCompanySelected}
                         setUserSearched={setUserSearched}
                         requestInvites={requestInvites}
        />
      </Modal>
    </Container>
  );
};