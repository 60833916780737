import React from 'react';



import { CardAvgcontent } from './styles';

import { VerticalBarChart } from './components/VerticalBarChart';

import { IconLoading } from '../../../../components/IconLoading';
import { RevenueFTEChartHeader } from './components/RevenueFTEChartHeader';
import { useNdrContext } from './context/NDRContext';
import { useNdr } from './hooks/UseNDR';

export const NDRChart: React.FC = () => {
  const { ndrChartData } = useNdrContext();
  const { isLoading } = useNdr();

  return (
    <>
      <RevenueFTEChartHeader />
      <CardAvgcontent>
        {isLoading && <IconLoading />}
        {!isLoading && <VerticalBarChart NDRChartData={ndrChartData} />}
      </CardAvgcontent>
    </>
  );
};
