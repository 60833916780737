import React, { useEffect } from 'react';

import { useAuthContext } from '../../stores/AuthContext';
import { useAuth } from '../../hooks/useAuth';
import { mixpanelTrack } from '../../services/SystemMonitor';
import { SearchInput } from '../../components/SearchInput';
import { useFaQContext } from './context/FaqContext';

import * as S from '../../styles/structure';

const FaqOptions: React.FC = () => {
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);
  const {searchQuestion,setSearchQuestion} = useFaQContext()

  useEffect(() => mixpanelTrack(monitorAgent, 'FaqOptions', userName, authData), []);

  return (
    <S.SimpleContainer>
      <SearchInput
        id='Search'
        placeholder='Descreva brevemente sua dúvida'
        value={searchQuestion}
        onChanging={(value) => setSearchQuestion(value)}
      />
    </S.SimpleContainer>
  );
};

export default FaqOptions;