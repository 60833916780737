import { Slider, List } from 'antd';
import moment from 'moment';
import React, { useRef } from 'react';
import ScrollBar from 'react-custom-scrollbars';

import { IconLoading } from '../../../../../../../../components/IconLoading';
import IconDot from '../../../../../../../../Icons/Dot';
import { AreaHistory } from '../../../../../../../../types/MonitoredData';

type Historic = {
  area: string;
  color: string;
  time: number;
};

type Props = {
  historic: Historic[];
  /*  {
      area: string;
      color: string;
      time: number;
    }
  ]; */
  loading: boolean;
  onChangePeriodHistory: (value: number) => any;
};

export const HistoryContent: React.FC<Props> = (props: Props) => {
  const { historic, onChangePeriodHistory, loading } = props;
  const ScrollbarsRef: React.MutableRefObject<any> = useRef<any>(null);

  const startList = () => {
    const list: JSX.Element[] = historic.map((area: AreaHistory, index:number) => {

      let hour: string = moment.duration(area.time).hours().toString().padStart(2,"0");
      //hour = hour.length === 1 ? `0${hour}` : hour;

      let minute: string = moment.duration(area.time).minutes().toString().padStart(2,"0");
      //minute = minute.length === 1 ? `0${minute}` : minute;

      let second: string = moment.duration(area.time).seconds().toString().padStart(2,"0");

      return (
        <List.Item key={`${index}${area.time}${area.area}`} className="history-item-list">
          <IconDot fill={area.color} />
          <div className="history-area">{area.area}</div>
          <div className="history-time">{`${hour}:${minute}:${second}`}</div>
        </List.Item>
      );
    });

    setTimeout(() => ScrollbarsRef.current?.forceUpdate(), 1700);

    return list;
  };

  return (
    <div className="history-content">
      <div className="title">
        <span>Histórico durante as últimas:</span>
      </div>
      <Slider
        className="period-time"
        defaultValue={5}
        max={24}
        // tooltipVisible
        tipFormatter={(value: any) => `${value}h`}
        tooltipPlacement="bottom"
        onChange={(value: number) => onChangePeriodHistory(value)}
      />
      <ScrollBar ref={ScrollbarsRef} className="period-history">
        {loading ? <IconLoading /> : <List>{startList()}</List>}
      </ScrollBar>
    </div>
  );
};
