import React from 'react';
import { MapProvider, Map } from 'react-map-gl';
import Switch from 'antd/lib/switch';

import { useMap } from './hooks/useMap';
import { Area } from './Area';
import { Detective } from '../MonitoringIndicators/detective';
import { SiteLoading } from '../SiteLoading';
import { ClientTypeProps } from '../../../../types/MonitoredData';

import * as S from './styles';

export const MonitoringMap: React.FC<ClientTypeProps> = (props: ClientTypeProps) => {
  const { isManager, isMap } = props;
  const {
    latitudeRef,
    longitudeRef,
    sateliteRef,
    changeMapTerrain,
    mapStyle,
    zoomRef,
    initializeMap,
  } = useMap();

  if (!initializeMap) return <SiteLoading />;

  return (
    <div>
      <MapProvider>
        <Map
          id="mymap"
          initialViewState={{
            longitude: longitudeRef.current,
            latitude: latitudeRef.current,
            zoom: zoomRef.current,
          }}
          style={{ height: '100vh' }}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''}
          mapStyle={mapStyle}
          //onClick={(e: MapLayerMouseEvent) => {console.log(e.lngLat)}}
        >
          <Area />
          <Detective isManager={isManager} isMap={isMap} />
        </Map>
      </MapProvider>
      <S.SwitchContainer>
        <S.SwitchLabel checked={!sateliteRef.current}>Street</S.SwitchLabel>
        <Switch style={{ marginTop: '3px' }} onChange={changeMapTerrain} />
        <S.SwitchLabel checked={sateliteRef.current}>Satélite</S.SwitchLabel>
      </S.SwitchContainer>
    </div>
  );
};
