import React from 'react';
import {Marker} from 'react-map-gl';

import * as S from '../styles';

interface DisplacementBtwnAreasPopupProps {
    lat:number;
    lng:number;
    contentName: string;
};

export const DisplacementBtwnAreasPopup: React.FC<DisplacementBtwnAreasPopupProps> = (props: DisplacementBtwnAreasPopupProps) => {
    const { contentName,lat,lng } = props;
  
    return (
      <Marker longitude={lng} latitude={lat} anchor="top">
          <S.Tooltip>
            <span>{contentName}</span>
          </S.Tooltip>
      </Marker>
    );
};