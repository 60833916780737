import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { Layout } from '../layouts/Layout';

interface ApplicationRoutesProps extends RouteProps {}

export const ApplicationRoutes: React.FC<ApplicationRoutesProps> = ({ path, component }) => (
  <Layout>
    <Route path={path} component={component} />
  </Layout>
);
