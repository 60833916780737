import React from 'react';

const IconAngleDown: React.FC = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.91666 5.34916C9.80736 5.24052 9.65952 5.17953 9.50541 5.17953C9.3513 5.17953 9.20345 5.24052 9.09416 5.34916L6.99999 7.41416L4.93499 5.34916C4.8257 5.24052 4.67785 5.17953 4.52374 5.17953C4.36963 5.17953 4.22179 5.24052 4.11249 5.34916C4.05782 5.40339 4.01442 5.46791 3.9848 5.53899C3.95519 5.61008 3.93994 5.68632 3.93994 5.76333C3.93994 5.84034 3.95519 5.91658 3.9848 5.98767C4.01442 6.05875 4.05782 6.12327 4.11249 6.1775L6.58582 8.65083C6.64005 8.70551 6.70457 8.7489 6.77565 8.77852C6.84674 8.80813 6.92298 8.82338 6.99999 8.82338C7.077 8.82338 7.15324 8.80813 7.22433 8.77852C7.29541 8.7489 7.35993 8.70551 7.41416 8.65083L9.91666 6.1775C9.97133 6.12327 10.0147 6.05875 10.0443 5.98767C10.074 5.91658 10.0892 5.84034 10.0892 5.76333C10.0892 5.68632 10.074 5.61008 10.0443 5.53899C10.0147 5.46791 9.97133 5.40339 9.91666 5.34916Z"
      fill="#4F5882"
    />
  </svg>
);

export default IconAngleDown;
