import React, { createContext, ReactNode, useState, useContext, Dispatch, SetStateAction } from 'react';
import Axios, { CancelTokenSource } from 'axios';

import { AreasInEvacuationSnapshot, EvacuationAreaMode, EvacuationSnapshot } from '../types/Emergency';
import { ICollaboratorsByAreaDetails, MapAreasData, MonitoredAreasInEmergencyData } from '../../../types/MonitoredData';
import { useAuth } from '../../../hooks';
import { getLocationsByArea, sendEvacuationData } from '../../../services/api/TrackfyService/integration';

type EvacuationContextProps = {
  data: EvacuationSnapshot;
  setData: Dispatch<SetStateAction<EvacuationSnapshot>>;
  getCollaboratorsByArea: () => void;
  handleEvacuationInit: (requestedAreas: MapAreasData[]) => void;
  handleEvacuationCancel: () => void;
  handleDangerAreasSelection: (area: MapAreasData, isMounted: boolean) => void;
  handleEvacuationMonitoringStartOrFinish: (start: boolean) => void;
  intervl: NodeJS.Timeout | undefined;
  setIntervl: Dispatch<SetStateAction<NodeJS.Timeout | undefined>>;
};

type Props = {
  children: ReactNode;
};

const EvacuationContext = createContext<EvacuationContextProps>({} as EvacuationContextProps);

const defaultEvacuationSnapshot: EvacuationSnapshot = {
    currentCollaboratorsInArea: [],
    currentSnapshot:[],
    collaboratorsInDangerAtStart: [],
    collaboratorsInDangerAreas: [], 
    dangerAreas: [],
    safeArea: "",
    mode: "notevacuating",
    initialTimestamp: 0,
    elapsedTime: 0,
    loadingData: false,
    collaboratorsSeenInSafeAreas: [],
}

export function EvacuationContextProvider(props: Props) {
    const { children } = props;
    const { getClientId } = useAuth();
    const [intervl, setIntervl] = useState<NodeJS.Timeout | undefined>(undefined);
    const [data, setData] = useState<EvacuationSnapshot>(defaultEvacuationSnapshot);

    const fetchCollaboratorsData = () => getCollaboratorsByArea();

    const getCollaboratorsByArea = (mode?: EvacuationAreaMode) => {
        const currentRequest: CancelTokenSource = Axios.CancelToken.source();

        getLocationsByArea(getClientId(), currentRequest.token, "", true)
            .then((monitoredAreas:MonitoredAreasInEmergencyData[]) => {

                const currentSnapshot: AreasInEvacuationSnapshot[] = [];
                const collaboratorsInDangerAreas: string[] = [];
                const colInSafety: any[] = [];

                monitoredAreas.forEach((area: MonitoredAreasInEmergencyData) => {
                    const collaborators = area.collaborators.map((collaborator: ICollaboratorsByAreaDetails) => {
                        const areaInDanger = data.dangerAreas.find((aid: string) => aid.trim() == area.nome.trim());

                        if(areaInDanger){
                            const colInDanger = collaboratorsInDangerAreas.find((collaboratorInDanger: string) => 
                                collaboratorInDanger == collaborator.alias);

                            if(!colInDanger)
                                collaboratorsInDangerAreas.push(collaborator.alias);
                        }

                        if(area.issafearea){
                            const colInSafeArea = colInSafety.find((safeCol: string) => collaborator.alias == safeCol);

                            if(!colInSafeArea){
                                colInSafety.push(collaborator.alias);
                                /*setData((prev: EvacuationSnapshot) => ({
                                    ...prev,
                                    collaboratorsSeenInSafeAreas: [...prev.collaboratorsSeenInSafeAreas, collaborator.alias]
                                }));*/
                            } 
                        }

                        return collaborator.alias;
                    });

                    currentSnapshot.push({
                        area: area.nome,
                        issafearea: area.issafearea,
                        total: area.total,
                        collaborators: collaborators
                    });
                });
                
                if(mode) {
                    const tmstamp = (new Date()).valueOf() ;
                
                    /*setData((prev: EvacuationSnapshot) => ({
                        ...prev,
                        mode: mode,
                        currentCollaboratorsInArea: monitoredAreas,
                        currentSnapshot: currentSnapshot,
                        collaboratorsInDangerAreas: collaboratorsInDangerAreas,
                        collaboratorsSeenInSafeAreas: [...prev.collaboratorsSeenInSafeAreas, ...colInSafety],
                        initialTimestamp: tmstamp,
                        loadingData: false
                    }));*/

                    setData((prev: EvacuationSnapshot) => {
                        const combinedCollaborators = [...prev.collaboratorsSeenInSafeAreas, ...colInSafety];
                        const uniqueCollaborators: any[] = [];
                        const seenNames = new Set();
                  
                        combinedCollaborators.forEach(collaborator => {
                          if (!seenNames.has(collaborator)) {
                            seenNames.add(collaborator);
                            uniqueCollaborators.push(collaborator);
                          }
                        });
                        
                        return{
                            ...prev,
                            mode: mode,
                            currentCollaboratorsInArea: monitoredAreas,
                            currentSnapshot: currentSnapshot,
                            collaboratorsInDangerAreas: collaboratorsInDangerAreas,
                            collaboratorsSeenInSafeAreas: uniqueCollaborators,
                            collaboratorsInDangerAtStart: collaboratorsInDangerAreas,
                            initialTimestamp: tmstamp,
                            loadingData: false
                        }
                    });
                }
                
                else
                    setData((prev: EvacuationSnapshot) => {
                        const combinedCollaborators = [...prev.collaboratorsSeenInSafeAreas, ...colInSafety];
                        const uniqueCollaborators: any[] = [];
                        const seenNames = new Set();
                  
                        combinedCollaborators.forEach(collaborator => {
                          if (!seenNames.has(collaborator)) {
                            seenNames.add(collaborator);
                            uniqueCollaborators.push(collaborator);
                          }
                        });

                        return{
                            ...prev,
                            currentCollaboratorsInArea: monitoredAreas,
                            currentSnapshot: currentSnapshot,
                            collaboratorsInDangerAreas: collaboratorsInDangerAreas,
                            collaboratorsSeenInSafeAreas: uniqueCollaborators
                        }
                    });
        })
        .catch((err) => {
            console.log(err);
            setData((prev: EvacuationSnapshot) => ({
                ...prev,
                currentCollaboratorsInArea: [],
                currentSnapshot: [],
                collaboratorsInDangerAreas: [],
                collaboratorsSeenInSafeAreas: []
            }));
        });
    }

    const handleEvacuationMonitoringStartOrFinish = (start: boolean) => {
        if(start){
            setData((prev: EvacuationSnapshot) => ({...prev, loadingData: true }));
            getCollaboratorsByArea("evacuating");
            const intvll = setInterval(fetchCollaboratorsData, 10000);
            setIntervl(intvll);
        } else {
            if (intervl) {
                clearInterval(intervl);
                setIntervl(undefined);
            }
            setData((prev: EvacuationSnapshot) => ({...prev, mode: "notevacuating" }));
            registerEvacuationIncident();
        }
    }

    const handleEvacuationCancel = () => setData(defaultEvacuationSnapshot);

    const handleEvacuationInit = (requestedAreas: MapAreasData[]) => {
        let safeAreas = "";

        requestedAreas.forEach((area: MapAreasData) => {
            if(area.issafearea)
                safeAreas = (safeAreas.length == 0) ? area.nome : safeAreas + ";" + area.nome;
        });

        if(safeAreas && safeAreas.length > 0) {
            setData((prev: EvacuationSnapshot) => ({...prev, 
                safeArea: safeAreas, 
                mode: "standby", 
                dangerAreas: [],
                currentSnapshot:[],
                currentCollaboratorsInArea: [],
                collaboratorsInDangerAreas: [],
                collaboratorsSeenInSafeAreas:[],
                initialTimestamp: 0,
                elapsedTime: 0,
                loadingData: false,
            }));
            //getCollaboratorsByArea();
        }  
    }

    const handleDangerAreasSelection = (area: MapAreasData, isMounted: boolean) => {
        if(isMounted)
            setData((prev: EvacuationSnapshot) => {
                if(prev.mode === 'standby' && !area.issafearea){
                if(prev.dangerAreas.find(a => a.trim() == area.nome.trim()))
                    return { ...prev, dangerAreas: prev.dangerAreas.filter(v => v.trim() !== area.nome.trim()) }
                else 
                    return { ...prev, dangerAreas: [...prev.dangerAreas, area.nome ] }
                }
                
                return prev;
            });
    }

    const registerEvacuationIncident = () => {
        const safeAreas: AreasInEvacuationSnapshot[] = [];
        let collaboratorsInSafeAreas: string[] = [];

        for (const area of data.currentSnapshot) {
            if(area.issafearea)
                safeAreas.push(area);
        }

        for (const area of safeAreas) {
            if(area)
                collaboratorsInSafeAreas = collaboratorsInSafeAreas.concat(area.collaborators);
        }
        
        const currentRequest: CancelTokenSource = Axios.CancelToken.source();

        if(safeAreas)
            sendEvacuationData(safeAreas, 
                               data.collaboratorsInDangerAreas, 
                               collaboratorsInSafeAreas, 
                               data.collaboratorsSeenInSafeAreas,
                               data.collaboratorsInDangerAtStart,
                               data.dangerAreas, 
                               data.elapsedTime, 
                               data.initialTimestamp, 
                               currentRequest.token);
    }

    const value = {data, 
                   setData, 
                   getCollaboratorsByArea, 
                   handleEvacuationInit, 
                   handleEvacuationCancel,
                   handleDangerAreasSelection, 
                   handleEvacuationMonitoringStartOrFinish, 
                   intervl,
                   setIntervl};
                   
    return <EvacuationContext.Provider value={value}>{children}</EvacuationContext.Provider>;
}

export function useEvacuationContext() {
    const context = useContext(EvacuationContext);
  
    if (typeof context === 'undefined') {
      throw new Error('EvacuationContext must be used within an EvacuationContext');
    }
  
    return context;
}
  