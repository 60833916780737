import React, { useState } from 'react';

import { VerticalBarChart } from './components/VerticalBarChart';


import { CardAvgcontent, GroupingButton,OptionsCard} from './styles';
import { useProductivityVSDisplacement } from './hooks/UseProductivityVSDisplacement';
import { useProductivityVSDisplacementContext } from './context/ProductivityVSDisplacementContext';
import { ProductivityVSDisplacementChartHeader } from './components/ProductivityVSDisplacement';
import { IconLoading } from '../../../components/IconLoading';


export const ProductivityVSDisplacementChart: React.FC = () => {
  const { prodVSDisChartData, areaSelected, setAreaSelected, setTimeVisualization } = useProductivityVSDisplacementContext();
  const { isLoading } = useProductivityVSDisplacement();

  return (
    <>
      <ProductivityVSDisplacementChartHeader areaSelected={areaSelected} setAreaSelected={setAreaSelected} />
      <CardAvgcontent>
        {isLoading && <IconLoading />}
        {!isLoading && <VerticalBarChart prodVSDisChartData={prodVSDisChartData} />}
      </CardAvgcontent>
      <OptionsCard>
        <GroupingButton onClick={ () => setTimeVisualization({ week: false, month: false, year: false })}>
          Diário
        </GroupingButton>
        <GroupingButton onClick={ () => setTimeVisualization({ week: true, month: false, year: false })}>
          Semanas
        </GroupingButton>
        <GroupingButton onClick={ () => setTimeVisualization({ week: false, month: true, year: false })}>
          Meses
        </GroupingButton>
        <GroupingButton onClick={ () => setTimeVisualization({ week: false, month: false, year: true })}>
          Anos
        </GroupingButton>
      </OptionsCard>
    </>
  );
};
