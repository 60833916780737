import React from 'react';
import { BuildingHistory } from './components/BuildingHistory';

import { DetectiveContextProvider } from './context/DetectiveContext';
import { History } from './components/History';
import { Search } from './components/Search';

import * as S from '../../../../monitoring/styles';

type DetectiveProps = {
  isManager: boolean;
  isMap: boolean;
};

export const Detective: React.FC<DetectiveProps> = (props: DetectiveProps) => {
  const { isManager, isMap } = props;

  return (
    <DetectiveContextProvider>
      <S.DetectiveContainer isManager={isManager}>
        <Search />
        {isMap ? <History /> : <BuildingHistory />}
      </S.DetectiveContainer>
    </DetectiveContextProvider>
  );
};
