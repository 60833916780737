import moment from 'moment';
import React, { createContext, ReactNode, useState, useContext } from 'react';
import { useAuthContext } from '../../../stores/AuthContext';
import { SelectOption } from '../../../components/Select/types';
import { Planning } from '../../indicators/types/Indicators';

type ConformityContextProps = {
  companySelected: SelectOption[];
  professionSelected: SelectOption[];
  areaSelected: SelectOption[];
  planningData: Planning[];
  resourcesPlanningData: Planning[];
  initialDate: any;
  finalDate: any;
  setCompanySelected: (company: SelectOption[]) => void;
  setProfessionSelected: (profession: SelectOption[]) => void;
  setAreaSelected: (area: SelectOption[]) => void;
  updatePeriod: (initial: any, final: any) => void;
  setPlanningData: (planning: Planning[]) => void;
  setResourcesPlanningData: (planning: Planning[]) => void;
};

type Props = {
  children: ReactNode;
};

const ConformityContext = createContext<ConformityContextProps>({} as ConformityContextProps);

export function ConformityContextProvider(props: Props) {
  const { children } = props;
  
  //const {authData} = useAuthContext();
  const [initialDate, setInitialDate] = useState<any>(moment());
  const [finalDate, setFinalDate] = useState<any>(moment());
  const [areaSelected, setAreaSelected] = useState<SelectOption[]>([]);
  const [professionSelected, setProfessionSelected] = useState<SelectOption[]>([]);
  const [companySelected, setCompanySelected] = useState<SelectOption[]>([]);
  const [planningData, setPlanningData] = useState<Planning[]>([]);
  const [resourcesPlanningData, setResourcesPlanningData] = useState<Planning[]>([]);

  /*const initialCompanySelected = (authData && authData.grpCompanies != 'a' && (!authData.grpCompanies.includes(","))) ?
    {value: authData.companyId.toString(), label: ""} : 
    {value: "0", label:"Selecione uma empresa"};
  const [companySelected, setCompanySelected] = useState<SelectOption[]>([initialCompanySelected]);*/

  const updatePeriod = (initial: any, final: any) => {
    setInitialDate(initial);
    setFinalDate(final);
  };

  const value = {
    areaSelected,
    companySelected,
    professionSelected,
    initialDate,
    finalDate,
    planningData,
	resourcesPlanningData,
    setAreaSelected,
    setCompanySelected,
    setProfessionSelected,
    updatePeriod,
    setPlanningData,
	setResourcesPlanningData,
  };
  return <ConformityContext.Provider value={value}>{children}</ConformityContext.Provider>;
}

export function useConformityContext() {
  const context = useContext(ConformityContext);

  if (typeof context === 'undefined') {
    throw new Error('ConformityContext must be used within an ConformityContext');
  }

  return context;
}
