import { Layout as AntdLayout } from 'antd';
import 'antd/dist/antd.css';
import './styles.css';
import React from 'react';
import ScrollBar from 'react-custom-scrollbars';

import { useMatchMedia } from '../../hooks';
import { Content } from './components/Content';
import { Header } from './components/Header';
import { SideBar } from './components/SideBar';
import { LayoutMenuContextProvider } from './context/LayoutMenuContext';

export const Layout: React.FC = ({ children }) => {
  const isMobile: boolean = useMatchMedia(767);

  return (
    <AntdLayout
      style={{
        minHeight: '100vh',
        overflow: 'hidden',
        paddingTop: `${isMobile ? '50px' : '0'}` /* makes the sidebar roll above the content */,
        paddingLeft: `${isMobile ? '0' : '70px'}` /* makes the sidebar roll above the content */,
      }}
    >
      <LayoutMenuContextProvider>
        <Header />
        <SideBar />
      </LayoutMenuContextProvider>

      <AntdLayout style={{ zIndex: 10 }}>
        <ScrollBar>
          <Content>{children}</Content>
        </ScrollBar>
      </AntdLayout>
    </AntdLayout>
  );
};
