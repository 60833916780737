import React from 'react';

import { CompanyList } from './CompanyList';

import * as S from '../styles';

type CompanyRegistrationProps = {
  isAdmin: boolean;
}

export const CompanyRegistrationContainer: React.FC<CompanyRegistrationProps> = ({isAdmin}) => (
  <S.Content>
    <CompanyList isAdmin = {isAdmin} />
  </S.Content>
);
