import React from 'react';

import { Page } from '../../components/PageStructure/Page';
import {ContentContainer} from '../../components/PageStructure/styles';
import MachinesContent from './components/MachinesContent';

export const MachinesSummaryContainer: React.FC = () => (
  <Page title="Sumário de Máquinas" 
        components={
          <ContentContainer value="">
            <MachinesContent />
          </ContentContainer>
        } 
  />
);