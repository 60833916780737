import React from 'react';


const IconAlertReports: React.FC = () => (
    <svg fill="white" height="24" viewBox="0 0 23 23" width="24" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.25 3C4.00736 3 3 4.00736 3 5.25V18.75C3 19.9926 4.00736 21 5.25 21H11.0502C11.1031 20.7398 11.1973 20.4886 11.3302 20.2563L13.4787 16.5H11.2501C10.8358 16.5 10.5001 16.1642 10.5001 15.75C10.5001 15.3358 10.8358 15 11.2501 15H14.3367L15.3332 13.2579C15.4414 13.0687 15.5707 12.899 15.7163 12.75H11.2501C10.8358 12.75 10.5001 12.4142 10.5001 12C10.5001 11.5858 10.8358 11.25 11.2501 11.25H16.7499C17.1642 11.25 17.4999 11.5858 17.4999 12C17.9216 11.9997 18.3493 12.1064 18.7413 12.3313C19.1266 12.5523 19.4459 12.8722 19.6665 13.2579L21 15.5892V5.25C21 4.00736 19.9926 3 18.75 3H5.25ZM8.75 8.25C8.75 8.80228 8.30228 9.25 7.75 9.25C7.19772 9.25 6.75 8.80228 6.75 8.25C6.75 7.69772 7.19772 7.25 7.75 7.25C8.30228 7.25 8.75 7.69772 8.75 8.25ZM10.5 8.25C10.5 7.83579 10.8358 7.5 11.25 7.5H16.75C17.1642 7.5 17.5 7.83579 17.5 8.25C17.5 8.66421 17.1642 9 16.75 9H11.25C10.8358 9 10.5 8.66421 10.5 8.25ZM7.75 13C7.19772 13 6.75 12.5523 6.75 12C6.75 11.4477 7.19772 11 7.75 11C8.30228 11 8.75 11.4477 8.75 12C8.75 12.5523 8.30228 13 7.75 13ZM8.75 15.75C8.75 16.3023 8.30228 16.75 7.75 16.75C7.19772 16.75 6.75 16.3023 6.75 15.75C6.75 15.1977 7.19772 14.75 7.75 14.75C8.30228 14.75 8.75 15.1977 8.75 15.75Z" />
        <path d="M17.1078 13.0527C17.478 12.952 17.8849 12.9928 18.2436 13.1986C18.4745 13.3311 18.6661 13.5229 18.7985 13.7544L22.8015 20.7528C23.0071 21.1124 23.048 21.5206 22.9473 21.892C22.8466 22.2634 22.6053 22.5946 22.2466 22.8004C22.0201 22.9304 21.7637 22.9987 21.5028 22.9987H13.4968C13.0836 22.9987 12.7102 22.8316 12.4387 22.5596C12.1672 22.2877 12 21.9133 12 21.4987C12 21.2369 12.0683 20.9798 12.1982 20.7528L16.2012 13.7544C16.4069 13.3948 16.7375 13.1534 17.1078 13.0527ZM17.9956 15.5C17.9956 15.2239 17.7717 15 17.4956 15C17.2195 15 16.9956 15.2239 16.9956 15.5V18.5C16.9956 18.7761 17.2195 19 17.4956 19C17.7717 19 17.9956 18.7761 17.9956 18.5V15.5ZM17.5 21.5C17.9142 21.5 18.25 21.1642 18.25 20.75C18.25 20.3358 17.9142 20 17.5 20C17.0858 20 16.75 20.3358 16.75 20.75C16.75 21.1642 17.0858 21.5 17.5 21.5Z" />
    </svg>
);

export default IconAlertReports;