import { useCallback, useEffect, useState } from 'react';
import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { getCreatedClients } from '../../../../../../services/api/TrackfyService';
import { CreatedClients } from '../../../../types/ClientCompany';

export const useClientList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [createdClients, setCreatedClients] = useState<CreatedClients[]>([]);
  const [isAdding, setIsAdding] = useState<boolean>(false);

  const handleCloseAddClients = useCallback(() => {
    setIsAdding(false);
  }, []);

  const handleCreatedClientsUpdate = (operation: number) => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    requestCreatedClients(currentRequest.token, operation);
  };

  const handleCloseAddClientsAndUpdateList = useCallback(() => {
    handleCreatedClientsUpdate(1);
  }, []);

  const requestCreatedClients = (token: CancelToken, operation: number) => {
    getCreatedClients(token)
      .then((data: CreatedClients[]) => {
        setCreatedClients(data);
        setIsLoading(false);

        if (operation === 1) setIsAdding(false);
      })
      .catch((err) => {
        console.log(err);
        setCreatedClients([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if (mounted) {
      setIsLoading(true);
      requestCreatedClients(currentRequest.token, 0);
    }

    return () => {
      mounted = false;
      currentRequest.cancel('request canceled by the user.');
    };
  }, []);

  return {
    createdClients,
    isLoading,
    isAdding,
    setIsAdding,
    handleCloseAddClients,
    handleCloseAddClientsAndUpdateList,
  };
};
