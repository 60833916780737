import { List } from 'antd';
import React, { useRef } from 'react';
import ScrollBar from 'react-custom-scrollbars';

import { useDetectiveContext } from '../../../context/DetectiveContext';
import { ResultList } from './ResultList';

type Props = {
  active: boolean;
  onSearch: (value?: string) => any;
};

export const SearchContent: React.FC<Props> = (props: Props) => {
  const { active, onSearch } = props;
  const { listCollaborators } = useDetectiveContext();
  const ScrollbarsRef: React.MutableRefObject<any> = useRef<any>(null);

  if (active) {
    setTimeout(() => ScrollbarsRef.current?.forceUpdate(), 1700);
  }

  return (
    <>
      {listCollaborators && (
        <ScrollBar ref={ScrollbarsRef} className="search-content">
          <List>
            {listCollaborators.filter(Boolean).map((collaborator: any) => (
              <ResultList key={collaborator?.id} collaborator={collaborator} onSearch={onSearch} colSimple={collaborator}/>
            ))}
          </List>
        </ScrollBar>
      )}
    </>
  );
};
