import React from 'react';
import BurndownChartHeader from './BurndownChartHeader';
import { Shadow } from '../../../components';

import styled from 'styled-components';
import BurndownChartContent from './BurndownChartContent';

const BurnoutCard = styled(Shadow)`
  //display: grid;
  //grid-template-columns: 1fr;
  //grid-template-rows: max-content auto;
  width: 100%;
  height: 100%;
  min-height: 400px;
  padding: 2.4rem 2.4rem 1.6rem;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
`;

const BurndownChart: React.FC = () => 
    <BurnoutCard>
        <BurndownChartHeader />
        <BurndownChartContent />
    </BurnoutCard>

export default BurndownChart; 