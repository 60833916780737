import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';

import AngleDown from '../../assets/angle-down.png';

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={AngleDown} alt="" />
    </components.DropdownIndicator>
  );
};