import React from 'react';

import { MonitoredMap } from '../../../components/MonitoredMap';
import { EmptyContainer } from '../../../components/Table/styles';
import { DisplacementBtwnAreasMedia } from './components/DisplacementBtwnAreasMedia';
import { DisplacementBtwnAreasHeader } from './components/DisplacementBtwnAreasHeader';
import { SelectInitialAndFinalAreas } from './components/DisplacementBtwnAreasSelect';
import { useDisplacementBtwnAreasContext } from './context/DisplacementBtwnAreasContext';
import { useDisplacementBtwnAreas } from './hooks/useDisplacementBtwnAreas';
import { IconLoading } from '../../../components/IconLoading';
import QuestionMark from '../../../Icons/QuestionMark';

import * as S from './styles';

export const DiplacementBetweenAreas: React.FC = () => {
    const { finalAreaSelected, initialAreaSelected, areasMarkers } = useDisplacementBtwnAreasContext()
    const { indicator, getMarkers } = useDisplacementBtwnAreas();

    const monitoredMapChilds = getMarkers().concat(
        <DisplacementBtwnAreasMedia key="DisplacementBtwnAreasMedia" value={indicator.value}/>);
    
    const emptyDisplacement = 
    <EmptyContainer>
        <QuestionMark />
        <span>Para visualizar os dados, selecione pelo menos um ponto de partida e um ponto de destino</span>
    </EmptyContainer>;

    return(
        <S.DisplacementBtwnAreasContainer>
            <DisplacementBtwnAreasHeader/>
            <SelectInitialAndFinalAreas/>
            {indicator.loading && <IconLoading />}
            { (!indicator.loading && (finalAreaSelected == 0 || initialAreaSelected == 0)) ? emptyDisplacement :
                <MonitoredMap childComponents={monitoredMapChilds} monitoredComponent= 'realTime' height='70vh'/>
            }
        </S.DisplacementBtwnAreasContainer>
    )
};