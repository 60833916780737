import moment from 'moment';
import React, { createContext, ReactNode, useState, useContext } from 'react';
import { useAuthContext } from '../../../stores/AuthContext';

type AgileanContextProps = {
  companySelected: number;
  professionSelected: number;
  areaSelected: number;
  activitySelected: string;
  initialDate: any;
  finalDate: any;
  setCompanySelected: (companie: number) => void;
  setProfessionSelected: (profession: number) => void;
  setAreaSelected: (area: number) => void;
  updatePeriod: (initial: any, final: any) => void;
  setActivitySelected: (activity: string) => void;
};

type Props = {
  children: ReactNode;
};

const AgileanContext = createContext<AgileanContextProps>({} as AgileanContextProps);

export function AgileanContextProvider(props: Props) {
  const { children } = props;
  
  const {authData} = useAuthContext();
  const [initialDate, setInitialDate] = useState(moment());
  const [finalDate, setFinalDate] = useState(moment());
  const [areaSelected, setAreaSelected] = useState(0);
  const [professionSelected, setProfessionSelected] = useState(0);
  const [activitySelected, setActivitySelected] = useState("");

  const companyId = (authData && authData.grpCompanies != 'a' && (!authData.grpCompanies.includes(","))) ?
    authData.companyId:0;
  
  const [companySelected, setCompanySelected] = useState<number>(companyId);

  const updatePeriod = (initial: any, final: any) => {
    setInitialDate(initial);
    setFinalDate(final);
  };

  const value = {
    areaSelected,
    companySelected,
    professionSelected,
    initialDate,
    finalDate,
    activitySelected,
    setAreaSelected,
    setCompanySelected,
    setProfessionSelected,
    updatePeriod,
    setActivitySelected,
  };
  return <AgileanContext.Provider value={value}>{children}</AgileanContext.Provider>;
}

export function useAgileanContext() {
  const context = useContext(AgileanContext);

  if (typeof context === 'undefined') {
    throw new Error('AgileanContext must be used within an AgileanContext');
  }

  return context;
}
