import { IAuthProfileAuthorization, IAuthProfilePayload } from "./api/auth";
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';
import { AuthData } from "../types";

const pendoInitialize = (profileData:IAuthProfilePayload, authorization:IAuthProfileAuthorization) =>
// @ts-ignore
window.pendo?.initialize({
  visitor: {
    id: profileData.userId,
    full_name: profileData.name,
    email: profileData.email,
  },
  account: {
    admin: authorization.admin,
  },
});

export const pendoTrack = (screen: string, component: string, description: string) =>
  // @ts-ignore
  window.pendo?.track('Registered', {
    screen,
    component,
    description,
});

export const mixpanelInitialize = (token:string, debugValue: boolean):OverridedMixpanel => {
    mixpanel.init(token, {debug: debugValue});
    return mixpanel;
}

export const mixpanelTrack = (monitorAgent:OverridedMixpanel|undefined, 
                              screen: string, 
                              userName: string|null,
                              authData: AuthData|undefined|null
                             ) => {
    if(monitorAgent && userName && authData?.persona && authData?.email){
      monitorAgent.track('Location Viewed ', { location: screen });
      monitorAgent.people.set({ $name: userName, $persona: authData?.persona, $email: authData?.email });
      monitorAgent.identify(userName);
    }
};

