import React, {useState } from 'react';
import Axios, { CancelToken, CancelTokenSource } from 'axios';

import { PlusIconVuesax } from '../../../Icons/Plus-Vuesax';
import { AlertIconVuesax } from '../../../Icons/Alert-Vuesax';
import { RoleIcon } from '../../../Icons/Role';
import { EmailIcon } from '../../../Icons/Email';
import { CompanyIcon } from '../../../Icons/Company';
import { IconCloseVuesax } from '../../../Icons/Close-Vuesax';
import CompanySelect from '../../filters/CompanySelect';
import ProfileSelect from '../../filters/ProfileSelect';
import { inviteUsers } from '../../../services/api/TrackfyService/integration';
import { UserInvite } from '../types';

import * as S from './styles';

interface Props {
  closeModal: () => void;
  setUserSearched: (value: string) => void;
  setProfileSelected: (value: number) => void;
  setCompanySelected:  (value: number) => void;
  requestInvites: (token: CancelToken) => void;
}

const userInviteDefaultValue: UserInvite = {
  company: 0,
  profile: 0,
  email: '',
};

export const ModalInviteUser: React.FC<Props> = (props: Props) => {
  const { closeModal, requestInvites } = props; 
  const [invites, setInvites] = useState<UserInvite[]>([userInviteDefaultValue]);
  const [confirm, setConfirm] = useState(false);
  const [companyNames, setCompanyNames] = useState<string[]>([]);
  const [professionNames, setProfessionNames] = useState<string[]>([]);

  const handleChangeInvite = (index: number, key: keyof UserInvite, value: number | string) => {
    const localInvites = [...invites];
    localInvites.splice(index, 1, { ...localInvites[index], [key]: value });
    setInvites(localInvites);
  };

  const addInvite = () => {
    setInvites([...invites, userInviteDefaultValue]);
  };

  const changeCompanyName = (index: number, value: string) => {
    const localNames = [...companyNames];
    localNames.splice(index, 1, value);
    setCompanyNames(localNames);
  };

  const changeProfessionName = (index: number, value: string) => {
    const localNames = [...professionNames];
    localNames.splice(index, 1, value);
    setProfessionNames(localNames);
  };

  const removeInvite = (index: number) => {
    const localInvites = [...invites];
    localInvites.splice(index, 1);
    setInvites(localInvites);
  };

  const handleInvitation = async () => {
    await requestUsersInvitation();
    return closeModal();
  }

  const requestUsersInvitation = async() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    
    await inviteUsers(invites, currentRequest.token)
    .then(() => {
      requestInvites(currentRequest.token);
      //closeModal();
      //notification('success', 'Convite enviado com sucesso!');
    })
    .catch(() => {}/*notification('error', 'Ocorreu um erro ao enviar os convites')*/);
  }

  return (
    <S.ModalContent onClick={() => {}}>
      <S.ModalTitle>
        <PlusIconVuesax fill="#2F4CDD" width={20} height={20} />
        <span>Convidar usuário</span>
        <AlertIconVuesax />
      </S.ModalTitle>
      <S.ContentRow style={{ marginBottom: '-1.8rem' }}>
        <S.ContentCell>
          <S.CellValue>
            <EmailIcon /> Email
        </S.CellValue>
        </S.ContentCell>
        <S.ContentCell>
        <S.CellValue>
            <CompanyIcon />
            Empresa
        </S.CellValue>
        </S.ContentCell>
        <S.ContentCell>
        <S.CellValue>
            <RoleIcon />
            Perfil
        </S.CellValue>
        </S.ContentCell>
      </S.ContentRow>
      {invites.map((invite, index) => (
        <S.ContentRow key={`invite-${index}`}>
          <S.ContentCell>
            {confirm ? (
              <p style={{ paddingLeft: '2.4rem' }}>{invites[index].email}</p>
            ) : (
              <S.ContentInput
                value={invites[index].email}
                placeholder="Digite o email de seu convidado"
                onChange={(e) => handleChangeInvite(index, 'email', e.target.value) }
              />
            )}
          </S.ContentCell>
          <S.ContentCell>
            {confirm ? (
              <p style={{ paddingLeft: '2.4rem' }}>{companyNames[index]}</p>
            ) : (
              <CompanySelect
                companySelected={invites[index].company}
                onChange={(company) => {
                  handleChangeInvite(index, 'company', company.id);
                  changeCompanyName(index, company.name);
                }}
                setCompanySelected={() => {}}
              />
            )}
          </S.ContentCell>
          <S.ContentCell>
            {confirm ? (
              <p style={{ paddingLeft: '2.4rem' }}>{professionNames[index]}</p>
            ) : (
              <ProfileSelect
                profileSelected={invites[index].profile}
                onChange={(profile) => {
                  handleChangeInvite(index, 'profile', profile.id);
                  changeProfessionName(index, profile.name);
                }}
                setProfileSelected={(v) => {}}
              />
            )}
          </S.ContentCell>
          {invites.length === 1 ? <></> : (
            <>
              {confirm ? <></> : (
                <S.IconRemove>
                  <IconCloseVuesax width={32} height={32} onClick={() => removeInvite(index)}/>
                </S.IconRemove>
              )}
            </>
          )}
        </S.ContentRow>
      ))}

      {confirm ? (
        <S.ContentRow style={{ justifyContent: 'center', fontWeight: 'bold', fontSize: '2rem' }}>
          Deseja realmente convidar {invites.length > 1 ? 'esses usuários' : 'esse usuário'}?
        </S.ContentRow>
      ) : (
        <S.AddInviteButton onClick={addInvite}>Adicionar convite</S.AddInviteButton>
      )}

      <S.ActionButtons style={{ justifyContent: confirm ? 'center' : 'flex-end' }}>
        <S.ActionButton
          color="#2F4CDD"
          bgColor="transparent"
          disabled={false}
          onClick={() => {
            if (confirm) {
              return setConfirm(false);
            }
            closeModal();
          }}
        >
          Cancelar
        </S.ActionButton>
        <S.ActionButton
          bgColor="#2F4CDD"
          color="#FFFFFF"
          disabled={invites.some((invite) => !invite.email || !invite.company || !invite.profile)}
          onClick={() => {
            if (confirm) 
              handleInvitation();
            
            setConfirm(true);
          }}
        >
          {confirm ? 'Convidar' : 'Avançar'}
        </S.ActionButton>
      </S.ActionButtons>
    </S.ModalContent>
  );
};