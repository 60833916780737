import React, { createContext, ReactNode, useContext, useState } from 'react';
import { AxiosResponse, CancelToken } from 'axios';

import { ResultListItem } from '../components/Search/components';
import { getCompanies } from '../services/api/TrackfyService';
import { Company } from '../types/Filters';

type CompanyFilterContextData = {
  companies: Company[];
  selectedCompany: ResultListItem | null;
  areasLoading: boolean;
  fetchCompaniesData: (cancelToken?: CancelToken) => void;
  handleUpdateCompanies: (companies: Company[]) => void;
  filterCompanies: (search: string) => void;
  handleSetCompanyFilter: (item: ResultListItem | null) => void;
  setAreasLoading: (value: boolean) => void;
};

const CompanyFilterContext = createContext({} as CompanyFilterContextData);

type CompanyFilterProviderProps = {
  children: ReactNode;
};

export function CompanyFilterContextProvider({ children }: CompanyFilterProviderProps) {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<ResultListItem | null>(null);
  const [areasLoading, setAreasLoading] = useState<boolean>(false);
  const [collaboratorsLoading, setCollaboratorsLoading] = useState<boolean>(true);

  const fetchCompaniesData = async (cancelToken?: CancelToken) => {
    getCompanies(false, cancelToken)
      .then((result: AxiosResponse<any>) => {
        setCompanies(
          result.data.map((Company: Company) => {
            return {
              label: Company.name,
              value: Company.id ? Company.id.toString() : '0',
            };
          })
        );
      })
      .catch((err) => console.log(err));
  };

  const handleUpdateCompanies = (companies: Company[]) => {
    setCompanies(companies);
  };

  const filterCompanies = async (search: string) => {
    const filteredCompanies = companies.filter((companie: Company) =>
      companie.name.toUpperCase().includes(search.toUpperCase())
    );
    setCompanies(filteredCompanies);
  };

  const handleSetCompanyFilter = (item: ResultListItem | null) => {
    setSelectedCompany(item);
  };

  const value = {
    companies,
    selectedCompany,
    fetchCompaniesData,
    handleUpdateCompanies,
    filterCompanies,
    handleSetCompanyFilter,
    areasLoading,
    setAreasLoading,
    collaboratorsLoading,
    setCollaboratorsLoading,
  };

  return <CompanyFilterContext.Provider value={value}>{children}</CompanyFilterContext.Provider>;
}

export function useCompanyFilterContext() {
  const context = useContext(CompanyFilterContext);

  if (typeof context === 'undefined') {
    throw new Error('CompanyFilterContext must be used within an useCompanyFilterContext');
  }

  return context;
}
