import React from 'react';
import { AreasTimeDivisionContextProvider } from './context/AreasTimeDivisionContext';

import { Card, ChartSingleRow } from './styles';
import { AreasTimeDivisionChartContent } from './AreasTimeDivisionContent';

export const AreasTimeDivisionChart: React.FC = () => (
    <ChartSingleRow>
        <Card>
            <AreasTimeDivisionContextProvider>
                <AreasTimeDivisionChartContent/>
            </AreasTimeDivisionContextProvider>
        </Card>
    </ChartSingleRow>
  );
