import React, { useRef } from 'react';
import ScrollBar from 'react-custom-scrollbars';
import { ProgressBar } from '../../../../components/ProgressBar';
import { EmptyContainer } from '../../../../components/Table/styles';
import Incident from '../../../../Icons/Incident';
import { Displacement } from '../../types/types';


import * as S from '../styles';

type VerticalBarChartProps = {
  displacementChartData: Displacement[];
  color: string;
};

export const ProgressBarChart: React.FC<VerticalBarChartProps> = (props: VerticalBarChartProps) => {
  const ScrollbarsRef: React.MutableRefObject<any> = useRef<any>(null);
  const { displacementChartData, color } = props;

  if(displacementChartData.length == 0)
  return (
    <S.Empty>
      <EmptyContainer>
        <Incident />
        <span>Não há dados para o período selecinado</span>
      </EmptyContainer>
    </S.Empty>
  )

  return (
    <ScrollBar ref={ScrollbarsRef}>
      <S.ProgressBarChartContainer>
        {displacementChartData
          .map((item: Displacement) => (
            <S.BarContainer key={item.name}>
              <ProgressBar
                label={item.name}
                value={item.min}
                color={color}
              />
            </S.BarContainer>
          ))}
      </S.ProgressBarChartContainer>
    </ScrollBar>
  );
};
