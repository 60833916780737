import React, { createContext, ReactNode, useState, useContext } from 'react';

import { PaginationControls } from '../../../../../components/Buttons/PaginationControls';
import { InOut, TimeVisualization } from '../../../../indicators/types/Indicators';
import { SelectOption } from '../../../../../components/Select/types';

type ContextValue = {
  inOutChartData: InOut[];
  isLoading: boolean;
  inOutValues: Array<InOut[]>;
  areaSelected: SelectOption[];
  timeVisualization: TimeVisualization;
  setTimeVisualization: (timeVisualization: TimeVisualization) => void;
  setInOutChartData: (value:InOut[]) => void;
  setInOutValues: (arrayAvarageValues: Array<InOut[]>) => void;
  setIsLoading: (value: boolean) => void;
  setAreaSelected: (area: SelectOption[]) => void;
};

const InOutContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function InOutContextProvider(props: Props) {
  const { children } = props;
  const [ inOutValues, setInOutValues] = useState<Array<InOut[]>>([]);
  const [ inOutChartData, setInOutChartData] = useState<InOut[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [areaSelected, setAreaSelected] = useState<SelectOption[]>([]);
  const [yearValues, setYearValues] = useState<boolean>(false);
  const [monthValues, setMonthValues] = useState<boolean>(false);
  const [weekValues, setWeekValues] = useState<boolean>(false);
  const [timeVisualization, setTimeVisualization] = 
    useState<TimeVisualization>({ week:false, month:false, year: false });

  const value = {
    inOutChartData,
    inOutValues,
    isLoading,
    areaSelected,
    yearValues,
    monthValues,
    weekValues,
    setInOutChartData,
    setInOutValues,
    setIsLoading,
    setAreaSelected,
    timeVisualization,
    setTimeVisualization,
  };

  return (
    <InOutContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={ inOutValues}
        setNewValue={setInOutChartData}
        title="Chegada/saída"
      />
    </InOutContext.Provider>
  );
}

export function useInOutContext() {
  const context = useContext( InOutContext);

  if (typeof context === 'undefined') {
    throw new Error('InOutContext must be used within an  InOnContext');
  }

  return context;
}