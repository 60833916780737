import React from 'react';

import { ContentContainer } from '../../components/PageStructure/styles';
import DynamicComponentsFactory from '../../components/DynamicComponents/DynamicComponentsFactory';
import ConformityOptions from './ConformityOptions';
import { ConformityContextProvider } from './context/ConformityContext';
import { useAuthContext } from '../../stores';

const PageConformity: React.FC<{}> = () =>{
  const {authData} = useAuthContext();
  const charts = authData ? authData.components.charts : [];
  let timeLineComponent = <></>;
  let burnDownComponent = <></>;

  if(authData){
    timeLineComponent = (authData.clientId == 4 || authData.clientId == 11) ? 
      <DynamicComponentsFactory charts={charts} component='areaTimeline'/> :
      <DynamicComponentsFactory charts={charts} component='timeline'/>;
	  
	burnDownComponent = (authData.clientId == 14) ? 
		<DynamicComponentsFactory charts={charts} component='burndown'/> :
		<DynamicComponentsFactory charts={charts} component='resourcesBurndown'/>;
  }
  
  return (
  <ContentContainer value="">
    <ConformityContextProvider>
      <ConformityOptions />
      {burnDownComponent}
      {timeLineComponent}
    </ConformityContextProvider>
  </ContentContainer> 
  );
}

export default PageConformity;
