import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { SelectOption } from '../../components/Select/types';
import { Select } from '../../components/Select';
import { getMonitoredResources } from '../../services/api/TrackfyService';
import { MonitoredResources } from '../../types/Filters';
import { useAuth } from '../../hooks/useAuth';
import { useAuthContext } from '../../stores/AuthContext';
import { mixpanelTrack } from '../../services/SystemMonitor';

type MonitoredResourcesSelectProps = {
  label?: string;
  companySelected: number;
  professionSelected: number;
  monitoredResourcesSelected: number;
  setMonitoredResourcesSelected: (value: number) => void;
  screen?: string;
};

const MonitoredResourcesSelect: React.FC<MonitoredResourcesSelectProps> = (
  props: MonitoredResourcesSelectProps
) => {
  const [monitoredResources, setMonitoredResources] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getClientId, USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();
  const {
    monitoredResourcesSelected,
    setMonitoredResourcesSelected,
    companySelected,
    professionSelected,
    label,
    screen,
  } = props;

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);

      //getMonitoredResources(getClientId(), companySelected, professionSelected, currentRequest.token)
      getMonitoredResources(getClientId(), companySelected.toString(), professionSelected.toString(), currentRequest.token)
        .then((result: AxiosResponse<any>) => {
          setMonitoredResources(
            result.data.map((MonitoredResources: MonitoredResources) => {
              return {
                label: MonitoredResources.name,
                value: MonitoredResources.id ? MonitoredResources.id.toString() : '0',
              };
            })
          );
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('getMonitoredResourcessApi request canceled by the user.');
    };
  }, [companySelected, professionSelected]);

  const onChanging = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setMonitoredResourcesSelected(Number(event.target.value));

    const label = event.target.selectedOptions[0].label;

    if (label != 'Selecione um recurso')
      mixpanelTrack(monitorAgent, `${screen} - Recurso ${label} selecionado`, userName, authData);
  };

  return (
    <Select
      id="monitoredResources-select"
      label={label}
      placeholder="Selecione um recurso"
      options={monitoredResources}
      value={monitoredResourcesSelected}
      loading={isLoading}
      onChanging={(event) => onChanging(event)}
    />
  );
};

export default MonitoredResourcesSelect;
