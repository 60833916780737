import React from 'react';
import IconUsers from '../../../../../Icons/Users';

import * as S from '../styles';

export const RevenueFTEChartHeader: React.FC = () => {
  return (
    <S.Header>
      <S.Titlecontainer>
        <IconUsers />
        <S.Title>NDR Trimestral</S.Title>
      </S.Titlecontainer>
    </S.Header>
  );
};
