import React from 'react';

const IconWorker: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 46 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginBottom: '-4px' }}
  >
    <path
      d="M40.9995 18.7577V18.3752C41.0131 14.5478 39.7988 10.817 37.5349 7.73096C35.2709 4.6449 
32.0769 2.36638 28.422 1.23022H28.287C26.3993 0.654748 24.4325 0.381373 22.4595 0.420224C17.7611 
0.678056 13.3382 2.71916 10.0935 6.12696C6.84874 9.53475 5.02679 14.0523 4.99948 18.7577C3.681 
19.2215 2.53901 20.0832 1.73116 21.2238C0.923313 22.3643 0.489441 23.7275 0.489441 25.1252C0.489441 
26.5229 0.923313 27.8861 1.73116 29.0267C2.53901 30.1672 3.681 31.0289 4.99948 31.4927C5.4629 35.936 
7.56204 40.0481 10.8888 43.0297C14.2156 46.0114 18.5322 47.6493 22.9995 47.6252H23.517C27.8369 47.4161
31.943 45.6831 35.1064 42.7339C38.2699 39.7846 40.286 35.8099 40.797 31.5152C42.131 31.0776 43.2953 
30.235 44.1279 29.1046C44.9605 27.9742 45.42 26.6124 45.4423 25.2086C45.4645 23.8049 45.0486 22.4291 
44.2522 21.2728C43.4559 20.1166 42.3189 19.2374 40.9995 18.7577V18.7577ZM23.4045 43.1252C20.1474 
43.2194 16.9661 42.1324 14.4477 40.0648C11.9293 37.9971 10.2437 35.0883 9.70198 31.8752H36.1845C35.6353 
34.917 34.0739 37.6837 31.7538 39.7261C29.4338 41.7684 26.4913 42.9663 23.4045 43.1252ZM38.7495 
27.3752H7.24948C6.65274 27.3752 6.08044 27.1382 5.65849 26.7162C5.23653 26.2943 4.99948 25.722 
4.99948 25.1252C4.99948 24.5285 5.23653 23.9562 5.65849 23.5342C6.08044 23.1123 6.65274 22.8752 
7.24948 22.8752H11.7495C12.3462 22.8752 12.9185 22.6382 13.3405 22.2162C13.7624 21.7943 13.9995 
21.222 13.9995 20.6252C13.9995 20.0285 13.7624 19.4562 13.3405 19.0342C12.9185 18.6123 12.3462 
18.3752 11.7495 18.3752H9.49948C9.60019 16.0411 10.2673 13.7665 11.4433 11.7478C12.6193 9.72902 
14.2688 8.02671 16.2495 6.78772V13.8752C16.2495 14.472 16.4865 15.0443 16.9085 15.4662C17.3304 
15.8882 17.9027 16.1252 18.4995 16.1252C19.0962 16.1252 19.6685 15.8882 20.0905 15.4662C20.5124 
15.0443 20.7495 14.472 20.7495 13.8752V5.07772C21.3602 4.97569 21.9762 4.90808 22.5945 
4.87522H22.9995C23.7535 4.88584 24.5057 4.95353 25.2495 5.07772V13.8752C25.2495 14.472 25.4865 
15.0443 25.9085 15.4662C26.3304 15.8882 26.9027 16.1252 27.4995 16.1252C28.0962 16.1252 28.6685 
15.8882 29.0905 15.4662C29.5124 15.0443 29.7495 14.472 29.7495 13.8752V6.72022C30.7039 7.27288 31.5943 7.92937 32.4045 8.67772C33.7017 9.93749 34.7327 11.445 35.4361 13.1109C36.1396 14.7767 36.5012 16.5669 36.4995 18.3752H34.2495C33.6527 18.3752 33.0804 18.6123 32.6585 19.0342C32.2365 19.4562 31.9995 20.0285 31.9995 20.6252C31.9995 21.222 32.2365 21.7943 32.6585 22.2162C33.0804 22.6382 33.6527 22.8752 34.2495 22.8752H38.7495C39.3462 22.8752 39.9185 23.1123 40.3405 23.5342C40.7624 23.9562 40.9995 24.5285 40.9995 25.1252C40.9995 25.722 40.7624 26.2943 40.3405 26.7162C39.9185 27.1382 39.3462 27.3752 38.7495 27.3752Z"
      fill="#FFFFFF"
    />
  </svg>
);

export default IconWorker;
