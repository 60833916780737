import React from 'react';
import { DefaultIconProps } from './types';

export const AlertIconVuesax: React.FC<DefaultIconProps> = ({ fill, style, width, height }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M10.0003 18.3346C14.5837 18.3346 18.3337 14.5846 18.3337 10.0013C18.3337 5.41797 14.5837 1.66797 10.0003 1.66797C5.41699 1.66797 1.66699 5.41797 1.66699 10.0013C1.66699 14.5846 5.41699 18.3346 10.0003 18.3346Z"
        stroke={fill || '#B3B3B3'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6.66797V10.8346"
        stroke={fill || '#B3B3B3'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99512 13.332H10.0026"
        stroke={fill || '#B3B3B3'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
