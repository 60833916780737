import React from 'react';

import { useAreasContext } from '../context/AreasContext';
import DynamicComponentsFactory from '../../../components/DynamicComponents/DynamicComponentsFactory';
import { AuthData } from '../../../types';
import { useMapArea } from '../../../components/MonitoredMap/hooks/useMapArea';
import { MonitoredAreasCollaborators } from '../../../components/MonitoredMap/components/MonitoredAreasCollaborators';
import BraskCompanySelect from '../../filters/BraskCompanySelect';
import { useAuth } from '../../../hooks';

import * as S from '../styles';

interface RealTimeMapComponentsProps {
    authData: AuthData | null | undefined;
}

export const RealTimeMapComponents: React.FC<RealTimeMapComponentsProps> = ({authData}) => {
  const { setIndicator, projectSelected, setProjectSelected } = useAreasContext();
  const { getClientId } = useAuth();
  const { mapAreas } = useMapArea(true, "notevacuating");

  const summary = (
    <DynamicComponentsFactory charts={authData?.components.charts} component="summary" key="realTimeSummary" />
  );

  const projectFilter = getClientId() == 12 ? 
    <S.FilterContainer>
        <BraskCompanySelect projectSelected={projectSelected} setProjectSelected={setProjectSelected}/>
    </S.FilterContainer> : <></>

  return (
      <>
        {mapAreas.mapGeofences}
        <S.MonitoredAreasContainer>
            {<MonitoredAreasCollaborators setIndicator={setIndicator} 
                                          individual={false}
                                          mode={"notevacuating"} />}
        </S.MonitoredAreasContainer>
        {summary}
        {projectFilter}
     </>
    );
};
