import styled from 'styled-components';

export interface LabelProps {
  checked?: boolean;
}

export interface DetectiveProps {
  isManager?: boolean;
}

export interface FixedAraProps {
  color: string;
  top: string;
}

export interface EvacuationButtonProps {
  active: boolean;
  isMobile: boolean;
}

export const SwitchContainer = styled.div`
  display: flex;
  background: rgb(255, 255, 255);
  position: fixed;
  left: 80px;
  bottom: 40px;
  width: 210px;
  flex-direction: row;
  padding-top: 5px;
  border-radius: 20px;
  justify-content: space-around;
`;

export const SwitchLabel = styled.h3<LabelProps>`
  color: rgb(79, 88, 130);
  font-weight: ${(props) => (props.checked ? 'bold' : 'normal')};
  margin-left: 5px;
`;

export const MonitoredAreasContainer = styled.div`
  position: absolute;
  width: 20%;
  top: 18px;
  left: 40px;
`;

export const FilterContainer = styled.div`
  position: absolute;
  width: calc(40% - 60px);
  top: 18px;
  left: calc(20% + 60px);
`;

export const DetectiveContainer = styled.div<DetectiveProps>`
  position: absolute;
  width: calc(40% - 60px);
  // top: 18px;
  // top: 100px;
  top: ${(props) => (props.isManager ? '100px' : '18px')};
  /*left: calc(60% + 20px);*/
  right: 40px;
  display: flex;
  justify-content: end;
`;

export const HHIndicatorsContainer = styled.div`
  position: absolute;
  width: 20%;
  top: 18px;
  right: 40px;
`;

export const LoadingContainer = styled.div`
  width: '100%';
  height: '100vh';
  display: 'flex';
  justifycontent: 'center';
  alignitems: 'center';
`;

export const FixedAreaCard = styled.div<FixedAraProps>`
  position: absolute;
  width: 150px;
  height: 40px;
  top: ${(props) => props.top};
  left: 10px;
  background-color: #fff;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #4f5882;
  text-align: center;
  line-height: 40px;

  border-color: ${(props) => props.color};
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
`;

export const CraneAreaCard = styled.div`
  position: fixed;
  width: 400px;
  height: 50px;
  //top: 700px;
  top: 92%;
  left: 33%;
  //left: 450px;
  background-color: #fff;
  border-radius: 5px;
`;

export const CraneAreaContent = styled.div`
  display: flex;
  width: 100%;
  heght: 100%;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #4f5882;
  text-align: center;
  line-height: 50px;
`;

export const StyledLegend = styled.div`
  color: #eaa800;
  margin-right: 5px;
  margin-left: 10px;
`;
