import { MonitoringIcon } from '../../../../Icons/Monitoring';
import { CardHeader } from '../CardHeader';
import { MonitoredAreaCollaborators } from './MonitoredAreaCollaborators';
import { MonitoredAreas } from './MonitoredAreas';
import { MonitoredData } from './MonitoredData';
import { AreasContextProvider } from './context/AreasContext';
import { MonitoringContextProvider } from './context/MonitoringContext';
import * as S from './styles';
import React from 'react';

interface Props {}
export const MonitoringCard: React.FC<Props> = ({}) => {
  return (
    <S.Container>
      <CardHeader title="Monitoramento" icon={<MonitoringIcon />} subtitle="(Tempo real)" />
      <AreasContextProvider>
        <MonitoringContextProvider>
          <MonitoredData />
          <MonitoredAreas />
        </MonitoringContextProvider>
      </AreasContextProvider>
    </S.Container>
  );
};
