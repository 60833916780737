import React, { ReactElement } from 'react';
import { HHIndicators } from './components/HHIndicators';
import { CollaboratorsCount } from './components/CollaboratorsCount';
import { CurrentCollaboratorsCount } from './components/CurrentCollaboratorsCount';
import { Absenteeism } from './components/Absenteeism';
import { PlanningCollaboratorsCount } from './components/PlanningCollaboratorsCount';

type SummaryComponentsProps = {
    summaryComponents : string[] | undefined,
	clientId: number
};

export const SummaryComponentsFactory: React.FC<SummaryComponentsProps> = ({summaryComponents, clientId}: 
    SummaryComponentsProps) => {
    const children: ReactElement[] = [];

    if(summaryComponents)
        summaryComponents.filter((component:string) => component != "companyfilter")
            .forEach((summaryComponent:string) => {
            switch(summaryComponent){
                case 'absenteeism': {
					children.push(<Absenteeism key={'Absenteeism'} />);
                    break;
                }

                case 'colaborattorsCount': {
                    children.push(<CollaboratorsCount key={'CollaboratorsCount'} />);
                    break;
                }   

                case 'currentColaborattorsCount': {
                    children.push(<CurrentCollaboratorsCount key={'CurrentCollaboratorsCount'} />);
                    break;
                }

                case 'planningCollaboratorsCount': {
                    children.push(<PlanningCollaboratorsCount key={'PlanningCollaboratorsCount'} />);
                    break;
                }

                default: {
                    children.push(<HHIndicators key={'HHIndicators'} />);
                    break;
                }
            }
        });

    return <React.Fragment>{children}</React.Fragment>;
}