import { useCallback, useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import { useProductivityContext } from '../../../context/PageProductivityContext';
import { useInOutContext } from '../context/InOutContext';
import { InOut } from '../../../../indicators/types/Indicators';
import { getInOutChartData } from '../../../../../services/api/TrackfyService';
import moment from 'moment';
import { useAuth } from '../../../../../hooks';
import { parseSelectOptions } from '../../../../../utils/Convert';

export const useInOut = () => {
  const { 
    companySelected, 
    professionSelected, 
    initialDate, 
    finalDate
  } = useProductivityContext();
  const { setInOutValues, setInOutChartData, areaSelected, timeVisualization } = useInOutContext();
  const [isLoading, setIsLoading] = useState(true);
  const { getClientId } = useAuth();

  const dividerPerFive = useCallback(
    (base: InOut[]): Array<InOut[]> => {
      const divisor: number = 2;
      const newArray: Array<InOut[]> = [];

      for (let i = 0; i < base.length; i += divisor) {
        const divider: InOut[] = base.slice(i, i + divisor);
        newArray.push(divider);
      }
      return newArray;
    },
    []
  );

  const handleChartData = (data: InOut[]) => {
    if (data && data.length > 0) {
      batchedUpdates(() => {
        setInOutValues(dividerPerFive(data));
        setInOutChartData(dividerPerFive(data)[0]);
      });
    } else {
      setInOutChartData([{ name: "sem registros", inLabel: "0hrs", in: 0, outLabel: "0hrs", out: 0}]);
    }

    setIsLoading(false);
  };

  const exportDataToXlsx = (inOutValues: Array<InOut[]>): any[] => {
    let date: string[] = []
    let inValue: string[] = []
    let outValue: string[] = []

    inOutValues.forEach((data: InOut[]) => {
      data.map((i: { name: string; inLabel: string; outLabel: string; }) => (
        date.push(i.name),
        inValue.push(i.inLabel),
        outValue.push(i.outLabel)
      ))
    })

    const formattedData: any[] = [];
    formattedData.push(["Data", "Entrada", "Saída"])

    date.forEach((date, index) => {
        const value1 = inValue[index];
        const value2 = outValue[index];
        formattedData.push([date, value1, value2]);
    });

    return formattedData
  }

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);
      batchedUpdates(() => setInOutValues([]));

      getInOutChartData(
        getClientId(),
        parseSelectOptions(companySelected),
        parseSelectOptions(professionSelected),
        moment(initialDate).startOf('day').valueOf(),
        moment(finalDate).valueOf(),
        parseSelectOptions(areaSelected),
        timeVisualization.week,
        timeVisualization.month,
        timeVisualization.year,
        currentRequest.token
      )
        .then((result: AxiosResponse<any>) => handleChartData(result.data))
        .catch((err) => console.log(err));
    }
    return () => {
      mounted = false;
      currentRequest.cancel('InOutChart request canceled by the user.');
    };
  }, [companySelected, professionSelected,initialDate,finalDate, areaSelected, timeVisualization]);

  return {
    dividerPerFive,
    handleChartData,
    isLoading,
    setIsLoading,
    exportDataToXlsx,
  };
};
