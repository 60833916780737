import React from 'react';
import IconControll from '../../../../Icons/Controll';

import * as S from './styles';

type Props = {
  titulo: string;
  onClick: any;
  expanded: boolean;
};

export const CardHeader: React.FC<Props> = (props: Props) => {
  const { titulo, onClick, expanded } = props;

  return (
    <S.CardHeader>
      <S.IconExpandable onClick={onClick}>{expanded ? '-' : '+'}</S.IconExpandable>
      <S.CardTitle>{titulo}</S.CardTitle>
      {false && (
        <S.CardControl>
          <IconControll />
        </S.CardControl>
      )}
    </S.CardHeader>
  );
};
