import React from 'react';
import { useCollaboratorsCount } from './hooks/useCollaboratorsCount';

import * as S from './styles';

export const CollaboratorsCount: React.FC = () => {
  const { indicator } = useCollaboratorsCount();

  return (
    <S.Card>
      <S.CardHeader>Colaboradores Hoje: </S.CardHeader>
      <S.CardContent>{indicator.value}</S.CardContent>
    </S.Card>
  );
};
