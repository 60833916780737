import React, { useState } from 'react';

import { CardHeader } from '../CardHeader';
import { PageIcon } from '../../../../Icons/Page';
import { useAuthContext } from '../../../../stores';
import { pagesIcons } from './pages';
import { PagesCarousel } from './Carousel';

import * as S from './styles';
import { MenuPermission } from '../../../../types';

export const PagesCard: React.FC = () => {
  const [currentPageHovered, setCurrentPageHovered] = useState('');
  const { authData } = useAuthContext();
  const allPages = authData?.menu || undefined;
  
  if (!allPages) return <></>;

  return (
    <S.Container fullWidth>
      <CardHeader title="Paginas" icon={<PageIcon />} />
      <S.PagesContainer>
        {allPages.length <= 7 ? (
          allPages.filter((page: MenuPermission) => page.key != "telahome")
            .map((page, index, arr) => {
            const isCardHovered = currentPageHovered === page.key;
            return (
              <S.CardContainer
                style={{ width: `${100 / arr.length}%`, color: isCardHovered ? '#2f4cdd' : '#666666' }}
                key={page.key}
              >
                <S.PageCard
                  onMouseEnter={() => setCurrentPageHovered(page.key)}
                  onMouseLeave={() => setCurrentPageHovered('')}
                  href={page.path}
                >
                  {pagesIcons[page.icon]({
                    fill: isCardHovered ? '#ffffff' : '#666666',
                    style: {
                      width: '75%',
                      minWidth: '6.3rem',
                      minHeight: '7.2rem',
                    },
                  })}
                </S.PageCard>
                {page.description}
              </S.CardContainer>
            );
          })
        ) : (
          <PagesCarousel pages={allPages} />
        )}
      </S.PagesContainer>
    </S.Container>
  );
};
