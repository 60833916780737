import React from 'react';

import { IconLoading } from '../../../../../components/IconLoading';

import { CardAvgcontent } from './styles';

import { VerticalBarChart } from './components/VerticalBarChart';
import { useDeslocamentosPeContext } from './context/DeslocamentosPe';
import { DeslocamentosPeChartHeader } from './components/DeslocamentosPeChartHeader';
import { useDeslocamentosPe } from './hooks/UseDeslocamentoPe';

const DeslocamentosPeChart: React.FC = () => {
  const { deslocamentosPeChartData } = useDeslocamentosPeContext();
  const { isLoading } = useDeslocamentosPe();

  return (
    <>
      <DeslocamentosPeChartHeader />
      <CardAvgcontent>
        {isLoading && <IconLoading />}
        {!isLoading && <VerticalBarChart deslocamentosPeChartData={deslocamentosPeChartData} />}
      </CardAvgcontent>
    </>
  );
};

export default DeslocamentosPeChart;
