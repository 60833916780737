import styled from 'styled-components';

export interface TitleContainerProps {
  flexDirection?: 'row' | 'column';
  hiddenOnPreview?: boolean;
  marginTop?: string;
  justifyContent? : string;
}

export interface TimelinePointProps {
  count: number;
}

export const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2.4rem;
  @media print {
    padding-top: 7rem;
  }
`;

export const PreviewHeader = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-family: Work Sans;
  margin-bottom: 3.2rem;
  @media print {
    display: none;
  }
`;

export const HeaderTitle = styled.h1`
  font-size: 2.4rem;
  font-weight: 700;
  color: #2f4cdd;
  margin: 0;
`;

export const HeaderDate = styled.span`
  text-align: center;
  line-height: 2.4rem;
  font-weight: 500;
  font-size: 1.4rem;
  color: #808080;
`;

export const PreviewContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Work Sans;
  width: 100%;
  gap: 3.2rem;
`;

export const TitleContainer = styled.div<TitleContainerProps>`
  display: ${({ hiddenOnPreview }) => (hiddenOnPreview ? 'none' : 'flex')};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  @media print {
    display: flex !important;
    margin-top: ${({ marginTop }) => marginTop || '10rem'};
  }
`;

export const ContentTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: #808080;
`;
export const ContentSubtitle = styled.p`
  color: #808080;
  font-size: 1.2rem !important;
  font-weight: 400;
`;

export const TimelineContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  @media print {
    //margin-bottom: 10rem;
  }
`;

export const TimelineCount = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  color: #008ae5;
  & span {
    font: normal 500 1.2rem/1.5 Work Sans, sans-serif;
    color: #008ae5;
  }
`;

export const TimelineLine = styled.span`
  background: #999999;
  width: 100%;
  height: 2px;
  display: flex;
  flex-grow: 1;
`;

export const TimelineData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  flex-grow: 1;
  & div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TimelinePoint = styled.span<TimelinePointProps>`
  display: block;
  width: 10px;
  height: 7px;
  border-radius: 100%;
  background: ${({ count }) => (count ? '#008ae5' : '#999999')};
  margin: 0 4px;
`;

export const TimelineHours = styled.span`
  margin-top: 5px;
  color: #999999;
  font: normal 500 0.8rem/1.5 Work Sans, sans-serif;
`;

export const PreviewInOutHeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: Work Sans;
  & h1 {
    font-size: 1.6rem;
    font-weight: 700;
    color: #808080;
    & span {
      font-weight: 500;
      color: #2f4cdd;
    }
  }
`;

export const TitleRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;