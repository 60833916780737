import React, { useEffect } from 'react';
import { Modal } from '../../../../components/Modal';
import { useAuth } from '../../../../hooks';
import IconClock from '../../../../Icons/Clock';
import IconLocation from '../../../../Icons/Location';
import IconReason from '../../../../Icons/Reason';
import IconTickCircle from '../../../../Icons/TickCircle';
import IconUsers from '../../../../Icons/Users';
import { mixpanelTrack } from '../../../../services/SystemMonitor';
import { useAuthContext } from '../../../../stores';

import * as S from './styles';
import { FileIconVuesax } from '../../../../Icons/File-Vuesax';

type IncidentDetailsProps = {
  isDetails: boolean;
  onClickToClose(): void;
  collaborator: string;
  place: string;
  timestamp: string;
  reason: string;
  id?: string;
  comment?: string;
  details?: string;
  solvedBy?: string;
  date?: string;
};

export const IncidentDetails: React.FC<IncidentDetailsProps> = ({
  isDetails,
  onClickToClose,
  collaborator,
  place,
  timestamp,
  reason,
  comment,
  solvedBy,
  date,
}: IncidentDetailsProps) => {
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  useEffect(
    () => mixpanelTrack(monitorAgent, 'Log de Incidentes - Detalhes dos incidentes', userName, authData),
    []
  );

  return (
    <Modal visible={isDetails}>
      <S.ResolveIncidentContainer>
        <S.ResolveIncidentHeaderTitleContainer>
          <S.ResolveIncidentHeaderLabel>
            <FileIconVuesax />
            Detalhes do incidente
          </S.ResolveIncidentHeaderLabel>
        </S.ResolveIncidentHeaderTitleContainer>
        <S.ResolveIncidentContent>
          <S.PrimaryDetailsContainer>
            <S.DetailItem>
              <IconUsers fill="#808080" />
              <S.ContentDetailItem>  
                <S.Title>Colaborador</S.Title>
                <S.Text>{collaborator}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
            <S.DetailItem>
              <IconLocation stroke="#808080" />
              <S.ContentDetailItem>
                <S.Title>Local</S.Title>
                <S.Text>{place}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
            <S.DetailItem>
              <IconClock stroke="#808080" />
              <S.ContentDetailItem>
                <S.Title>Horário da emergência</S.Title>
                <S.Text>{timestamp}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
            <S.DetailItem>
              <IconReason stroke="#808080" />
              <S.ContentDetailItem>
                <S.Title>Motivo do acionamento</S.Title>
                <S.Text>{reason}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
          </S.PrimaryDetailsContainer>

          <S.SecondaryDetailsContainer>
            <S.DetailItem>
              <IconClock stroke="#808080" />
              <S.ContentDetailItem>
                <S.Title>Horário de registro da resolução</S.Title>
                <S.Text>{date}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
            <S.DetailItem>
              <IconTickCircle fill="#808080" />
              <S.ContentDetailItem>
                <S.Title>Resolvido Por</S.Title>
                <S.Text>{solvedBy}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
          </S.SecondaryDetailsContainer>

          <S.CommentContainer>
            <S.CommentHeader>
              <S.Title>Comentário</S.Title>
            </S.CommentHeader>
            <S.TextArea disabled={true}>{comment}</S.TextArea>
          </S.CommentContainer>
          <S.ButtonsContainer>
            <S.CancelButtonContainer>
              <S.CloseButton
                title="Fechar"
                onClick={onClickToClose}
                //disabled={resolvingIncident}
                //loading={resolvingIncident}
              />
            </S.CancelButtonContainer>
          </S.ButtonsContainer>
        </S.ResolveIncidentContent>
      </S.ResolveIncidentContainer>
    </Modal>
  );
};
