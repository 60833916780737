import React from 'react';

import IconChevronLeft from '../../../Icons/ChevronLeft';
import { PaginationControlsProps, usePaginationControl } from './hooks/usePaginationControl';
import * as S from './styles';

export const PaginationControls = (props: PaginationControlsProps) => {
  const { arrayValues, setNewValue, title } = props;

  const { handleClick, dotActive } = usePaginationControl({ arrayValues, setNewValue, title });

  return arrayValues?.[dotActive] && arrayValues.length > 1 ? (
    <S.Container>
      <S.Button onClick={() => handleClick('left')} disabled={dotActive === 0}>
        <IconChevronLeft />
      </S.Button>
      {/* arrayValues.slice(startDot, lastDot).map((i, index) => (
        <Dots key={i[dotActive]?.name} dotActive={index + startDot === dotActive} />
      )) */}
      <S.Button
        onClick={() => handleClick('right')}
        disabled={dotActive === arrayValues.length - 1}
      >
        <IconChevronLeft />
      </S.Button>
    </S.Container>
  ) : (
    <></>
  );
};
