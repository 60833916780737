import React from 'react';

import { ClientList } from './ClientsList';

import * as S from '../styles';

export const ClientRegistrationContainer: React.FC = () => (
  <S.Content>
    <ClientList />
  </S.Content>
);
