import React, { createContext, ReactNode, useState, useContext } from 'react';
import { PaginationControls } from '../../../../components';
import { ProductivityVSDisplacement, TimeVisualization } from '../../types/types';




type ContextValue = {
  prodVSDisChartData: ProductivityVSDisplacement[];
  isLoading: boolean;
  prodVSDisValues: Array<ProductivityVSDisplacement[]>;
  areaSelected: number;
  timeVisualization: TimeVisualization;
  setTimeVisualization: (timeVisualization: TimeVisualization) => void;
  setProdVSDisChartData: (value:ProductivityVSDisplacement[]) => void;
  setProdVSDisValues: (arrayAvarageValues: Array<ProductivityVSDisplacement[]>) => void;
  setIsLoading: (value: boolean) => void;
  setAreaSelected: (area: number) => void;
};

const ProductivityVSDisplacementContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function ProductivityVSDisplacementContextProvider(props: Props) {
  const { children } = props;
  const [ prodVSDisValues, setProdVSDisValues] = useState<Array<ProductivityVSDisplacement[]>>([]);
  const [ prodVSDisChartData, setProdVSDisChartData] = useState<ProductivityVSDisplacement[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [areaSelected, setAreaSelected] = useState<number>(0);
  const [timeVisualization, setTimeVisualization] = 
    useState<TimeVisualization>({ week:false, month:false, year: false });

  const value = {
    prodVSDisChartData,
    prodVSDisValues,
    isLoading,
    areaSelected,
    setProdVSDisChartData,
    setProdVSDisValues,
    setIsLoading,
    setAreaSelected,
    timeVisualization,
    setTimeVisualization,
  };

  return (
    <ProductivityVSDisplacementContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={prodVSDisValues}
        setNewValue={setProdVSDisChartData}
        title="Produtividade VS Deslocamento"
      />
    </ProductivityVSDisplacementContext.Provider>
  );
}

export function useProductivityVSDisplacementContext() {
  const context = useContext(ProductivityVSDisplacementContext);

  if (typeof context === 'undefined') {
    throw new Error('ProductivityVSDisplacementContext must be used within an ProductivityVSDisplacementContext');
  }

  return context;
}