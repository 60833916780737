import styled from 'styled-components';

export const DisplacementCardContainer = styled.div`
  margin-top: 29px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Titlecontainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;

  & > svg {
    margin-right: 1.6rem;
    margin-top: 0.3rem;
  }
`;

export const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0.6rem;
`;

export const SummaryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
  justify-content: space-between;

  @media (max-width: 1280px) {
    &&& {
      gap: 3rem;
    }
  }
`;

export const RankSummaryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
`;

export const RankDescription = styled.span`
  font: normal 500 1.4rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 1.5rem;

  @media (max-width: 576px) {
    &&& {
      font-size: 1.2rem;
    }
  }
`;

export const AverageDescription = styled.span`
  font: normal 500 1.4rem/1 Work Sans, sans-serif;
  color: #4f5882;
  //margin-bottom: 1.5rem;
  margin-bottom: 27px;

  @media (max-width: 576px) {
    &&& {
      font-size: 1.2rem;
    }
  }
`;

//Rank ProgressBarChart
export const ProgressBarChartContainer = styled.div`
  width: 100%;
`;

export const BarContainer = styled.div``;

//RankLabel
export const RankLabelContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  gap: 9px;
  margin-bottom: 2rem;
`;

export const RankLabelValue = styled.div`
  font: normal 600 4rem/1.5 Work Sans, sans-serif;
  color: #4f5882;

  @media (max-width: 576px) {
    &&& {
      font-size: 3.5rem;
    }
  }
`;

export const Empty = styled.div`
  display: flex;
  justfy-content: center;
  align-items: center;
  height: 100%;
`
