import React from 'react';
import styled from 'styled-components';

import IconUsers from '../../../Icons/Users';
import { useAuth } from '../../../hooks';
import { useAuthContext } from '../../../stores';
import DynamicComponentsFactory from '../../../components/DynamicComponents/DynamicComponentsFactory';
import { Info } from '../../../components/Info';
import { getTile, getTooltipContent } from './BurndownChartDynamicContent';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin-top: 0.9rem;
  margin-bottom: 2rem;
`;

const Titlecontainer = styled.div`
  display: flex;
  align-items: center;

  /*& > svg {
    margin-right: 1.6rem;
  }*/

  gap: 1.6rem;
`;

const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0;
`;

const BurndownChartHeader: React.FC = () => {
  const {authData} = useAuthContext();
  const charts = authData ? authData.components.charts : [];
  const { getClientId } = useAuth();
  const clientId = getClientId();
  const title = getTile(clientId);
  const tooltipContent = getTooltipContent(clientId);

  return (
    <Header>
      <Titlecontainer>
        <IconUsers />
        <Title>{title}</Title>
        <Info content={tooltipContent} position='right' burndown={true}/>
      </Titlecontainer>
      <DynamicComponentsFactory charts={charts} component='burndownExport'/>
    </Header>
  );
};

export default BurndownChartHeader;
