import { useCallback, useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import moment from 'moment';

import { useProductivityContext } from '../../../context/PageProductivityContext';
import { useAverageTimeContext } from '../context/AverageTimeContext';
import { AverageTimeByPeople } from '../../../../indicators/types/Indicators';
import { getAverageTimeChartData } from '../../../../../services/api/TrackfyService';
import { useAuth } from '../../../../../hooks';
import { parseSelectOptions } from '../../../../../utils/Convert';

export const useAverageTime = () => {
  const { companySelected, professionSelected, initialDate, finalDate } = useProductivityContext();
  const { areaSelected, setAverageValues, setAverageTimeChartData } = useAverageTimeContext();
  const [isLoading, setIsLoading] = useState(true);
  const { getClientId } = useAuth();
  const clientId: number = getClientId();

  const dividerPerFive = useCallback(
    (base: AverageTimeByPeople[]): Array<AverageTimeByPeople[]> => {
      const divisor: number = 5;
      const newArray: Array<AverageTimeByPeople[]> = [];

      for (let i = 0; i < base.length; i += divisor) {
        const divider: AverageTimeByPeople[] = base.slice(i, i + divisor);
        newArray.push(divider);
      }
      return newArray;
    },
    []
  );

  const handleChartData = (data: AverageTimeByPeople[]) => {
    if (data && data.length > 0) {
      batchedUpdates(() => {
        setAverageValues(dividerPerFive(data));
        setAverageTimeChartData(dividerPerFive(data)[0]);
      });
    } else {
      setAverageTimeChartData([{ value: 0, label: '0hrs', name: 'Sem Registros' }]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);
      batchedUpdates(() => setAverageValues([]));

      getAverageTimeChartData(
        clientId,
        parseSelectOptions(companySelected),
        parseSelectOptions(professionSelected),
        parseSelectOptions(areaSelected),
        0,
        moment(initialDate).startOf('day').valueOf(),
        moment(finalDate).valueOf(),
        currentRequest.token,
      )
        .then((result: AxiosResponse<any>) => handleChartData(result.data))
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('AverageTimeChart request canceled by the user.');
    };
  }, [companySelected, professionSelected, initialDate, finalDate, areaSelected]);

  return {
    dividerPerFive,
    handleChartData,
    isLoading,
    setIsLoading,
  };
};
