import React, { useEffect } from 'react';
import { Modal } from '../../../../components/Modal';
import { Textarea } from '../../../../components/Textarea';
import { useAuth } from '../../../../hooks';
import IconClock from '../../../../Icons/Clock';
import IconLocation from '../../../../Icons/Location';
import IconReason from '../../../../Icons/Reason';
import IconTickCircle from '../../../../Icons/TickCircle';
import IconUsers from '../../../../Icons/Users';
import { mixpanelTrack } from '../../../../services/SystemMonitor';
import { useAuthContext } from '../../../../stores';
import { useSolveIncident } from './hooks/useResolveIncident';

import * as S from './styles';

type SolveIncidentProps = {
  isSolving: boolean;
  onClickToClose(): void;
  onClickToSolve(): void;
  collaborator: string;
  place: string;
  timestamp: string;
  reason: string;
  details?: string;
  id?: string;
};

export const SolvingIcident: React.FC<SolveIncidentProps> = ({
  isSolving,
  onClickToClose,
  collaborator,
  place,
  timestamp,
  reason,
  id,
  onClickToSolve,
  details,
}: SolveIncidentProps) => {
  const { solvingIncident, handleSolveIncident, comment, handleCommentChange, characterCount, isDefined } =
    useSolveIncident(id);
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  useEffect(
    () => mixpanelTrack(monitorAgent, 'Log de Incidentes - Abriu popup para incidente', userName, authData),
    []
  );
  
  return (
    <Modal visible={isSolving}>
      <S.SolveIncidentContainer>
        <S.SolveIncidentHeaderTitleContainer>
          <IconTickCircle />
          <S.SolveIncidentHeaderLabel>Resolver incidente</S.SolveIncidentHeaderLabel>
        </S.SolveIncidentHeaderTitleContainer>
        <S.SolveIncidentContent>
          <S.DetailsContainer>
            <S.DetailItem>
              <IconUsers />
              <S.ContentDetailItem>
                <S.Title>Colaborador</S.Title>
                <S.Text>{collaborator}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
            <S.DetailItem>
              <IconLocation stroke="#808080" />
              <S.ContentDetailItem>
                <S.Title>Local</S.Title>
                <S.Text>{place}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
            <S.DetailItem>
              <IconClock stroke="#808080" />
              <S.ContentDetailItem>
                <S.Title>Horário da emergência</S.Title>
                <S.Text>{timestamp}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
            <S.DetailItem>
              <IconReason stroke="#808080" />
              <S.ContentDetailItem>
                <S.Title>Motivo do acionamento</S.Title>
                <S.Text>{reason}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
          </S.DetailsContainer>

          <S.CommentContainer>
            <S.CommentHeader>
              <S.Title>Comentário</S.Title>
              <S.Text>(obrigatório)</S.Text>
            </S.CommentHeader>
            <S.TextArea>
              <Textarea
                placeholder="Escreva como o incidente foi resolvido..."
                maxLength={500}
                //disabled={loadingComments || savingComment}
                value={comment}
                onChange={(value) => handleCommentChange(value)}
              />
            </S.TextArea>
            <S.CharacterCounter>{characterCount}/500</S.CharacterCounter>
          </S.CommentContainer>
          <S.ButtonsContainer>
            <S.CancelButtonContainer>
              <S.CloseButton
                title="Cancelar"
                onClick={onClickToClose}
                disabled={solvingIncident}
                loading={solvingIncident}
              />
            </S.CancelButtonContainer>
            <S.SolveButtonContainer>
              <S.SolveButton
                title="Resolver"
                disabled={!isDefined()}
                loading={solvingIncident}
                onClick={async () => {
                  await handleSolveIncident();
                  onClickToSolve();
                }}
              />
            </S.SolveButtonContainer>
          </S.ButtonsContainer>
        </S.SolveIncidentContent>
      </S.SolveIncidentContainer>
    </Modal>
  );
};
