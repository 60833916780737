import styled from 'styled-components';

interface Props {
  expanded: boolean;
}

export const CardHeader = styled.div`
  display: flex;
  height: 60px;
  width: 100%;
`;
export const IconExpandable = styled.span`
  width: 10px;
  height: 10px;
  margin-left: 32px;
  margin-top: 19px;
  cursor: pointer;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #2f4cdd;
`;
export const CardTitle = styled.div`
  margin-left: 95px;
  margin-top: 19px;
  width: auto;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #4f5882;
`;

export const CardControl = styled.div`
  width: 19px;
  height: 19px;
  margin-left: 285px;
  margin-top: 19px;
  cursor: pointer;
  opacity: 0.24;
`;

export const IndicatorsExpandableCard = styled.div`
  width: 100%;
  height: 60px;
  max-height: 438px;
  display: flex;
  overflow: hidden;
  background: #ffffff;
  border: 0.5px solid #ebebf9;
  box-sizing: border-box;
  box-shadow: 8px 8px 16px 4px rgba(80, 94, 165, 0.13);
  border-radius: 10px;
  z-index: 999;
  justify-content: center;
`;

export const MonitoringIndicatorsContainer = styled.div`
  position: absolute;
  width: 25%;
  display: grid;
  gap: 18px;
  right: 40px;
  top: 18px;
`;