import React from 'react';
import { DefaultIconProps } from './types';

export const MessageQuestionIcon: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M51 55.2892H39L25.65 64.1691C23.67 65.4891 21 64.0793 21 61.6793V55.2892C12 55.2892 6 49.2892 6 40.2892V22.2891C6 13.2891 12 7.28906 21 7.28906H51C60 7.28906 66 13.2891 66 22.2891V40.2892C66 49.2892 60 55.2892 51 55.2892Z"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.0004 34.0781V33.4482C36.0004 31.4082 37.2605 30.3282 38.5205 29.4582C39.7505 28.6182 40.9803 27.5382 40.9803 25.5582C40.9803 22.7982 38.7604 20.5781 36.0004 20.5781C33.2404 20.5781 31.0205 22.7982 31.0205 25.5582"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9865 41.25H36.0135"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
