import React from 'react';

import Incident from '../../Icons/Incident';
import { IconLoading } from '../IconLoading';
import { Tbody, EmptyContainer } from './styles';
import TableRow from './TableRow';

type TableBodyProps = {
  tableData: Array<string[]>;
  loading: boolean;
  isEmpty: boolean;
  height?: string;
  backgroundColors?: string[];
  emptyMessage?: string;
};

const TableBody: React.FC<TableBodyProps> = (props: TableBodyProps) => {
  const { tableData, loading, isEmpty, height, backgroundColors, emptyMessage } = props;

  if (loading)
    return (
      <Tbody>
        <tr>
          <td width="100%" height="100%" colSpan={100}>
            <IconLoading />
          </td>
        </tr>
      </Tbody>
    );

  if (isEmpty)
    return (
      <Tbody>
        <tr>
          <td width="100%" height="100%" colSpan={100}>
            <EmptyContainer>
              <Incident />
              <span>{emptyMessage ?? "Não há dados para o período selecinado"}</span>
            </EmptyContainer>
          </td>
        </tr>
      </Tbody>
    );

  return (
    <Tbody>
      {
        tableData.map((item: string[], index: number) => {
          const backgroundColor = backgroundColors ? backgroundColors[index] : '';
          const even = (index % 2 === 0);
          return <TableRow key={'TBRow_' + index} 
                           even={even} 
                           item={item} 
                           index={index} 
                           background={backgroundColor}
                           tableHeight={height ?? '22px'} />
        })
      }
    </Tbody>
  );
};

export default TableBody;
