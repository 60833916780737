import React from 'react';

import LogoNegative from '../../../../../assets/brand/LogoNegative';
import IconOutlineWhite from '../../../../../Icons/brand/IconOutlineWhite';
import IconMenu from '../../../../../Icons/Menu';
import MenuHamburger from '../../../../../Icons/MenuHamburger';
import { useLayoutMenuContext } from '../../../context/LayoutMenuContext';
import { Header, IconCollapse } from './styles';

export const SideBarHeader: React.FC = () => {
  const { onCollapse, collapsed } = useLayoutMenuContext();
  return (
    <Header className={collapsed ? 'collapsed' : ''}>
      <IconCollapse>
        {collapsed ? <MenuHamburger onClick={onCollapse} /> : <IconMenu onClick={onCollapse} />}
      </IconCollapse>
      {collapsed ? <IconOutlineWhite /> : <LogoNegative />}
    </Header>
  );
};
