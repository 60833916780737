import moment from 'moment';
import React, { createContext, ReactNode, useState, useContext } from 'react';
import { useAuthContext } from '../../../stores/AuthContext';

type Props = {
  children: ReactNode;
};

type DisplacementContextProps = {
  companySelected: number;
  professionSelected: number;
  initialDate: any;
  finalDate: any;
  setCompanySelected: (companie: number) => void;
  setProfessionSelected: (profession: number) => void;
  updatePeriod: (initial: any, final: any) => void;
};

const DisplacementContext = createContext<DisplacementContextProps>({} as DisplacementContextProps);

export function DisplacementContextProvider(props: Props) {
  const { children } = props;

  const {authData} = useAuthContext();
  const [initialDate, setInitialDate] = useState<any>(moment());
  const [finalDate, setFinalDate] = useState<any>(moment());
  const [professionSelected, setProfessionSelected] = useState(0);

  const companyId = (authData && authData.grpCompanies != 'a' && (!authData.grpCompanies.includes(","))) ?
    authData.companyId : 0;

  const [companySelected, setCompanySelected] = useState<number>(companyId);

  const updatePeriod = (initial: any, final: any) => {
    setInitialDate(initial);
    setFinalDate(final);
  };

  const value = {
    companySelected,
    professionSelected,
    initialDate,
    finalDate,
    setCompanySelected,
    setProfessionSelected,
    updatePeriod,
  };
  return <DisplacementContext.Provider value={value}>{children}</DisplacementContext.Provider>;
}

export function useDisplacementContext() {
  const context = useContext(DisplacementContext);

  if (typeof context === 'undefined') {
    throw new Error('DisplacementContext must be used within an useDisplacementContext');
  }

  return context;
}