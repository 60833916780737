import { AxiosResponse, CancelToken } from 'axios';

import { getConfig } from '../../../config/app.config';
import {
  Burndown,
  AreaTimeline,
  CollaboratorsCount,
  Timeline,
} from '../../../containers/indicators/types/Indicators';
import { MachinesSummaryData } from '../../../containers/machinesSummary/types/MachinesSummaryTypes';
import { DashboardInOutData, DashboardWorkingPTData, MonitoredAreasInEmergencyData, RealTimeLocations } from '../../../types/MonitoredData';
import { IHeatmapArea } from '../../../containers/moviment/HeatMap/types/HeatMapData';
import { ConfigKey } from '../../../enum/ConfigKey';
import * as integration from './integration';
import * as mock from './mock';
import { Feedbacks } from '../../../containers/insights/types/Insights';
import { TextInsightsMessages } from '../../../containers/textInsights/types/TextInsights';
import {
  AddCollaborators,
  DeleteCollaborators,
  LinkCollaboratos,
} from '../../../containers/linkCollaborator/types/linkCollaborators';

import { Incidents, SolveIncidents } from '../../../containers/incidents/types/Incidents';
import {
  CreateArea,
  CreatedClients,
  CreatedCompany,
} from '../../../containers/clientCompanyRegistration/types/ClientCompany';
import { ScannertStatus } from '../../../containers/scannerStatus/types';
import { UserInvite } from '../../../containers/users/types';

const selectedModule = getConfig(ConfigKey.REACT_APP_USE_MOCKS) === 'true' ? mock : integration;

export type CoordinatesResponse = {
  id: number;
  hasMap: boolean;
  latitude: string;
  longitude: string;
  zoom: string;
};

export type TimelineData = {
  companies: string;
  professions: string;
  areas: string;
  initialDate: number;
  finalDate: number;
  currentDate: number;
  cancelToken: CancelToken;
  trackfy?: boolean;
};

export type GetAreas = (
  cancelToken: CancelToken,
  excludeImproductZone?: boolean,
  order?: boolean
) => Promise<AxiosResponse<any>>;

export type GetAverageTimeChartData = (
  clientId: number,
  companies: string,
  professions: string,
  areas: string,
  collaboratorId: number,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken,
  trackfy?: boolean
) => Promise<AxiosResponse<any>>;

export type GetCompanies = (operational: boolean, cancelToken?: CancelToken) => Promise<AxiosResponse<any>>;

export type GetHeatMapData = (
  clientId: number,
  companyId: number,
  professionId: number,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
) => Promise<IHeatmapArea[]>;

export type GetHHIndicators = (
  clientId: number,
  companyId: string,
  cancelToken?: CancelToken
) => Promise<AxiosResponse<any>>;

export type GetMachinesSummary = (cancelToken?: CancelToken) => Promise<MachinesSummaryData>;

export type GetMapCoordinates = (cancelToken: CancelToken) => Promise<CoordinatesResponse>;

export type GetMonitoredResources = (
  clientId: number,
  companyId: string,
  professionId: string,
  cancelToken?: CancelToken
) => Promise<AxiosResponse<any>>;

export type GetPeopleInAreasChartData = (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken,
  trackfy?: boolean
) => Promise<AxiosResponse<any>>;

export type GetProductivityByAreaData = (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken,
  trackfy?: boolean
) => Promise<AxiosResponse<any>>;

export type GetProfessions = (cancelToken?: CancelToken) => Promise<AxiosResponse<any>>;

export type GetProfiles = (cancelToken?: CancelToken) => Promise<AxiosResponse<any>>;

export type GetInvites = (
  profileSelected: number,
  companySelected: number,
  userSearched: string,
  cancelToken?: CancelToken
) => Promise<AxiosResponse<any>>;

export type InviteUsers = (invites: UserInvite[], cancelToken?: CancelToken) => Promise<void>;

export type CancelInvite = (inviteId: number, cancelToken?: CancelToken) => Promise<void>;

export type GetRankChartData = (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken,
  trackfy?: boolean
) => Promise<AxiosResponse<any>>;

export type GetTimelineData = (clientId: number, data: TimelineData) => Promise<Timeline[]>;

export type GetAreaTimelineData = (clientId: number, data: TimelineData) => Promise<AreaTimeline[]>;

export type PutMachineStatus = (machineCod: string, status: string, cancelToken?: CancelToken) => Promise<void>;

export type GetMonitoredAreasInEmergency = (
  clientId: number,
  cancelToken: CancelToken
) => Promise<MonitoredAreasInEmergencyData[]>;

export type GetMonitoredFloor = (
  clientId: number,
  cancelToken: CancelToken,
  areaId: number,
  companyId: number
) => Promise<MonitoredAreasInEmergencyData>;

export type GetRetiradaPT = (companyId: number, cancelToken: CancelToken) => Promise<AxiosResponse<any>>;

export type GetLunch = (companyId: number, cancelToken: CancelToken) => Promise<AxiosResponse<any>>;

export type GetAverageTimeInsights = (
  companyId: number,
  areaId: number,
  cancelToken: CancelToken
) => Promise<AxiosResponse<any>>;

export type GetChegadaSaida = (
  companyId: number,
  areaId: number,
  cancelToken: CancelToken
) => Promise<AxiosResponse<any>>;

export type GetFeedbacks = (companyId: number, cancelToken: CancelToken) => Promise<Feedbacks[]>;

export type GetEmergencyNotifications = (clientId: number, cancelToken: CancelToken) => Promise<any[]>;

export type GetAgileanData = (
  companyId: number,
  professionId: number,
  areaId: number,
  activityId: string,
  initialDate: number,
  finalDate: number,
  hours: number,
  cancelToken: CancelToken
) => Promise<Burndown>;

export type GetAgileanCurves = (
  companyId: number,
  professionId: number,
  areaId: number,
  activityId: string,
  initialDate: number,
  finalDate: number,
  hours: number,
  plan: number,
  cancelToken: CancelToken
) => Promise<Burndown>;

export type GetActivities = (areaId: number, cancelToken: CancelToken) => Promise<AxiosResponse<any>>;

export type GetWorkingPermitChartData = (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  week: boolean,
  month: boolean,
  year: boolean,
  cancelToken: CancelToken
) => Promise<AxiosResponse<any>>;

export type GetInOutChartData = (
  clientId: number,
  companies: string,
  professions: string,
  initialDate: number,
  finalDate: number,
  areas: string,
  week: boolean,
  month: boolean,
  year: boolean,
  cancelToken: CancelToken
) => Promise<AxiosResponse<any>>;

export type GetCollaboratorsCount = (
  clientId: number,
  companyId: string,
  currentDate: string,
  cancelToken: CancelToken
) => Promise<CollaboratorsCount[]>;

export type GetPlanningCollaboratorsCount = (
  companyId: number,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
) => Promise<CollaboratorsCount[]>;

export type GetTextInsightsMessages = (
  companyId: number,
  professionId: number,
  areaId: number,
  collaboratorId: number,
  clientId: number,
  cancelToken: CancelToken
) => Promise<TextInsightsMessages[]>;

export type GetClients = (cancelToken?: CancelToken, omitCivil?: boolean) => Promise<AxiosResponse<any>>;

export type GetLinkedTags = (
  companyId: number,
  professionId: number,
  tagAlias: string,
  cancelToken: CancelToken
) => Promise<LinkCollaboratos[]>;

export type GetTagsToLink = (cancelToken: CancelToken) => Promise<AxiosResponse<any>>;

export type AddNewCollaborator = (
  addCollaboratorsRequest: AddCollaborators,
  cancelToken: CancelToken
) => Promise<any>;

export type DeleteCollaborator = (
  deleteCollaboratorsRequest: DeleteCollaborators,
  cancelToken: CancelToken
) => Promise<any>;

export type GetNewCollaboratorAlias = (professionId: number, cancelToken: CancelToken) => Promise<string>;

export type GetProductivityVSDisplacement = (
  companyId: number,
  professionId: number,
  initialDate: number,
  finalDate: number,
  areaId: number,
  week: boolean,
  month: boolean,
  year: boolean,
  cancelToken: CancelToken
) => Promise<AxiosResponse<any>>;

export type GetDisplacement = (
  companyId: number,
  professionId: number,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
) => Promise<AxiosResponse<any>>;

export type GetAbsenteeism = (
  clientId: number,
  companyId: string,
  currentDate: string,
  cancelToken: CancelToken
) => Promise<AxiosResponse<any>>;

export type GetIncidents = (
  clientId: number,
  professionId: number,
  initialDate: number,
  finalDate: number,
  areaId: number,
  cancelToken: CancelToken
) => Promise<Incidents[]>;

export type SolveIncident = (
  clientId: number,
  solveIncidentRequest: SolveIncidents,
  cancelToken: CancelToken
) => Promise<any>;

export type GetCreatedClients = (cancelToken: CancelToken) => Promise<CreatedClients[]>;

export type CreateNewClient = (
  createNewClientRequest: CreatedClients,
  cancelToken: CancelToken
) => Promise<AxiosResponse<any>>;

export type GetCreatedCompany = (cancelToken: CancelToken, clientId: number) => Promise<CreatedCompany[]>;

export type CreateNewCompany = (
  createNewClientRequest: CreatedCompany,
  cancelToken: CancelToken
) => Promise<AxiosResponse<any>>;

export type GetSiteCoordinates = (clientId: number, cancelToken: CancelToken) => Promise<CoordinatesResponse>;

export type CreateNewArea = (
  createNewClientRequest: CreateArea,
  cancelToken: CancelToken
) => Promise<AxiosResponse<any>>;

export type GetMovingOnFootInsight = (cancelToken: CancelToken) => Promise<AxiosResponse<any>>;

export type GetMovingByCarInsight = (cancelToken: CancelToken) => Promise<AxiosResponse<any>>;

export type GetAverageSpeedInsight = (cancelToken: CancelToken) => Promise<AxiosResponse<any>>;

export type GetOptimizationsInsight = (cancelToken: CancelToken) => Promise<AxiosResponse<any>>;

export type GetEconomiaDiariaInsight = (cancelToken: CancelToken) => Promise<AxiosResponse<any>>;

export type GetRoiInsight = (cancelToken: CancelToken) => Promise<AxiosResponse<any>>;

export type GetSavingsInsight = (cancelToken: CancelToken) => Promise<AxiosResponse<any>>;

export type GetLocations = (cancelToken: CancelToken) => Promise<RealTimeLocations[]>;

export type GetScannersStatus = (clientId: number, cancelToken: CancelToken) => Promise<ScannertStatus[]>;

export type GetAreasTimeDivision = (
  clientId: number,
  companies: string,
  professions: string,
  collaborators: string,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
) => Promise<AxiosResponse<any>>;

export type GetDisplacementsAtoB = (
  companies: string,
  professions: string,
  collaborators: string,
  initialDate: number,
  finalDate: number,
  initialArea: number,
  finalArea: number,
  cancelToken: CancelToken
) => Promise<AxiosResponse<any>>;

export type GetDashboardInOutData = (companies: string,
                                      professions: string,
                                      collaborators: string,
                                      areas: string,
                                      initialDate: number,
                                      finalDate: number,
                                      week: boolean,
                                      month: boolean,
                                      quarter: boolean,
                                      cancelToken: CancelToken) => Promise<DashboardInOutData>;

export type GetDashboardWorkingPermit = (companies: string,
                                        professions: string,
                                        currentDate: number,
                                        initialDate: number,
                                        finalDate: number,
                                        week: boolean,
                                        month: boolean,
                                        quarter: boolean,
                                        cancelToken: CancelToken) => Promise<DashboardWorkingPTData>;

export const getAreas: GetAreas = selectedModule.getAreas;
export const getAverageTimeChartData: GetAverageTimeChartData = selectedModule.getAverageTimeChartData;
export const getCompanies: GetCompanies = selectedModule.getCompanies;
export const getHeatMapData: GetHeatMapData = selectedModule.getHeatMapData;
export const getHHIndicators: GetHHIndicators = selectedModule.getHHIndicators;
export const getMachinesSummary: GetMachinesSummary = selectedModule.getMachinesSummary;
export const getMapCoordinates: GetMapCoordinates = selectedModule.getMapCoordinates;
export const getMonitoredResources: GetMonitoredResources = selectedModule.getMonitoredResources;
export const getPeopleInAreasChartData: GetPeopleInAreasChartData = selectedModule.getPeopleInAreasChartData;
export const getProfessions: GetProfessions = selectedModule.getProfessions;
export const getProductivityByAreaData: GetProductivityByAreaData = selectedModule.getProductivityByAreaData;
export const getRankChartData: GetRankChartData = selectedModule.getRankChartData;
export const getTimelineData: GetTimelineData = selectedModule.getTimelineData;
export const putMachineStatus: PutMachineStatus = selectedModule.putMachineStatus;
export const getMonitoredAreasInEmergency: GetMonitoredAreasInEmergency =
  selectedModule.getMonitoredAreasInEmergency;
export const getMonitoredFloor: GetMonitoredFloor = selectedModule.getMonitoredFloor;
export const getRetiradaPTChartData: GetRetiradaPT = selectedModule.getRetiradaPTChartData;
export const getLunchChartData: GetLunch = selectedModule.getLunchChartData;
export const getAverageTimeInsightsChartData: GetAverageTimeInsights =
  selectedModule.getAverageTimeInsightsChartData;
export const getChegadaSaidaChartData: GetChegadaSaida = selectedModule.getChegadaSaidaChartData;
export const getFeedbacksData: GetFeedbacks = selectedModule.getFeedbacksData;
export const getEmergencyNotifications: GetEmergencyNotifications = selectedModule.getEmergencyNotifications;
export const getAgileanData: GetAgileanData = selectedModule.getAgileanData;
export const getAgileanCurves: GetAgileanCurves = selectedModule.getAgileanCurves;
export const getActivities: GetActivities = selectedModule.getActivities;
export const getWorkingPermitChartData: GetWorkingPermitChartData = selectedModule.getWorkingPermitChartData;
export const getInOutChartData: GetInOutChartData = selectedModule.getInOutChartData;
export const getCollaboratorsCount: GetCollaboratorsCount = selectedModule.getCollaboratorsCount;
export const getPlanningCollaboratorsCount: GetPlanningCollaboratorsCount =
  selectedModule.getPlanningCollaboratorsCount;
export const getTextInsightsMessages: GetTextInsightsMessages = selectedModule.getTextInsightsMessages;
export const getClients: GetClients = selectedModule.getClients;
export const getLinkedTags: GetLinkedTags = selectedModule.getLinkedTags;
export const getTagsToLink: GetTagsToLink = selectedModule.getTagsToLink;
export const addNewCollaborator: AddNewCollaborator = selectedModule.addNewCollaborator;
export const deleteCollaborator: DeleteCollaborator = selectedModule.deleteCollaborator;
export const getNewCollaboratorAlias: GetNewCollaboratorAlias = selectedModule.getNewCollaboratorAlias;
export const getProductivityVSDisplacement: GetProductivityVSDisplacement =
  selectedModule.getProductivityVSDisplacement;
export const getDisplacement: GetDisplacement = selectedModule.getDisplacement;
export const getMovingOnFootInsight: GetMovingOnFootInsight = selectedModule.getMovingOnFootInsight;
export const getMovingByCarInsight: GetMovingByCarInsight = selectedModule.getMovingByCarInsight;
export const getAverageSpeedInsight: GetAverageSpeedInsight = selectedModule.getAverageSpeedInsight;
export const getOptimizationsInsight: GetOptimizationsInsight = selectedModule.getOptimizationsInsight;
export const getDailySavingInsight: GetEconomiaDiariaInsight = selectedModule.getDailySavingInsight;
export const getRoiInsight: GetRoiInsight = selectedModule.getRoiInsight;
export const getSavingsInsight: GetSavingsInsight = selectedModule.getSavingsInsight;
export const getLocations: GetLocations = selectedModule.getLocations;
export const getAbsenteeism: GetAbsenteeism = selectedModule.getAbsenteeism;
export const getIncidents: GetIncidents = selectedModule.getIncidents;
export const solveIncident: SolveIncident = selectedModule.solveIncident;
export const getScannersStatus: GetScannersStatus = selectedModule.getScannersStatus;
export const getAreaTimelineData: GetAreaTimelineData = selectedModule.getAreaTimelineData;
export const getAreasTimeDivision: GetAreasTimeDivision = selectedModule.getAreasTimeDivision;
export const getCreatedClients: GetCreatedClients = selectedModule.getCreatedClients;
export const createNewClient: CreateNewClient = selectedModule.createNewClient;
export const getCreatedCompany: GetCreatedCompany = selectedModule.getCreatedCompany;
export const createNewCompany: CreateNewCompany = selectedModule.createNewCompany;
export const getSiteCoordinates: GetSiteCoordinates = selectedModule.getSiteCoordinates;
export const createNewArea: CreateNewArea = selectedModule.createNewArea;
export const getDisplacementsAtoB: GetDisplacementsAtoB = selectedModule.getDisplacementsAtoB;
export const getDashboardInOutData: GetDashboardInOutData = selectedModule.getDashboardInOutData;
export const getDashboardWorkingPermit: GetDashboardWorkingPermit = selectedModule.getDashboardWorkingPermit;