import React from 'react';

import { CardHeader } from './CardHeader';
import { useCard } from './hooks/useCard';
import { HHIndicators } from './components/HHIndicators';
import { CollaboratorsCount } from './components/CollaboratorsCount';
import { CurrentCollaboratorsCount } from './components/CurrentCollaboratorsCount';
import { PlanningCollaboratorsCount } from './components/PlanningCollaboratorsCount';
import { Absenteeism } from './components/Absenteeism';

import * as S from './styles';

type Props = {
  titulo: string;
  civil: boolean;
};

export const IndicatorsCard: React.FC<Props> = ({ titulo, civil }: Props) => {
  const { cardExpanded, handleClickExpand } = useCard();

  return (
    <>
      <S.IndicatorsExpandableCard>
        <CardHeader titulo={titulo} expanded={cardExpanded} onClick={handleClickExpand} />
      </S.IndicatorsExpandableCard>
      {cardExpanded && (
        <>
          <HHIndicators />
          <CollaboratorsCount />
          <CurrentCollaboratorsCount />
          <Absenteeism/>
          {civil && <PlanningCollaboratorsCount />}
        </>
      )}
    </>
  );
};
