import React from 'react';

import { PageHeader } from '../../components/PageStructure/PageHeader';
import { CollaboratorsList } from './components/CollaboratorsList';
import { LinkCollaboratorsContextProvider } from './context/LinkCollaboratorsContext';
import { Container, Content } from './styles';

export const LinkCollaboratorContainer: React.FC = () => {
  return (
    <Container>
      <PageHeader title="Vinculação de Colaborador" />
      <Content>
        <LinkCollaboratorsContextProvider>
          <CollaboratorsList/>
        </LinkCollaboratorsContextProvider>
      </Content>
    </Container>
  );
};