import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';
import useSwr from 'swr';

import { useAuth, useIsMounted } from '../../../hooks';
import { getLocations } from '../../../services/api/TrackfyService/integration';
import { RealTimeLocations } from '../../../types/MonitoredData';
import { getEndpoint } from '../../../services/api/TrackfyService/endpoints';
import { MonitoredTags } from '../types/Monitoring';

export const useTagsLocations = () => {
  const [monitoredTags, setMonitoredTags] = useState<MonitoredTags>({ trackfyTags: [], clientTags: [] });   
  const mountedRef = useIsMounted();
  const { getClientId } = useAuth();
 
  const currentRequest: CancelTokenSource = Axios.CancelToken.source();
  const endpoint: string = getEndpoint('currentlocation', false);

  const requestMonitoredTagLocations = (token: CancelToken, mounted: boolean): void => {
    getLocations(token)
      .then((data:RealTimeLocations[])=> {

        if(getClientId() == 11) {
          const trackfyTags = data.filter((item:RealTimeLocations) => {
            return (item.tag == "ac233fad6fe2" ||
            item.tag == "ac233fad6fe4" ||
            item.tag == "ac233fad6fe9" ||
            item.tag == "ac233fad6fdc" ||
            item.tag == "ac233fad6fdd" ||
            item.tag == "ac233fad6fde" ||
            item.tag == "ac233fad6fdf" ||
            item.tag == "ac233fad6fe1" ||
            item.tag == "ac233fad6fe8" ||
            item.tag == "ac233fad6fe7" ||
            item.tag == "ac233fad6fe6")
          });
        
          const clientTags = data.filter((item:RealTimeLocations) => {
            return (item.tag != "ac233fad6fe2" &&
            item.tag != "ac233fad6fe4" &&
            item.tag != "ac233fad6fe9" &&
            item.tag != "ac233fad6fdc" &&
            item.tag != "ac233fad6fdd" &&
            item.tag != "ac233fad6fde" &&
            item.tag != "ac233fad6fdf" &&
            item.tag != "ac233fad6fe1" &&
            item.tag != "ac233fad6fe8" &&
            item.tag != "ac233fad6fe7" &&
            item.tag != "ac233fad6fe6")
          });

          mounted && setMonitoredTags({ trackfyTags: trackfyTags, clientTags: clientTags });
        } else {
          mounted && setMonitoredTags({ trackfyTags: [], clientTags:data  });
        }
      })
      .catch((err) => {
        console.log(err);
        setMonitoredTags({ trackfyTags: [], clientTags: [] });
      })
  }

  useSwr( mountedRef.current ? endpoint : null,
          async () => { requestMonitoredTagLocations(currentRequest.token, mountedRef.current) },
                      { focusThrottleInterval: 10001, refreshInterval: 10000 }
  );

  useEffect(() => {
    mountedRef.current = true;
    
    if(mountedRef.current)
      requestMonitoredTagLocations(currentRequest.token, mountedRef.current);

    return () => { 
      mountedRef.current = false; 
      currentRequest.cancel('request canceled by the user.');
    }
  }, []);

  return { monitoredTags };
};