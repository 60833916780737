import React from 'react';
import { ToastContainer } from 'react-toastify';

import { useCreateNewArea } from './hooks/useCreateArea';
import { Modal } from '../../../../../components/Modal';
import IconClose from '../../../../../Icons/Close';
import IconUsers from '../../../../../Icons/Users';
import IconManager from '../../../../../Icons/Manager';

import { SimpleContainer } from '../../../../../styles/structure';
import * as S from './styles';

type AddAreaContainerProps = {
  isAdmin: boolean;
  isActive: boolean;
  onClickToSave(): void;
  onClickToClose(): void;
};

export const AddAreaContainer: React.FC<AddAreaContainerProps> = (props: AddAreaContainerProps) => {
  const { isAdmin, isActive, onClickToClose, onClickToSave } = props;
  const { isDefined, handleSaveClient, createArea, setNameSelected, setColorSelected } = useCreateNewArea(isAdmin);

  return (
    <>
      <ToastContainer />
      <Modal visible={isActive}>
        <S.AddAreaContainer>
          <S.CloseButton onClick={onClickToClose}>
            <IconClose />
          </S.CloseButton>

          <S.AddAreaHeaderTitleContainer>
            <S.IconUsersContainer>
              <IconUsers />
            </S.IconUsersContainer>
            <S.AddAreaHeaderLabel>Criar área nova</S.AddAreaHeaderLabel>
          </S.AddAreaHeaderTitleContainer>

          <S.AddAreaFrontContent>
            <S.AreaInfoContainer>
              <S.FilterInputContainer>
                <S.InputLabel>Área</S.InputLabel>
                <SimpleContainer>
                  <S.Inpunt
                    placeholder="Insira o nome da área"
                    value={createArea.name}
                    onChange={(event) => setNameSelected(event.target.value)}
                  />
                </SimpleContainer>
              </S.FilterInputContainer>
              <S.FilterInputContainer>
                <S.InputLabel>Cor</S.InputLabel>
                <SimpleContainer>
                  <S.Inpunt
                    placeholder="Insira uma cor em hexadecimal"
                    value={createArea.color}
                    onChange={(event) => setColorSelected(event.target.value)}
                  />
                </SimpleContainer>
              </S.FilterInputContainer>
            </S.AreaInfoContainer>

            <S.SaveButtonContainer>
              <S.SaveButton
                title="Criar área nova"
                icon={<IconManager />}
                disabled={!isDefined()}
                onClick={async () => {
                  const isSaved = await handleSaveClient();
                  if (isSaved) onClickToSave();
                }}
              />
            </S.SaveButtonContainer>
          </S.AddAreaFrontContent>
        </S.AddAreaContainer>
      </Modal>
    </>
  );
};
