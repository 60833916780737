import React from 'react';

import { useHhindicator } from './hooks/useHhindicator';
import { Info } from '../../../../../components/Info';
import { useAuth } from '../../../../../hooks';
import { getHHIndicatorTooltipContent } from './SummaryIndicatorsTooltip';

import * as S from './styles';

export const HHIndicators: React.FC = () => {
  const { indicator } = useHhindicator();
  const { getClientId } = useAuth();
  const clientId = getClientId();
  const tooltipContent = getHHIndicatorTooltipContent(clientId);

  return (
    <S.Card>
      <S.CardHeader>HH Monitorado:</S.CardHeader>
      {<S.CardContent>{indicator.value}</S.CardContent>}
      <S.IconContent>
        <Info content={tooltipContent} position={'left'} burndown={false}/>
      </S.IconContent>
    </S.Card>
  );
};
