import styled from 'styled-components';

export const SurveyContainer = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const ModalContent = styled.div`
  width: 405px;
  height: 200px;
  max-height: 438px;

  overflow: hidden;
  background: #ffffff;

  border: 0.5px solid #ebebf9;
  box-sizing: border-box;
  box-shadow: 8px 8px 16px 4px rgba(80, 94, 165, 0.13);
  border-radius: 10px;

  justify-content: center;
`;

export const Modaltitle = styled.div`
  margin-top: 30px;
  margin-left: 5px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #4f5882;
`;

export const Link = styled.a`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #2f4cdd;
`;

export const Button = styled.div`
  display: flex;
  background-color: #2f4cdd;
  color: white;
  cursor: pointer;
  width: 130px;
  height: 50px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin: 40px auto;
`;

export const ButtonText = styled.span`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 2px;
`;
