import React from 'react';
import { Popup } from 'react-map-gl';

import { Header, Title, Content } from '../styles';
import { useAreaPopup } from '../hooks/useAreaPopup';

type PopupProps = {
  lat: number;
  lng: number;
  contentName: string;
  contentCollaborators: any[];
  notGroup: boolean;
  groupByTeam: boolean;
};

export const AreaPopup: React.FC<PopupProps> = (props: PopupProps) => {
  const { contentName, contentCollaborators, lat, lng, notGroup, groupByTeam } = props;
  const { getGroupedData, getIndividualData, getTeamData } = useAreaPopup();

  let data: React.JSX.Element = getGroupedData(contentCollaborators);

  if (groupByTeam) data = getTeamData(contentCollaborators);
  else if (notGroup) data = getIndividualData(contentCollaborators);

  return (
    <Popup longitude={lng} latitude={lat} anchor="top">
      <Header>
        <Title>
          <span>{contentName}</span>
        </Title>
      </Header>
      <Content>{data}</Content>
    </Popup>
  );
};
