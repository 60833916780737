import React from 'react';
import Table from '../../../../components/Table/Table';
import { getObjectToStringMatrix } from '../../../../utils/Convert';
import { LinkCollaboratos } from '../../types/linkCollaborators';
import { headers } from './Header';

import * as S from './styles';

export type CollaboratorsTableProps = {
  detailData: LinkCollaboratos[],
  loading: boolean,
  empty: boolean
};

export const CollaboratorsTable: React.FC<CollaboratorsTableProps> = (props: CollaboratorsTableProps) => {
    const {detailData, loading, empty} = props

    return(
    <S.TableContainer>
        <Table tableHeaders={headers} 
            tableData={getObjectToStringMatrix(detailData)} 
            loading={loading} 
            isEmpty={empty}/>
    </S.TableContainer>
    )
}