import { filters } from './QuestionsAndAnswers';
import { useFaQContext } from './context/FaqContext';
import * as S from './styles';
import React from 'react';

interface Props {}
export const ScrollChips: React.FC<Props> = ({}) => {
  const { setSubjectFilter, subjectFilter } = useFaQContext();
  return (
    <S.ScrollChipsContainer draggable>
      {filters.map((filter, index) => (
        <S.Chip key={`${filter}-${index}`} onClick={() => setSubjectFilter(filter)}>
          {filter}
        </S.Chip>
      ))}
    </S.ScrollChipsContainer>
  );
};
