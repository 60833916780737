import React, { createContext, ReactNode, useState, useContext } from 'react';
import { PaginationControls } from '../../../../../components';


import { OnePage } from '../../../types/OnePage';

type ContextValue = {
  revenueFTEChartData: OnePage[];
  isLoading: boolean;
  revenueFTEValues: Array<OnePage[]>;
  setRevenueFTEChartData: (value: OnePage[]) => void;
  setRevenueFTEValues: (arrayAvarageValues: Array<OnePage[]>) => void;
  setIsLoading: (value: boolean) => void;
};

const RevenueFTEContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function RevenueFTEContextProvider(props: Props) {
  const { children } = props;
  const [revenueFTEValues, setRevenueFTEValues] = useState<Array<OnePage[]>>([]);
  const [revenueFTEChartData, setRevenueFTEChartData] = useState<OnePage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const value = {
    revenueFTEChartData,
    revenueFTEValues,
    isLoading,
    setRevenueFTEChartData,
    setRevenueFTEValues,
    setIsLoading,
  };

  return (
    <RevenueFTEContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={revenueFTEValues}
        setNewValue={setRevenueFTEChartData}
        title="Otimizações"
      />
    </RevenueFTEContext.Provider>
  );
}

export function useRevenueFTEContext() {
  const context = useContext(RevenueFTEContext);

  if (typeof context === 'undefined') {
    throw new Error('RevenueFTEContext must be used within an RevenueFTEContext');
  }

  return context;
}
