import React from 'react';

import { useAreasContext } from '../../../../context/AreasContext';

import * as S from './styles';

export const CurrentCollaboratorsCount: React.FC = () => {
  const { indicator } = useAreasContext();

  return (
    <S.Card>
      <S.CardHeader> Colaboradores Ativos: </S.CardHeader>
      <S.CardContent>{indicator.value}</S.CardContent>
    </S.Card>
  );
};
