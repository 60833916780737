import React, { createContext, ReactNode, useState, useContext } from 'react';

import { CalendarEvent } from '../../../indicators/types/Indicators';

type FullCalendarContextProps = {
    events: CalendarEvent[];
    setEvents: (events: CalendarEvent[]) => void;
};

type Props = {
  children: ReactNode;
};

const FullCalendarContext = createContext<FullCalendarContextProps>({} as FullCalendarContextProps);

export function FullCalendarContextProvider(props: Props) {
  const { children } = props;
  const [events, setEvents] = useState<CalendarEvent[]>([]);

  const value = {
    events,
    setEvents,
  };
  return <FullCalendarContext.Provider value={value}>{children}</FullCalendarContext.Provider>;
}

export function useFullCalendarContext() {
  const context = useContext(FullCalendarContext);

  if (typeof context === 'undefined') {
    throw new Error('FullCalendarContext must be used within an FullCalendarContext');
  }

  return context;
}
