import styled from 'styled-components';

interface LegendColor {
    color?: string;
  }
  

export const Square = styled.div<LegendColor>`
    width: 10px;
    height: 10px; 
    background-color: ${(props) => (props.color)};
`

export const CustomLegend = styled.div<LegendColor>`
    color: ${(props) => (props.color)};
    margin-right:15px;
    margin-left:5px;
`

export const Legend = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`