import { TooltipContent } from "../../../../../components/Info/Tooltip";

export const getWorkingPermitTooltipContent = (clientId: number): TooltipContent[] => {
    return [
        {
            content: 'Registra o tempo médio de permanência na área da retirada da permissão de trabalho.',
        },
        {
            content: 'Os filtros “Semanal”, “Mensal” e “Anual”, localizados logo abaixo do gráfico, ignoram o filtro da pagina!'
        }
    ];
};