import React, { useState } from 'react';
import { Marker } from 'react-map-gl';

import { useAuth } from '../../../hooks';
import { mixpanelTrack } from '../../../services/SystemMonitor';
import { useAuthContext } from '../../../stores';
import { AreaPopup } from './AreaPopup';
import { DangerOutlinedIcon } from '../../../Icons/DangerOutline';
import { CheckGuardIcon } from '../../../Icons/CheckGuard';

import * as S from '../styles';

type Props = {
  lat:number;
  lng:number;
  iconContent: number;
  contentName: string;
  contentCollaborators: any[];
  notGroup: boolean;
  groupByTeam: boolean;
  mode: string; 
  dangerAreas?: string[]; 
  safeArea?: string;
  color?: string;  
};

const getDangerAreaIcon = (mode: string, iconContent: number, lat: number, lng: number, color?: string) => {
  if(mode === "evacuating")
    return (<Marker latitude={lat} longitude={lng}>
              <S.EvacuationIconCounter bgColor={color ? color : '#FFFFFF'}>
                {iconContent}
              </S.EvacuationIconCounter>
            </Marker>);
  else
    return (<Marker latitude={lat} longitude={lng}>
              <DangerOutlinedIcon fill="#FFFFFF" width={32} height={32} />
            </Marker>);
}

const getSafeAreaIcon = (mode: string, iconContent: number, lat: number, lng: number, color?: string) => {
  if(mode === "evacuating")
    return (<Marker latitude={lat} longitude={lng}>
              <S.EvacuationIconCounter bgColor={color ? color : '#FFFFFF'}>
                {iconContent}
              </S.EvacuationIconCounter>
            </Marker>);
  else
    return (<Marker latitude={lat} longitude={lng}>
              <CheckGuardIcon fill="#ffffff" width={32} height={32} />
            </Marker>);
}

export const AreaMarker: React.FC<Props> = (props: Props) => {
  const { lat, lng, iconContent, contentName, contentCollaborators, notGroup, groupByTeam, color, mode, 
    dangerAreas, safeArea } = props;
  const [showPopup, setShowPopup] = useState(false);
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  const onHover = () => {
    setShowPopup(true);
    mixpanelTrack(monitorAgent, 'Popup dos ícones',userName, authData);
  };

  const onLeave = () => setShowPopup(false);

  if(mode !== "notevacuating") {
    if(dangerAreas) {
      const dangerAreaIdx = dangerAreas.findIndex((dangerArea: string) => dangerArea == contentName);

      if(dangerAreaIdx >= 0)
        return getDangerAreaIcon(mode, iconContent, lat, lng, color);
      else {
        const safeAreas = safeArea ? safeArea.split(";") : [];

        if( safeAreas.length && safeAreas.find((safeArea: string) => safeArea == contentName) )
          return getSafeAreaIcon(mode, iconContent, lat, lng, color);

        else return null;
      }
    } 

    else return null;  
  } 

  return (
    <>
      <Marker latitude={lat} longitude={lng}>
        <S.Pin onMouseEnter={onHover} onMouseLeave={onLeave}>
        <S.Badge>
          <S.H1>{iconContent}</S.H1>
        </S.Badge>
        </S.Pin>
      </Marker>
      {showPopup && (
        <AreaPopup
          lng={lng}
          lat={lat}
          contentName={contentName}
          contentCollaborators={contentCollaborators}
          notGroup={notGroup}
          groupByTeam={groupByTeam}
        />
      )}
    </>
  );
};