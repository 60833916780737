import React from 'react';

import { useAreasContext } from '../../../context/AreasContext';
import { useAuth } from '../../../../../hooks';
import { getCurrentCollaboratorsTooltipContent } from './SummaryIndicatorsTooltip';
import { Info } from '../../../../../components/Info';

import * as S from './styles';

export const CurrentCollaboratorsCount: React.FC = () => {
  const { indicator } = useAreasContext();
  const { getClientId } = useAuth();
  const clientId = getClientId();
  const tooltipContent = getCurrentCollaboratorsTooltipContent(clientId);

  return (
    <S.Card>
      <S.CardHeader> Colaboradores Ativos: </S.CardHeader>
      <S.CardContent>{indicator.value}</S.CardContent>
      <S.IconContent>
        <Info content={tooltipContent} position={'left'} burndown={false}/>
      </S.IconContent>
    </S.Card>
  );
};
