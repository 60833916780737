import { Collapse } from 'antd';
import React, { useRef, useEffect, useState } from 'react';
import ScrollBar, { positionValues } from 'react-custom-scrollbars';

import { useCompanyFilterContext } from '../../../../../../../stores';
import { ListHeader, ListItem } from '.';
import { MonitoredAreasData, OccupationData } from '../../../../../../../types/MonitoredData';
import { IconLoading } from '../../../../../../../components/IconLoading';
import { useAuth } from '../../../../../../../hooks';

const { Panel } = Collapse;

export type CardContentProps = {
  floorsData: MonitoredAreasData[];
}

export const CardContent: React.FC<CardContentProps> = (props: CardContentProps) => {
  //const [mounted, setMounted] = useState<boolean>(false);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  //const { monitoredAreas, updateMonitoredAreas, setIndicator } = useAreasContext();
  const ScrollbarsRef: React.MutableRefObject<any> = useRef<any>(null);
  const scrollPositionTop: React.MutableRefObject<number> = useRef<number>(0);
  const { selectedCompany, areasLoading, setAreasLoading } = useCompanyFilterContext();
  const { getClientId } = useAuth();
  const { floorsData } = props;
  
  //const companyId = selectedCompany?.id ? selectedCompany?.id : '0';
  const clientId: number = getClientId();
  //const useV2 = (clientId == 11 || clientId == 1 || clientId == 6);

  /*const getMonitoredAreas = (companyId: number, currentRequest: CancelTokenSource) => {
    setIndicator({ loading: true, value: 'Carregando...' });

    getMonitoredAreasData(clientId, companyId, currentRequest.token)
      .then((data: MonitoredData) => {
        if (data) updateMonitoredAreas(data);
        else updateMonitoredAreas({ areas: [], collaborators: 0 });
      })
      .catch((err) => console.log(err))
      .finally(() => setAreasLoading(false));
  };

  // Mantém os dados de monitoramento das áreas sincronizados
  const url = useV2 ?  'https://backend.trackfyapp.com/rest/v2/monitoring/areas': 'monitoring/areas';
  //const url = useV2 ? "http://localhost:8083/rest/v2/monitoring/areas" : 'monitoring/areas';

  useSwr(
    mounted ? url : null,
    async () => {
      const currentRequest: CancelTokenSource = Axios.CancelToken.source();
      getMonitoredAreas(Number.parseInt(companyId), currentRequest);
    },
    {
      focusThrottleInterval: 90001,
      refreshInterval: 90000,
    }
  );*/

  useEffect(() => {
    const scrollTop = () => ScrollbarsRef.current?.scrollTop(scrollPositionTop.current);
    if (scrollPositionTop.current) scrollTop();
  }, [scrollPositionTop]);

  /*useEffect(() => {
    setMounted(true);
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    getMonitoredAreas(Number.parseInt(companyId), currentRequest);

    return () => {
      setMounted(false);
      currentRequest.cancel('GetMonitoredAreas request canceled by the user.');
    };
  }, [companyId]);*/

  if (!floorsData)
    return <p style={{ color: '#000', textAlign: 'center' }}>Carregando dados...</p>;

  const ContainerAreaMonitored = () => {
    if (floorsData.length > 0) setTimeout(() => ScrollbarsRef.current?.forceUpdate(), 500);

    const handleScrollFrame = (values: positionValues) =>
      (scrollPositionTop.current = values.scrollTop);
    //customStyle={{marginLeft:"150px", height:"50px", width:"50px"}}

    return (
      <div className="content">
        <ScrollBar ref={ScrollbarsRef} onScrollFrame={handleScrollFrame}>
          {areasLoading ? (
            <div style={{ marginLeft: '150px', marginTop: '15px' }}>
              <IconLoading />
            </div>
          ) : (
            <Collapse
              className="list-monitor"
              expandIconPosition="right"
              activeKey={activeKeys}
              onChange={(e: any) => {
                setActiveKeys(e);
                setTimeout(() => ScrollbarsRef.current && ScrollbarsRef.current.forceUpdate(), 250);
              }}
            >
              { floorsData
                .sort((a: MonitoredAreasData, b: MonitoredAreasData) => a.ordem - b.ordem)
                .map((area: MonitoredAreasData, idx: number) => {
                  const quantity: number =
                    area.data.length === 0
                      ? 0
                      : area.data.reduce(
                          (previousValue: any, currentValue: OccupationData) =>
                            Number.parseInt(previousValue) + Number.parseInt(currentValue.quantity.toString()),
                          0
                        );

                  return (
                    <Panel
                      key={idx + '_' + area.nome + '_' + area.coordenadas}
                      className="area-monitored"
                      header={<ListHeader title={area.nome} quantity={quantity} color={area.cor} />}
                    >
                      <ListItem data={area.data} />
                    </Panel>
                  );
                })}
            </Collapse>
          )}
        </ScrollBar>
      </div>
    );
  };

  return <ContainerAreaMonitored />;
};

/*async (url: string) => {
  setAreasLoading(true);
  setFinished(false);
  const clientId = localStorage.getItem(CLIENT_ID) ? localStorage.getItem(CLIENT_ID) : '2';
  
  await fetchData(token, url, { clientId })
  .then((res: AxiosResponse<any>) => {
    if (finished) 
      return;
            
    if (!res.data || res.data.length === 0) {
      console.log(`res.data.length: ${res.data.length}`);
      return;
    }
  
    if (!isMounted.current)
      return;
     
    setAreasLoading(false);
    updateMonitoredAreas(res.data);
  })
  // eslint-disable-next-line no-console
  .catch((error: any) => console.error(error));
},*/

/*useEffect(() => {
  setMounted(true);
  return () => { setMounted(false); }
}, [monitoredAreas]);*/
