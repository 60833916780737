import React from "react";

import { RTMonitorinBoardProps } from "../types/Monitoring";
import { RealTimeLocations } from "../../../types/MonitoredData";
import { Clock } from "./Clock";

import * as S from '../styles';
import { Tbody } from "../../../components/Table/styles";

export const TagsLocationList = (props: RTMonitorinBoardProps) => {
    const { areaIcons, title } = props
     
    return (
        <S.Container>
            <S.Title>{title}</S.Title>
            <S.ClockContainer>
                <Clock/>
            </S.ClockContainer>
            <table>
                <Tbody>
                    {areaIcons.map((area:RealTimeLocations, index:number)=> {
                        const areaBackgroundColor = index % 2 === 0 ? "#fff" : "#d4d5dd";

                        return (
                            areaIcons.length > 0 ?
                                <S.Trstyled key={"tr_" + area.tag} height="25px" color={areaBackgroundColor}>
                                    <S.Tdstyled key={area.color + "_" + area.tag} width="10px">
                                        <S.AreaPin style={{ backgroundColor: `${area.color}` }} />
                                    </S.Tdstyled>
                                    <S.Tdstyled key={area.area + "_" + area.tag} width="205px">{area.area}</S.Tdstyled>
                                    <S.Tdstyled key={area.alias + "_" + area.tag} width="175px">{area.alias}</S.Tdstyled>
                                    <S.Tdstyled key={area.tag} width="82px">{area.tag}</S.Tdstyled>
                                </S.Trstyled>
                            : <React.Fragment key={"emptyRealTimelocation1"}/>
                        )
                    })}
                </Tbody>
            </table>
        </S.Container>);
}