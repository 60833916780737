import React from 'react';

import { OptionsContainer } from '../../components/PageStructure/styles';
import { DateRangePicker } from '../../components';
import { getFollowingFriday } from '../../utils/DateTime';
import LogtypesSelect from '../filters/LogTypesSelect';
import { useSystemlogsContext } from './context/SystemlogsContext';

import * as S from '../../styles/structure';

const SystemlogsOptions: React.FC = () => {
  const {
    updatePeriod,
    logTypesSelected,
    setLogTypesSelected
  } = useSystemlogsContext();

  return (
    <OptionsContainer>
      <S.SimpleContainer>
        <LogtypesSelect
          label={undefined}
          logTypesSelected={logTypesSelected}
          setLogtypesSelected={setLogTypesSelected}
        />
      </S.SimpleContainer>

      <S.SimpleContainer>
        <DateRangePicker
          updatePeriod={updatePeriod}
          screen="Logs do sistema"
          maxDate={getFollowingFriday(new Date())}
          component="Logs do sistema - "
          selectRange
          showToday
          loadDate={new Date()}
        />
      </S.SimpleContainer>
    </OptionsContainer>
  );
};

export default SystemlogsOptions;
