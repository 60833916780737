import React from 'react';

import * as S from '../styles';

interface DisplacementBtwnAreasMediaProps {
  value: string;
};

export const DisplacementBtwnAreasMedia: React.FC<DisplacementBtwnAreasMediaProps> = ({value}) => (
  <S.Card>
    <S.CardHeader>Tempo médio de deslocamento:</S.CardHeader>
    <S.CardContent>{value}</S.CardContent>
  </S.Card>
);