import React, { useState } from 'react';

import DailySummaryReport from './DailySummaryReport';
import { useDailySummaryContext } from '../context/DailySummaryContext';
import { dailyHeaders, monthlyHeaders } from './HeadersData';
import { DailySummaryMonitor, MediaData, SummaryFooterProps } from '../types/DailySummaryTypes';
import IconClock from '../../../Icons/Clock';
import { getDateBrFormat } from '../../../utils/DateTime';
import { ContentContainer } from './styled';

const PageContent: React.FC = () => {
  const [dailyData, setDailyData] = useState<DailySummaryMonitor[]>([]);
  const [montlhyData, setMonthlyData] = useState<DailySummaryMonitor[]>([]);
  const [dailyMedia, setDailyMedia] = useState<MediaData>({ media: '' });
  const [monthlyMedia, setMonthlyMedia] = useState<MediaData>({ media: '' });
  const { companySelected, date } = useDailySummaryContext();

  return (
    <ContentContainer>
      <DailySummaryReport
        title={`Monitoramento do dia ${getDateBrFormat(date)}`}
        company={companySelected}
        date={date.toDate().valueOf()}
        monthly={false}
        detailHeader={dailyHeaders}
        setData={setDailyData}
        detailData={dailyData}
        footer={
          {
            icon: <IconClock />,
            title: 'Permanência em áreas produtivas por colaborador:',
            data: dailyMedia,
            monthly: false,
            setData: setDailyMedia,
          } as SummaryFooterProps
        }
      />
      <DailySummaryReport
        title="Média acumulada mensal"
        company={companySelected}
        date={date.toDate().valueOf()}
        monthly
        detailHeader={monthlyHeaders}
        setData={setMonthlyData}
        detailData={montlhyData}
        footer={
          {
            icon: <IconClock />,
            title: 'Permanência em áreas produtivas por colaborador:',
            data: monthlyMedia,
            monthly: true,
            setData: setMonthlyMedia,
          } as SummaryFooterProps
        }
      />
    </ContentContainer>
  );
};

export default PageContent;
