import { TableHeader } from '../../../components/Table/Table';

export const headers: TableHeader[] = [
  {
    title: 'Usuário',
    icon: undefined,
  },
  {
    title: 'Mensagem',
    icon: undefined,
  },
  {
    title: 'Data',
    icon: undefined,
  },
];