import React, { ReactElement } from 'react';

/*import { Tab } from '../../../components/Tabs/Tab';
import { Tabs } from '../../../components/Tabs';
import PageConformity from '../../conformity/PageConformity';
import PageProductivity from '../../productivity/PageProductivity';
import Moviment from '../../moviment/Moviment';
import { useAuth } from '../../../hooks';*/
import { useAuthContext } from '../../../stores/AuthContext';
import { TabsFactory } from './TabsFactory';

export const PageTabs: React.FC<{}> = () => {
  const {authData} = useAuthContext();

  return (<TabsFactory tabs={authData?.components.tabs} />)
}

/*
   const { getClientId } = useAuth();
  const clientId: number = getClientId();
  const companies: string = authData ? authData.grpCompanies : "";
  const userId = localStorage.getItem('@trackfy_user_id') ? localStorage.getItem('@trackfy_user_id') : '1';
  const industryClients = (clientId == 11 || clientId == 14 || clientId == 15 || clientId == 4);
  const children: ReactElement[] = [];

  if(companies!="7"){
      children.push(
        <Tab title="Conformidade">
          <PageConformity />
        </Tab>);
  }

  children.push(
      <Tab title="Produtividade">
        <PageProductivity />
      </Tab>);

  if((!industryClients) || (userId == '32'))
    children.push(
        <Tab title="Movimentação">
          <Moviment />
        </Tab>);

  return <Tabs children={children} />;
  */