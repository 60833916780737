import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.6rem 1.6rem 1.6rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #0823ad;
  @media (max-width: 576px) {
    padding: 2.6rem 0 1.6rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 3.5rem;
  padding: 3.5rem 2.4rem;
  overflow: hidden;

  background: #f3f3f3;
  border-top-style: none;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  @media (max-width: 576px) {
    border-top-right-radius: 0;
    padding: 2.5rem 1.5rem;
  }
`;
