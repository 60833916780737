import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { Layer, Source } from 'react-map-gl';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';

import { useAuth, useIsMounted } from '../../../hooks';
import { fetchData } from '../../../services/requests';
import cookieService from '../../../services/CookieService';
import { MapAreasData } from '../../../types/MonitoredData';
import { IconCraneArea } from '../../../Icons/CraneArea';
import { parseDataToCoordinates } from '../../../utils/Convert';

import * as S from '../styles';
import { colors } from '../../../styles/colors';

export interface MapAreas {
  mapGeofences: React.JSX.Element[];
  requestedAreas: MapAreasData[];
}

const getRestrictedArea = (name: string, coordenadas: string): React.JSX.Element => {
  const geojson: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: { title: 'GuindasteBlackLine' },
        geometry: { type: 'Polygon', coordinates: [parseDataToCoordinates(coordenadas)] },
      },
    ],
  };

  return (
    <React.Fragment key={'craneborder_' + name}>
      <Source id={'cranebordersrc_' + name} type="geojson" data={geojson} key={'craneborder_source_' + name}>
        <Layer id={'craneborder_' + name} type="fill" paint={{ 'fill-opacity': 0 }} key={'craneborder_fill_' + name} />
        <Layer
          id={'craneborderborder_' + name}
          type="line"
          key={'craneborder_border_' + name}
          paint={{ 'line-width': 2, 'line-color': '#000000', 'line-dasharray': [5, 6] }}
        />
      </Source>
    </React.Fragment>
  );
};

const parseArea = (area: MapAreasData, mode: string, restrictedAreas: React.JSX.Element[], dangerAreas?: string[], safeArea?: string): React.JSX.Element => {
  const { nome, cor, coordenadas, restricted } = area;
  const coordinates = parseDataToCoordinates(coordenadas);
  const safeAreas = safeArea ? safeArea.split(";") : [];
  
  const geojson: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          title: nome,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [coordinates],
        },
      },
    ],
  };

  let paint: mapboxgl.FillPaint = {
    'fill-opacity': 0.4,
    'fill-color': cor,
  };

  let paintBorder: mapboxgl.LinePaint = {
    'line-width': 1,
    'line-color': cor,
  };

  if ((mode !== "notevacuating") && dangerAreas) {
    const isDangerArea = dangerAreas.some(a => a === area.nome);

    paint = { 
      "fill-color": mode === "evacuating" ? 
        (isDangerArea ? '#ED3A3A' : 'transparent') : (isDangerArea ? '#E24444' : colors.red), 
      "fill-opacity": isDangerArea ? 0.9 : 0.3
    }

    paintBorder = {
      'line-width': isDangerArea ? 4 : 2,
      'line-color': mode === "evacuating" ? (isDangerArea ? '#ED3A3A' : 'transparent') : '#E24444',
    };

    if( safeAreas.length && safeAreas.find((safeArea:string) => area.nome === safeArea) ) {
      paint['fill-color'] = '#33B469';
      paintBorder['line-color'] = '#33B469';
      paintBorder['line-dasharray'] = undefined;
    }
  }

  if(restricted){
    paintBorder = {
      'line-width': 2,
      'line-color': mode !== "notevacuating" ? colors.red : cor,
      'line-dasharray': [5, 6],
    };

    restrictedAreas.push(getRestrictedArea(nome, coordenadas));
  }

  return (
    <React.Fragment key={'id_container' + nome}>
      <Source id={'id_src_' + nome} type="geojson" data={geojson} key={'src_' + nome}>
        <Layer id={'id_fill_' + nome} type="fill" paint={paint} key={'fill_' + nome} />
        <Layer id={'id_border_' + nome} type="line" paint={paintBorder} key={'border_' + nome} />
      </Source>
    </React.Fragment>
  );
};

const requestAreas = async (mountedRef: React.MutableRefObject<boolean>,
                            token: string,
                            url: string,
                            clientId: number,
                            filterHelmetsArea: boolean,
                            setMapAreas: (mapAreas: MapAreas) => void,
                            mode: string, 
                            userId: number,
                            dangerAreas?: string[],
                            safeArea?: string): Promise<void> => {
  const res: AxiosResponse<any> = await fetchData(token, url, {});

  if (!mountedRef.current) return;
  if (!res.data || res.data.length === 0) return;

  const requestedAreas: MapAreasData[] = res.data;
                  
  let restrictedAreas: React.JSX.Element[] = [];

  let areasData = requestedAreas.filter((area: MapAreasData) => area.coordenadas);

  if (filterHelmetsArea) areasData = areasData.filter((area: MapAreasData) => area.nome !== 'Sala capacetes');

  const areasComponents: React.JSX.Element[] = [];

  if (clientId === 14) {
    if(userId == 69){
      areasComponents.push(
        <React.Fragment key="imgContiner">
          <Source
            id="image"
            type="image"
            key="image"
            url={"https://trackfy-frontend-assets.s3.us-east-2.amazonaws.com/toyoBackground.png"}
            coordinates={[
              [-47.12904659250427, -22.730839071213595],
              [-47.12711860811419, -22.731103028208906],
              [-47.127438748591885, -22.733459281123928],
              [-47.12939759678818, -22.733204803157022]
            ]}
          >
            <Layer type="raster" />
          </Source>
        </React.Fragment>
      );
    } else {
      areasComponents.push(
        <React.Fragment key="imgContiner">
          <Source
            id="image"
            type="image"
            key="image"
            url={"https://trackfy-frontend-assets.s3.us-east-2.amazonaws.com/toyoBackground.png"}
            coordinates={[
              [-47.129009039400984, -22.730909158284618],
              [-47.12714082178209, -22.731119024417026],
              [-47.127488118647705, -22.733460666794457],
              [-47.12934436051205, -22.73320662262644],
            ]}
          >
            <Layer type="raster" />
          </Source>
        </React.Fragment>
      );
    }
  
    areasComponents.push(
      <React.Fragment key="imgContiner2">
        <Source
          id="image2"
          type="image"
          key="image2"
          url="https://trackfy-frontend-assets.s3.us-east-2.amazonaws.com/toyo2Background.png"
          coordinates={[
            [-47.128323270810085, -22.725893644393494],
            [-47.12624187661439, -22.726215260368093],
            [-47.126697852147345, -22.72853582091129],
            [-47.12866659356379, -22.728273584699792],
          ]}
        >
          <Layer type="raster" />
        </Source>
      </React.Fragment>
    );
  }

  areasData.forEach((area: MapAreasData) => { areasComponents.push(parseArea(area, mode, restrictedAreas, dangerAreas, safeArea)) });

  if(mode === "notevacuating" && restrictedAreas.length > 0)
    areasComponents.push(
      <S.CraneAreaCard key={'CraneAreaCard'}>
        <S.CraneAreaContent key={'CraneAreaContent'}>
          <IconCraneArea key={'IconCraneArea'} />
          <S.StyledLegend key={'StyledLegend'}>Área restrita</S.StyledLegend>
        </S.CraneAreaContent>
      </S.CraneAreaCard>
  );

  setMapAreas({ mapGeofences: areasComponents.concat(restrictedAreas), requestedAreas: requestedAreas });
};

export const useMapArea = (filterHelmetsArea: boolean, mode: string, dangerAreas?: string[], safeArea?: string) => {
  const [mapAreas, setMapAreas] = useState<MapAreas>({ mapGeofences: [], requestedAreas: [] });
  const { getClientId } = useAuth();
  const mountedRef = useIsMounted();
  const token: any = cookieService.get('token');
  const user = localStorage.getItem('@trackfy_user_id');
  const userId = user ? Number.parseInt(user) : 1;
  
  useEffect(() => {
    mountedRef.current = true;
    const currentSimpleAreasRequest: CancelTokenSource = Axios.CancelToken.source();
    const endpoint = 'https://backend.trackfyapp.com/rest/monitoring/simpleareas';

    if (mountedRef.current)
      requestAreas(
        mountedRef,
        token,
        endpoint,
        getClientId(),
        filterHelmetsArea,
        setMapAreas,
        mode,
        userId,
        dangerAreas,
        safeArea
      );

    return () => {
      mountedRef.current = false;
      currentSimpleAreasRequest.cancel('request canceled by the user.');
    };
  }, [mode, dangerAreas]);

  return { mapAreas };
};
