import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import useSwr from 'swr';
import { useMap } from 'react-map-gl';

import { useAuth, useIsMounted, useTrackfyApi } from '../../../../../../../../hooks';
import cookieService from '../../../../../../../../services/CookieService';
import { fetchData } from '../../../../../../../../services/requests';
import { useDetectiveContext } from '../../../context/DetectiveContext';
import { MonitoringCollaboratorData } from '../../../../../../../../types/MonitoredData';
import { useCompanyFilterContext } from '../../../../../../../../stores/CompanyFilterContext';
import { pendoTrack } from '../../../../../../../../services/SystemMonitor';

export const useSearch = () => {
  const { getClientId } = useAuth();

  // const [active, setActive] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);
  const { generateMonitoredCollaboratorsApi } = useTrackfyApi();
  const {
    dataMonitoredCollaborators,
    updateDataMonitoredCollaborators,
    updateListCollaborators,
    searchListActive,
    setSearchListActive,
  } = useDetectiveContext();
  const [collaboratorsLoading, setCollaboratorsLoading] = useState<boolean>(false);

  const token: any = cookieService.get('token');
  const value: React.MutableRefObject<string> = useRef('');
  const currentRequest: CancelTokenSource = Axios.CancelToken.source();
  const isMounted = useIsMounted();
  const { selectedCompany } = useCompanyFilterContext();
  const companyId = selectedCompany?.id ? selectedCompany?.id : '0';
  const clientId: number = getClientId();

  //const user = localStorage.getItem('@trackfy_user_id');
  //const userId = user ? Number.parseInt(user) : 1;
  //const useV2 = (clientId == 11 || clientId == 1 || clientId == 6 || userId == 6);

  /*const endpointPath = (useV2) ? //`http://localhost:8083/rest/v2/monitoring/collaborators` 
    'https://backend.trackfyapp.com/rest/v2/monitoring/collaborators' : `/monitoring/collaborators`;*/
      
  const endpointPath = 'https://backend.trackfyapp.com/rest/v2/monitoring/collaborators';
  const url = `${endpointPath}${companyId ? `?companyId=${companyId}` : ''}`;
  const { mymap } = useMap();

  useSwr(
    mounted ? url : null,
    async (url: string) => {
      await fetchData(token, url, { clientId }, currentRequest.token)
        .then((res: AxiosResponse<any>) => {
          const { data } = res;

          if (!isMounted.current) return;
          updateDataMonitoredCollaborators(data);
        })
        .catch(() => {
          if (!isMounted.current) return;
          updateDataMonitoredCollaborators([]);
        });
    },
    {
      revalidateOnFocus: false,
      refreshInterval: 30000,
    }
  );

  // Filtra os dados dos recursos
  const getListMonitoredCollaborators = useCallback(
    (search?: string) => {

      value.current = search || value.current;
      const collaborators = dataMonitoredCollaborators.filter(
        (collaborator: MonitoringCollaboratorData) => {
          if (!collaborator) return !collaborator;
          //if(useV2){
            return collaborator.collaborator.trim().toUpperCase().includes(value.current.trim().toUpperCase());
          //} 
          
          /*else {
            return (
              collaborator.collaborator
                .trim()
                .toUpperCase()
                .includes(value.current.trim().toUpperCase()) ||
              collaborator.historic[0].area
                .trim()
                .toUpperCase()
                .includes(value.current.trim().toUpperCase())   
            );
          }*/
        }
      );
      
      updateListCollaborators(collaborators);

    },[dataMonitoredCollaborators]

  );

  const handleClickButtonSeacrh = () => {
    if (mymap?.getZoom() != 17) mymap?.setZoom(17);

    value.current = '';
    getListMonitoredCollaborators();
    // setActive(!active);
    setSearchListActive(!searchListActive);
    pendoTrack('Tela de monitoramento', 'Detetive', 'Clique');
  };

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if (mounted) {
      if (clientId != null)
        generateMonitoredCollaboratorsApi(
          clientId,
          Number.parseInt(companyId),
          currentRequest.token,
          updateDataMonitoredCollaborators,
          setCollaboratorsLoading
        );
      else
        generateMonitoredCollaboratorsApi(
          2,
          Number.parseInt(companyId),
          currentRequest.token,
          updateDataMonitoredCollaborators,
          setCollaboratorsLoading
        );
    }

    return () => {
      currentRequest.cancel('Operation canceled by the user.');
      mounted = false;
    };
  }, [companyId]);

  useEffect(() => {
    let initialized = true;

    if (initialized) {
      setMounted(true);

      if (dataMonitoredCollaborators.length > 0) {
        getListMonitoredCollaborators('');
      }
    }

    return () => {
      currentRequest.cancel('Operation canceled by the user.');
      initialized = false;
    };
  }, [dataMonitoredCollaborators]);

  if (collaboratorsLoading)
    return { searchListActive, handleClickButtonSeacrh, getListMonitoredCollaborators };

  return { searchListActive, handleClickButtonSeacrh, getListMonitoredCollaborators };
};
