import { ToolFilled } from '@ant-design/icons';
import React from 'react';

import {
  BarChart,
  CartesianGrid,
  XAxis,
  ResponsiveContainer,
  LabelList,
  Legend,
  Bar,
  YAxis,
  Tooltip,
} from 'recharts';
import { DeslocamentosPe } from '../../../../types/Insights';

type VerticalBarChartProps = {
  deslocamentosPeChartData: DeslocamentosPe[] | undefined;
};

export const VerticalBarChart: React.FC<VerticalBarChartProps> = ({ deslocamentosPeChartData }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={700} height={250} data={deslocamentosPeChartData}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="nome" />
        <YAxis type="number" domain={[0, 2]} hide={true} />
        <Legend />
        <Tooltip/>
        <Bar 
          dataKey="chegada" 
          fill="#008AE5"
          radius={[5, 5, 0, 0]} 
          barSize={40} 
          name='Chegada'
         >
          <LabelList dataKey="chegadaLabel" position="insideBottom" offset={30} />
        </Bar>
        <Bar 
          dataKey="idaAlmoco" 
          fill="#E17D00" 
          radius={[5, 5, 0, 0]} 
          barSize={40} 
          name='Ida almoço'
        >
          <LabelList dataKey="idaAlmocoLabel" position="insideBottom" offset={30} />
        </Bar>
        <Bar 
          dataKey="almoco" 
          fill="#E57056" 
          radius={[5, 5, 0, 0]} 
          barSize={40} 
          name= 'Almoço'
        >
          <LabelList dataKey="almocoLabel" position="insideBottom" offset={30} />
        </Bar>
        <Bar
          dataKey="voltaAlmoco"
          fill="#00A0A9"
          radius={[5, 5, 0, 0]}
          barSize={40}
          name= 'Volta almoço'
        >
          <LabelList dataKey="voltaAlmocoLabel" position="insideBottom" offset={30} />
        </Bar>
        <Bar 
          dataKey="saida" 
          fill="#F8581D"
          radius={[5, 5, 0, 0]}
          barSize={40}
          name= 'Saída'
        >
          <LabelList dataKey="saidaLabel" position="insideBottom" offset={30} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
