import React from 'react';

import { TableStyled, Thead } from './styles';
import TableBody from './TableBody';

export type TableHeader<T = unknown> = {
  title: string;
  icon: JSX.Element | undefined;
  customColumn?: (row: T) => React.ReactNode;
};

type TableProps = {
  tableHeaders: TableHeader[];
  tableData: Array<string[]>;
  loading: boolean;
  isEmpty: boolean;
  emptyMessage?: string;
  backgroundColors?: string[];
};

const Table: React.FC<TableProps> = (props: TableProps) => {
  const { tableHeaders, tableData, loading, isEmpty, backgroundColors, emptyMessage } = props;

  return (
    <TableStyled>
      <Thead>
        <tr>
          {tableHeaders.map((item: any, index: number) => (
            <td key={`${index}-${item.title}`}>
              {item.title} {item.icon}
            </td>
          ))}
        </tr>
      </Thead>
      <TableBody tableData={tableData} 
                 loading={loading} 
                 isEmpty={isEmpty} 
                 backgroundColors={backgroundColors} 
                 emptyMessage={emptyMessage}/>
    </TableStyled>
  );
};

export default Table;
