import styled from 'styled-components';

export type TooltipContainerProps = {
  visible: boolean;
};

export const TooltipContainer = styled.div<TooltipContainerProps>`
  position: absolute;
  bottom: 100%;
  right: -150px;

  display: ${({ visible }) => (visible ? 'flex' : 'none')};

  background: #ffffff;
  border-radius: 10px 10px 0px 10px;
  box-shadow: 0px 2px 8px 2px rgb(85 85 85 / 8%);

  z-index: 999;
`;
