import { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { getValueExpansion } from '../../../../../services/api/TrackfyService/integration';
import { OnePage } from '../../../types/OnePage';

export const useOnePage = () => {
  const [valueExpansion, setValueExpansion] = useState<OnePage[]>([])
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);
      
      getValueExpansion(currentRequest.token)
        .then((result: AxiosResponse<any>) => {
          setValueExpansion(result.data)
          setIsLoading(false)
        })
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('ValueExpansion request canceled by the user.');
    };
  }, []);

  return {
    isLoading,
    setIsLoading,
    valueExpansion
  };
};
