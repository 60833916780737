import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { getTagsToLink } from '../../services/api/TrackfyService';
import { LinkTags } from '../linkCollaborator/types/linkCollaborators';
import { Select } from '../../components/Select';
import { SelectOption } from '../../components/Select/types';

type CompanySelectProps = {
  label?: string;
  tagSelected: number;
  setTagSelected: (value: number) => void;
};

const TagSelect: React.FC<CompanySelectProps> = (props: CompanySelectProps) => {
  const [tags, setTags] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { tagSelected, setTagSelected, label } = props;

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);

      getTagsToLink(currentRequest.token)
        .then((result: AxiosResponse<any>) => {
          setTags(
            result.data.map((Tag: LinkTags) => {
              return {
                label: Tag.alias,
                value: Tag.id ? Tag.id.toString() : '0',
              };
            })
          );
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('getCompanysApi request canceled by the user.');
    };
  }, []);

  return (
    <Select
      id="avrgtime-Tag-select"
      label={label}
      placeholder="Selecione uma Tag"
      options={tags}
      value={tagSelected}
      loading={isLoading}
      onChanging={(event) => setTagSelected(Number(event.target.value))}
    />
  );
};

export default TagSelect;
