import styled from 'styled-components';

export const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.8rem 1.2rem;
  font: normal 500 1.2rem/1.5 Work Sans, sans-serif;
  background-color: #ffffff;
  width: 6rem;
`;
