import React, { useEffect, useState } from 'react';
import { Map, TileLayer, ZoomControl, Circle, Tooltip } from 'react-leaflet';
import moment from 'moment';
import Axios, { CancelTokenSource } from 'axios';

import { useMovimentContext } from '../context/MovimentContext';
import { useMap } from '../../monitoring/components/MonitoringMap/hooks/useMap';
import { useTrackfyApi } from '../../../hooks/useTrackfyApi';
import { IHeatmapArea } from './types/HeatMapData';
import { IconLoading } from '../../../components/IconLoading';
import Incident from '../../../Icons/Incident';

import styled from 'styled-components';
import './styles.css';
import { useAuth } from '../../../hooks';
import QuestionMark from '../../../Icons/QuestionMark';

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & span {
    font: normal 500 1.5rem/1.5 Work Sans, sans-serif;
    color: #4f5882;
  }
`;

const HeatMapContent: React.FC = () => {
  const { companySelected, professionSelected, initialDate, finalDate } = useMovimentContext();
  const { getClientId } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [heatMapData, setHeatMapData] = useState<IHeatmapArea[]>([]);
  const { getHeatMapDataApi } = useTrackfyApi();
  const { latitudeRef, longitudeRef, initializeMap } = useMap();
  const companySelectedFormated = companySelected.length > 0 ? parseInt(companySelected.map(item => item.value)[0]) : 0;
  const professionSelectedFormated = professionSelected.length > 0 ? parseInt(professionSelected.map(item => item.value)[0]) : 0;

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted)
      getHeatMapDataApi(
        getClientId(),
        companySelectedFormated,
        professionSelectedFormated,
        moment(initialDate).valueOf(),
        moment(finalDate).valueOf(),
        currentRequest.token,
        setIsLoading,
        setHeatMapData,
        setIsEmpty
      );

    return () => {
      mounted = false;
      currentRequest.cancel('HeatMap request canceled by the user.');
    };
  }, [companySelected, professionSelected, initialDate, finalDate]);

  if (isEmpty)
    return (
      <EmptyContainer>
        <Incident />
        <span>Não há dados de movimentação para o período selecionado</span>
      </EmptyContainer>
    );

  if(companySelected.length > 1 || professionSelected.length > 1)
      return (
        <EmptyContainer>
          <QuestionMark />
          <span>Para visualizar os dados, selecione apenas um único item em cada filtro</span>
        </EmptyContainer>
      );
    
  return (
    <>
      {initializeMap ? (
        <div>
          <Map
            center={[latitudeRef.current, longitudeRef.current]}
            zoom={40}
            scrollWheelZoom={false}
            zoomControl={false}
            className="leaflet-heatMap"
          >
            <TileLayer
              attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
            <ZoomControl position="topleft" />

            {heatMapData.map((area: IHeatmapArea, index:number) => (
              <Circle
                center={[area.center[0], area.center[1]]}
                radius={area.radius}
                color={area.color}
                fillColor={area.fillColor}
                opacity={area.opacity}
                key={index + area.name + area.value}
              >
                <Tooltip>{area.name}</Tooltip>
              </Circle>
            ))}
          </Map>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconLoading />
        </div>
      )}
    </>
  );
};

export default HeatMapContent;
