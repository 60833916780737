import { useEffect, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import moment from 'moment';

import { useAuth } from '../../../../../hooks';
import { getCollaboratorsCount } from '../../../../../services/api/TrackfyService';
import { CollaboratorsCount } from '../../../../indicators/types/Indicators';

interface CollaboratorsCountProps {
  indicator: string;
  loading: boolean;
}

export const useCollaboratorsCount = () => {
  const [collaboratorsIndicator, setCollaboratorsIndicator] = useState<CollaboratorsCountProps>({indicator : '', loading: true});
  const { getClientId } = useAuth();
  const companyId = '0';
  const clientId: number = getClientId();

  useEffect(() => {
    setCollaboratorsIndicator((prev: CollaboratorsCountProps) => ({...prev,  loading: true}));
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if (mounted) {
      getCollaboratorsCount(clientId, companyId, moment().format('YYYY-MM-DD'), currentRequest.token)
        .then((data: CollaboratorsCount[]) => {
          let indicatorText = '0';

          if (data && data.length > 0) indicatorText = data[0].value.toString();

          setCollaboratorsIndicator({indicator:indicatorText, loading: false});
        })
        .catch((err: any) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('CollaboratorCount request canceled by the user.');
    };
  }, [companyId]);

  return { collaboratorsIndicator };
};
