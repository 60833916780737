import React, { useCallback } from 'react';
import styled from 'styled-components';

import { pendoTrack } from '../../../services/SystemMonitor';

type Props = {
  screen: string;
  title: string;
  index: number;
  selectedTab: number;
  setSelectedTab: (index: number) => void;
};

type PropsTypeActive = {
  active: boolean;
};

const List = styled.li`
  width: 150px;
  height: 40px;
  text-align: center;
  list-style: none;
  border-style: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${(props: PropsTypeActive) => (props.active ? '#f3f3f3' : '#0823ad')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  min-width: max-content;
  flex-basis: 150px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  @media (max-width: 500px) {
    width: 89.5px;
  }
`;

const Label = styled.p`
  font: normal 500 1.6rem/1.5 Work Sans, sansserif;
  color: ${(props: PropsTypeActive) => (props.active ? '#0e1c60' : '#E4E8FB')};
  font-weight: ${(props: PropsTypeActive) => (props.active ? 500 : 400)};
  cursor: pointer;
  margin: 0;
  transition: all 0.3s;
  user-select: none;
`;

export const TabTitle: React.FC<Props> = ({
  screen,
  title,
  setSelectedTab,
  index,
  selectedTab,
}) => {
  const onClick = useCallback(() => {
    if (process.env.NODE_ENV === 'production') pendoTrack(screen, title, 'Visualização');
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <List active={index === selectedTab}>
      <Label active={index === selectedTab} onClick={onClick}>
        {title}
      </Label>
    </List>
  );
};
