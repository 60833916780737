import styled from 'styled-components';

interface SubjectFilterProps {
  background: boolean,
}

export const SubjectContainer = styled.div`
  width: 100%;
  //min-height: 250px;
  background: #ffffff;
  border: 0.5px solid #ebebf9;
  box-sizing: border-box;
  //box-shadow: 8px 8px 16px 4px rgba(80, 94, 165, 0.13);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 24px;
  gap: 32px
`;
export const SubjectTitle = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  //position: absolute;
`;
export const SubjectTitleLabel = styled.div`
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
  color: #0E1742
`
export const ItemContent = styled.div`
  display: flex;
  padding: 8px 12px 9px 8px;
  //align-items: center;
  gap: 8px;
  border-radius: 8px;
  border-left: 3px solid var(--State-color-Info_Color, #2F80ED);
  background: rgba(119, 177, 255, 0.15);
  cursor: pointer;
`
export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;  
`
export const ItemQuestion = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  color: #000;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
`
export const ItemAnswer = styled.div`
  //display: flex;
  width: 100%;
  color: #131212;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400; 
`
export const ItemQuestionAndAswer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0px;
`
export const ItemIcon = styled.div`
  display: flex
`
export const SubjectFilter = styled.div`
  display: flex;
  gap: 20px;
  overflow: auto;
`
export const SubjectFilterItems = styled.div<SubjectFilterProps>`
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 100px;
  border: 1px solid #2F4CDD;
  color: ${(props) => props.background ? '#ffffff' : '#2F4CDD'};
  background: ${(props) => props.background ? '#2F4CDD' : ''};
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700; 
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`
export const HeaderQuestion = styled.div`
  color: #18266F;
  text-align: center;
  font-family: "Work Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
`