import React from 'react';

import { InsightsContextProvider } from './context/InsightsContext';
import { PageHeader } from '../../components/PageStructure/PageHeader';
import DeslocamentosPeChart from './components/charts/DeslocamentoPeChart';
import { DeslocamentosPeContextProvider } from './components/charts/DeslocamentoPeChart/context/DeslocamentosPe';
import { DeslocamentosCarroContextProvider } from './components/charts/DeslocamentoCarroChart/context/DeslocamentosPe';
import DeslocamentosCarroChart from './components/charts/DeslocamentoCarroChart';
import { VelocidadeMediaContextProvider } from './components/charts/VelocidadeMedia/context/VelocidadeMedia';
import VelocidadeMediaChart from './components/charts/VelocidadeMedia';
import { OtimizacoesContextProvider } from './components/charts/Otimizacoes/context/Otimizacoes';
import OtimizacoesChart from './components/charts/Otimizacoes';
import { RoiContextProvider } from './components/charts/Roi/context/RoiContext';
import RoiChart from './components/charts/Roi';
import { EconomiaDiariaContextProvider } from './components/charts/EconomiaDiaria/context/EconomiaDiaria';
import EconomiaDiariaChart from './components/charts/EconomiaDiaria';
import { EconomiaContextProvider } from './components/charts/Economia/context/EconomiaContext';
import EconomiaChart from './components/charts/Economia';
/*import InsightsOptions from './components/InsightsOptions';
import { FeedbacksContainer } from './components/feedbacks';
import { AverageTimeInsightsContextProvider } from './components/charts/AverageTimeInsightsChart/context/AverageTimeInsightsContext';
import AverageTimeInsightsChart from './components/charts/AverageTimeInsightsChart';
import { LunchContextProvider } from './components/charts/LunchChart/context/LunchContext';
import LunchChart from './components/charts/LunchChart';
import { ChegadaSaidaContextProvider } from './components/charts/ChegadaSaidaChart/context/ChegadaSaidaContext';
import ChegadaSaidaChart from './components/charts/ChegadaSaidaChart';
import { RetiradaPTContextProvider } from './components/charts/RetiradaPTChart/context/RetiradaPTContext';
import RetiradaPTChart from './components/charts/RetiradaPTChart';*/

import * as S from './styles';
import { Container, ContentContainer } from '../../components/PageStructure/styles';

export const InsightsContainer: React.FC = () => {
  return (
    <Container>
      <PageHeader title="Insights" />
      <ContentContainer value="">
        <InsightsContextProvider>
          {/*<InsightsOptions />
          <FeedbacksContainer />*/}
          <S.ChartRow>
             {/*<S.Card>
              <AverageTimeInsightsContextProvider>
                <AverageTimeInsightsChart />
              </AverageTimeInsightsContextProvider>
            </S.Card>
            <S.Card>
              <LunchContextProvider>
                <LunchChart />
              </LunchContextProvider>
            </S.Card>*/}
             <S.Card>
              <DeslocamentosPeContextProvider>
                <DeslocamentosPeChart/>
              </DeslocamentosPeContextProvider>
            </S.Card>
            <S.Card>
              <DeslocamentosCarroContextProvider>
                <DeslocamentosCarroChart/>
              </DeslocamentosCarroContextProvider>
            </S.Card>
          </S.ChartRow>
          <S.ChartRow>
            {/*<S.Card>
              <ChegadaSaidaContextProvider>
                <ChegadaSaidaChart />
              </ChegadaSaidaContextProvider>
            </S.Card>
            <S.Card>
              <RetiradaPTContextProvider>
                <RetiradaPTChart />
              </RetiradaPTContextProvider>
            </S.Card>*/}
            <S.Card>
              <VelocidadeMediaContextProvider>
                <VelocidadeMediaChart/>
              </VelocidadeMediaContextProvider>
            </S.Card>
            <S.Card>
              <OtimizacoesContextProvider>
                <OtimizacoesChart/>
              </OtimizacoesContextProvider>
            </S.Card>
          </S.ChartRow>
          <S.ChartRow>
            <S.Card>
              <EconomiaDiariaContextProvider>
                <EconomiaDiariaChart/>
              </EconomiaDiariaContextProvider>
            </S.Card>
            <S.Card>
              <RoiContextProvider>
                <RoiChart/>
              </RoiContextProvider>
            </S.Card>
          </S.ChartRow>
          <S.ChartRow>
            <S.Card>
              <EconomiaContextProvider>
                <EconomiaChart/>
              </EconomiaContextProvider>
            </S.Card>
          </S.ChartRow>
        </InsightsContextProvider>
      </ContentContainer>
    </Container>
  );
};
