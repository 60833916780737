import moment from 'moment';
import React, { createContext, ReactNode, useState, useContext } from 'react';
import { useAuthContext } from '../../../stores/AuthContext';

type Props = {
  children: ReactNode;
};

type IncidentsContextProps = {
  professionSelected: number;
  areaSelected: number;
  initialDate: any;
  finalDate: any;
  setProfessionSelected: (profession: number) => void;
  setAreaSelected(area: number): void;
  updatePeriod: (initial: any, final: any) => void;
};

const IncidentsContext = createContext<IncidentsContextProps>({} as IncidentsContextProps);

export function IncidentsContextProvider(props: Props) {
  const { children } = props;

  const { authData } = useAuthContext();
  const [initialDate, setInitialDate] = useState<any>(moment());
  const [finalDate, setFinalDate] = useState<any>(moment());
  const [areaSelected, setAreaSelected] = useState<number>(0);
  const [professionSelected, setProfessionSelected] = useState<number>(0)

  const companyId = authData && authData.grpCompanies != 'a' && !authData.grpCompanies.includes(',') ? 
    authData.companyId:0;

  const updatePeriod = (initial: any, final: any) => {
    setInitialDate(initial);
    setFinalDate(final);
  };

  const value = {
    professionSelected,
    areaSelected,
    initialDate,
    finalDate,
    setProfessionSelected,
    setAreaSelected,
    updatePeriod
  };

  return (
    <IncidentsContext.Provider value={value}>{children}</IncidentsContext.Provider>
  );
}

export function useIncidentsContext() {
  const context = useContext(IncidentsContext);

  if (typeof context === 'undefined') {
    throw new Error('IncidentsContext must be used within an useIncidentsContext');
  }

  return context;
}
