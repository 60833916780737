import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { SelectOption } from '../../components/Select/types';
import { Select } from '../../components/Select';
import { getLogtypes } from '../../services/api/TrackfyService/integration';
import { useAuth } from '../../hooks/useAuth';
import { useAuthContext } from '../../stores/AuthContext';

type LogtypesSelectProps = {
  label?: string;
  logTypesSelected: number;
  setLogtypesSelected: (value: number) => void;
};

type Logtypes = {
  name: string;
  id: number;
}

const LogtypesSelect: React.FC<LogtypesSelectProps> = (props: LogtypesSelectProps) => {
  const [lotTypes, setLogTypes] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { logTypesSelected, setLogtypesSelected, label } = props;

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
        setIsLoading(true);

        getLogtypes(currentRequest.token)
          .then((result: AxiosResponse<any>) => {
            setLogTypes(
              result.data.map((Logtypes: Logtypes) => {
                return {
                  label: Logtypes.name,
                  value: Logtypes.id ? Logtypes.id.toString() : '0',
                };
              })
            );
            setIsLoading(false);
          })
          .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('getLogtypessApi request canceled by the user.');
    };
  }, []);

  const onChanging = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLogtypesSelected(Number(event.target.value));
    const label = event.target.selectedOptions[0].label;
  };

  return (
    <Select
      id="avrgtime-Logtypes-select"
      label={label}
      placeholder="Selecione um tipo"
      options={lotTypes}
      value={logTypesSelected}
      loading={isLoading}
      onChanging={(event) => onChanging(event)}
    />
  );
};

export default LogtypesSelect;
