import { useState, useEffect } from 'react';
//import { useCookies } from 'react-cookie';
import useSwr from 'swr';
import Axios from 'axios';

import { useIsMounted } from '.';
import api from '../services/api';

export function useDataMonitored<Data = any>(url: string) {
  const [dataMonitored, setDataMonitored] = useState<any>();
  const [errorMessage, setErrorMessage] = useState();
  const [mounted, setMounted] = useState(false);

  // const [cookie] = useCookies(['auth']);

  const isMounted = useIsMounted();

  useEffect(() => {
    setMounted(true);
  }, []);

  const { data, error } = useSwr<Data>(
    mounted ? url : null,
    async (url: string) => {
      // const { token } = cookie as any;

      const response: any = await api.get(url, {
        cancelToken: Axios.CancelToken.source().token,
        /* headers: {
          Authorization: `Bearer ${token}`,
        }, */
      });

      return response;
    },
    {
      errorRetryCount: 2,
      errorRetryInterval: 1,
      refreshInterval: 60000,
    }
  );

  if (!data) return;

  if (!isMounted.current) return;

  setDataMonitored(data);
  setErrorMessage(error);

  return { dataMonitored, errorMessage };
}

export default useDataMonitored;
