import { Select as SelectInput } from 'antd';
import styled from 'styled-components';
import Vector from '../../../assets/vector.svg';

interface SelectProps {
  borderColor?: string;
}

export const Select = styled(SelectInput)<SelectProps>`
  &&& {
    width: 100%;
    & > .ant-select-selector {
      display: flex;
      flex-direction: row;
      align-items: center;
      //background: #ffffff;
      //border: 1px solid #e4e8fb;
      background: url(${Vector}) no-repeat right transparent;
      background-position-x: calc(100% - 12px);
      border: ${({ borderColor }) => `1px solid ${borderColor || '#6d6f79'}`};
      border-radius: 8px;
      height: 46px;
      transition: border 0.3s ease-in-out;
      &:hover {
        border: thin solid #2f4cddbe;
      }
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: 0 0 0px 2.5px #2f4cdd44;
      border: thin solid #2f4cddbe;
    }

    & .ant-select-selection-placeholder,
    .ant-select-selection-item {
      font: normal 500 1.4rem/1.5 Work Sans, sans-serif;
      //color: #4f5882;
      color: #000000;
      font-size: 14px;
    }
  }
`;
