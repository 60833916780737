import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { Layer, Source } from 'react-map-gl';

import cookieService from '../../../../../../../services/CookieService';
import { useIsMounted } from '../../../../../../../hooks';
import { fetchData } from '../../../../../../../services/requests';
import { MapAreasData } from '../../../../../../../types/MonitoredData';
import { useRegisterAreaContext } from '../../../context/RegisterAreaContext';
import { getEndpoint } from '../../../../../../../services/api/TrackfyService/endpoints';

export const useArea = (isAdmin: boolean) => {
  const mountedRef = useIsMounted();
  const [mapAreas, setMapAreas] = useState<MapAreasData[]>([]);
  const { clientSelected, updateAreas, getClientId } = useRegisterAreaContext();
  const token: any = cookieService.get('token');

  const requestAreas = async (url: string): Promise<void> => {
    const currentClient = isAdmin ? clientSelected : getClientId();
    await fetchData(token, url, { clientId: currentClient })
      .then((res: AxiosResponse<any>) => {
        if (!res.data || res.data.length === 0) return;
        const areas = res.data.filter((item: any) => item.nome !== 'ÁREA NÃO MAPEADA');
        setMapAreas(areas);
      })
      // eslint-disable-next-line no-console
      .catch((error: any) => console.error(error));
  };

  useEffect(() => {
    const currentSimpleAreasRequest: CancelTokenSource = Axios.CancelToken.source(); 
    requestAreas(getEndpoint('simpleareas', false));

    return () => {
      mountedRef.current = false;
      currentSimpleAreasRequest.cancel('request canceled by the user.');
    };
  }, [clientSelected, updateAreas]);

  const parseDataToCoordinates = (coordinates: string) => {
    try {
      const coordinatesFormated = coordinates
        .replace('"', '')
        .split('],[')
        .map((coord: any) =>
          coord
            .replaceAll('[', '')
            .replaceAll(']', '')
            .split(',')
            .map((value: string) => Number(value))
        );

      return coordinatesFormated;
    } catch (_error) {
      return [];
    }
  };

  function createArea() {
    const areas = mapAreas
      .filter((area: MapAreasData) => area.coordenadas)
      .map((area: MapAreasData, idx: number) => {
        const { nome, cor, coordenadas } = area;

        const coordinates = parseDataToCoordinates(coordenadas);

        const geojson: FeatureCollection<Geometry, GeoJsonProperties> = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                title: nome,
              },
              geometry: {
                type: 'Polygon',
                coordinates: [coordinates],
              },
            },
          ],
        };

        const paint: mapboxgl.FillPaint = {
          'fill-opacity': 0.4,
          'fill-color': cor,
        };

        const paintBorder: mapboxgl.LinePaint = {
          'line-width': 1,
          'line-color': cor,
        };

        return (
          <Source type="geojson" data={geojson} key={idx}>
            <Layer id={idx + nome} type="fill" paint={paint} />
            <Layer id={idx + nome + 'border'} type="line" paint={paintBorder} />
          </Source>
        );
      });

    return areas;
  }

  return { mapAreas, createArea };
};
