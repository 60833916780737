import React from 'react';
import { PageHeader } from '../../../../components/PageStructure/PageHeader';
import { HeaderTimeline } from './HeaderTimeline';
import { DataPeriod } from './DataPeriod';
import { StageName } from './HeaderTimeline/mock';

const pageHeaderStyle: React.CSSProperties = {
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '2.4rem',
};

interface Props {
  stage: StageName;
  periodType: string;
}
export const HomeHeader: React.FC<Props> = ({ periodType, stage }) => {
  return (
    <PageHeader title="Home" style={pageHeaderStyle}>
      {/*<HeaderTimeline stage={stage} />
      <DataPeriod periodType={periodType} />*/}
    </PageHeader>
  );
};
