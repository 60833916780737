import { useCallback, useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import moment from 'moment';
import { useProductivityVSDisplacementContext } from '../context/ProductivityVSDisplacementContext';
import { useDisplacementContext } from '../../context/DisplacementContext';
import { ProductivityVSDisplacement } from '../../types/types';
import { getProductivityVSDisplacement } from '../../../../services/api/TrackfyService';

export const useProductivityVSDisplacement = () => {
  const { 
    companySelected, 
    professionSelected, 
    initialDate, 
    finalDate
  } = useDisplacementContext();
  const { setProdVSDisValues, setProdVSDisChartData,areaSelected,timeVisualization } = useProductivityVSDisplacementContext();
  const [isLoading, setIsLoading] = useState(true);

  const dividerPerFive = useCallback(
    (base: ProductivityVSDisplacement[]): Array<ProductivityVSDisplacement[]> => {
      const divisor: number = 2;
      const newArray: Array<ProductivityVSDisplacement[]> = [];

      for (let i = 0; i < base.length; i += divisor) {
        const divider: ProductivityVSDisplacement[] = base.slice(i, i + divisor);
        newArray.push(divider);
      }
      return newArray;
    },
    []
  );

  const handleChartData = (data: ProductivityVSDisplacement[]) => {
    if (data && data.length > 0) {
      batchedUpdates(() => {
        setProdVSDisValues(dividerPerFive(data));
        setProdVSDisChartData(dividerPerFive(data)[0]);
      });
    } else {
      setProdVSDisChartData([{ 
        date: "sem registros", 
        serviceTime: 0, 
        serviceTimeLabel: "0%",
        displacement:0,
        displacementLabel: "0%",
        almox: 0,
        almoxLabel: "0%"}]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);
      batchedUpdates(() => setProdVSDisValues([]));

      getProductivityVSDisplacement(
        companySelected,
        professionSelected,
        moment(initialDate).startOf('day').valueOf(),
        moment(finalDate).valueOf(),
        areaSelected,
        timeVisualization.week,
        timeVisualization.month,
        timeVisualization.year,
        currentRequest.token
      )
        .then((result: AxiosResponse<any>) => handleChartData(result.data))
        .catch((err) => console.log(err));
    }
    return () => {
      mounted = false;
      currentRequest.cancel('ProductivityVSDisplacementChart request canceled by the user.');
    };
  }, [companySelected, professionSelected,initialDate,finalDate, areaSelected, timeVisualization]);

  return {
    dividerPerFive,
    handleChartData,
    isLoading,
    setIsLoading
  };
};