import React from 'react';

import { RegisterAreaContextProvider } from './context/RegisterAreaContext';
import { AreaDesigner } from './AreaDesigner';

import * as S from '../styles';

type AreasRegistrationContainerProps = {
  isAdmin: boolean;
}

export const AreasRegistrationContainer: React.FC<AreasRegistrationContainerProps> = ({isAdmin}) => (
  <S.Content>
    <RegisterAreaContextProvider>
      <AreaDesigner isAdmin={isAdmin} />
    </RegisterAreaContextProvider>
  </S.Content>
);
