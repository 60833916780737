import React, { useState } from 'react';

import MachinesSummaryReport from './MachinesSummaryReport';
import { headers } from './Header';
import { MachinesSummaryData } from '../types/MachinesSummaryTypes';

const MachinesContent: React.FC = () => {
  const [dailyData, setDailyData] = useState<MachinesSummaryData>({ data: [], statusValues: [], idValues:[] });
  return (
    <MachinesSummaryReport detailHeader={headers} setData={setDailyData} detailData={dailyData} />
  );
};

export default MachinesContent;