import React, { useState } from 'react';

import * as S from './styles';

type props = {
  clientId: string | null;
};

export const SatisfactionSurvey: React.FC<props> = (props: props) => {
  const [showSurvey, setShowSurvey] = useState<boolean>(true);
  const { clientId } = props;

  if (showSurvey)
    return (
      <S.SurveyContainer>
        <S.ModalContent>
          <S.Modaltitle>
            Nos ajude a melhorar o Trackfy respondendo o nosso{' '}
            <S.Link href="https://forms.gle/ZaDgubNpA7T1LX2E8">formulário de satisfação</S.Link> com
            apenas 5 perguntas
          </S.Modaltitle>
          `
          <S.Button onClick={() => setShowSurvey(false)}>
            <S.ButtonText>Já respondi!</S.ButtonText>
          </S.Button>
        </S.ModalContent>
      </S.SurveyContainer>
    );

  return <></>;
};
