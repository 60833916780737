import styled from 'styled-components';

export const Chart = styled.div`
  width: 100%;
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;
  padding: 0 3px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

export const Text = styled.p`
  font: normal 500 1.4rem/1.2 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0;
`;

export const ContainerBar = styled.div`
  width: 100%;
  height: 8px;
  margin-top: 7px;
  background-color: #dbdff1;
  border-radius: 30px;
  overflow: hidden;
`;

export const Bar = styled.div`
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 30px;
  transition: width 1.8s;
`;
