import { PersonHistory } from '../../../../../../../../types/MonitoredData';

export const initialHistory: PersonHistory = {
  collaborator: '',
  currentLocation: {
    area: '',
    color: '',
    time: 0,
    lat: 0,
    lng: 0,
  },
  historic: [
    {
      area: '',
      color: '',
      time: 0,
    },
  ],
};
