import styled from 'styled-components';

export const TextInsightsCard = styled.div`
  display: inline;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 350px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: rgb(85 85 85 / 8%) 0px 0px 8px 2px;
  padding: 1em;
  overflow: hidden auto;
  cursor: pointer;
  white-space: nowrap;
`;

export const TextInsightsContentBackground = styled.div`
  //margin-top: 19px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  color: #4f5882;
  background: #f4f5ff;
  padding: 1em;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TextInsightsContent = styled.div`
  //margin-top: 19px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  color: #4f5882;
  padding: 1em;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ExpandedContainer = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 75em;
  height: 35em;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: rgb(85 85 85 / 8%) 0px 0px 8px 2px;
  padding: 1em;
  padding-top: 2em;
  gap: 15px;
  overflow: auto;
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0px;
  top: 5px;
`;