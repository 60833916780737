import React from 'react';

import { PageHeader } from '../../components/PageStructure/PageHeader';
import { SystemlogsContextProvider } from './context/SystemlogsContext';
import { Container, Content, SysLogsContainer } from './styles';
import SystemlogsOptions from './SystemlogsOptions';
import { SystemLogsTable } from './SystemlogsTable';

export const SystemLogsContainer: React.FC = () => {
    return (
      <Container>
        <PageHeader title="Logs do sistema" />
        <Content>
          <SystemlogsContextProvider>
            <SysLogsContainer>
                <SystemlogsOptions />
                <SystemLogsTable />
              </SysLogsContainer>
          </SystemlogsContextProvider>
        </Content>
      </Container>
    );
  };