import React from 'react';
import tippy from 'tippy.js';

import { PerspectiveFloor } from './PerspectiveFloor';
import { PerspectiveHall } from './PerspectiveHall';
import { OccupationData } from '../../../../../types/MonitoredData';

import * as S from '../styles';

type BuildingFloorProps = {
  area: any;
  isHall: boolean;
};

export const PerspectiveBuildingFloors: React.FC<BuildingFloorProps> = (
  props: BuildingFloorProps
) => {
  const { area, isHall } = props;

  const fireTooltip = () => {
    let isFetching = false;
    let src: any = null;
    let error: any = null;

    tippy('#floorContiner', {
      onShow(instance) {
        if (isFetching || src || error) return;

        isFetching = true;

        let contentHtml: string =
          "<div class='tooltipTitleContainer'>" +
          `<h5 class='tooltipTitle'><strong>${area.nome}:  ${area.total} colaboradores</strong></h5>` +
          `</div>`;

        if (!area.operacional) contentHtml = `<h5 class='tooltipEnter'>Área não monitorada</h5>`;
        else {
          area.data.map((item: OccupationData) => {
            contentHtml =
              contentHtml + `<h5 class='tooltipEnter'>${item.occupation}: ${item.quantity}</h5>`;
          });
        }

        instance.setContent(contentHtml);
      },
      onHidden(instance) {
        instance.setContent('Loading...');
        src = null;
        error = null;
      },
      placement: 'right',
      allowHTML: true,
      content: `<h5 class='tooltipEnter'>Loading...</h5>`,
      theme: 'custom',
    });
  };

  return (
    <S.PerspectiveFloorContainer id="floorContiner" onMouseEnter={fireTooltip}>
      {!isHall ? (
        <PerspectiveFloor productive={area.operacional} color={area.cor} />
      ) : (
        <PerspectiveHall productive={area.operacional} color={area.cor} />
      )}
    </S.PerspectiveFloorContainer>
  );
};
