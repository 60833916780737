import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { hotjar } from 'react-hotjar';

import { Router } from './router';
import { PermissionsContextProvider, AuthContextProvider } from './stores';

import './index.css';

const root = document.getElementById('root');

if (process.env.NODE_ENV === 'production') hotjar.initialize(2239908, 6);

ReactDOM.render(
  <CookiesProvider>
    <AuthContextProvider>
      <PermissionsContextProvider>
        <Router />
      </PermissionsContextProvider>
    </AuthContextProvider>
  </CookiesProvider>,
  root
);
