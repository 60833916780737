import React from 'react';
import styled from 'styled-components';

import IconUsers from '../../Icons/Users';
import IconClock from '../../Icons/Clock';

const Header = styled.div`
  display: flex;
  align-items: center;
  //margin-top: 0.9rem;
  margin-bottom: 2rem;
`;

const Titlecontainer = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 1.6rem;
  }
`;

const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0;
`;

export type AgileanHeaderProps = {
    title?: string;
    hours: number;
};

const AgileanHeader: React.FC<AgileanHeaderProps> = (props: AgileanHeaderProps) => {
    const {title, hours} = props;
    return (
        <Header>
            <Titlecontainer>
            {(hours == 0) ? <IconUsers /> : <IconClock stroke="#B7BEDB" />}
            <Title>{title}</Title>
            </Titlecontainer>
        </Header>
)};

export default AgileanHeader;
