import React, { ComponentType, useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { routesConfig } from '../config';
import { RouteKey } from '../enum';
import { Login } from '../pages/Login';
import { NotFoundPage } from '../pages/NotFound';
import { UserGroupRoute } from '../types';
import { useAuthContext, usePermissionsContext } from '../stores';
import { ApplicationRoutes } from './applicationRoutes';
import { PrivateRoutes } from './privateRoutes';


export type AppRoute = {
  key: RouteKey;
  path: string;
  component: ComponentType<any>;
};

export const Routes: React.FC = () => {
  const [routes, setRoutes] = useState<AppRoute[]>();

  const { authData } = useAuthContext();

  const { permissions, loadingPermissions } = usePermissionsContext();

  useEffect(() => {
    if (!permissions || loadingPermissions || !authData) return;

    setRoutes(
      permissions?.routes?.map((route: UserGroupRoute) => ({
        key: route.key,
        path: route.path,
        component: routesConfig[route.key],
      }))
    );
  }, [authData, permissions, loadingPermissions]);

  // TODO: criar página de NOT FOUND
  return (
    <Switch>
      <Route path="/" component={Login} exact />

      <Route>
        <PrivateRoutes>
          <Switch>
            {routes
              ?.map((route: AppRoute) => (
                <ApplicationRoutes key={route.key} path={route.path} component={route.component} />
              ))
              .concat(
                <Route key="page-404">
                  <ApplicationRoutes path="*" component={NotFoundPage} />
                  <Redirect from="*" to="/404" />
                </Route>
              )}
          </Switch>
        </PrivateRoutes>
      </Route>
    </Switch>
  );
};
