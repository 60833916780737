import React, { createContext, ReactNode, useState, useContext } from 'react';

import { PaginationControls } from '../../../../components/Buttons/PaginationControls';
import { Timeline } from '../../../indicators/types/Indicators';

type ContextValue = {
  timelineData: Timeline[];
  timelineValues: Array<Timeline[]>;
  setTimelineData: (value: Timeline[]) => void;
  setTimelineValues: (arrayAvarageValues: Array<Timeline[]>) => void;
};

const TimelineContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function TimelineContextProvider(props: Props) {
  const { children } = props;

  const [timelineData, setTimelineData] = useState<Timeline[]>([]);
  const [timelineValues, setTimelineValues] = useState<Array<Timeline[]>>([]);

  const value = {
    timelineData,
    setTimelineData,
    timelineValues,
    setTimelineValues,
  };

  return (
    <TimelineContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={timelineValues}
        setNewValue={setTimelineData}
        title="Timeline de conformidade"
      />
    </TimelineContext.Provider>
  );
}

export function useTimelineContext() {
  const context = useContext(TimelineContext);

  if (typeof context === 'undefined') {
    throw new Error('TimelineContext must be used within an TimelineContext');
  }

  return context;
}
