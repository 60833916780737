import styled from 'styled-components';
import { colors } from '../../styles/colors';

export interface StatusCircleProps {
  status: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(243, 243, 243);
  padding: 2.4rem;
  border-radius: 2rem;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(85, 85, 85, 0.08) 0px 2px 8px 2px;
  overflow-y: scroll;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
`;

export const Table = styled.table`
  font-size: 1.6rem;
  width: 100%;
  & td {
    font-size: 1.6rem;
    padding: 0.8rem 0.6rem;
  }
`;

export const Thead = styled.thead`
  font-size: 1.6rem;
  background-color: ${colors.royalBlue};
  color: #ffffff;
  font: normal 600 1.2rem/1.5 Work Sans, sans-serif;
  & td {
    text-align: center;
  }
`;

export const Tbody = styled.tbody`
  font-size: 1.6rem;
  font: normal 500 1.2rem/1.5 Work Sans, sans-serif;
  & tr:nth-child(odd) {
    background-color: rgb(244, 245, 255);
  }
  & td {
    text-align: center;
    width: 20%;
  }
  & * {
    cursor: default;
  }
`;

export const StatusCircle = styled.div<StatusCircleProps>`
  background-color: ${({ status }) => (status ? colors.green : colors.red)};
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  text-align: center;
`;

export const ContentContainerHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2rem;
  padding-top: 4.4rem;
  margin-bottom: 3.2rem;
  gap: 2rem;
  & h1 {
    margin: 0;
    font-size: 2rem;
  }
`;