import React from 'react';
import Axios, { CancelToken, CancelTokenSource } from 'axios';

import { ContentCell } from './ContentCell';
import { UsersIconVuesax } from '../../../Icons/Users-Vuesax';
import { CompanyIcon } from '../../../Icons/Company';
import { RoleIcon } from '../../../Icons/Role';
/*import { EmailIcon } from '../../../Icons/Email';
import { ComputerIcon } from '../../../Icons/Computer';
import { ClockTickIcon } from '../../../Icons/ClockTick';*/
import IconInfo from '../../../Icons/Info';
import { DocumentIcon } from '../../../Icons/Document';
import { CalendarIconVuesax } from '../../../Icons/Calendar-Vuesax';
import { User } from '../types';

import * as S from './styles';
import { revokeUserAccess } from '../../../services/api/TrackfyService/integration';

export interface Props {
  closeModal: () => void;
  requestInvites: (token: CancelToken) => void;
  user?: User;
}

export const ModalDetails: React.FC<Props> = ({ user, closeModal, requestInvites }) => {

  const revokeUser = async () => {
    if (user){
      const currentRequest: CancelTokenSource = Axios.CancelToken.source();

      await revokeUserAccess(user.userId, currentRequest.token)
      .then((response) => {
          requestInvites(currentRequest.token);
          //notification('success', 'Convite cancelado com sucesso!');
        })
      .catch((err) => {} /*notification('error', 'Ocorreu um erro ao cancelar o convite')*/);
    }
  }

  const handleUserExclusion = async () => {
    await revokeUser();
    closeModal();
  }

  return (
    <S.ModalDetailsContent onClick={closeModal}>
      <S.ModalTitle>
        <DocumentIcon />
        <span>Detalhes</span>
        <IconInfo fill="#B3B3B3" />
      </S.ModalTitle>
      {user ? (
        <>
          <S.ContentRow>
            <ContentCell icon={<UsersIconVuesax />} title={'Usuário'} subtitle={user.user} />
            <ContentCell icon={<CompanyIcon />} title={'Empresa'} subtitle={user.company} />
            <ContentCell icon={<RoleIcon />} title={'Perfil'} subtitle={user.profile ? user.profile : ""} />
          </S.ContentRow>
          {/*<S.ContentRow>
            <ContentCell icon={<EmailIcon />} title={'Email'} subtitle={user.email || 'Não cadastrado'} />
            <ContentCell icon={<ComputerIcon />} title={'Número de acessos mensais'} subtitle={'5'} />
            <ContentCell icon={<ClockTickIcon />} title={'Tempo médio por sessão'} subtitle={user.sessionAverageDuration} />
          </S.ContentRow>*/}
          <S.ContentRow>
            <ContentCell icon={<CalendarIconVuesax />} title={'Convite'} subtitle={user.inviteDate || ''} />
            {/*<ContentCell icon={<CalendarIconVuesax />} title={'Primeiro acesso'} subtitle={user.firstLogin || ''}/>
            <ContentCell icon={<CalendarIconVuesax />} title={'Último acesso'} subtitle={user.lastLogin} />*/}
          </S.ContentRow>
          <S.ContentButtonRow>
            <S.ModalButton color="#2F4CDD" onClick={closeModal}>Cancelar</S.ModalButton>
            <S.ModalButton color="#ED3A3A" onClick={handleUserExclusion}>Excluir usuário</S.ModalButton>
            {/*<S.ModalButton color="#2F4CDD" onClick={closeModal}>Histórico de acessos</S.ModalButton>*/}
          </S.ContentButtonRow>
        </>
      ) : (
        <></>
      )}
    </S.ModalDetailsContent>
  );
};