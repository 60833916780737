import React from 'react';

import { useAverageTimeContext } from './context/AverageTimeContext';
import { useAverageTime } from './hooks/useAverageTime';
import { AverageTimeChartHeader } from './components/AverageTimeChartHeader';
import { VerticalBarChart } from './components/VerticalBarChart';
import { IconLoading } from '../../../../components/IconLoading';

import { CardAvgcontent } from './styles';

const AverageTimeChart: React.FC<{}> = () => {
  const { areaSelected, setAreaSelected, averageTimeChartData } = useAverageTimeContext();
  const { isLoading } = useAverageTime();

  return (
    <>
      <AverageTimeChartHeader areaSelected={areaSelected} setAreaSelected={setAreaSelected} />
      <CardAvgcontent>
        {isLoading && <IconLoading />}
        {!isLoading && <VerticalBarChart averageTimeChartData={averageTimeChartData} />}
      </CardAvgcontent>
    </>
  );
};

export default AverageTimeChart;
