import React from 'react';

import { ArrowRightVuesax } from '../../../../Icons/ArrowRight-Vuesax';

import * as S from './styles';

interface Props {
  data: {
    area: string;
    hours: string;
    arrived: string;
    exited: string;
  }[];
}

const partitionDataArray = (props: Props): any[] => {
  const element = [];

  if (props.data.length <= 8) element.push(props.data.slice(0, props.data.length));
  else {
    element.push(props.data.slice(0, 8));

    for (let i = 9; i < props.data.length - 1; i += 16) {
      const elementsLeft = props.data.length - i;
      if (elementsLeft > 16) element.push(props.data.slice(i, i + 16));
      else element.push(props.data.slice(i, i + elementsLeft));
    }
  }

  return element;
};

const getHoursCountItem = (index: number, entrance: boolean): string => {
  if (index === 0) return "#999999";

  if(entrance)
    return index % 2 ?  "#2F4CDD" : "#00B390";
  else
    return index % 2 ? "#00B390" : "#2F4CDD";
};

export const DisplacementChart: React.FC<Props> = (props) => {
  const elements = partitionDataArray(props);

  return (
    <>
      {elements.map((data, index) => (
        <S.Container key={`container_${index}`} marginTop={index > 0 ? '7rem' : '0rem'}>
          <S.HoursCountContainer>
            {data.map((item: any, index: number) => (
              <S.HoursCountSection minHeight={data.length === 1 ? '15rem' : '3rem'}>
                <div key={`hours-count-${index}`}>
                  <S.HoursCountItem
                    isLastItem={false}
                    color={getHoursCountItem(index, true)}
                  >
                    Chegada: {item.arrived}
                  </S.HoursCountItem>
                  <S.HoursCountItem
                    isLastItem={index === data.length - 1}
                    color={getHoursCountItem(index, false)}
                  >
                    Saída: {item.exited}
                  </S.HoursCountItem>
                </div>
              </S.HoursCountSection>
            ))}

            <S.HoursCountSection minHeight={data.length === 1 ? '30rem' : '6rem'}>
              <div>
                <S.HoursCountItem isLastItem={false} color="#2F4CDD">
                  Chegada: {data[data.length - 1].arrived}
                </S.HoursCountItem>
                <S.HoursCountItem isLastItem={false} color="#999999">
                  Saída: {data[data.length - 1].exited}
                </S.HoursCountItem>
              </div>
            </S.HoursCountSection>
          </S.HoursCountContainer>

          <S.ChartContainer>
            {data.map((item: any, index: number) => {
              const isLastItem = index === data.length - 1;
              const isFirstItem = index === 0;
              const color = index % 2 ? '#33E6C3' : '#5970E4';
              return (
                <S.ChartSection
                  key={`chart-item-${index}`}
                  color={color}
                  isFirstItem={isFirstItem}
                  isLastItem={isLastItem}
                  minHeight={data.length === 1 ? '30rem' : '6rem'}
                >
                  <S.ChartSectionLabel isLastItem={false}>{item.area}-</S.ChartSectionLabel>
                  <div />
                  {isLastItem ? <S.ChartSectionLabel isLastItem>{data[0].area}-</S.ChartSectionLabel> : <></>}
                </S.ChartSection>
              );
            })}
          </S.ChartContainer>

          <S.DisplacementContainer>
            {data.map((item: any, index: number, arr: any[]) => (
              <S.DisplacementSection minHeight={data.length === 1 ? '15rem' : '3rem'}>
                <S.DisplacementItem
                  color={index % 2 ? '#00b390' : '#2f4cdd'}
                  key={`displacement-item-${item.area}`}
                >
                  <span>
                    {item.area} <ArrowRightVuesax /> {arr[index + 1] ? arr[index + 1].area : arr[0].area}
                  </span>
                  <span>{item.hours}</span>
                </S.DisplacementItem>
              </S.DisplacementSection>
            ))}
          </S.DisplacementContainer>
        </S.Container>
      ))}
    </>
  );
};
