import React, { ReactNode } from 'react';

import * as S from './styles';

export type TooltipProps = {
  children: ReactNode;
  visible?: boolean;
};

export const Tooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
  const { children, visible = false } = props;
  return <S.TooltipContainer visible={visible}>{children}</S.TooltipContainer>;
};
