import { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { useCompanyFilterContext } from '../../../../../../../stores';
import { getHHIndicators } from '../../../../../../../services/api/TrackfyService';
import { useAuth } from '../../../../../../../hooks';

type hhIndicator = {
  loading: boolean;
  value: string;
};

export const useHhindicator = () => {
  const [indicator, setIndicator] = useState<hhIndicator>({ loading: true, value: 'Carregando...' });
  const { selectedCompany } = useCompanyFilterContext();
  const { getClientId } = useAuth();

  const clientId: number = getClientId();
  const companyId = selectedCompany?.id ? selectedCompany?.id : '0';

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if (mounted) {
      setIndicator({ loading: true, value: 'Carregando...' });

      getHHIndicators(clientId, companyId, currentRequest.token)
        .then((result: AxiosResponse<any>) => {
          let indicatorText = '0';

          if (result.data) indicatorText = result.data;

          setIndicator({ loading: false, value: indicatorText + ' horas' });
        })
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('Hhindicator request canceled by the user.');
    };
  }, [companyId]);

  return { indicator };
};
