import { useCallback, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import { useAuth } from '../../../../../../hooks/useAuth';
import { useAuthContext } from '../../../../../../stores/AuthContext';
import { mixpanelTrack } from '../../../../../../services/SystemMonitor';
import { useIsMounted } from '../../../../../../hooks/useIsMounted';
import { notification } from '../../../../../../utils/Notification';
import { createNewClient } from '../../../../../../services/api/TrackfyService';
import { CreatedClients } from '../../../../types/ClientCompany';

export const useCreateNewClient = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [addingClients, setAddingClients] = useState<boolean>(false);
  const [createClients, setCreateClients] = useState<CreatedClients>({
    name: '',
    type: '',
    lat: '',
    lng: '',
  });
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();

  const setNameSelected = useCallback((value: string) => {
    setCreateClients((prevState: CreatedClients) => ({
      ...prevState,
      name: value,
    }));
  }, []);

  const setTypeSelected = useCallback((value: string) => {
    setCreateClients((prevState: CreatedClients) => ({
      ...prevState,
      type: value,
    }));
  }, []);

  const setLatSelected = useCallback((value: string) => {
    setCreateClients((prevState: CreatedClients) => ({
      ...prevState,
      lat: value,
    }));
  }, []);

  const setLngSelected = useCallback((value: string) => {
    setCreateClients((prevState: CreatedClients) => ({
      ...prevState,
      lng: value,
    }));
  }, []);

  const isMounted = useIsMounted();

  const isDefined = (): boolean => {
    if (createClients.name && createClients.type) return true;

    return false;
  };

  const handleSaveClient = async (): Promise<boolean> => {
    const isValidFields = isDefined();

    if (!isValidFields) return false;

    setAddingClients(true);

    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    const addNewClientRequest: CreatedClients = {
      name: createClients.name,
      type: createClients.type,
      lat: createClients.lat,
      lng: createClients.lng,
    };

    await createNewClient(addNewClientRequest, currentRequest.token)
      .then(() => {
        notification('success', `${createClients.name} cadastrado com sucesso!`);
        mixpanelTrack(monitorAgent, `Cliente ${createClients.name} foi criado`, userName, authData);
      })
      .catch((err) => {
        console.log(err);
        notification('error', 'Ocorreu um erro ao criar o cliente');
      });

    if (isMounted.current) setAddingClients(false);
    return true;
  };

  return {
    isLoading,
    isDefined,
    handleSaveClient,
    addingClients,
    createClients,
    setCreateClients,
    setNameSelected,
    setTypeSelected,
    setLatSelected,
    setLngSelected,
    setIsLoading,
  };
};
