import React, { useEffect } from 'react';

import { useAuthContext } from '../../../stores/AuthContext';
import { useTextInsightsContext } from '../context/TextInsightsContext';
import { useAuth } from '../../../hooks/useAuth';
import { mixpanelTrack } from '../../../services/SystemMonitor';

import { OptionsContainer } from '../../../components/PageStructure/styles';
import * as S from '../../../styles/structure';
import ClientSelect from '../../filters/ClientSelect';

const TextInsightsOptions: React.FC = () => {
  const { clientSelected, setClientSelected } = useTextInsightsContext();
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  useEffect(() => mixpanelTrack(monitorAgent, 'Insights', userName, authData), []);

  return (
    <OptionsContainer>
        <S.SimpleContainer>
            <ClientSelect
            label={undefined}
            clientSelected={clientSelected}
            setClientSelected={setClientSelected}
            />
        </S.SimpleContainer>
    </OptionsContainer>
  );
};

export default TextInsightsOptions;