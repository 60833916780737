import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { SelectOption } from '../../components/Select/types';
import { Select } from '../../components/Select';
import { Profile } from '../../types/Filters';
import { getProfiles } from '../../services/api/TrackfyService/integration';
import { useAuth } from '../../hooks/useAuth';
import { useAuthContext } from '../../stores/AuthContext';
import { mixpanelTrack } from '../../services/SystemMonitor';

type ProfileSelectProps = {
  label?: string;
  profileSelected: number;
  setProfileSelected: (value: number) => void;
  demo?: boolean;
  screen?: string;
  onChange?: (value: { id: number; name: string }) => void;
};

const ProfileSelect: React.FC<ProfileSelectProps> = (props: ProfileSelectProps) => {
  const [profiles, setProfiles] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();
  const { profileSelected, setProfileSelected, label, demo, screen, onChange } = props;

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
        setIsLoading(true);

        getProfiles(currentRequest.token)
            .then((result: AxiosResponse<any>) => {
            setProfiles(
              result.data.map((Profile: Profile) => {
                return {
                  label: Profile.name,
                  value: Profile.id ? Profile.id.toString() : '0',
                };
              })
            );
            setIsLoading(false);
        })
        .catch((err: any) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('getProfilesApi request canceled by the user.');
    };
  }, []);

  const onChanging = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setProfileSelected(Number(event.target.value));

    const label = event.target.selectedOptions[0].label;

    if (onChange)
      onChange({ id: Number(event.target.value), name: label });

    if (label != 'Selecione uma perfil')
      mixpanelTrack(monitorAgent, `${screen} - Perfil ${label} selecionado`, userName, authData);
  };

  return (
    <Select
      id="profile-select"
      label={label}
      placeholder="Selecione um perfil"
      options={profiles}
      value={profileSelected}
      loading={isLoading}
      onChanging={(event) => onChanging(event)}
    />
  );
};

export default ProfileSelect;
