import { useCallback, useEffect, useState } from 'react';
import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { getLinkedTags } from '../../../../../services/api/TrackfyService';
import { useLinkCollaboratorsContext } from '../../../context/LinkCollaboratorsContext';
import { DeleteCollaborators, LinkCollaboratos } from '../../../types/linkCollaborators';
import { ActionsContainer, Delete } from '../../CollaboratorsList/styles';
import React from 'react';
import { TooltipContent } from '../../../../../components/Info/Tooltip';

export const useCollaboratorsList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [linkCollaborators, setLinkCollaborators] = useState<LinkCollaboratos[]>([]);
  const { companySelected, professionSelected, searchTag } = useLinkCollaboratorsContext();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<DeleteCollaborators>({
    tagAlias: '',
    collaborator: '',
  });

  const getLinkCollaboratorsTooltipContent = (clientId: number): TooltipContent[] => {
    return [
            {
              content: 'É uma pagina de gerenciamento dos colaboradores monitorados pela Trackfy, ela mostra a lista de todos os colaboradores vinculados à tags, além de possibilitar a vinculação de novos colaboradores, desvinculação e substituição de vínculos.',
            },
        ];
  };

  const handleCloseAddCollaborador = useCallback(() => {
    setIsAdding(false);
  }, []);

  const handleCloseAddCollaboradorAndUpdateList = useCallback(() => {
    handleLinkedCollaboratorsUpdate(1);
  }, []);

  const handleCloseDeleteCollaborador = useCallback(() => {
    setIsDeleting(false);
  }, []);

  const handleCloseDeleteCollaboradorAndUpdateList = useCallback(() => {
    handleLinkedCollaboratorsUpdate(2);
  }, []);

  const handleLinkedCollaboratorsUpdate = (operation: number) => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    requestLinkedCollaborators(currentRequest.token, operation);
  };

  const requestLinkedCollaborators = (token: CancelToken, operation: number) => {
    getLinkedTags(companySelected, professionSelected, searchTag, token)
      .then((data) => {
        data.map((item) => {
          item.delete = (
            <ActionsContainer
              onClick={() => {
                setSelectedRow({ tagAlias: item.tag, collaborator: item.collaborator });
                setIsDeleting(true);
              }}
            >
              <Delete />
            </ActionsContainer>
          );
        });

        setLinkCollaborators(data);
        setIsLoading(false);

        if (operation == 1) setIsAdding(false);
        else if (operation == 2) setIsDeleting(false);
      })
      .catch((err) => {
        console.log(err);
        setLinkCollaborators([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if (mounted) {
      setIsLoading(true);
      requestLinkedCollaborators(currentRequest.token, 0);

      /*getLinkedTags(companySelected, professionSelected, searchTag, currentRequest.token)
        .then((data) => {
          data.map((item) => {
            item.delete = (
              <ActionsContainer
                onClick={() => {
                  setSelectedRow({ tagAlias: item.tag, collaborator: item.collaborator });
                  setIsDeleting(true);
                }}
              >
                <Delete />
              </ActionsContainer>
            );
          });

          setLinkCollaborators(data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLinkCollaborators([]);
          setIsLoading(false);
        });*/
    }

    return () => {
      mounted = false;
      currentRequest.cancel(' request canceled by the user.');
    };
  }, [companySelected, professionSelected, searchTag]);

  return {
    linkCollaborators,
    isLoading,
    isAdding,
    setIsAdding,
    handleCloseAddCollaborador,
    isDeleting,
    handleCloseDeleteCollaborador,
    selectedRow,
    handleCloseAddCollaboradorAndUpdateList,
    handleCloseDeleteCollaboradorAndUpdateList,
    getLinkCollaboratorsTooltipContent,
  };
};
