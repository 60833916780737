import { useEffect, useState } from 'react';

import { pendoTrack } from '../../../../services/SystemMonitor';

export type PaginationControlsProps = {
  arrayValues: any[];
  setNewValue: (newValue: any[]) => void;
  title?: string;
};

export const usePaginationControl = (props: PaginationControlsProps) => {
  const { arrayValues, setNewValue, title } = props;

  const [dotActive, setDotActive] = useState<number>(0);
  const [[startDot, lastDot], setDots] = useState<number[]>([0, 5]);

  useEffect(() => {
    setDotActive(0);
    setDots([0, 5]);
    return () => {
      setDotActive(0);
      setDots([0, 5]);
    };
  }, [arrayValues]);

  const handleClick = (side: string) => {
    const newActive: number = side === 'left' ? dotActive - 1 : dotActive + 1;
    setDotActive(newActive);
    setNewValue(arrayValues?.[newActive] || []);

    if (newActive >= lastDot) {
      const nextPageInFooter = 1;

      const newStartDot: number = startDot + nextPageInFooter;
      const newLastDot: number =
        lastDot + nextPageInFooter > arrayValues.length - nextPageInFooter
          ? arrayValues.length
          : lastDot + nextPageInFooter;

      setDots([newStartDot, newLastDot]);
    }

    if (newActive < startDot) {
      const prevPageInFooter = 1;
      const startPage = 0;
      const initialLastPage = 5;

      const newStartDot: number =
        startDot - prevPageInFooter < startPage ? startPage : startDot - prevPageInFooter;
      const newLastDot: number = newStartDot + initialLastPage;

      setDots([newStartDot, newLastDot]);
    }

    if (title) pendoTrack('Tela de Indicadores', title, 'Paginação');
  };

  return {
    dotActive,
    handleClick,
  };
};
