import styled from 'styled-components';

interface CardProps{
  fullWidth?: boolean
}

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 3.5rem 2.4rem;
  overflow: hidden;
  background: #eeeeee;
  border-radius: 0.8rem;
  display: grid;
  flex-wrap: wrap;
  gap: 2.4rem;
  grid-template-columns: repeat(12, minmax(0, 1fr));
`;

export const Card = styled.section<CardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.6rem;
  padding: 2.4rem;
  background: #f8f8f8;
  grid-column: ${({fullWidth}) => fullWidth ? 'span 12 / span 12' : 'span 6 / span 6'};
  @media (max-width: 1000px){
    grid-column: span 12 / span 12;
  }
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  gap: 1.6rem;
  margin-bottom: 3.2rem;
  & p {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-family: Work Sans;
    color: '#0e1742';
  }
`;

export const ContainerTwoCards = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: span 6 / span 6;
  @media (max-width: 1000px){
    grid-column: span 12 / span 12;
  }
  gap: 2.4rem;
  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.6rem;
    padding: 2.4rem;
    background: #f8f8f8;
    max-height: 400px !important;
  }
  & > div:last-child {
    height: 100%;
    max-height: 400px !important;
  }
`;
