import React from 'react';
import Axios, { CancelToken, CancelTokenSource } from 'axios';

import { AlertIconVuesax } from '../../../Icons/Alert-Vuesax';

import * as S from './styles';
import { cancelUserInvite } from '../../../services/api/TrackfyService/integration';
import { notification } from '../../../utils/Notification';

export interface Props {
  closeModal: () => void;
  requestInvites: (token: CancelToken) => void;
  inviteId: number;
}

export const ModalInviteCancel: React.FC<Props> = ({ closeModal, inviteId, requestInvites }) => {
  const cancelInvite = async () => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    await cancelUserInvite(inviteId, currentRequest.token)
    .then((response) => {
        requestInvites(currentRequest.token);
        //notification('success', 'Convite cancelado com sucesso!');
      })
    .catch((err) => {} /*notification('error', 'Ocorreu um erro ao cancelar o convite')*/);
  }

  const handleCancelation = async () => {
    await cancelInvite();
    closeModal();
  }

  return (
    <S.ModalInviteCancelContent>
      <AlertIconVuesax fill="#ED3A3A" width={55} height={55} />
      <strong>Tem certeza que deseja cancelar o convite?</strong>
      Esse usuário não poderá mais usar o link enviado para criar sua conta, <br /> mas você poderá enviar outro
      <p>convite posteriormente!</p>
      <S.ModalInviteCancelButtons>
        <button onClick={closeModal}>Manter convite</button>
        <button onClick={handleCancelation}>Cancelar convite</button>
      </S.ModalInviteCancelButtons>
    </S.ModalInviteCancelContent>
  );
};