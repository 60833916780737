import React from 'react';

import { HomeHeader } from './components/Header';
import { MonitoringCard } from './components/MonitoringCard';
import { EmergencyCard } from './components/EmergencyCard';
import { HelpCard } from './components/HelpCard';
import { InOutCard } from './components/InOutCard';
import { PTWithdrawalCard } from './components/PTWithdrawalCard';
import { PagesCard } from './components/PagesCard';
import { InOutContextProvider } from '../productivity/charts/InOut/context/InOutContext';

import * as S from './styles';
import { Container } from '../../components/PageStructure/styles';
import { Content } from './styles';

interface Props {}
export const HomeContainer: React.FC<Props> = ({}) => {
  return (
    <Container>
      <HomeHeader periodType="Mensal" stage="acompanhamento" />
      <Content>
        <MonitoringCard />
        <S.ContainerTwoCards>
          <EmergencyCard />
          <HelpCard />
        </S.ContainerTwoCards>
        <InOutContextProvider>
          <InOutCard />
        </InOutContextProvider>
        <PTWithdrawalCard />
        <PagesCard />
      </Content>
    </Container>
  );
};
