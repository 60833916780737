import styled from 'styled-components';

export const RankCardContainer = styled.div`
  margin-top: 29px;
  max-height: 40vh;
  height: calc(100% - 236px);
  display: flex;
  justify-content: center;
  align-items: start;
  overflow: auto;
`;

//RankHeader
export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Titlecontainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
  gap: 1.6rem;

  & > svg {
    //margin-right: 1.6rem;
    margin-top: -0.2rem;
  }
`;

export const IconContainer = styled.div`
  margin-top: -0.2rem;
`;

export const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0.6rem;
`;

export const SummaryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
  justify-content: space-between;

  @media (max-width: 1280px) {
    &&& {
      gap: 3rem;
    }
  }
`;

export const RankSummaryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
`;

export const RankDescription = styled.span`
  font: normal 500 1.4rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 1.5rem;

  @media (max-width: 576px) {
    &&& {
      font-size: 1.2rem;
    }
  }
`;

export const AverageDescription = styled.span`
  font: normal 500 1.4rem/1 Work Sans, sans-serif;
  color: #4f5882;
  //margin-bottom: 1.5rem;
  margin-bottom: 27px;

  @media (max-width: 576px) {
    &&& {
      font-size: 1.2rem;
    }
  }
`;

//Rank ProgressBarChart
export const ProgressBarChartContainer = styled.div`
  width: 100%;
`;

export const BarContainer = styled.div``;

//RankLabel
export const RankLabelContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  gap: 9px;
  margin-bottom: 2rem;
`;

export const RankLabelValue = styled.div`
  font: normal 600 4rem/1.5 Work Sans, sans-serif;
  color: #4f5882;

  @media (max-width: 576px) {
    &&& {
      font-size: 3.5rem;
    }
  }
`;

/* const SearchContainer = styled.div`
  height: auto;
  min-height: 50px;
  display: flex;
  flex-direction: column;
`;

const InputSearch = styled(Input.Search)`
  &&& {
    width: 100%;
    & > .ant-input-wrapper {
      position: relative;
    }
    & > .ant-input-affix-wrapper {
    }
    &.ant-input-search .ant-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #ffffff;
      border: 1px solid #e4e8fb;
      border-radius: 8px;
      height: 46px;
      transition: border 0.3s ease-in-out;
      font: normal 400 1.4rem/1.5 Work Sans, sans-serif;
      color: #4f5882;
      border: 1px solid #5669c8;

      &:hover {
        border: thin solid #2f4cddbe;
      }
      &:focus {
        box-shadow: 0 0 0px 2.5px #2f4cdd44;
        border: thin solid #2f4cddbe;
      }

      &::placeholder {
        color: #4f5882;
      }
    }

    & .ant-input-group-addon {
      position: absolute;
      left: auto;
      right: 45px;
      border: 0;
      top: 0;
      background: transparent;
      z-index: 99;

      & > button {
        height: 46px;
        width: 46px;
        background: transparent;
        border: 0;

        &:after {
          border-radius: 8px;
        }
      }
      & svg {
        fill: #2f4cdd;
      }
    }
  }
`; */
