import React from 'react';

import { FilterState } from '../../../../enum/FilterState';
import IconAngleDown from '../../../../Icons/AngleDown';
import { Option } from '../Select/Option';

import { Select } from '..';

interface antdSelectProps {
  dataRequestState: String;
  data: any[];
  handleChange: any;
  initialValue: string;
  handleClick?: any;
  id?: string;
  placeholder?: string;
  borderColor?: string;
  style?: React.CSSProperties;
}

const AntdSelect: React.FC<antdSelectProps> = ({
  dataRequestState,
  data,
  handleChange,
  initialValue,
  handleClick,
  id,
  placeholder,
  borderColor,
  style,
}) =>
  dataRequestState === FilterState.PEDDING ? (
    <Select
      onChange={(e) => handleChange(e)}
      onClick={(_e) => handleClick && handleClick()}
      disabled
      suffixIcon={IconAngleDown}
      placeholder="Loading ..."
      id={id}
      borderColor={borderColor}
      style={style}
    />
  ) : (
    <Select
      onChange={(e) => handleChange(e)}
      disabled={false}
      suffixIcon={IconAngleDown}
      placeholder={placeholder || initialValue}
      id={id}
      borderColor={borderColor}
      style={style}
    >
      <Option key={0} value={0}>
        {initialValue}
      </Option>
      {data.map((item: any) => (
        <Option key={item.id} value={item.id}>
          {' '}
          {item.name}
        </Option>
      ))}
    </Select>
  );

export default AntdSelect;
