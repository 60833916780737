import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  justifycontent: center;
  alignitems: center;
`;

export const FilterContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;
