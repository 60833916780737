import React from 'react';

import { CardHeader } from '../CardHeader';
import { UsersIconVuesax } from '../../../../Icons/Users-Vuesax';
import { TrendDownIcon } from '../../../../Icons/TrendDown';
import { TrendUpIcon } from '../../../../Icons/TrendUp';
import { IconLoading } from '../../../../components/IconLoading';
import { usePTWithdrawlCard } from './hooks/usePTWithdrawlCard';

import * as S from './styles';

interface Props {}

export interface WorkingPermitResponse {
  value: number;
  label: string;
  name: string;
}

const getMinImprovement = (value?: boolean) => {
  if(value == true)
    return <TrendDownIcon fill="#33B469" />;
  else if (value == false)
    return <TrendUpIcon fill="#ED3A3A" />
  else 
    return <></>;
}

const getMaxImprovement = (value?: boolean) => {
  if(value == true)
    return <TrendDownIcon fill="#33B469" />;
  else if (value == false)
    return <TrendUpIcon fill="#ED3A3A" />
  else 
    return <></>;
}

export const PTWithdrawalCard: React.FC<Props> = ({}) => {
  const { dashboardData } = usePTWithdrawlCard();

  if(dashboardData.loading)
    return (<S.Container>
                <IconLoading />
            </S.Container>);

  return (
    <S.Container>
      <CardHeader title="Retirada de PT" icon={<UsersIconVuesax />} />
      <S.TimeDataSection>
        <div>
          <span>Menor tempo</span>
          <h1>
            {dashboardData.data.min ? dashboardData.data.min : '-'}
            {getMinImprovement(dashboardData.data.minImproved)}
          </h1>
        </div>
        <div>
          <span>Média</span>
          <h1>
            {dashboardData.data.average ? dashboardData.data.average : '-'}
            {/*dashboardData.data.averageImproved ? <TrendDownIcon fill="#33B469" /> :  <TrendUpIcon fill="#ED3A3A" />*/}
          </h1>
        </div>
        <div>
          <span>Maior tempo</span>
          <h1>
            {dashboardData.data.max ? dashboardData.data.max : '-'}
            {getMaxImprovement(dashboardData.data.maxImproved)}
          </h1>
        </div>
      </S.TimeDataSection>
    </S.Container>
  );
};