import React from 'react';
import Select, { GroupBase, OptionsOrGroups, StylesConfig } from 'react-select';

type MultiSelectProps = {
  id: string;
  name: string;
  placeholder: string;
  options?: OptionsOrGroups<unknown, GroupBase<unknown>>;
  currentValue: any;
  isLoading?: boolean;
  dropdownIndicator?: any;
  styles?: StylesConfig<unknown, true, GroupBase<unknown>>;
  onChange?: (selected: any) => void;
  isMulti?: true;
};

export const MultiSelect: React.FC<MultiSelectProps> = ({
  id,
  name,
  placeholder,
  options,
  isLoading,
  dropdownIndicator,
  styles,
  onChange,
  currentValue,
  isMulti,
}) => (
  <Select
    id={id}
    name={name}
    placeholder={placeholder}
    options={options}
    isLoading={isLoading}
    isDisabled={isLoading}
    components={dropdownIndicator}
    styles={styles}
    closeMenuOnSelect={false}
    value={currentValue}
    onChange={onChange}
    isMulti={isMulti}
    isClearable
  />
);
