export type Header = {
  title: string;
};

export const headers: Header[] = [
  {
    title: 'Partnumber',
  },
  {
    title: 'Identificação',
  },
  {
    title: 'Última localização registrada',
  },
  {
    title: 'Data e Hora',
  },
  {
    title: 'Status',
  },
];