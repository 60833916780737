import React from 'react';

import { IconLoading } from '../../../../../components/IconLoading';

import { CardAvgcontent } from './styles';

import { VerticalBarChart } from './components/VerticalBarChart';
import { useOtimizacoes } from './hooks/UseOtimizacoes';
import { useOtimizacoesContext } from './context/Otimizacoes';
import { OtimizacoesChartHeader } from './components/OtimizacoesChartHeader';

const OtimizacoesChart: React.FC = () => {
  const { otimizacoesChartData } = useOtimizacoesContext();
  const { isLoading } = useOtimizacoes();

  return (
    <>
      <OtimizacoesChartHeader />
      <CardAvgcontent>
        {isLoading && <IconLoading />}
        {!isLoading && <VerticalBarChart otimizacoesChartData={otimizacoesChartData} />}
      </CardAvgcontent>
    </>
  );
};

export default OtimizacoesChart;
