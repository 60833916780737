import styled from 'styled-components';
import * as Global from '../../styles';

export const Header = styled(Global.CardTitle)`
  width: 100%;
  & span {
    color: #b3b3b3;
    margin-left: 0.4rem;
  }
`;
