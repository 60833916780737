import React from 'react';

import { usePeopleInAreaContext } from './context/PeopleInAreasContext';
import PeopleInAreasChartHeader from './components/PeopleInAreasChartHeader';
import List from './components/List/List';
import { usePeopleInAreas } from './hooks/usePeopleInAreas';
import { IconLoading } from '../../../../components/IconLoading';

import styled from 'styled-components';

export const CardContent = styled.div`
  height: 100%;
  padding: 0 1rem;
  max-height: 385px;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  & svg {
    border-radius: 10px;

    & text {
      font: normal 500 1.3rem/1 Work Sans, sans-serif !important;
      fill: #4f5882 !important;
    }
  }
`;

const PeopleInAreasChart: React.FC<{}> = () => {
  const { peopleInAreasChartData } = usePeopleInAreaContext();
  const { isLoading } = usePeopleInAreas();

  return (
    <>
      <PeopleInAreasChartHeader />
      <CardContent>
        {isLoading && <IconLoading />}
        {!isLoading && <List monitoredData={peopleInAreasChartData} />}
      </CardContent>
    </>
  );
};

export default PeopleInAreasChart;
