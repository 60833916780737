import React, { useEffect, useState } from 'react';

import { Chart, Header, Text, ContainerBar, Bar } from './styled';

type Props = {
  label: string;
  value: number;
  color: string;
};

export const ProgressBar: React.FC<Props> = ({ label, value, color }: Props) => {
  const [progressValue, setProgressValue] = useState('0%');

  useEffect(() => {
    let mount = false;

    if (!mount) {
      setTimeout(() => {
        const newValue = `${value.toString()}%`;

        setProgressValue(newValue);
      }, 500);
    }

    return () => {
      mount = true;
    };
  }, [progressValue]);

  return (
    <Chart>
      <Header>
        <Text className="label">{label}</Text>
        <Text className="value">{value.toString().replace('.', ',')}%</Text>
      </Header>
      <ContainerBar>
        <Bar className="bar" style={{ width: progressValue, backgroundColor: `${color}` }} />
      </ContainerBar>
    </Chart>
  );
};
