import React from 'react';

import { TableHeader } from '../../../components/Table/Table';
import IconArrowDown from '../../../Icons/ArrowDown';
import IconArrowUp from '../../../Icons/ArrowUp';
import IconWorker from '../../../Icons/Worker';
import IconLocation from '../../../Icons/Location';

export const dailyHeaders: TableHeader[] = [
  {
    title: 'Áreas Monitoradas',
    icon: <IconLocation />,
  },
  {
    title: 'Nº de Colaboradores',
    icon: <IconWorker />,
  },
  {
    title: 'Primeiro Acesso',
    icon: <IconArrowDown />,
  },
  {
    title: 'Última Saída',
    icon: <IconArrowUp />,
  },
];

export const monthlyHeaders: TableHeader[] = [
  {
    title: 'Áreas Monitoradas',
    icon: <IconLocation />,
  },
  {
    title: 'Nº de Colaboradores',
    icon: <IconWorker />,
  },
  {
    title: 'Primeiro Acesso',
    icon: <IconArrowDown />,
  },
  {
    title: 'Última Saída',
    icon: <IconArrowUp />,
  },
];
