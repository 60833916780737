import { useState } from 'react';
import { Timeline } from '../../../indicators/types/Indicators';

export const useTimeline = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const exportDataToXlsx = (timelineValues: Array<Timeline[]>): any[]  => {
    let count: string[] = [];
    let time: string[] = [];
    let date: string[] = [];
    
    timelineValues.forEach((timelineValue: Timeline[]) => {
      timelineValue.map(i => (
        date.push(i.date),
        time.push(i.time),
        count.push(i.count)
      ))
    })

    const formattedData: any[] = [];
    formattedData.push(["Data", "Horário", "Quantidade"])

    date.forEach((date, index) => {
        const value1 = time[index];
        const value2 = count[index];
        formattedData.push([date, value1, value2]);
    });

    return formattedData
  }

  const formatTimelineDate = (timelineData: any[]): string => {
    if (timelineData && timelineData.length > 0) {
      if (timelineData[0].date) {
        return `${timelineData[0].date.substring(8, 10)}/${timelineData[0].date.substring(
          5,
          7
        )}/${timelineData[0].date.substring(0, 4)}`;
      }
    }
    return '';
  };

  const dividerPerEighteen = (base: any[]): Array<any[]> => {
    const divisor: number = 18;
    const newArray: Array<any[]> = [];

    for (let i = 0; i < base.length; i += divisor) {
      const divider: any[] = base.slice(i, i + divisor);
      newArray.push(divider);
    }
    return newArray;
  };

  const dividerPerTwentyfour = (base: any[]): Array<any[]> => {
    const divisor: number = 24;
    const newArray: Array<any[]> = [];

    for (let i = 0; i < base.length; i += divisor) {
      const divider: any[] = base.slice(i, i + divisor);
      newArray.push(divider);
    }
    return newArray;
  };

  return {
    isLoading,
    isEmpty,
    setIsLoading,
    setIsEmpty,
    formatTimelineDate,
    dividerPerEighteen,
    dividerPerTwentyfour,
    exportDataToXlsx,
  };
};
