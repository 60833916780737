import styled from 'styled-components';

export const TableContainer = styled.div`
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    width: 8px;
    border: 1px solid #f3f3f3;
    border-radius: 2px;
  }
`;
