import React from 'react';

import AreaSelect from '../../../../../filters/AreaSelect';
import IconUsers from '../../../../../../Icons/Users';

import * as S from '../styles';

export const RoiChartHeader: React.FC = () => {
  return (
    <S.Header>
      <S.Titlecontainer>
        <IconUsers />
        <S.Title>ROI (Valores em R$)</S.Title>
      </S.Titlecontainer>
    </S.Header>
  );
};
