import styled from 'styled-components';
import { sg } from '../../styles/style-guide';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5c5c5c45 0% 0% no-repeat padding-box;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  z-index: 999;
`;

export const Card = styled.div`
  display: flex;
  background: ${sg.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px 2px #55555514;
  border: 1px solid #c4c4c4;
  border-radius: 15px;
  opacity: 1;
`;