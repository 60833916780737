import React from 'react';
import { DefaultIconProps } from './types';

export const MonitoringIcon: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M15.2667 10.0013C17.4333 10.0013 18.3333 9.16796 17.5333 6.43462C16.9917 4.59296 15.4083 3.00962 13.5667 2.46796C10.8333 1.66796 10 2.56796 10 4.73462V7.13462C10 9.16796 10.8333 10.0013 12.5 10.0013H15.2667Z"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6662 12.2482C15.8912 16.1065 12.1912 18.9065 7.98287 18.2232C4.82453 17.7148 2.28287 15.1732 1.7662 12.0148C1.0912 7.82318 3.87454 4.12318 7.7162 3.33984"
        stroke={fill || '#666666'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
