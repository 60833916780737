import axios, { CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';

import { getDashboardWorkingPermit } from '../../../../../services/api/TrackfyService';
import { useIsMounted } from '../../../../../hooks';
import { DashboardWorkingPTData } from '../../../../../types/MonitoredData';

export interface PTData {
  loading: boolean;
  data: DashboardWorkingPTData;
}

export const usePTWithdrawlCard = () => {
  const mountedRef = useIsMounted();
  const [dashboardData, setDashboardData] = useState<PTData>({
    loading: true,
    data: {
      min: '00min',
      minImproved: false,
      average: '00min',
      averageImproved: false,
      max: '00min',
      maxImproved: false,
    }
  });



  const getMinValueImprovement = () => {

  }
  
  useEffect(() => {
    mountedRef.current = true;

    setDashboardData( (prev: PTData) => ({ ...prev, loading: true }) );
    const currentRequest: CancelTokenSource = axios.CancelToken.source();
    const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 1);

    const initialDate = currentDate.valueOf();
    const finalDate = (new Date()).valueOf();

    getDashboardWorkingPermit("", "", (new Date()).valueOf(), initialDate, finalDate, false, true, 
        false, currentRequest.token)
      .then((data : DashboardWorkingPTData) => setDashboardData({loading: false, data: data}))
      .catch((err) => console.log(err));

    return () => {
      mountedRef.current = false;
      currentRequest.cancel('request canceled by the user.');
    };
  }, []);


  return {
    dashboardData,
  };
};