import styled from 'styled-components';

export const Item = styled.div`
  height: 48px;
  padding: 12px 20px 13px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #f0f0f0;

  cursor: pointer;

  &:hover {
    background-color: lightblue;

    transition: background-color 0.7s linear;
  }
`;

export const Label = styled.span`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #4f5882;

  overflow: hidden;
`;

export const SuccessIconContainer = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    width: 20px !important;
    height: 18px !important;
  }
`;
