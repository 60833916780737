import React from 'react';

import Table from '../../../components/Table/Table';
import { getObjectToStringMatrix } from '../../../utils/Convert';
import { headers } from './Header';
import { useSystemLogs } from '../hooks/useSystemLogs';

import * as S from './styles';

export const SystemLogsTable: React.FC = () => {
    const { logs, isLoading, isEmpty } = useSystemLogs();

    return(
        <S.TableContainer>
            <Table tableHeaders={headers} 
                   tableData={getObjectToStringMatrix(logs)} 
                   loading={isLoading} 
                   isEmpty={isEmpty}
                   emptyMessage='Não há logs'
            />
        </S.TableContainer>
    );
}