import React, { useEffect } from 'react';
import Axios, { CancelTokenSource } from 'axios';

import { DateRangePicker } from '../../../components';
import { createDateObjtWithTmezoffset, getLastCommercialDateUSFormat } from '../../../utils/DateTime';
import { useDailySummaryContext } from '../context/DailySummaryContext';
import { OptionsContainer } from '../../../components/PageStructure/styles';
import { useTrackfyApi } from '../../../hooks/useTrackfyApi';
import { mixpanelTrack, pendoTrack } from '../../../services/SystemMonitor';
import AntdSelect from '../../../components/Forms/Selects/AntdSelect';
import { useAuthContext } from '../../../stores/AuthContext';
import { useAuth } from '../../../hooks';

const PageOptions: React.FC = () => {
  const { companies, companiesState, getCompaniesApi } = useTrackfyApi();
  const { updatePeriod, setCompanySelected } = useDailySummaryContext();
  const lastCommercialDate: Date = createDateObjtWithTmezoffset(
    new Date(getLastCommercialDateUSFormat())
  );
  const {authData} = useAuthContext();
  const compIds:string = authData? authData.grpCompanies:'t';

  const { monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  useEffect(() => {
    let mounted = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted){
      mixpanelTrack(monitorAgent, 'Sumário diário', userName, authData); 
      getCompaniesApi(currentRequest.token);
    } 

    return () => {
      mounted = false;
      currentRequest.cancel('getCompaniesApi request canceled by the user.');
    };
  }, []);

  return (
    <OptionsContainer>
      {((compIds!="a") && (!compIds.includes(",")))?
        <></>:
        <div>
          <AntdSelect
            dataRequestState={companiesState}
            data={companies}
            handleChange={(e: any) => {
              setCompanySelected(parseInt(e, 10));
              pendoTrack('Sumário diário', 'Filtro de empresas', 'Alteração');
            }}
            initialValue="Empresas"
          />
        </div>
      }
      <div>
        <DateRangePicker
          updatePeriod={updatePeriod}
          screen="Sumário diário"
          component=""
          maxDate={lastCommercialDate}
          selectRange={false}
          showToday={false}
          loadDate={lastCommercialDate}
        />
      </div>
    </OptionsContainer>
  );
};

export default PageOptions;
