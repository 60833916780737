import React from 'react';

import AreaSelect from '../../../../../filters/AreaSelect';
import IconUsers from '../../../../../../Icons/Users';

import * as S from '../styles';

export const VelocidadeMediaChartHeader: React.FC = () => {
  return (
    <S.Header>
      <S.Titlecontainer>
        <IconUsers />
        <S.Title>Velocidade Media (Valores em Km/h)</S.Title>
      </S.Titlecontainer>
    </S.Header>
  );
};
