import { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { getHHIndicators } from '../../../../../../services/api/TrackfyService';
import { useAuth } from '../../../../../../hooks';
import { useCompaniesContext } from '../../../../context/CompaniesContext';
import { parseSelectOptions } from '../../../../../../utils/Convert';
import { useAreasContext } from '../../../../context/AreasContext';

type hhIndicator = {
  loading: boolean;
  value: string;
};

export const useHhindicator = () => {
  const [indicator, setIndicator] = useState<hhIndicator>({ loading: true, value: 'Carregando...' });
  const { companySelected } = useCompaniesContext();
  const { projectSelected } = useAreasContext();
  const { getClientId } = useAuth();

  const getCompaniesForBraskem = ():string => {
    if(projectSelected == 1)
      return "94";

    else if(projectSelected == 2)
      return "30;105;106;107;108;109";

    return "0";
  }

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if (mounted) {
      const clientId = getClientId();
      const companies: string = clientId == 12 ? getCompaniesForBraskem() : parseSelectOptions(companySelected);
      setIndicator({ loading: true, value: 'Carregando...' });

      getHHIndicators(clientId, companies, currentRequest.token)
        .then((result: AxiosResponse<any>) => {
          let indicatorText = '0';

          if (result.data) indicatorText = result.data;

          setIndicator({ loading: false, value: indicatorText + ' horas' });
        })
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('Hhindicator request canceled by the user.');
    };
  }, [companySelected, projectSelected]);

  return { indicator };
};
