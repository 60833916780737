import styled from 'styled-components';
import { Card, CardTitle } from '../../styles';

export const Container = styled(Card)`
  gap: 1.6rem;
`;
export const Header = styled(CardTitle)`
  margin-bottom: 1.6rem;
`;

type MainMessageProps = {
  type: 'error' | 'success';
};

export const MainMessage = styled.h1<MainMessageProps>`
  color: ${({ type }) => (type === 'success' ? '#33b469' : '#ED3A3A')};
  font-weight: 500;
  font-size: 1.8rem;
  font-family: Work Sans;
  text-align: center;
  width: 100%;
  
   & a {
    color: #ed3a3a;
    text-decoration: underline;
  }
`;
