import styled from 'styled-components';

export interface DetectiveProps {
  isManager?: boolean;
}

interface BuildingFloorProps {
  entrance?: boolean;
  productive?: boolean;
  left?: boolean;
}

export const MonitoredAreas = styled.div`
  position: absolute;
  width: 20%;
  top: 18px;
  left: 40px;
`;

export const FilterContainer = styled.div`
  position: absolute;
  width: calc(40% - 60px);
  top: 18px;
  left: calc(20% + 60px);
  z-index: 1000;
`;

export const DetectiveContainer = styled.div<DetectiveProps>`
  position: absolute;
  width: calc(40% - 60px);
  // top: 18px;
  // top: 100px;
  //top: ${(props) => (props.isManager ? '100px' : '18px')};
  //top: ${(props) => (props.isManager ? '319px' : '18px')};
  top: ${(props) => (props.isManager ? '397px' : '18px')};
  /*left: calc(60% + 20px);*/
  right: 40px;

  display: flex;
  justify-content: end;
`;

export const HHIndicatorsContainer = styled.div`
  position: absolute;
  width: 20%;
  top: 18px;
  right: 40px;
`;

export const BuildingPageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const BuildingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 500px;
  margin: 6% 0 0 0;
  border-radius: 6px;
  box-shadow: rgb(35 35 35 / 9%) 0px 2px 8px 2px;
`;

export const FloorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f0f2f5;
  //background: #fff;
  width: 310px;
  margin: 5% 0 5% 0;
  border-bottom: solid 2px;
  border-top: solid 2px;
  border-radius: 3px;
  box-shadow: rgb(35 35 35 / 9%) 0px 2px 8px 2px;
`;

export const FloorContainer = styled.div<BuildingFloorProps>`
  display: flex;
  justify-content: center;
  background: ${(props) => (props.productive ? '#fff' : '#f0f2f5')};
  min-width: 260px;
  height: ${(props) => (props.entrance ? '76px' : '48px')};
  margin-top: 2px;
  border: ${(props) => (props.productive ? 'solid 1px' : 'solid 1px gray')};
  border-radius: 3px;
  box-shadow: rgb(35 35 35 / 9%) 0px 2px 8px 2px;

  &:hover {
    cursor: ${(props) => (props.productive ? 'pointer' : 'default')};
    border style: solid;
    background: ${(props) => (props.productive ? '#2f4cdd' : '#f0f2f5')};
    border-color: ${(props) => (props.productive ? '#2f4cdd' : '#f0f2f5')};
    opacity: 0.7;
    transition: 0.4s;
  }
`;

export const FloorInnerContainer = styled.div`
  width: 90%;
  min-width: 260px;
`;

export const FloorRowContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  min-width: 260px;
`;

export const WindowsHeaderLeftContainer = styled.div<BuildingFloorProps>`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  min-width: 120px;
  height: 10px;
  border-left-style: solid;
  border-left-width: 1px;
  border-color: ${(props) => (props.productive ? 'black' : '#d0d0d0')};
`;

export const WindowsHeaderRightContainer = styled.div<BuildingFloorProps>`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  min-width: 120px;
  height: 10px;
  border-right-style: solid;
  border-right-width: 1px;
  border-color: ${(props) => (props.productive ? 'black' : '#d0d0d0')};
`;

export const BigWindowHeader = styled.div<BuildingFloorProps>`
  width: 35px;
  height: 5px;
  border: ${(props) => (props.productive ? 'solid 1px' : 'solid 1px #d0d0d0')};
`;

export const SmallWindowHeader = styled.div<BuildingFloorProps>`
  width: 25px;
  height: 1px;
  border: ${(props) => (props.productive ? 'solid 1px' : 'solid 1px #d0d0d0')};
`;

export const WindowsContainer = styled.div<BuildingFloorProps>`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  min-width: 120px;
  border: ${(props) => (props.productive ? 'solid 1px' : 'solid 1px #d0d0d0')};
  border-radius: 1px;
`;

export const SmallWindow = styled.div<BuildingFloorProps>`
  width: 25px;
  height: 30px;
  border-radius: 1px;
  border: ${(props) => (props.productive ? 'solid 1px' : 'solid 1px #d0d0d0')};
`;

export const BigWindow = styled.div<BuildingFloorProps>`
  width: 35px;
  height: 30px;
  border-radius: 1px;
  border: ${(props) => (props.productive ? 'solid 1px' : 'solid 1px #d0d0d0')};
`;

export const HallRowContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  min-width: 260px;
  gap: 2px;
`;

export const HallHeaderContainer = styled.div<BuildingFloorProps>`
  display: flex;
  justify-content: ${(props) => (props.left ? 'flex-end' : 'flex-start')};
  align-items: center;
  width: 50%;
  min-width: 120px;
  height: 10px;
  border-left-style: ${(props) => (props.left ? 'solid' : 'none')};
  border-left-width: ${(props) => (props.left ? '1px' : '0px')};
  border-right-style: ${(props) => (props.left ? 'none' : 'solid')};
  border-right-width: ${(props) => (props.left ? '01px' : '1px')};
  border-color: ${(props) => (props.productive ? 'black' : '#d0d0d0')};
`;

export const HallContainer = styled.div<BuildingFloorProps>`
  display: flex;
  justify-content: ${(props) => (props.left ? 'flex-end' : 'flex-start')};
  align-items: center;
  width: 50%;
  min-width: 120px;
  border: ${(props) => (props.productive ? 'solid 1px' : 'solid 1px #d0d0d0')};
  border-radius: 1px;
`;

export const HallHeader = styled.div<BuildingFloorProps>`
  width: 50px;
  height: 2px;
  border: ${(props) => (props.productive ? 'solid 1px' : 'solid 1px #d0d0d0')};
`;

export const HallEntrance = styled.div<BuildingFloorProps>`
  width: 50px;
  height: 60px;
  border-radius: 1px;
  border: ${(props) => (props.productive ? 'solid 1px' : 'solid 1px #d0d0d0')};
`;

export const BuildingHistoryContainer = styled.div`
  position: absolute;
  width: 244px;
  height: 314px;
  top: 178px;
  right: 140px;
  border-radius: 8px;
  background: #fff;
`;

export const MonitoringIndicatorsContainer = styled.div`
  position: absolute;
  width: 25%;
  display: grid;
  gap: 18px;
  right: 40px;
  top: 18px;
`;
