import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { SelectOption } from '../../components/Select/types';
import { getActivities } from '../../services/api/TrackfyService';
import { Activity } from '../../types/Filters';
import { Select } from '../../components/Select';

type ActivitySelectProps = {
  label?: string;
  activitySelected: string;
  setActivitySelected: (value: string) => void;
  areaId?: number;
};

const ActivitySelect: React.FC<ActivitySelectProps> = (props: ActivitySelectProps) => {
  const [activities, setActivitys] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { activitySelected, setActivitySelected, label, areaId } = props;

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);
      const area = areaId ? areaId : 0;

      getActivities(area, currentRequest.token)
        .then((result: AxiosResponse<any>) => {
          setActivitys(
            result.data.map((activity: Activity) => {
              return {
                label: activity.name,
                value: activity.id ? activity.id.toString() : '0',
              };
            })
          );
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('getActivitiesApi request canceled by the user.');
    };
  }, [areaId]);

  return (
    <Select
      id="avrgtime-activity-select"
      label={label}
      placeholder="Selecione uma atividade"
      options={activities}
      value={activitySelected}
      loading={isLoading}
      onChanging={(event) => setActivitySelected(event.target.value.toString())}
    />
  );
};

export default ActivitySelect;
