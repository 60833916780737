import React, { ReactElement } from 'react';

import { Tabs } from "../../../components/Tabs";
import { Tab } from "../../../components/Tabs/Tab";
import { TabComponent } from '../../../types';
import { componentConfig } from '../../../config/component.config';

type TabsFactoryProps = {
  tabs : TabComponent[] | undefined
};

export const TabsFactory: React.FC<TabsFactoryProps> = ({ tabs }: TabsFactoryProps) => {
  const children: ReactElement[] = [];

  if(tabs)
    tabs.forEach((tabComponent:TabComponent) => {
      const tab: ReactElement = componentConfig[tabComponent.component];
    
      children.push(
        <Tab title={tabComponent.title} key={'Tab_' + tabComponent.title}>
          {tab}
        </Tab>
      )
    });
  
  return <Tabs children={children} />;
}