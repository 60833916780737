import React from 'react';

export interface Props {
  fill?: string;
  width?: number;
  height?: number;
}

export const DangerOutlinedIcon: React.FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 6V9.33333"
        stroke={fill || '#ED3A3A'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00004 14.2735H3.96004C1.6467 14.2735 0.680037 12.6202 1.80004 10.6002L3.88004 6.85352L5.84004 3.33352C7.0267 1.19352 8.97337 1.19352 10.16 3.33352L12.12 6.86018L14.2 10.6068C15.32 12.6268 14.3467 14.2802 12.04 14.2802H8.00004V14.2735Z"
        stroke={fill || '#ED3A3A'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99634 11.333H8.00233H7.99634Z"
        fill={fill || '#ED3A3A'}
      />
      <path
        d="M7.99634 11.333H8.00233"
        stroke={fill || '#ED3A3A'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};