import styled from 'styled-components';

import { sg } from '../../styles/style-guide';
import AngleDown from '../../assets/angle-down.png';

type SelectFieldStyleProps = {
  height?: string;
  width?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
  boxSizing?: string;
  textAlign?: string;
  letterSpacing?: string;
  opacity?: string;
  hoverBorder?: string;
  disabledColor?: string;
  disabled: boolean;
  secondary?: boolean;
};

type SelectOptionStyleProps = {
  display?: string;
  width?: string;
  padding?: string;
  textAlign?: string;
  font?: string;
  letterSpacing?: string;
  filter?: string;
  cursor?: string;
};

type SelectLabelStyleProps = {
  position?: string;
  padding?: string;
  backgroundColor?: string;
  fontSize?: string;
  fontWeight?: string;
  textOverflow?: string;
  lineHeight?: string;
  overflow?: string;
  transform?: string;
};

export const SelectGroup = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

export const SelectLabel = styled.label<SelectLabelStyleProps>`
  position: ${(SelectLabelStyleProps) =>
    SelectLabelStyleProps.position ? SelectLabelStyleProps.position : 'absolute'};
  padding: ${(SelectLabelStyleProps) => (SelectLabelStyleProps.padding ? SelectLabelStyleProps.padding : '0 4px')};
  background-color: ${(SelectLabelStyleProps) =>
    SelectLabelStyleProps.backgroundColor ? SelectLabelStyleProps.backgroundColor : sg.colors.white};
  font-size: ${(SelectLabelStyleProps) =>
    SelectLabelStyleProps.fontSize ? SelectLabelStyleProps.fontSize : '2rem'};
  font-weight: ${(SelectLabelStyleProps) =>
    SelectLabelStyleProps.fontWeight ? SelectLabelStyleProps.fontWeight : '600'};
  text-overflow: ${(SelectLabelStyleProps) =>
    SelectLabelStyleProps.textOverflow ? SelectLabelStyleProps.textOverflow : 'ellipsis'};
  line-height: ${(SelectLabelStyleProps) =>
    SelectLabelStyleProps.lineHeight ? SelectLabelStyleProps.lineHeight : '1'};
  color: ${(SelectLabelStyleProps) =>
    SelectLabelStyleProps.color ? SelectLabelStyleProps.color : sg.colors.dark};
  overflow: ${(SelectLabelStyleProps) =>
    SelectLabelStyleProps.overflow ? SelectLabelStyleProps.overflow : 'hidden'};
  transform: ${(SelectLabelStyleProps) =>
    SelectLabelStyleProps.transform ? SelectLabelStyleProps.transform : 'translate(-1px, -11px) scale(0.75)'};
`;

export const SelectField = styled.select<SelectFieldStyleProps>`
  all: ${({ secondary }) => (secondary ? 'unset' : '')};
  height: ${(SelectFieldStyleProps) => (SelectFieldStyleProps.height ? SelectFieldStyleProps.height : '100%')};
  width: ${(SelectFieldStyleProps) => (SelectFieldStyleProps.width ? SelectFieldStyleProps.width : '100%')};
  padding: ${(SelectFieldStyleProps) =>
    SelectFieldStyleProps.padding ? SelectFieldStyleProps.padding : '13px 30px 12px 16px'};
  background: url(${AngleDown}) no-repeat right #ffffff;
  background-position-x: calc(100% - 12px);

  border: ${(SelectFieldStyleProps) =>
    SelectFieldStyleProps.secondary
      ? '1px solid #e3e3e3'
      : SelectFieldStyleProps.border
      ? SelectFieldStyleProps.border
      : '1px solid #4f5882'};
  border-radius: ${(SelectFieldStyleProps) =>
    SelectFieldStyleProps.borderRadius ? SelectFieldStyleProps.borderRadius : '10px'};
  box-sizing: ${(SelectFieldStyleProps) =>
    SelectFieldStyleProps.boxSizing ? SelectFieldStyleProps.boxSizing : 'border-box'};
  text-align: ${(SelectFieldStyleProps) =>
    SelectFieldStyleProps.textAlign ? SelectFieldStyleProps.textAlign : 'left'};
  letter-spacing: ${(SelectFieldStyleProps) =>
    SelectFieldStyleProps.letterSpacing ? SelectFieldStyleProps.letterSpacing : '0px'};
  opacity: ${(SelectFieldStyleProps) => (SelectFieldStyleProps.opacity ? SelectFieldStyleProps.opacity : '1')};
  color: ${(SelectFieldStyleProps) => (SelectFieldStyleProps.color ? SelectFieldStyleProps.color : '#4f5882')};
  font: normal 500 1.4rem/1.5 Work Sans, sans-serif;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  cursor: ${({ disabled }) => (disabled ? 'wait' : 'pointer')};

  &::-webkit-scrollbar {
    width: 8px;
  }
  & :hover {
    border-color: '#2f4cddbe';
  }
  &:focus {
    box-shadow: ${({ secondary }) => (secondary ? '0 0 0px 2.5px #2f4cdd44' : '')};
    border: ${({ secondary }) => (secondary ? 'thin solid #2f4cddbe' : '')};
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    width: 8px;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
  }

  &:disabled {
    color: ${(SelectFieldStyleProps) =>
      SelectFieldStyleProps.disabledColor ? SelectFieldStyleProps.disabledColor : '#afafaf'};
    background: url(${AngleDown}) no-repeat right #ffffff;
    background-position-x: calc(100% - 12px);
  }
`;

export const Option = styled.option<SelectOptionStyleProps>`
  display: ${(SelectOptionStyleProps) =>
    SelectOptionStyleProps.display ? SelectOptionStyleProps.display : 'block'};
  width: ${(SelectOptionStyleProps) => (SelectOptionStyleProps.width ? SelectOptionStyleProps.width : '100%')};
  padding: ${(SelectOptionStyleProps) =>
    SelectOptionStyleProps.padding ? SelectOptionStyleProps.padding : '10px'};
  text-align: ${(SelectOptionStyleProps) =>
    SelectOptionStyleProps.textAlign ? SelectOptionStyleProps.textAlign : 'left'};
  font: ${(SelectOptionStyleProps) =>
    SelectOptionStyleProps.font
      ? SelectOptionStyleProps.font
      : 'normal normal normal 1.4rem/2.1rem Work Sans, sans-serif'};
  letter-spacing: ${(SelectOptionStyleProps) =>
    SelectOptionStyleProps.letterSpacing ? SelectOptionStyleProps.letterSpacing : '0px'};
  color: ${(SelectOptionStyleProps) => (SelectOptionStyleProps.color ? SelectOptionStyleProps.color : '#666666')};
  filter: ${(SelectOptionStyleProps) => (SelectOptionStyleProps.filter ? SelectOptionStyleProps.filter : 'none')};
  cursor: ${(SelectOptionStyleProps) =>
    SelectOptionStyleProps.cursor ? SelectOptionStyleProps.cursor : 'pointer'};
`;
