import { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { getLastUpdate } from '../../../../../services/api/TrackfyService/integration';

export const useLastUpdate = () => {
  const [lastUpdate, setLastUpdate] = useState<string>()
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);
      
      getLastUpdate(currentRequest.token)
        .then((result: AxiosResponse<any>) => {
          setLastUpdate(result.data)
          setIsLoading(false)
        })
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('LastUpdate request canceled by the user.');
    };
  }, []);

  return {
    isLoading,
    setIsLoading,
    lastUpdate
  };
};
