import styled from 'styled-components';

interface ContainerProps {
  isMobile: boolean;
}

export const Container = styled.div<ContainerProps>`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & span {
    font: normal 500 1.5rem/1.5 Work Sans, sans-serif;
    color: #4f5882;
  }
`;

export const LinesContainer = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
  justify-content: center;
  flex-wrap: ${({ isMobile }) => (isMobile ? 'wrap' : 'inherit')};
`;

export const LineRow = styled.div<ContainerProps>`
  max-width: 100%;
  width: ${({ isMobile }) => (isMobile ? 'auto' : '100%')};
  display: flex;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;

export const AreaName = styled.p`
  width: 11rem;
  max-width: 11rem;
  margin-right: 2rem;
`;