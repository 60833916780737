import React, { createContext, ReactNode, useState, useContext } from 'react';

import { PaginationControls } from '../../../../../../components/Buttons/PaginationControls';
import { EconomiaDiaria } from '../../../../types/Insights';

type ContextValue = {
  economiaDiariaChartData: EconomiaDiaria[];
  isLoading: boolean;
  economiaDiariaValues: Array<EconomiaDiaria[]>;
  setEconomiaDiariaChartData: (value: EconomiaDiaria[]) => void;
  setEconomiaDiariaValues: (arrayAvarageValues: Array<EconomiaDiaria[]>) => void;
  setIsLoading: (value: boolean) => void;
};

const EconomiaDiariaContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function EconomiaDiariaContextProvider(props: Props) {
  const { children } = props;
  const [economiaDiariaValues, setEconomiaDiariaValues] = useState<Array<EconomiaDiaria[]>>([]);
  const [economiaDiariaChartData, setEconomiaDiariaChartData] = useState<EconomiaDiaria[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const value = {
    economiaDiariaChartData,
    economiaDiariaValues,
    isLoading,
    setEconomiaDiariaChartData,
    setEconomiaDiariaValues,
    setIsLoading,
  };

  return (
    <EconomiaDiariaContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={economiaDiariaValues}
        setNewValue={setEconomiaDiariaChartData}
        title="Otimizações"
      />
    </EconomiaDiariaContext.Provider>
  );
}

export function useEconomiaDiariaContext() {
  const context = useContext(EconomiaDiariaContext);

  if (typeof context === 'undefined') {
    throw new Error('EconomiaDiariaContext must be used within an EconomiaDiariaContext');
  }

  return context;
}
