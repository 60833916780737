import React from 'react';

export const IconMsgQuestionVuesax: React.FC = () => (
    <svg width="100" height="100" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M90.667 98.2946H69.3337L45.6003 114.081C42.0803 116.428 37.3337 113.921 37.3337 109.655V98.2946C21.3337 98.2946 10.667 87.6279 10.667 71.6279V39.6276C10.667 23.6276 21.3337 12.9609 37.3337 12.9609H90.667C106.667 12.9609 117.334 23.6276 117.334 39.6276V71.6279C117.334 87.6279 106.667 98.2946 90.667 98.2946Z" stroke="#18266F" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M63.9997 60.5859V59.4661C63.9997 55.8395 66.2398 53.9194 68.4798 52.3727C70.6665 50.8794 72.8529 48.9595 72.8529 45.4395C72.8529 40.5328 68.9063 36.5859 63.9997 36.5859C59.093 36.5859 55.1465 40.5328 55.1465 45.4395" stroke="#18266F" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M63.9757 73.3346H64.0237H63.9757Z" fill="#18266F"/>
        <path d="M63.9757 73.3346H64.0237" stroke="#18266F" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

);