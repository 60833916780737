import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import moment from 'moment';

import { useReportsContext } from '../../context/ReportsContext';
import { useAuth } from '../../../../hooks';
import { getResourcesQuantity } from '../../../../services/api/TrackfyService/integration';
import { PreviewHeader } from './PreviewHeader';
import { Form } from '../ReportContent';
import { ExampleLineChart, ExampleBarChart, ExampleTimelineChart } from './Chart';
import { IconLoading } from '../../../../components/IconLoading';

import * as S from './styles';

interface Props {
  form: Form;
}

interface ResourcesAmount {
  loading: boolean;
  report: {
    area: any[];
    function: any[];
    history: any[];
    timeline: any[];
    qtdTotal: number;
    maxQtdTotal: number;
  }
}

export const PreviewQuantityByCollaborator: React.FC<Props> = ({ form }) => {
  const { areaSelected, professionSelected, collaboratorSelected, companySelected } = useReportsContext();
  const { getClientId } = useAuth();
  const { period } = form;
  const [resourcesQuantity, setResourcesQuantity] = useState<ResourcesAmount>({
    loading: true,
    report: {
      area: [],
      function: [],
      history: [],
      timeline: [],
      qtdTotal: 0,
      maxQtdTotal: 0
    }
  });
  
  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    setResourcesQuantity(previousState => ({...previousState, loading: true}));

    if (mounted) {
      getResourcesQuantity(
        getClientId(),
        companySelected ? companySelected.toString() : '',
        professionSelected ? professionSelected.toString() : '',
        areaSelected ? areaSelected.toString() : '',
        collaboratorSelected ? collaboratorSelected.toString() : '',
        form.period?.initial.valueOf() ? form.period?.initial.valueOf() : moment().valueOf(),
        form.period?.final.valueOf() ? form.period?.final.valueOf() : moment().valueOf(),
        moment().valueOf(),
        currentRequest.token
      )
      .then((response: AxiosResponse<any>) => {
        if (response.data) {
          setResourcesQuantity({report: response.data, loading: false});
          //lembrar de colocar o showPreview no contexto e chamar aqui
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
    return () => {
      mounted = false;
      currentRequest.cancel('InOutChart request canceled by the user.');
    };
  }, [companySelected, professionSelected, form.period?.initial, form.period?.final, areaSelected,
      collaboratorSelected]);
  
  const attachEndlabel = (elements: any[]) => {
    if (elements.length > 0) {
      let newElements = elements.map((item) => ({
        name: item.name,
        id: item.id,
        hours: item.value ? Number.parseFloat(item.value.toString()) : 0,
      }));
    
      newElements = newElements.map((v, index, arr) => {
        const totalValues = arr.reduce((acc, val) => {
          return acc + val.hours;
        }, 0);

        const percentValue = v.hours > 0 ? (v.hours * 100) / totalValues : 0.0;
    
        return {
          ...v,
          endLabel: `${v.hours}h - ${percentValue.toFixed(1)}%`,
        };
      });
    
      return newElements;
    }
    
    return [];
  };
    
  const getChartLine = (rawData: any[]): any[] => {
    if (!rawData || rawData.length == 0) return [];
    else {
      const data = rawData.map((item: any) => {
        return {
          week: item.week.substring(0, 6) + item.week.substring(8, 10),
          hours: Number.parseFloat(item.hours),
        };
      });
    
      return data;
    }
  };
    
  const getChartDataArray = (rawData: any[]): any[] => (rawData && rawData.length > 0 ? rawData : []);
    
  const getChartData = (rawData: string): string => (rawData && rawData.length > 0 ? rawData.toString() : '0');

  if((resourcesQuantity.loading))
    return <IconLoading />;

  return (
    <>
      <PreviewHeader period={period} title="Quantitativo de recursos" />
      <S.PreviewContent>
        <S.TitleContainer>
          <S.ContentTitle>Quantitativo total:</S.ContentTitle>
          <S.ContentTitle style={{ fontWeight: 500, margin: '0 .8rem' }}>{getChartData(resourcesQuantity.report.qtdTotal.toString())}</S.ContentTitle>
          <S.ContentTitle style={{ fontWeight: 400 }}>(Max.: {getChartData(resourcesQuantity.report.maxQtdTotal.toString())})</S.ContentTitle>
        </S.TitleContainer>

        <S.TitleContainer flexDirection="column">
          <S.ContentTitle>Linha do tempo de presença</S.ContentTitle>
          <S.ContentSubtitle style={{ fontStyle: 'italic' }}>
            media do total de recursos / Hora do dia
          </S.ContentSubtitle>
          <ExampleTimelineChart data={resourcesQuantity.report.timeline}/>
        </S.TitleContainer>

        <S.TitleContainer flexDirection="column" className="pagebreak">
          <S.ContentTitle>Histórico de quantidade</S.ContentTitle>
          <S.ContentSubtitle>Quantitativo acumulado e sua % em relação ao total</S.ContentSubtitle>
          <ExampleLineChart data={getChartLine(resourcesQuantity.report.history)}/>
        </S.TitleContainer>

        <S.TitleContainer flexDirection="column" className="pagebreak">
          <S.ContentTitle>Quantitativo por função</S.ContentTitle>
          <S.ContentSubtitle>Quantitativo acumulado por função - e sua % em relação ao total</S.ContentSubtitle>
          <ExampleBarChart data={attachEndlabel(getChartDataArray(resourcesQuantity.report.function))} />
        </S.TitleContainer>

        <S.TitleContainer flexDirection="column">
          <S.ContentTitle>Quantitativo por área</S.ContentTitle>
          <S.ContentSubtitle>Quantitativo acumulado por area - e sua % em relação ao total</S.ContentSubtitle>
          <ExampleBarChart data={attachEndlabel(getChartDataArray(resourcesQuantity.report.area))} />
        </S.TitleContainer>
      </S.PreviewContent>
    </>
  );
};
