import React from 'react';
import { DefaultIconProps } from './types';

export const CompanyIcon: React.FC<DefaultIconProps> = ({ width, height, style, fill }) => {
  return (
    <svg
      style={style}
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4166 18.3349H3.39993C2.43327 18.3349 1.6416 17.5599 1.6416 16.6099V4.24326C1.6416 2.05993 3.2666 1.06826 5.25827 2.04326L8.95827 3.85993C9.75827 4.25159 10.4166 5.29326 10.4166 6.17659V18.3349Z"
        stroke={fill || '#808080'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3087 12.5503V15.7003C18.3087 17.5003 17.4753 18.3336 15.6753 18.3336H10.417V8.68359L10.8087 8.76693L14.5587 9.60859L16.2503 9.98359C17.3503 10.2253 18.2503 10.7919 18.3003 12.3919C18.3087 12.4419 18.3087 12.4919 18.3087 12.5503Z"
        stroke={fill || '#808080'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.58301 7.5H7.47467"
        stroke={fill || '#808080'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.58301 10.832H7.47467"
        stroke={fill || '#808080'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5586 9.60729V12.2906C14.5586 13.324 13.7169 14.1656 12.6836 14.1656C11.6503 14.1656 10.8086 13.324 10.8086 12.2906V8.76562L14.5586 9.60729Z"
        stroke={fill || '#808080'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3003 12.3927C18.2503 13.376 17.4336 14.1677 16.4336 14.1677C15.4003 14.1677 14.5586 13.326 14.5586 12.2927V9.60938L16.2503 9.98437C17.3503 10.226 18.2503 10.7927 18.3003 12.3927Z"
        stroke={fill || '#808080'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};