import { useCallback, useEffect, useRef, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import Map, { MapRef } from 'react-map-gl';

import { GeolocationPosition } from '../../../../../../../types/Postion';
import { useIsMounted } from '../../../../../../../hooks';
import { CoordinatesResponse, getSiteCoordinates } from '../../../../../../../services/api/TrackfyService';
import { useAuthContext } from '../../../../../../../stores';
import { useRegisterAreaContext } from '../../../context/RegisterAreaContext';

export const useMap = (isAdmin: boolean) => {
  const [initializeMap, setInitializeMap] = useState<boolean>(false);
  const { clientSelected, setCoordinates, getClientId } = useRegisterAreaContext();

  const latitudeRef = useRef(0);
  const longitudeRef = useRef(0);
  const isManagerRef = useRef(false);
  const zoomRef = useRef(17);
  const mapRef = useRef<MapRef>(null);

  const isMounted = useIsMounted();
  const { authData } = useAuthContext();
  const mapStyle = 'mapbox://styles/mapbox/satellite-streets-v11';

  const handleDrawCreate = (event: any) => {
    setCoordinates(JSON.stringify(event.features[0].geometry.coordinates[0]));
  };

  const onDelete = useCallback(() => {
    setCoordinates('');
  }, []);

  const mapGoToCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
      if (initializeMap) return;

      const latitude: number = Number(position.coords.latitude);
      const longitude: number = Number(position.coords.longitude);

      if (initializeMap) return;

      if (!isMounted.current) return;

      latitudeRef.current = latitude;
      longitudeRef.current = longitude;
      setInitializeMap(true);
    });
  };

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let companies = '';
    if (authData) companies = authData.grpCompanies;
    isManagerRef.current = companies === 'a' || companies.includes(',');
    const currentClient = isAdmin ? clientSelected : getClientId();

    getSiteCoordinates(currentClient, currentRequest.token)
      .then((data: CoordinatesResponse) => {
        const { latitude, longitude } = data;

        if (!latitude || !longitude) return mapGoToCurrentLocation();

        latitudeRef.current = Number(data.latitude);
        longitudeRef.current = Number(data.longitude);
        zoomRef.current = Number(17);

        mapRef.current?.easeTo({
          center: [Number(data.longitude), Number(data.latitude)],
          zoom: 17,
        });

        setInitializeMap(true);
      })
      .catch((_error) => mapGoToCurrentLocation());

    return () => {
      currentRequest.cancel('RegisterAreaMap request canceled by the user.');
    };
  }, [clientSelected]);

  return {
    initializeMap,
    mapStyle,
    Map,
    isManagerRef,
    latitudeRef,
    longitudeRef,
    zoomRef,
    mapRef,
    handleDrawCreate,
    onDelete,
  };
};
