import moment from 'moment';
import React, { createContext, ReactNode, useState, useContext } from 'react';
import { useAuthContext } from '../../../stores/AuthContext';

type TextInsightsContextProps = {
  companySelected: number;
  clientSelected: number;
  initialDate: any;
  finalDate: any;
  setCompanySelected: (companie: number) => void;
  setClientSelected: (client: number) => void;
  updatePeriod: (initial: any, final: any) => void;
};

type Props = {
  children: ReactNode;
};

const TextInsightsContext = createContext<TextInsightsContextProps>({} as TextInsightsContextProps);

export function TextInsightsContextProvider(props: Props) {
  const { children } = props;

  const { authData } = useAuthContext();
  const [initialDate, setInitialDate] = useState<any>(moment());
  const [finalDate, setFinalDate] = useState<any>(moment());

  const companyId =
    authData && authData.grpCompanies != 'a' && !authData.grpCompanies.includes(',')
      ? authData.companyId
      : 0;

  const [companySelected, setCompanySelected] = useState<number>(companyId);
  const [clientSelected, setClientSelected] = useState<number>(0);

  const updatePeriod = (initial: any, final: any) => {
    setInitialDate(initial);
    setFinalDate(final);
  };

  const value = {
    companySelected,
    clientSelected,
    initialDate,
    finalDate,
    setCompanySelected,
    setClientSelected,
    updatePeriod,
  };
  return <TextInsightsContext.Provider value={value}>{children}</TextInsightsContext.Provider>;
}

export function useTextInsightsContext() {
  const context = useContext(TextInsightsContext);

  if (typeof context === 'undefined') {
    throw new Error('TextInsightsContext must be used within an useTextInsightsContext');
  }

  return context;
}