import React from 'react';

import { Bar, BarChart, CartesianGrid, XAxis, ResponsiveContainer, LabelList, YAxis, Legend } from 'recharts';
import { ProductivityVSDisplacement } from '../../types/types';

import * as S from './style';

type VerticalBarChartProps = {
  prodVSDisChartData: ProductivityVSDisplacement[] | undefined;
};

export const VerticalBarChart: React.FC<VerticalBarChartProps> = ({ prodVSDisChartData }) => {

  const CustomLegend = () => {
    return (
      <S.Legend>
        <S.Square color='rgba(0,111,255,1)'/>
        <S.CustomLegend color='rgba(0,111,255,1)'>Tempo em serviço</S.CustomLegend>

        <S.Square color='rgba(0,213,209,1)'/>
        <S.CustomLegend color='rgba(0,213,209,1)'>Tempo no almoxarifado</S.CustomLegend>

        <S.Square color='rgba(255,199,0,1)'/>
        <S.CustomLegend color='rgba(255,199,0,1)'>Tempo em deslocamento</S.CustomLegend>
      </S.Legend>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={700} height={250} data={prodVSDisChartData}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="date" />
        <Legend content={CustomLegend}/>
        {/*<YAxis label={{ value: 'Minutos', angle: -90, position: 'insideLeft' }} />*/}
        <Bar dataKey="serviceTime" fill="rgba(0,111,255,1)" radius={[5, 5, 0, 0]} barSize={40}>
          <LabelList dataKey="serviceTimeLabel" position="insideBottom" offset={30} />
        </Bar>
        <Bar dataKey="almox" fill="rgba(0,213,209,1)" radius={[5, 5, 0, 0]} barSize={40}>
          <LabelList dataKey="almoxLabel" position="insideBottom" offset={30} />
        </Bar>
        <Bar dataKey="displacement" fill="rgba(255,199,0,1)" radius={[5, 5, 0, 0]} barSize={40}>
          <LabelList dataKey="displacementLabel" position="insideBottom" offset={30} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
