import React from 'react';

export const InfleetContainer: React.FC = () => {
  return (
    <div style={{ width: '100%', height: '100vh', border: '1px solid black' }}>
      <iframe
        src="https://app.infleet.com.br/"
        style={{ width: '100%', height: '100%' }}
        frameBorder="0"
        allowFullScreen
        title="Infleet Machine Summary"
      />
    </div>
  );
};