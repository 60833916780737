import styled from 'styled-components';

/*export const Title = styled.h1`
  font: normal 600 1.9rem/1 Work Sans, sans-serif;
  color: #4f5882;
  text-align: center;
  box-sizing: border-box;
  margin: 0;
`;*/

export const Button = styled.button`
  padding: 2px;
  border: 0.5px solid #ebebf9;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 0.5px 0.5px 0.5px #bcbbbb;
  align-items: center;
  justify-content: center;
  width: 120px;
  font-weight:500;

  &:hover {
    cursor: pointer;
    background: #273dab;
    color: white;
    width: 110px;
    transition: 0.4s;
  }
`;

export const TrStyled2 = styled.tr`
  height: 35px;
  font-weight: 500;
`;