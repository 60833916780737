import React from 'react';

type Props = {
    stroke: string;
  };

export const IconFinishVuesax: React.FC<Props> = ({stroke}) => (
    <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.58331 3.3335V36.6668" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.58331 6.66715C10.3551 7.28435 11.9038 7.61529 13.3335 7.73389C15.1183 7.88194 16.7175 7.69906 18.3335 7.32903C19.9202 6.9657 21.523 6.42193 23.3335 5.83385C27.5001 4.4804 33.3335 5.00046 33.3335 6.66602V21.666C33.3335 20.6257 31.0576 20.0322 28.3335 20.0632C26.6964 20.0817 24.8975 20.3258 23.3335 20.8339C19.6793 22.0208 16.8708 23.0273 13.3335 22.7339C11.9038 22.6153 10.3551 22.2844 8.58331 21.6672" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);