import React from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';

import { useAuthContext } from '../../stores';
import { AreasContextProvider } from './context/AreasContext';
import { RealTimeComponentsContainer } from './components/RealTimeComponentsContainer';
import { CompaniesContextProvider } from './context/CompaniesContext';

export const RealTimeContainer: React.FC<{}> = () => {
  const { authData } = useAuthContext();
  const hasMap = authData && authData.hasMap ? authData.hasMap : false;
  
  return (
    <AreasContextProvider>
      <CompaniesContextProvider>
        <RealTimeComponentsContainer authData={authData} hasMap={hasMap} />
      </CompaniesContextProvider>
    </AreasContextProvider>
  );
};
