import React from 'react';

import { CardContent, CardHeader } from './components';
import { useCard } from './hooks/useCard';

import './styles.css';

type Props = {
  titulo: string;
};

export const Card: React.FC<Props> = ({ titulo }: Props) => {
  const { cardExpanded, handleClickExpand } = useCard();

  return (
    <div className={cardExpanded ? 'card expanded' : 'card'}>
      <CardHeader titulo={titulo} expanded={cardExpanded} onClick={handleClickExpand} />
      <CardContent />
    </div>
  );
};
