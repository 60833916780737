import React from 'react';
import moment from 'moment';

import { useCreateNewClient } from './hooks/useCreateClient';
import { useAuthContext } from '../../../../../stores/AuthContext';
import { Modal } from '../../../../../components/Modal';
import IconClose from '../../../../../Icons/Close';
import IconUsers from '../../../../../Icons/Users';
import IconManager from '../../../../../Icons/Manager';
import { ToastContainer } from 'react-toastify';

import { SimpleContainer } from '../../../../../styles/structure';
import * as S from './styles';

type AddClientContainerProps = {
  isActive: boolean;
  setIsAdding(value: boolean): void;
  onClickToSave(): void;
  onClickToClose(): void;
};

export const AddClientContainer: React.FC<AddClientContainerProps> = (props: AddClientContainerProps) => {
  const { isActive, onClickToClose, onClickToSave } = props;
  const { authData } = useAuthContext();
  const {
    isDefined,
    handleSaveClient,
    createClients,
    setNameSelected,
    setTypeSelected,
    setLatSelected,
    setLngSelected,
  } = useCreateNewClient();

  return (
    <>
      <ToastContainer />
      <Modal visible={isActive}>
        <S.AddClientContainer>
          <S.CloseButton onClick={onClickToClose}>
            <IconClose />
          </S.CloseButton>

          <S.ClientListHeaderTitleContainer>
            <S.IconUsersContainer>
              <IconUsers />
            </S.IconUsersContainer>
            <S.ClientListHeaderLabel>Criar cliente novo</S.ClientListHeaderLabel>
          </S.ClientListHeaderTitleContainer>

          <S.AddClientFrontContent>
            <S.ClientInfoContainer>
              <S.FilterInputContainer>
                <S.InputLabel>Cliente</S.InputLabel>
                <SimpleContainer>
                  <S.ClientInpunt
                    placeholder="Insira o nome do cliente"
                    value={createClients.name}
                    onChange={(event) => setNameSelected(event.target.value)}
                  />
                </SimpleContainer>
              </S.FilterInputContainer>
              <S.FilterInputContainer>
                <S.InputLabel>Área de atuação(civil,industria...)</S.InputLabel>
                <SimpleContainer>
                  <S.ClientInpunt
                    placeholder="Insira a área de atuação do cliente"
                    value={createClients.type}
                    onChange={(event) => setTypeSelected(event.target.value)}
                  />
                </SimpleContainer>
              </S.FilterInputContainer>
            </S.ClientInfoContainer>

            <S.ClientInfoContainer>
              <S.FilterInputContainer>
                <S.InputLabel>Latitude</S.InputLabel>
                <SimpleContainer>
                  <S.ClientInpunt
                    placeholder="Insira a latitude"
                    value={createClients.lat}
                    onChange={(event) => setLatSelected(event.target.value)}
                  />
                </SimpleContainer>
              </S.FilterInputContainer>
              <S.FilterInputContainer>
                <S.InputLabel>Longitude</S.InputLabel>
                <SimpleContainer>
                  <S.ClientInpunt
                    placeholder="Insira a longitude"
                    value={createClients.lng}
                    onChange={(event) => setLngSelected(event.target.value)}
                  />
                </SimpleContainer>
              </S.FilterInputContainer>
            </S.ClientInfoContainer>

            <S.SaveButtonContainer>
              <S.SaveButton
                title="Criar cliente novo"
                icon={<IconManager />}
                disabled={!isDefined()}
                //loading={addingClient}
                onClick={async () => {
                  const isSaved = await handleSaveClient();
                  if (isSaved) onClickToSave();
                }}
              />
            </S.SaveButtonContainer>
          </S.AddClientFrontContent>
        </S.AddClientContainer>
      </Modal>
    </>
  );
};
