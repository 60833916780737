import styled from 'styled-components';

interface BuildingFloorProps {
  entrance?: boolean;
  productive?: boolean;
  isLeft?: boolean;
  isBig?: boolean;
  color?: string;
}

export const AreasCards = styled.div`
  position: absolute;
  width: 20%;
  top: 18px;
  left: 40px;
`;

export const MonitoringIndicatorsContainer = styled.div`
  position: absolute;
  width: 25%;
  display: grid;
  gap: 18px;
  right: 40px;
  top: 18px;
`;


export const BuildingPageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PerspectiveBuildingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 500px;
  min-height: 825px;
  margin: 3% 0 0 0;
  border-radius: 6px;
  box-shadow: rgb(35 35 35 / 9%) 0px 2px 8px 2px;
`;

export const PerspectiveRoof = styled.div`
  position: absolute;
  width: 150px;
  height: 150px;
  border: 1px solid black;
  transform: rotateX(-40deg) rotateY(32deg) rotateX(90deg) translateZ(65px);
  z-index: 1000;
  background: #d3d3d3;
`;

export const PerspectiveFloorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -52px;
  margin-left: -150px;
`;

export const PerspectiveFloorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 32px;

  &:hover > div {
    cursor: pointer;
    border style: solid;
    background: #2f4cdd;
    border-color: #2f4cdd;
    opacity: 0.7;
    transition: 0.4s;
  }
`;

export const PerspectiveRowContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const FrontWall = styled.div<BuildingFloorProps>`
  position: absolute;
  width: 150px;
  //height: ${(props) => (props.entrance ? '75px' : '50px')};
  height: ${(props) => (props.entrance ? '65px' : '40px')};
  //background: rgba(255,255,255,0.3);
  background: ${(props) => (props.productive ? props.color : 'rgba(255,255,255,0.3)')};
  border: 1px solid black;
  color: white;
  text-align: center;
  line-height: 100px;
  transform: rotateX(-40deg) rotateY(32deg) translateZ(75px);
  z-index: 1000;
`;

export const FrontHeaderContainer = styled.div<BuildingFloorProps>`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 15px;
  width: 50%;
  //border-left-style: solid;
  border-color: ${(props) => (props.productive ? 'black' : '#d0d0d0')};
  border-left-style: ${(props) => (props.isLeft ? 'solid' : 'none')};
  border-left-width: 1px;
  border-right-style: ${(props) => (props.isLeft ? 'none' : 'solid')};
  border-right-width: 1px;
`;

export const FrontHeader = styled.div<BuildingFloorProps>`
  width: ${(props) => (props.isBig ? '20px' : '15px')};
  height: ${(props) => (props.isBig ? '5px' : '1px')};
  border: ${(props) => (props.productive ? 'solid 1px #000' : 'solid 1px #d0d0d0')};
`;

export const FrontWindowsContainer = styled.div<BuildingFloorProps>`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  //height: 35px;
  height: 25px;
  border: ${(props) => (props.productive ? 'solid 1px #000' : 'solid 1px #d0d0d0')};
  border-radius: 1px;
`;

export const FrontWindow = styled.div<BuildingFloorProps>`
  width: ${(props) => (props.isBig ? '20px' : '15px')};
  //height: ${(props) => (props.isBig ? '25px' : '15px')};
  height: ${(props) => (props.isBig ? '20px' : '10px')};
  border: ${(props) => (props.productive ? 'solid 1px #000' : 'solid 1px #d0d0d0')};
  border-radius: 1px;
`;

export const LeftWall = styled.div<BuildingFloorProps>`
  position: absolute;
  width: 150px;
  //height: ${(props) => (props.entrance ? '75px' : '50px')};
  height: ${(props) => (props.entrance ? '65px' : '40px')};
  //background: rgba(255,255,255,0.3);
  background: ${(props) => (props.productive ? props.color : 'rgba(255,255,255,0.3)')};
  border: 1px solid black;
  color: white;
  text-align: center;
  line-height: 100px;
  transform: rotateX(-40deg) rotateY(32deg) rotateY(-90deg) translateZ(75px);
  z-index: 1000;
`;

export const LeftStrip = styled.div<BuildingFloorProps>`
  width: 27.5px;
  //height: 50px;
  height: 40px;
  background: ${(props) => (props.productive ? 'gray' : '#c3c3c3')};
  border: ${(props) => (props.productive ? 'solid 1px gray' : 'solid 1px #c3c3c3')};
  box-shadow: rgb(35 35 35 / 9%) 0px 15px 8px 15px;
  opacity: 0.5;
`;

export const LeftWindow = styled.div<BuildingFloorProps>`
  width: 15px;
  height: 15px;
  border-radius: 1px;
  margin-top: 15px;
  border: ${(props) => (props.productive ? 'solid 1px #000' : 'solid 1px #d0d0d0')};
`;

export const HallRowContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 2px;
`;

export const HallHeaderContainer = styled.div<BuildingFloorProps>`
  display: flex;
  justify-content: ${(props) => (props.isLeft ? 'flex-end' : 'flex-start')};
  align-items: center;
  width: 50%;
  height: 10px;
  border-left-style: ${(props) => (props.isLeft ? 'solid' : 'none')};
  border-left-width: ${(props) => (props.isLeft ? '1px' : '0px')};
  border-right-style: ${(props) => (props.isLeft ? 'none' : 'solid')};
  border-right-width: ${(props) => (props.isLeft ? '01px' : '1px')};
  border-color: ${(props) => (props.productive ? 'black' : '#d0d0d0')};
`;

export const HallContainer = styled.div<BuildingFloorProps>`
  display: flex;
  justify-content: ${(props) => (props.isLeft ? 'flex-end' : 'flex-start')};
  align-items: center;
  width: 50%;
  border: ${(props) => (props.productive ? 'solid 1px #000' : 'solid 1px #d0d0d0')};
  border-radius: 1px;
`;

export const HallHeader = styled.div<BuildingFloorProps>`
  width: 40px;
  height: 2px;
  border: ${(props) => (props.productive ? 'solid 1px #000' : 'solid 1px #d0d0d0')};
`;

export const HallEntrance = styled.div<BuildingFloorProps>`
  width: 40px;
  //height: 60px;
  height: 50px;
  border-radius: 1px;
  border: ${(props) => (props.productive ? 'solid 1px #000' : 'solid 1px #d0d0d0')};
`;

export const HallWindow = styled.div<BuildingFloorProps>`
  width: 20px;
  height: 20px;
  border-radius: 1px;
  margin-top: 27.5px;
  border: ${(props) => (props.productive ? 'solid 1px #000' : 'solid 1px #d0d0d0')};
`;
