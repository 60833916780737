import React, { createContext, ReactNode, useState, useContext, SetStateAction, Dispatch } from 'react';
import { DisplacementBtwnAreasMarkerProps } from '../components/DisplacementBtwnAreasMarker';

export interface selectedAreasMarkers {
  initialAreaOnMap: DisplacementBtwnAreasMarkerProps;
  finalAreaOnMap: DisplacementBtwnAreasMarkerProps;
}

type DisplacementBtwnAreasContextProps = {
  initialAreaSelected: number,
  initialAreaLabelSelected: string,
  finalAreaSelected: number,
  finalAreaLabelSelected: string,
  areasMarkers: selectedAreasMarkers,
  setInitialAreaSelected: (initArea: number) => void,
  setInitialAreaLabelSelected: (initArea: string) => void,
  setFinalAreaSelected: (finalArea: number) => void,
  setFinalAreaLabelSelected: (finalArea: string) => void,
  setAreasMarkers: Dispatch<SetStateAction<selectedAreasMarkers>>;
};

type Props = {
  children: ReactNode;
};

const DisplacementBtwnAreasContext = 
    createContext<DisplacementBtwnAreasContextProps>({} as DisplacementBtwnAreasContextProps);

export function DisplacementBtwnAreasContextProvider(props: Props) {
  const { children } = props;

  const [initialAreaSelected, setInitialAreaSelected] = useState<number>(0);
  const [initialAreaLabelSelected, setInitialAreaLabelSelected] = useState<string>('');
  const [finalAreaSelected, setFinalAreaSelected] = useState<number>(0);
  const [finalAreaLabelSelected, setFinalAreaLabelSelected] = useState<string>('');
  const [areasMarkers, setAreasMarkers] = useState<selectedAreasMarkers>({
    initialAreaOnMap: { contentName: "", color: "", icon:<div/>, lat:0, lng:0, coordinates:"" },
    finalAreaOnMap: { contentName: "", color: "", icon:<div/>, lat:0, lng:0, coordinates:"" }
  });

  const value = {
    initialAreaSelected,
    initialAreaLabelSelected,
    finalAreaSelected,
    finalAreaLabelSelected,
    areasMarkers,
    setInitialAreaSelected,
    setInitialAreaLabelSelected,
    setFinalAreaSelected,
    setFinalAreaLabelSelected,
    setAreasMarkers
  };
  
  return <DisplacementBtwnAreasContext.Provider value={value}>{children}</DisplacementBtwnAreasContext.Provider>;
}

export function useDisplacementBtwnAreasContext() {
  const context = useContext(DisplacementBtwnAreasContext);

  if (typeof context === 'undefined') {
    throw new Error('DisplacementAtoBContext must be used within an DisplacementAtoBContext');
  }

  return context;
}