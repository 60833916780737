import React from 'react';

import { AddAreaContainer } from '../CreateArea';
import IconUsers from '../../../../../Icons/Users';
import { Button } from '../../../../../components/Buttons/Button';
import IconAdd from '../../../../../Icons/Add';
import { useDrawArea } from './hooks/useDrawArea';
import { DrawMap } from './Map/DrawMap';
import { ToastContainer } from 'react-toastify';

import * as S from './styles';
import 'react-toastify/dist/ReactToastify.css';

type AreaDesignerProps = {
  isAdmin: boolean;
}

export const AreaDesigner: React.FC<AreaDesignerProps> = ({isAdmin}) => {
  const { isAdding, setIsAdding, handleCloseAddArea, handleCloseAddAreaAndUpdateMap } = useDrawArea();

  return (
    <S.DrawAreaContainer>
      <ToastContainer />
      <S.DrawAreaHeader>
        <S.DrawAreaHeaderTitleContainer>
          <S.IconUsersContainer>
            <IconUsers />
          </S.IconUsersContainer>
          <S.DrawAreaHeaderLabel>Criação de áreas monitoradas</S.DrawAreaHeaderLabel>
        </S.DrawAreaHeaderTitleContainer>
        <S.NewAreaButtonContainer>
          <Button
            title="Nova área"
            icon={<IconAdd fill="#FFF" height="20px" width="20px" />}
            onClick={() => setIsAdding(true)}
          />
        </S.NewAreaButtonContainer>
      </S.DrawAreaHeader>

      <DrawMap isAdmin={isAdmin} />

      {isAdding && (
        <AddAreaContainer
          isAdmin={isAdmin}
          isActive={isAdding}
          onClickToClose={handleCloseAddArea}
          onClickToSave={handleCloseAddAreaAndUpdateMap}
        />
      )}
    </S.DrawAreaContainer>
  );
};
