import { Line, LineConfig } from '@ant-design/charts';
import Axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { IconLoading } from '../../../components/IconLoading';
import { useTrackfyApi } from '../../../hooks/useTrackfyApi';
import { useConformityContext } from '../context/ConformityContext';
import { useAuth } from '../../../hooks';
import { parseSelectOptions } from '../../../utils/Convert';
import { getLegend } from './BurndownChartDynamicContent';

const BurndownChartContent: React.FC = () => {
  const { areaSelected, companySelected, professionSelected, initialDate, finalDate, setPlanningData,
    planningData } = useConformityContext();
  const { getClientId } = useAuth();
  const { getBurndownChartDataApi } = useTrackfyApi();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const color = ['#4F5882', '#468FEA', '#00E0B4'];

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    getBurndownChartDataApi(
      getClientId(),
      parseSelectOptions(companySelected),
      parseSelectOptions(professionSelected),
      parseSelectOptions(areaSelected),
      moment(initialDate).valueOf(),
      moment(finalDate).valueOf(),
      currentRequest.token,
      setIsLoading,
      setPlanningData
    );

    return () => {
      currentRequest.cancel('BurndownChart request canceled by the user.');
    };
  }, [areaSelected, companySelected, professionSelected, initialDate, finalDate]);

  const config: LineConfig = {
    data: planningData,
    xField: 'time',
    yField: 'value',
    color,
    seriesField: 'type',
    point: {
      size: 5,
      shape: 'diamond | circule',
    },
    yAxis: {
      label: {
        formatter: function formatter(v: any) {
          return ''.concat(v, 'hrs');
        },
      },
    },
    legend: {
      custom: true,
      position: 'bottom',
      items: getLegend(getClientId(), color),
    },
  };

  if (isLoading)
    return <IconLoading customDivStyle={{ marginTop: '100px', marginLeft: '45%', marginRight: '45%' }} />;

  return <Line {...config} />;
};

export default BurndownChartContent;
