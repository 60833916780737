import React, { createContext, ReactNode, useState, useContext } from 'react';

import { PaginationControls } from '../../../../../../components/Buttons/PaginationControls';
import { Otimizacoes } from '../../../../types/Insights';

type ContextValue = {
  otimizacoesChartData: Otimizacoes[];
  isLoading: boolean;
  otimizacoesValues: Array<Otimizacoes[]>;
  setOtimizacoesChartData: (value: Otimizacoes[]) => void;
  setOtimizacoesValues: (arrayAvarageValues: Array<Otimizacoes[]>) => void;
  setIsLoading: (value: boolean) => void;
};

const OtimizacoesContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function OtimizacoesContextProvider(props: Props) {
  const { children } = props;
  const [otimizacoesValues, setOtimizacoesValues] = useState<Array<Otimizacoes[]>>([]);
  const [otimizacoesChartData, setOtimizacoesChartData] = useState<Otimizacoes[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const value = {
    otimizacoesChartData,
    otimizacoesValues,
    isLoading,
    setOtimizacoesChartData,
    setOtimizacoesValues,
    setIsLoading,
  };

  return (
    <OtimizacoesContext.Provider value={value}>
      {children}
      <PaginationControls
        arrayValues={otimizacoesValues}
        setNewValue={setOtimizacoesChartData}
        title="Otimizações"
      />
    </OtimizacoesContext.Provider>
  );
}

export function useOtimizacoesContext() {
  const context = useContext(OtimizacoesContext);

  if (typeof context === 'undefined') {
    throw new Error('otimizacoesContext must be used within an otimizacoesContext');
  }

  return context;
}
