import styled from 'styled-components';

interface ContainerProps {
  marginTop?: string;
}

interface ChartSectionProps {
  color: string;
  isFirstItem: boolean;
  isLastItem: boolean;
  minHeight?: string;
}

interface HoursCountSectionProps {
  minHeight?: string;
}

interface ChartSectionLabelProps extends Pick<ChartSectionProps, 'isLastItem'> {}
interface HoursCountItemProps extends Pick<ChartSectionProps, 'color' | 'isLastItem'> {}
interface DisplacementItemProps extends Pick<ChartSectionProps, 'color'> {}

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: ${({ marginTop }) => marginTop};
`;

export const HoursCountContainer = styled.aside`
  padding: 0 !important;
  padding-top: 0.5rem !important;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 4rem;
  height: 100%;
  min-height: 45rem;
  gap: 2.2rem;
`;
export const ChartContainer = styled.div`
  display: flex;
  width: 6.5rem;
  flex-direction: column;
  margin: 0rem 2rem;
  min-height: 45rem;
`;
export const DisplacementContainer = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  padding-right: 20rem;
  gap: 1.7rem;
  min-height: 45rem;
`;

export const HoursCountSection = styled.div<HoursCountSectionProps>`
  display: flex;
  min-height: ${({ minHeight }) => minHeight};
  height: 3.8rem;
  width: 100%;
`;
export const ChartSection = styled.div<ChartSectionProps>`
  display: flex;
  justify-content: flex-end;
  min-height: ${({ minHeight }) => minHeight};
  height: 6rem;
  width: 100%;
  position: relative;
  border-radius: ${({ isFirstItem, isLastItem }) =>
    isFirstItem ? '4px 4px 0 0' : isLastItem ? '0 0 4px 4px' : 0};
  & div {
    background-color: ${({ color }) => color};
    width: 1.6rem;
    height: 100%;
    border-radius: ${({ isFirstItem, isLastItem }) =>
      isFirstItem ? '4px 4px 0 0' : isLastItem ? '0 0 4px 4px' : 0};
  }
`;
export const DisplacementSection = styled.div<HoursCountSectionProps>`
  display: flex;
  min-height: ${({ minHeight }) => minHeight};
  height: 4.3rem;
  width: 100%;
`;

export const HoursCountItem = styled.div<HoursCountItemProps>`
  color: ${({ color }) => color};
  width: 100%;
  text-align: right;
  font-weight: 600;
  font-family: Work Sans;
  font-size: 1.1rem;
  margin-bottom: ${({ isLastItem }) => (isLastItem ? '0.5rem' : '0')};
`;
export const ChartSectionLabel = styled.span<ChartSectionLabelProps>`
  position: absolute;
  top: ${({ isLastItem }) => (isLastItem ? 'inherit' : '-1.1rem')};
  bottom: ${({ isLastItem }) => (isLastItem ? '-0.8rem' : 'inherit')};
  right: 1.2rem;
  width: 100%;
  color: #999999;
  font-size: 1.1rem;
  font-family: Work Sans;
`;
export const DisplacementItem = styled.div<DisplacementItemProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: ${({ color }) => color};
  font-weight: 500;
  font-family: Work Sans;
  font-size: 1.1rem;
  text-align: center;
`;
