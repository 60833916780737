import React from 'react';

import { IconLoading } from '../../../../../components/IconLoading';

import { CardAvgcontent } from './styles';

import { VerticalBarChart } from './components/VerticalBarChart';

import { useEconomia } from './hooks/UseEconomia';
import { useEconomiaContext } from './context/EconomiaContext';
import { EconomiaChartHeader } from './components/EconomiaChartHeader';

const EconomiaChart: React.FC = () => {
  const { economiaChartData } = useEconomiaContext();
  const { isLoading } = useEconomia();

  return (
    <>
      <EconomiaChartHeader />
      <CardAvgcontent>
        {isLoading && <IconLoading />}
        {!isLoading && <VerticalBarChart economiaChartData={economiaChartData} />}
      </CardAvgcontent>
    </>
  );
};

export default EconomiaChart;
