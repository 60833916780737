import { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { useAuth } from '../../../../../hooks';
import { getHHIndicators } from '../../../../../services/api/TrackfyService';

interface HHProps {
  indicator: string;
  loading: boolean;
}

export const useHhindicator = () => {
  const [HHIndicator, setHHIndicator] = useState<HHProps>({indicator : '', loading: true});
  const { getClientId } = useAuth();

  const clientId: number = getClientId();
  const companyId = '0';

  useEffect(() => {
    setHHIndicator((prev: HHProps) => ({...prev,  loading: true}));
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if (mounted) {
      getHHIndicators(clientId, companyId, currentRequest.token)
        .then((result: AxiosResponse<any>) => {
          let indicatorText = '0';

          if (result.data) indicatorText = result.data;

          setHHIndicator({indicator:indicatorText, loading: false});
        })
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('Hhindicator request canceled by the user.');
    };
  }, [companyId]);

  return { HHIndicator };
};
