import React from 'react';
import Table from '../../../../components/Table/Table';
import { getObjectToStringMatrix } from '../../../../utils/Convert';
import { Incidents } from '../../types/Incidents';
import { headers } from './Header';

import * as S from './styles';

export type IncidentsTableProps = {
  detailData: Incidents[];
  loading: boolean;
  empty: boolean;
  solved: string[];
};

export const IncidentsTable: React.FC<IncidentsTableProps> = (props: IncidentsTableProps) => {
  const { detailData, loading, empty, solved } = props;

  return (
    <S.TableContainer>
      <Table
        tableHeaders={headers}
        tableData={getObjectToStringMatrix(detailData)}
        loading={loading}
        isEmpty={empty}
        backgroundColors={solved}
        emptyMessage="Ainda não há incidentes"
      />
    </S.TableContainer>
  );
};
