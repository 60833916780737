import React from 'react';

interface Props{
    fill?: string;
    width?: number;
    height?: number;
}

export const CheckGuardIcon: React.FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
        width={width || '24'}
        height={height || '24'}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9099 11.1203C20.9099 16.0103 17.3599 20.5903 12.5099 21.9303C12.1799 22.0203 11.8198 22.0203 11.4898 21.9303C6.63984 20.5903 3.08984 16.0103 3.08984 11.1203V6.73028C3.08984 5.91028 3.70986 4.98028 4.47986 4.67028L10.0498 2.39031C11.2998 1.88031 12.7098 1.88031 13.9598 2.39031L19.5298 4.67028C20.2898 4.98028 20.9199 5.91028 20.9199 6.73028L20.9099 11.1203Z"
        stroke={fill || '#ED3A3A'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.0498 11.8697L10.6598 13.4797L14.9598 9.17969"
        stroke={fill || '#ED3A3A'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};