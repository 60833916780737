import {
  IAuthRequest,
  changePassAndSignIn as changePassAndSignInService,
  signIn as signInService,
  getRefreshToken as getRefreshTokenService,
  IAuthResponse,
} from '../services/api/auth';
import CookieService from '../services/CookieService';
import { AuthData, DynamicComponents, TabComponent } from '../types';

const ACCESS_TOKEN_KEY = '@trackfy_access_token';
const REFRESH_ACCESS_TOKEN_KEY = '@trackfy_refresh_access_token';
const USER_ID = '@trackfy_user_id';
const CLIENT_ID = '@trackfy_client_id';
const USER_NAME = '@trackfy_user_name';
const COMPANY_ID = '@trackfy_company_id';

export const useAuth = () => {
  const isAuthenticated = () => localStorage.getItem(ACCESS_TOKEN_KEY) !== null;
  const getToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);
  const getRefreshToken = () => CookieService.get(REFRESH_ACCESS_TOKEN_KEY);

  const splitComponents = (components:string[]): DynamicComponents => {
    const tabs: TabComponent[] = [];
    const summary: string[] = [];
    const charts: string[] = [];

    if(components && components.length > 0) {
      components.forEach((component:string) => {
        switch(component){
          case'conformityTab':{
            tabs.push({ component: 'conformity', title: 'Conformidade'});
            break;
          }
            
          case'productivityTab':{
            tabs.push({ component: 'productivity', title: 'Produtividade'});
            break;
          }
            
          case'movimentTab':{
            tabs.push({ component: 'moviment', title: 'Movimentação'});
            break;
          }
            
          case 'absenteeism':{
            summary.push('absenteeism');
            break;
          }

          case 'companyfilter':{
            summary.push('companyfilter'); 
            break;
          }
            
          case 'colaborattorsCount':{
            summary.push('colaborattorsCount'); 
            break;
          }
                    
          case 'currentColaborattorsCount': {
            summary.push(component); 
            break;
          }
                  
          case 'planningCollaboratorsCount': {
            summary.push('planningCollaboratorsCount'); 
            break;
          }
                    
          case 'hhindicator':{
            summary.push('hhindicator');
            break;
          }
            
          case 'burndown':{
            charts.push('burndown');
            break;
          }
		  
          case 'burndownExport':{
            charts.push('burndownExport');
            break;
          }
		  
		  case 'resourcesBurndown':{
            charts.push('resourcesBurndown');
            break;
          }
		  
		  case 'resourcesBurndownExport':{
            charts.push('resourcesBurndownExport');
            break;
          }

          case 'timeline':{
            charts.push('timeline');
            break;
          }

          case 'timelineExport':{
            charts.push('timelineExport');
            break;
          }

          case 'areaTimeline':{
            charts.push('areaTimeline');
            break;
          }

          case 'areaTimelineExport':{
            charts.push('areaTimelineExport');
            break;
          }
            
          case 'summary':{
            charts.push('summary');
            break;
          }
            
          case 'inOutChart':{
            charts.push('inOutChart');
            break;
          }
          
          case 'inoutExport':{
            charts.push('inoutExport');
            break;
          }

          case 'workingPermitChart':{
            charts.push('workingPermitChart');
            break;
          }
            
          case 'heatmap':{
            charts.push('heatmap');
            break;
          }
            
          case 'movCalendar':{
            charts.push('movCalendar');
            break;
          }

          case 'movCalendarExport':{
            charts.push('movCalendarExport');
            break;
          }
            
          case 'movTimeline':{
            charts.push('movTimeline');
            break;
          }

          case 'areasTimeDivision':{
            charts.push('areasTimeDivision');
            break;
          }

          case 'areasTimeDivisionExport':{
            charts.push('areasTimeDivisionExport');
            break;
          }

          case 'displacementBtwnAreas':{
            charts.push('displacementBtwnAreas');
            break;
          }

          case 'evacuation':{
            charts.push('evacuation');
            break;
          }
        }
      });
    }
    
    return {
      tabs: tabs,
      summary: summary,
      charts: charts
    }
  }
  
  const sigInProvider = async (params: IAuthRequest) => {
    const response:IAuthResponse = await signInService(params);
    const { access_token, refresh_token, authorization, profileData } = response;

    localStorage.setItem('authData', JSON.stringify(response));
    localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
    localStorage.setItem(USER_ID, profileData.userId.toString());
    localStorage.setItem(CLIENT_ID, profileData.clientId.toString());
    localStorage.setItem(COMPANY_ID, profileData.companyId.toString());
    localStorage.setItem(USER_NAME, profileData.name);
    CookieService.set(REFRESH_ACCESS_TOKEN_KEY, refresh_token, {
      path: '/',
    });

    const parsedComponents = splitComponents(profileData.components);

    const authData: AuthData = {
      access_token,
      refresh_token,
      isAdmin: authorization.admin,
      username: profileData.name,
      email: profileData.email,
      showSurvey: profileData.showSurvey,
      persona: profileData.persona,
      clientId: profileData.clientId,
      companyId: profileData.companyId,
      grpCompanies: profileData.grpCompanies,
      hasMap: profileData.hasMap,
      routes: profileData.routes,
      menu: profileData.menu,
      components: parsedComponents,
    }; 

    return authData;
  };

  const changePassAndSigInProvider = async (params: IAuthRequest) => {
    const response:IAuthResponse = await changePassAndSignInService(params);
    const { access_token, refresh_token, authorization, profileData } = response;

    localStorage.setItem('authData', JSON.stringify(response));
    localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
    localStorage.setItem(USER_ID, profileData.userId.toString());
    localStorage.setItem(CLIENT_ID, profileData.clientId.toString());
    localStorage.setItem(COMPANY_ID, profileData.companyId.toString());
    localStorage.setItem(USER_NAME, profileData.name);
    CookieService.set(REFRESH_ACCESS_TOKEN_KEY, refresh_token, { path: '/'});

    const parsedComponents = splitComponents(profileData.components);

    const authData: AuthData = {
      access_token,
      refresh_token,
      isAdmin: profileData.isTrackfy ? profileData.isTrackfy : false,
      username: profileData.name,
      email: profileData.email,
      showSurvey: profileData.showSurvey,
      persona: profileData.persona,
      clientId: profileData.clientId,
      companyId: profileData.companyId,
      grpCompanies: profileData.grpCompanies,
      hasMap: profileData.hasMap,
      routes: profileData.routes,
      menu: profileData.menu,
      components: parsedComponents,
    }; 

    return authData;
  };

  const signOutProvider = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(CLIENT_ID);
    CookieService.remove(REFRESH_ACCESS_TOKEN_KEY);

    return null;
  };

  const getRefreshTokenProvider = async () => {
    const refreshToken = getRefreshToken();

    if (!refreshToken) return;

    const accessToken = await getRefreshTokenService(refreshToken);

    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    return accessToken;
  };

  const getClientId = ():number => {
    const localClient: string | null = localStorage.getItem(CLIENT_ID);
    const clientId: number = (localClient != null) ? Number.parseInt(localClient) : 2;
    return clientId;
  }

  const getIsAdmin = (): boolean => {
    const auth = localStorage.getItem('authData');
    if (!auth) return false;

    const jsonResponse: any = JSON.parse(auth) as AuthData;
    if (!jsonResponse) return false;

    return jsonResponse.profileData.isTrackfy;
  }
  
  const getUserName = ():string => {
    const localUserName: string | null = localStorage.getItem(USER_NAME);
    const userName: string = (localUserName != null) ? localUserName : "";
    return userName;
  }

  return {
    CLIENT_ID,
    USER_NAME,
    getUserName,
    isAuthenticated,
    getToken,
    getRefreshToken,
    changePassAndSigInProvider,
    sigInProvider,
    signOutProvider,
    getRefreshTokenProvider,
    getClientId,
    splitComponents,
    getIsAdmin,
  };
};
