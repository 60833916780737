import React from 'react';

import { Bar, BarChart, CartesianGrid, XAxis, ResponsiveContainer, LabelList, Legend } from 'recharts';
import { AreasTimeDivision } from '../../types/types';
import { useAuth } from '../../../../hooks';

import * as S from './style';

type VerticalBarChartProps = {
  areasTimeDivisionChartData: AreasTimeDivision[] | undefined;
};

export const VerticalBarChart: React.FC<VerticalBarChartProps> = ({ areasTimeDivisionChartData }) => {
  const { getClientId } = useAuth();
  
  const CustomLegend = () => {
    if(getClientId() == 11){
      return (
        <S.Legend>
          <S.Square color='rgba(0,111,255,1)'/>
          <S.CustomLegend color='rgba(0,111,255,1)'>Máquinas</S.CustomLegend>
  
          <S.Square color='rgba(0,213,209,1)'/>
          <S.CustomLegend color='rgba(0,213,209,1)'>Amarração</S.CustomLegend>
  
          <S.Square color='rgba(255,199,0,1)'/>
          <S.CustomLegend color='rgba(255,199,0,1)'>Outros</S.CustomLegend>
        </S.Legend>
      );
    } else {
      return (
        <S.Legend>
          <S.Square color='rgba(0,111,255,1)'/>
          <S.CustomLegend color='rgba(0,111,255,1)'>Áreas produtivas</S.CustomLegend>
  
          <S.Square color='rgba(0,213,209,1)'/>
          <S.CustomLegend color='rgba(0,213,209,1)'>Oficina</S.CustomLegend>
  
          <S.Square color='rgba(255,199,0,1)'/>
          <S.CustomLegend color='rgba(255,199,0,1)'>Outros</S.CustomLegend>
        </S.Legend>
      );
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={700} height={250} data={areasTimeDivisionChartData}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="date" />
        <Legend content={CustomLegend} />
        <Bar dataKey="item1" fill="rgba(0,111,255,1)" radius={[5, 5, 0, 0]} barSize={50}>
          <LabelList dataKey="item1Label" position="top" offset={5}/>
        </Bar>
        <Bar dataKey="item2" fill="rgba(0,213,209,1)" radius={[5, 5, 0, 0]} barSize={50}>
          <LabelList dataKey="item2Label" position="top" offset={5}/>
        </Bar>
        <Bar dataKey="others" fill="rgba(255,199,0,1)" radius={[5, 5, 0, 0]} barSize={50}>
          <LabelList dataKey="othersLabel" position="top" offset={5}/>
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
