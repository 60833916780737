import React, { useRef } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';

import { AreasContextProvider } from './context/AreasContext';
import { useAuthContext } from '../../stores';
import { MonitoringMap } from './components/MonitoringMap';
import { PespectiveMonitBuilding } from './components/PerspectiveMonitBuilding';
import { MonitoringIndicators } from './components/MonitoringIndicators';

export const MonitoringContainer: React.FC<{}> = () => {
  const { authData } = useAuthContext();
  const isManagerRef = useRef(false);
  const showSurvey = authData && authData.showSurvey ? authData.showSurvey : false;
  const hasMap = authData && authData.hasMap ? authData.hasMap : false;
  const civil = authData?.clientId === 7 || authData?.clientId === 10;
  let companies = authData && authData.grpCompanies ? authData.grpCompanies : 't';
  isManagerRef.current = companies == 'a' || companies.includes(',');

  return (
    <AreasContextProvider>
      {hasMap ? (
        <MonitoringMap isManager={isManagerRef.current} isMap={hasMap} />
      ) : (
        <PespectiveMonitBuilding isManager={isManagerRef.current} isMap={hasMap} />
      )}
      <MonitoringIndicators
        isManager={isManagerRef.current}
        showSurvey={showSurvey}
        civil={civil}
      />
    </AreasContextProvider>
  );
};
