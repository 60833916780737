import styled from 'styled-components';

interface FormButtonProps {
  color: string;
  border: string;
  bgColor: string;
}

interface ReportPreviewProps {
  isDownloadPdf: boolean;
}

export const FilterSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  gap: 2.4rem;
  & > div {
    display: flex;
    gap: 2.4rem;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    & span {
      display: flex;
      align-items: center;
      font-family: Work Sans;
      font-weight: 500;
      font-size: 1.6rem;
      color: #0e1742;
      & p {
        color: #666666;
        margin: auto;
      }
      & svg {
        margin-right: 1.6rem;
      }
    }
  }
`;

export const InputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const InputLabel = styled.label`
  font-family: Work Sans;
  font-weight: 500;
  font-size: 1.6rem;
  color: #0e1742;
  width: 100%;
  text-align: left;
`;

export const ReportsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 2.4rem;
  padding: 2.4rem;
  & aside,
  form {
    flex-grow: 1;
    border-radius: 1.6rem;
    display: flex;
    flex-direction: column;
    padding: 2.4rem;
  }
`;

export const FormHeader = styled.header`
  display: flex;
  //justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 3.2rem;
  gap: 1.6rem;

  & svg {
    margin-right: 1.6rem;
  }
`;

export const HeaderText = styled.span`
    font-family: Work Sans;
    font-weight: 500;
    font-size: 1.6rem;
    color: #0e1742;
`;

export const FormButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
  margin-top: 12rem;
`;

export const FormButton = styled.button<FormButtonProps>`
  all: unset;
  padding: 0.8rem 1.4rem;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  border: ${({ border }) => border};
  font-weight: 500;
  font-size: 1.6rem;
  border-radius: 0.8rem;
  cursor: pointer;
  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
`;

export const ReportPreview = styled.div<ReportPreviewProps>`
  width: 50%;
  background-color: #ffffff;
  text-align: center;
  max-height: ${({ isDownloadPdf }) => '75vh'};
  overflow-y: hidden;

  @media print {
    max-height: auto;
  }
`;

export const ReportPreviewTitle = styled.p`
  color: #b3b3b3;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
`;

/*
export const ReportPreview = styled.aside`
  width: 30%;
  background-color: '#FFFFFF';
  text-align: 'center';
  max-height: 
  & p {
    color: #b3b3b3;
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
  }
`;
*/
