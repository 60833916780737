import React, { createContext, ReactNode, useState, useContext } from 'react';
import { useAuth } from '../../../../../hooks';

type RegisterAreaContextProps = {
  clientSelected: number;
  coordinates: string;
  updateAreas: boolean;
  setClientSelected: (client: number) => void;
  setCoordinates: (coords: string) => void;
  setUpdateAreas: (area: boolean) => void;
  getClientId: () => number;
};

type Props = {
  children: ReactNode;
};

const RegisterAreaContext = createContext<RegisterAreaContextProps>({} as RegisterAreaContextProps);

export function RegisterAreaContextProvider(props: Props) {
  const { children } = props;

  const { getClientId } = useAuth();

  const [coordinates, setCoordinates] = useState<string>('');

  const [clientSelected, setClientSelected] = useState<number>(1);

  const [updateAreas, setUpdateAreas] = useState<boolean>(false);

  const value = {
    clientSelected,
    setClientSelected,
    coordinates,
    setCoordinates,
    updateAreas,
    setUpdateAreas,
    getClientId,
  };
  return <RegisterAreaContext.Provider value={value}>{children}</RegisterAreaContext.Provider>;
}

export function useRegisterAreaContext() {
  const context = useContext(RegisterAreaContext);

  if (typeof context === 'undefined') {
    throw new Error('RegisterAreaContext must be used within an RegisterAreaContext');
  }

  return context;
}
