import styled from 'styled-components';

export const Card = styled.div`
    width: 100%;
    height: 60px;
    max-height: 438px;
    display: flex;
    overflow: hidden;
    background: #ffffff;
    border: 0.5px solid #ebebf9;
    box-sizing: border-box;
    box-shadow: 8px 8px 16px 4px rgba(80, 94, 165, 0.13);
    border-radius: 10px;
    //z-index: 999;
    justify-content: center;
`;

export const CardHeader = styled.div`
    margin-top: 19px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #4f5882;
`;

export const CardContent = styled.div`
    margin-left: 5px;
    margin-top: 19px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2f4cdd;
`;

export const IconContent = styled.div`
    margin-top: 19px;
    margin-left: 300px;
    position: absolute;
`;