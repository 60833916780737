import React from 'react';

import {
  BarChart,
  CartesianGrid,
  XAxis,
  ResponsiveContainer,
  LabelList,
  Legend,
  Bar,
  YAxis,
  Tooltip,
} from 'recharts';
import { OnePage } from '../../../types/OnePage';


type VerticalBarChartProps = {
  revenueFTEChartData: OnePage[] | undefined;
};

export const VerticalBarChart: React.FC<VerticalBarChartProps> = ({ revenueFTEChartData }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={700} height={250} data={revenueFTEChartData}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" />
        {/*<YAxis type="number"  hide={true} />*/}
        <Bar dataKey="value" fill="#008AE5" radius={[5, 5, 0, 0]} barSize={40} name='Chegada'>
          <LabelList dataKey="valueLabel" position="top" offset={5} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
