import React from 'react';
import { IconLoading } from '../../../../components/IconLoading';
import { useLastUpdate } from './hooks/useLastUpdate';

import * as S from './styles'

export const LastUpdateContainer: React.FC = () => {;
  const {isLoading, lastUpdate} = useLastUpdate()

  return (
    <S.LastUpdateContainer>
      <S.Title>Última atualização:</S.Title>
      {isLoading && <IconLoading customStyle={{fontSize: 30}}/>}
      {!isLoading && <S.Date>{lastUpdate}</S.Date>}
    </S.LastUpdateContainer>
  );
};