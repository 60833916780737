import React from 'react';
import { DefaultIconProps } from './types';

export const MonitoringIconVuesax: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M54.96 36.0015C62.76 36.0015 66 33.0015 63.12 23.1615C61.17 16.5315 55.47 10.8315 48.84 8.88152C39 6.00152 36 9.24151 36 17.0415V25.6815C36 33.0015 39 36.0015 45 36.0015H54.96Z"
        stroke={fill || 'white'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.0003 44.1012C57.2103 57.9912 43.8903 68.0713 28.7403 65.6113C17.3703 63.7813 8.22028 54.6312 6.36028 43.2612C3.93028 28.1712 13.9503 14.8512 27.7803 12.0312"
        stroke={fill || 'white'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
