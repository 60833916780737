import { TooltipContent } from "../../../../../components/Info/Tooltip";

export const getHHIndicatorTooltipContent = (clientId: number): TooltipContent[] => {
    return [
            {
              content: 'Quantidade de recursos monitorados (TAGs entregues) multiplicado  pela jornada de trabalho (por padrão, 8 Horas de trabalho).'
            },
        ];
};

export const getCollaboratorsCountTooltipContent = (clientId: number): TooltipContent[] => {
    return[
      {
        content: 'Quantidade de trabalhadores registrados no dia corrente.'
      }
    ]
};

export const getCurrentCollaboratorsTooltipContent = (clientId: number): TooltipContent[] => {
  return[
    {
      content: 'Quantidade de trabalhadores em áreas produtivas no momento observado.'
    }
  ]
};

export const getAbsenteeismTooltipContent = (clientId: number): TooltipContent[] => {
  return[
    {
      content: 'Porcentagem de trabalhadores ausentes no dia corrente, até o momento.'
    }
  ]
};

export const getPlanningCollaboratorsCountTooltipContent = (clientId: number): TooltipContent[] => {
  return[
    {
      content: 'Valor definido no Planejamento'
    }
  ]
};