import styled from 'styled-components';
import { Button } from '../../../../../components/Buttons/Button';
import { sg } from '../../../../../styles/style-guide';

export const AddCompanyContainer = styled.div`
  position: relative;
  width: 862px;
  height: 404px;
  padding: 48px 51px 40px 34px;
  display: flex;
  flex-direction: column;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 20px;
  height: 16px;
  background-color: transparent;
  text-align: right;
  font: normal normal 300 1.6rem/1.9rem Poppins;
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
  border: none;
  cursor: pointer;
`;

export const CompanyListHeaderTitleContainer = styled.div`
  display: flex;
  gap: 1.6rem;
`;

export const IconUsersContainer = styled.div``;

export const CompanyListHeaderLabel = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.3rem;
  color: ${sg.colors.dark};
`;

export const AddCompanyFrontContent = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2.3rem;
`;

export const CompanyInfoContainer = styled.div`
  display: flex;
  //align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const CompanyInputContainer = styled.div`
  flex-basis: 43.5rem;
`;

export const FilterInputContainer = styled.div`
  flex-basis: 36.8rem;
`;

export const SaveButton = styled(Button)`
  width: 25rem;
  height: 50px;
  align-self: flex-end;
`;

export const InputLabel = styled.label`
  font-weight: bold;
  font-size: 12px;
  color: #4f5882;
  margin-left: 10px;
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 60px;
`;

export const CompanyInpunt = styled.input`
  height: 100%;
  width: 100%;

  padding: 13px 30px 12px 16px;
  font: 500 1.4rem / 1.5 Work Sans, sans-serif;
  border: 1px solid #ccc;
  border-radius: 10px;
  border: 1px solid rgb(79, 88, 130);
  margin-bottom: 10px;
`;
