import React from 'react';
import { DefaultIconProps } from './types';

export const TrendDownIcon: React.FC<DefaultIconProps> = ({ fill, width, height, style, onClick }) => {
  return (
    <svg
      width={width || '40'}
      height={height || '40'}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M27.5 24.1654L20.5 17.1654L17.8333 21.1654L12.5 15.832"
        stroke={fill || '#ED3A3A'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.167 24.1654H27.5003V20.832"
        stroke={fill || '#ED3A3A'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9997 36.6654H24.9997C33.333 36.6654 36.6663 33.332 36.6663 24.9987V14.9987C36.6663 6.66536 33.333 3.33203 24.9997 3.33203H14.9997C6.66634 3.33203 3.33301 6.66536 3.33301 14.9987V24.9987C3.33301 33.332 6.66634 36.6654 14.9997 36.6654Z"
        stroke={fill || '#ED3A3A'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
