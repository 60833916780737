import React, { createContext, ReactNode, useState, useContext } from 'react';

import { CollaboratorTimelineEvent } from '../../../indicators/types/Indicators';

type ContextValue = {
  timelineValues: Array<CollaboratorTimelineEvent[]>;
  timelineData: CollaboratorTimelineEvent[];
  isLoading: boolean;
  isEmpty: boolean;
  setTimelineValues: (arrayAvarageValues: Array<CollaboratorTimelineEvent[]>) => void;
  setTimelineData: (values: CollaboratorTimelineEvent[]) => void;
  setIsLoading: (value: boolean) => void;
  setIsEmpty: (value: boolean) => void;
};

const CollaboratorTimelineContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function TimelineContextProvider(props: Props) {
  const { children } = props;

  const [timelineValues, setTimelineValues] = useState<Array<CollaboratorTimelineEvent[]>>([]);
  const [timelineData, setTimelineData] = useState<CollaboratorTimelineEvent[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const value = {
    timelineValues,
    timelineData,
    setTimelineValues,
    setTimelineData,
    isLoading,
    setIsLoading,
    isEmpty,
    setIsEmpty,
  };

  return (
    <CollaboratorTimelineContext.Provider value={value}>
      {children}
    </CollaboratorTimelineContext.Provider>
  );
}

export function useTimelineContext() {
  const context = useContext(CollaboratorTimelineContext);

  if (typeof context === 'undefined') {
    throw new Error('TimelineContext must be used within an TimelineContext');
  }

  return context;
}
