import React from 'react';

import { IconLoading } from '../../components/IconLoading';
import { useLogin } from './hooks/useLogin';

import * as S from './styles';

interface Props {
    user: string;
}

export const PasswordRegistration: React.FC<Props> = ({user}) => {
    const {loading, message, typeMessages, handlePasswordChange, handlePassUpdate, handleConfirmPassUpdate}  = useLogin();

    const handleFormSubmit = (e: any) => {
        handlePasswordChange(e, user);
    }
    return (
        <S.LoginForm onSubmit={handleFormSubmit}>
            <S.FormControl>
                <h1>Defina sua senha</h1>
                <h4>Por medidas de seguança, em seu primeiro acesso, solicitamos que altere a sua senha.</h4>
            </S.FormControl>
            <S.FormControl>
                <S.Label>            
                    <S.Input disabled value={user} />
                </S.Label>
            </S.FormControl>
            <S.FormControl>
                <S.Label>
                    <S.Input autoFocus
                            type="password"
                            placeholder="Digite sua senha"
                            onChange={(e) => handlePassUpdate(e.target.value)} />
                </S.Label>
                <S.Label>
                    <S.Input type="password" 
                             placeholder="Confirme sua senha" 
                             onChange={(e) => handleConfirmPassUpdate(e.target.value)} />
                </S.Label>
            </S.FormControl>
            <S.FormControl>
                {loading && (<S.IconLoadingContainer><IconLoading customStyle={{ fontSize: 24 }} /></S.IconLoadingContainer>)}
                {message && (<S.Message type={message}>{typeMessages[message].icon} {typeMessages[message].message}</S.Message>)}
                {!loading && !message && <S.Space />}
            </S.FormControl>
            <S.FormControl>
                <S.Submit type="submit" disabled={loading}>
                    Registrar
                </S.Submit>
            </S.FormControl>
        </S.LoginForm>
 );
};