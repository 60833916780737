import React from 'react';

import { IconLoading } from '../../../../../components/IconLoading';
import IconClose from '../../../../../Icons/Close';
import { useTextInsights } from '../hooks/useTextInsights';

import * as S from '../styles';

type Props = {
  onClick: any;
  content: any;
};

export const CardExpanded: React.FC<Props> = (props: Props) => {
  const { onClick, content } = props;
  const { isLoading } = useTextInsights();

  return (
    <S.ExpandedContainer>
      <S.ModalContent>
        {isLoading && <IconLoading customDivStyle={{ marginTop: '12em' }} />}

        <S.CloseButton onClick={onClick}>
          <IconClose />
        </S.CloseButton>

        {!isLoading && <div>{content}</div>}
      </S.ModalContent>
    </S.ExpandedContainer>
  );
};