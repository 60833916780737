import React from 'react';
import { Container } from '../../components/PageStructure/styles';
import { PageHeader } from './PageHeader';

type PageProps = {
    title: string;
    components: JSX.Element[] | JSX.Element;
}

export const Page: React.FC<PageProps> = (props:PageProps) => {
    const {title, components} = props;

    return (<Container>
                <PageHeader title={title} />
                {components}
            </Container>
    );
};
  