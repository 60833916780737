import React from 'react';

import { useHhindicator } from './hooks/useHhindicator';

import * as S from './styles';

export const HHIndicators: React.FC = () => {
  const { indicator } = useHhindicator();

  return (
    <S.Card>
      <S.CardHeader>HH Monitorado:</S.CardHeader>
      {<S.CardContent>{indicator.value}</S.CardContent>}
    </S.Card>
  );
};
