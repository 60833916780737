import { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import moment from 'moment';

import { useProductivityContext } from '../../../context/PageProductivityContext';
import { PresenceByArea } from '../../../../indicators/types/Indicators';
import { getProductivityByAreaData } from '../../../../../services/api/TrackfyService';
import { useAuth } from '../../../../../hooks';
import { parseSelectOptions } from '../../../../../utils/Convert';

export const usePresenceArea = () => {
  const { companySelected, professionSelected, initialDate, finalDate } = useProductivityContext();
  const [presenceAreaChartData, setPresenceAreaChartData] = useState<PresenceByArea[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { getClientId } = useAuth();
  const clientId: number = getClientId();
  
  const handleChartData = (data: PresenceByArea[]) => {
    if (data) setPresenceAreaChartData(data);
    else setPresenceAreaChartData([]);

    setIsLoading(false);
  };

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);

      getProductivityByAreaData(
        clientId,
        parseSelectOptions(companySelected),
        parseSelectOptions(professionSelected),
        moment(initialDate).startOf('day').valueOf(),
        moment(finalDate).valueOf(),
        currentRequest.token,
      )
        .then((result: AxiosResponse<any>) => handleChartData(result.data))
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('PresenceAreaChart request canceled by the user.');
    };
  }, [companySelected, professionSelected, initialDate, finalDate]);

  return {
    isLoading,
    setIsLoading,
    presenceAreaChartData,
  };
};
