import React, { CSSProperties, useEffect, useState } from 'react';

import * as S from './styles';

export interface CounterProps {
  style?: CSSProperties;
  stop?: boolean;
  getElapsedTime?: (seconds: number) => void;
}

export const Counter: React.FC<CounterProps> = ({ style, stop, getElapsedTime }) => {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    if (stop) return;
    const interval = setInterval(() => {
      if (seconds === 59) {
        setMinutes((prev) => prev + 1);
        setSeconds(0);
      } else {
        setSeconds((prev) => prev + 1);
      }
      if (getElapsedTime) {
        const time = minutes * 60 + seconds + 1;
        getElapsedTime(time);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <S.Counter style={style}>
      {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </S.Counter>
  );
};
