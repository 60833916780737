import styled from 'styled-components';

interface BarSectionItemProps {
  color: string;
  firstItem?: boolean;
  lastItem?: boolean;
}

interface BarSectionProps {
  size: number;
}

interface LabelSquareProps {
  color: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 40rem;
`;

export const ChartRow = styled.div`
  display: flex;
  border-radius: 0.4rem;
  width: 100%;
  min-width: 40rem;
  margin-bottom: 0.6rem;
`;

export const BarSectionItem = styled.div<BarSectionItemProps>`
  background-color: ${({ color }) => color};
  display: flex;
  width: 100%;
  border-radius: ${({ firstItem, lastItem }) => (firstItem ? '4px 0 0 4px' : lastItem ? '0 4px 4px 0' : '')};
  height: 100%;
  height: 1.6rem;
`;

export const BarSection = styled.div<BarSectionProps>`
  min-width: 8rem;
  width: ${({ size }) => `${size}%`};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
`;

export const BarSectionLabel = styled.span<Pick<BarSectionItemProps, 'color'>>`
  color: ${({ color }) => color};
  font-weight: 600;
  font-family: Work Sans;
  text-align: center;
  white-space: nowrap;
`;

export const BarTotal = styled.div`
  width: 100%;
  display: flex;
  height: 0.4rem;
  background-color: #ff4873;
  border-radius: 0.8rem;
`;

export const BarTotalLabel = styled.span`
  color: #ff4873;
  font-weight: 500;
  width: 100%;
  text-align: center;
  font-family: Work Sans;
  font-size: 1rem;
`;

export const LabelSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 1.2rem;
  gap: 1rem;
  & div {
    display: flex;
    align-items: center;
    font-family: Work Sans;
    font-weight: 400;
    color: #999999;
  }
`;

export const LabelSquare = styled.div<LabelSquareProps>`
  width: 0.8rem;
  height: 0.8rem;
  background-color: ${({ color }) => color};
  margin-right: 0.2rem;
`;