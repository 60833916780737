import { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import moment from 'moment';
import { Displacement } from '../../../displacement/types/types';
import { getDisplacement } from '../../../../services/api/TrackfyService';
import { useDisplacementContext } from '../../context/DisplacementContext';

export const useDisplacement = () => {
  const { companySelected, professionSelected, initialDate, finalDate } = useDisplacementContext();
  const [displacementChartData, setDisplacementChartData] = useState<Displacement[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleChartData = (data: Displacement[]) => {
    if (data && data.length > 0) {
      setDisplacementChartData(data);
    } else {
      setDisplacementChartData([]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);

      getDisplacement(
        companySelected,
        professionSelected,
        moment(initialDate).startOf('day').valueOf(),
        moment(finalDate).valueOf(),
        currentRequest.token,
      )
        .then((result: AxiosResponse<any>) => handleChartData(result.data))
        .catch((err) => console.log(err));
    }

    return () => {
      currentRequest.cancel('DisplacementChart request canceled by the user.');
      mounted = false;
    };
  }, [companySelected, professionSelected, initialDate, finalDate]);

  return {
    displacementChartData,
    isLoading,
    setDisplacementChartData,
    setIsLoading,
  };
};
