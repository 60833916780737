import React from 'react';

import {
  BarChart,
  CartesianGrid,
  XAxis,
  ResponsiveContainer,
  LabelList,
  Legend,
  Bar,
  YAxis,
  Tooltip,
} from 'recharts';
import { Economia } from '../../../../types/Insights';

type VerticalBarChartProps = {
  economiaChartData: Economia[] | undefined;
};

export const VerticalBarChart: React.FC<VerticalBarChartProps> = ({ economiaChartData }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={700} height={250} data={economiaChartData}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="nome" />
        <Tooltip/>
        <Legend />
        <Bar dataKey="cenario1" fill="#FF5050" radius={[5, 5, 0, 0]} barSize={40} name='A pé retirando outliers'>
          <LabelList dataKey="cenario1Label" position="insideBottom" offset={30} />
        </Bar>
        <Bar dataKey="cenario2" fill="#FF4873" radius={[5, 5, 0, 0]} barSize={40} name='Curto prazo'>
          <LabelList dataKey="cenario2Label" position="insideBottom" offset={30} />
        </Bar>
        <Bar dataKey="cenario3" fill="#FF7098" radius={[5, 5, 0, 0]} barSize={40} name='A pé mínimo alcançado'>
          <LabelList dataKey="cenario3Label" position="insideBottom" offset={30} />
        </Bar>
        <Bar dataKey="cenario4" fill="#E0A31C" radius={[5, 5, 0, 0]} barSize={40} name='Mínimo ótimo(5Km/h)'>
          <LabelList dataKey="cenario4Label" position="insideBottom" offset={30} />
        </Bar>
        <Bar dataKey="cenario5" fill="#F2A267" radius={[5, 5, 0, 0]} barSize={40} name='A carro'>
          <LabelList
            dataKey="cenario5Label"
            position="insideBottom"
            offset={30}
            style={{ fontSize: '10px' }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
