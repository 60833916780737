import { useCallback, useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import { getNDR } from '../../../../../services/api/TrackfyService/integration';
import { useNdrContext } from '../context/NDRContext';
import { OnePage } from '../../../types/OnePage';


export const useNdr = () => {
  const { setNdrValues, setNdrChartData } = useNdrContext();
  const [isLoading, setIsLoading] = useState(true);

  const dividerPerThree = useCallback((base: OnePage[]): Array<OnePage[]> => {
    const divisor: number = 3;
    const newArray: Array<OnePage[]> = [];

    for (let i = 0; i < base.length; i += divisor) {
      const divider: OnePage[] = base.slice(i, i + divisor);
      newArray.push(divider);
    }
    return newArray;
  }, []);

  const handleChartData = (data: OnePage[]) => {
    if (data && data.length > 0) {
      batchedUpdates(() => {
        setNdrValues(dividerPerThree(data));
        setNdrChartData(dividerPerThree(data)[0]);
      });
    } else {
      setNdrChartData([{ name: 'Sem Registros', value: 0, valueLabel: '0'}]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);
      batchedUpdates(() => setNdrValues([]));

      getNDR(currentRequest.token)
        .then((result: AxiosResponse<any>) => handleChartData(result.data))
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('NDR request canceled by the user.');
    };
  }, []);

  return {
    dividerPerThree,
    handleChartData,
    isLoading,
    setIsLoading,
  };
};
