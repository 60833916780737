import { getUserGroupPermissions } from '../services/api/userGroupPermissions';
import { useAuth } from '.';
import { useAuthContext } from '../stores';
import { UserGroupPermissions } from '../types';

export const useGetUserGroupPermissions = async (): Promise<UserGroupPermissions | null> => {
  const { authData } = useAuthContext();
  const { getToken } = useAuth();
  const accessToken = getToken();

  if (!accessToken) return null;

  return getUserGroupPermissions(authData, accessToken);
};
