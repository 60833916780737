import React from 'react';

import { TagsLocationList } from "./TagsLocationList"
import { useTagsLocations } from "../hooks/useTagsLocations";
import { useAuth } from "../../../hooks";

import * as S from '../styles';

export const CalibrationLists = () => {
    const { getClientId } = useAuth();
    const { monitoredTags } = useTagsLocations();

    return (
        <React.Fragment>
            <TagsLocationList areaIcons={monitoredTags.clientTags} clientId={getClientId()}/>
            {
                (monitoredTags.trackfyTags.length > 0) && 
                <S.ClientTagsContainer>
                    <TagsLocationList areaIcons={monitoredTags.trackfyTags} title='Tags fixas' clientId={getClientId()} />
                </S.ClientTagsContainer>
            }
        </React.Fragment>
    );
}
