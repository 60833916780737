import React from 'react';

interface PropsTypes {
  height?: string;
  width?: string;
  fill?: string;
}

const IconFall: React.FC<PropsTypes> = ({ fill, height, width }: PropsTypes) => (
  <svg
    fill={fill ? fill : '#b9020a'}
    height={height ? height : '30'}
    width={width ? width : '30'}
    viewBox="0 0 457.68 457.68"
  >
    <g>
      <g id="XMLID_22_">
        <path
          id="XMLID_30_"
          d="M453.822,195.374l-58.311-44.323l45.737-40.447c3.195-2.825,4.189-7.396,2.455-11.292
                    c-1.733-3.896-5.779-6.215-10.03-5.738l-61.163,6.943l26.917-68.152c1.591-4.027,0.341-8.622-3.07-11.288
                    c-3.412-2.665-8.17-2.77-11.696-0.254L318.889,67.78l-14.022-54.566c-1.041-4.05-4.543-6.989-8.712-7.311
                    c-4.157-0.317-8.081,2.044-9.731,5.886L262.659,67.11l-66.396-34.183c-4.117-2.12-9.157-1.059-12.068,2.541
                    c-2.913,3.599-2.901,8.748,0.031,12.333l42.625,52.133l-45.023,21.367c-3.829,1.817-6.05,5.895-5.499,10.098
                    c0.551,4.203,3.748,7.571,7.916,8.34l46.369,8.554c0,0,10.757,1.677,16.309,10.642c0.044,0.072,0.116,0.122,0.175,0.181
                    l9.481-10.928c2.297-2.648,3-6.324,1.841-9.633s-4.002-5.744-7.449-6.38l-33.463-6.174l28.999-13.762
                    c2.714-1.288,4.685-3.753,5.345-6.684c0.659-2.932-0.067-6.003-1.97-8.329l-20.773-25.407l33.922,17.464
                    c2.408,1.24,5.227,1.425,7.776,0.508c2.551-0.916,4.606-2.85,5.675-5.34l16.59-38.618l10.435,40.61
                    c0.802,3.121,3.09,5.645,6.117,6.749s6.403,0.643,9.025-1.228l50.452-36.021l-20.746,52.529c-1.26,3.191-0.754,6.81,1.332,9.533
                    c2.087,2.723,5.456,4.155,8.858,3.766l46.548-5.284l-31.451,27.814c-2.193,1.939-3.401,4.758-3.292,7.682
                    c0.108,2.925,1.522,5.646,3.851,7.418l30.374,23.087l-31.878-7.801c-3.224-0.787-6.633,0.114-9.044,2.399
                    c-2.41,2.284-3.496,5.637-2.882,8.901l6.75,35.854l-27.148-33.493c-2.056-2.537-5.248-3.886-8.497-3.576
                    c-3.252,0.302-6.138,2.209-7.69,5.082l-20.833,38.531l-8.793-52.25c-0.463-2.753-2.081-5.175-4.445-6.658
                    c-2.33-1.46-5.208-1.895-7.931-1.101l-25.402,7.419c0.817,3.021,1.263,6.163,1.303,9.372l0.133,10.569l18.893-5.518l11.684,69.435
                    c0.692,4.114,3.92,7.335,8.036,8.018c4.118,0.685,8.211-1.322,10.195-4.993l27.384-50.648l42.275,52.154
                    c2.817,3.475,7.636,4.595,11.697,2.714c4.06-1.88,6.325-6.278,5.497-10.675l-11.098-58.949l62.802,15.369
                    c4.493,1.097,9.151-1.107,11.143-5.286C458.722,203.179,457.508,198.175,453.822,195.374z"
        />
        <circle id="XMLID_536_" cx="115.693" cy="200.763" r="35.901" />
        <path
          id="XMLID_819_"
          d="M320.305,412.487l-69.806-35.484c-2.952-1.502-6.236-2.284-9.609-2.257l-103.278,0.945l78.446-17.485
                    l13.552-13.011l3.346,9.424l7.753-0.071c6.8-0.029,13.217,1.536,18.943,4.449l23.151,11.768l-24.996-70.408
                    c-2.383-6.712-8.037-11.737-14.983-13.315c-6.944-1.577-14.216,0.51-19.266,5.533l-30.721,30.561
                    c-7.876-32.024-5.631-22.898-11.418-46.429l44.549-12.322c7.584-2.098,12.802-9.045,12.703-16.914l-0.782-62.048
                    c-0.12-9.563-7.97-17.222-17.539-17.103c-9.563,0.121-17.224,7.97-17.103,17.54l0.613,48.694l-36.492,10.094
                    c-5.532-3.879-12.643-5.444-19.719-3.704c-45.884,11.284-43.007,10.473-45.305,11.4l17.54,8.809l-64.43-13.751l-22.017-55.728
                    c-3.524-8.917-13.646-13.234-22.475-9.746c-8.89,3.511-13.262,13.573-9.746,22.475l25.429,64.366
                    c2.12,5.366,6.79,9.358,12.495,10.576l71.148,15.184l-18.879,0.384c5.13,20.86,20.61,83.804,26.398,107.341l0.031-0.041
                    c2.497,8.697,10.492,15.049,19.96,15.049c0.064,0,0.128,0,0.192-0.001l98.198-0.9l65.277,33.182
                    c10.257,5.212,22.759,1.101,27.95-9.11C334.618,430.202,330.539,417.689,320.305,412.487z"
        />
      </g>
    </g>
  </svg>
);

export default IconFall;
