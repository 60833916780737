import { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelToken, CancelTokenSource } from 'axios';

import { getInvites } from "../../../services/api/TrackfyService/integration";
import { User } from '../types';

export const useUsers = (userSearched: string, profileSelected: number, companySelected: number) => {
    const [users, setUsers] = useState<User[]>([]); 
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const currentRequest: CancelTokenSource = Axios.CancelToken.source();
        let mounted = true;
    
        if (mounted)
          requestInvites(currentRequest.token);
    
        return () => {
          mounted = false;
          currentRequest.cancel('request canceled by the user.');
        };
    }, [profileSelected, companySelected, userSearched]);

    const requestInvites = (token: CancelToken) => {
        setIsLoading(true);

        getInvites(profileSelected, companySelected, userSearched, token)
        .then((result: AxiosResponse<any>) => {
            const invites = result.data.map((item: any) => {
                return {
                    id: item.id,
                    userId: item.userId,
                    user: item.name,
                    company: item.company,
                    profile: item.profile,
                    inviteDate: item.date,
                    isRegistered: item.accepted,
                }
            });
            setUsers(invites);
            setIsLoading(false);
        })
        .catch((err: any) => {
            console.log(err);
            setUsers([]);
            setIsLoading(false);
        });
    };

    return {
        users,
        requestInvites,
        isLoading
    }
}