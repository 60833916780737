import React from 'react';

import * as S from './styles';

interface Props {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
}

export const ContentCell: React.FC<Props> = ({ icon, title, subtitle }) => {
  return (
    <S.ContentCell>
      {icon}
      <div>
        <span>{title}</span>
        <p>{subtitle}</p>
      </div>
    </S.ContentCell>
  );
};