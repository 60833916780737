import React from 'react';
import styled from 'styled-components';

import CollaboratorTimelineLine from './CollaboratorTimelineLine';
import { Shadow } from '../../../components';
import CollaboratorTimelineHeader from './CollaboratorTimelineHeader';

const TimelineCard = styled(Shadow)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content auto;
  width: 100%;
  height: 100%;
  min-height: 200px;
  padding: 2.4rem 2.4rem 1.6rem;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  //overflow-y: hidden;
  //overflow-x: scroll;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
`;

const CollaboratorTimeline: React.FC = () => 
    <TimelineCard>
        <CollaboratorTimelineHeader />
        <CollaboratorTimelineLine />
    </TimelineCard>


export default CollaboratorTimeline;