import React from 'react'
import { Modal } from '../../../../components/Modal';
import IconClose from '../../../../Icons/Close';
import IconUsers from '../../../../Icons/Users';
import { useDeleteCollaborator } from './hooks/useDeleteCollaborator';

import * as S from './styles';

type DeleteCollaboratorProps = {
  isDeleting: boolean;
  onClickToClose(): void;
  onClickToDelete(): void;
  collaborator: string | undefined,
  tagAlias: string 
};

export const DeleteCollaborator: React.FC<DeleteCollaboratorProps> = ({
  isDeleting,
  onClickToClose,
  collaborator,
  tagAlias,
  onClickToDelete
}: DeleteCollaboratorProps) => {
  const { deletingCollaborator, handleDeleteCollaborator } = useDeleteCollaborator(tagAlias)

  return (
    <Modal visible={isDeleting}>
      <S.DeleteCollaboratorContainer>
        <S.CloseButton onClick={onClickToClose}>
          <IconClose />
        </S.CloseButton>
        <S.CollaboratorListHeaderTitleContainer>
          <S.IconUsersContainer>
            <IconUsers />
          </S.IconUsersContainer>
          <S.CollaboratorListHeaderLabel>Desvincular Colaborador: </S.CollaboratorListHeaderLabel>
          <S.Subtitle>{collaborator}</S.Subtitle>
        </S.CollaboratorListHeaderTitleContainer>
        <S.DeleteServiceFrontContent>
          <S.TitleContainer>
            <S.Title>Deseja realmente desvincular o colaborador ?</S.Title>
          </S.TitleContainer>
          <S.ButtonsContainer>
            <S.CancelButtonContainer>
              <S.CancelButton
                title="Cancelar"
                onClick={onClickToClose}
                disabled={deletingCollaborator}
                loading={deletingCollaborator}
              />
            </S.CancelButtonContainer>
            <S.DeleteButtonContainer>
              <S.DeleteButton
                title="Desvincular"
                disabled={deletingCollaborator}
                loading={deletingCollaborator}
                onClick={async () => {
                  await handleDeleteCollaborator();
                  onClickToDelete();
                }}
              />
            </S.DeleteButtonContainer>
          </S.ButtonsContainer>
        </S.DeleteServiceFrontContent>
      </S.DeleteCollaboratorContainer>
    </Modal>
  );
};