import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import moment from 'moment';

import { useAuth } from '../../../../hooks';
import { useReportsContext } from '../../context/ReportsContext';
import { getMonitoredHH } from '../../../../services/api/TrackfyService/integration';
import { PreviewHeader } from './PreviewHeader';
import { ExampleLineChart, ExampleBarChart } from './Chart';
import { Form } from '../ReportContent';
import { CapitalizeSingleWord } from '../../../../utils/Capitalize';
import { IconLoading } from '../../../../components/IconLoading';

import * as S from './styles';

interface Props {
  form: Form;
  profession: string;
}

interface MonitoredHH {
  report: { 
    hhArea: any[];
    hhColab: any[];
    hhFunction: any[];
    hhHistory: any[];
    hhMedia: any[];
    hhPago: number;
    hhTotal: number;
    hhInformed: number;
  },
  loading: boolean;
}

export const PreviewMonitoredHH: React.FC<Props> = ({ form, profession }) => {
  const { areaSelected, professionSelected, collaboratorSelected, companySelected } = useReportsContext();
  const { getClientId } = useAuth();
  const [monitoredHH, setMonitoredHH] = useState<MonitoredHH>({
    loading: true,
    report: {
      hhHistory: [],
      hhArea: [],
      hhColab: [],
      hhFunction: [],
      hhMedia: [],
      hhTotal: 0,
      hhPago: 0,
      hhInformed: 0
    }
  });
  const { period } = form;

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    setMonitoredHH(previousState => ({...previousState, loading: true}));

    getMonitoredHH(
      getClientId(),
      companySelected ? companySelected.toString() : '',
      professionSelected ? professionSelected.toString() : '',
      areaSelected ? areaSelected.toString() : '',
      collaboratorSelected ? collaboratorSelected.toString() : '',
      form.period?.initial.valueOf() ? form.period?.initial.valueOf() : moment().valueOf(),
      form.period?.final.valueOf() ? form.period?.final.valueOf() : moment().valueOf(),
      currentRequest.token
    )
      .then((response: AxiosResponse<any>) => {
        if (response.data) 
          setMonitoredHH({ report: response.data, loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [companySelected, professionSelected, areaSelected, collaboratorSelected, form.period?.initial, 
      form.period?.final]);
  
  const attachEndlabel = (elements: any[]) => {
    if (elements.length > 0) {
      let newElements = elements.map((item) => ({
        name: item.name,
        id: item.id,
        hours: item.value ? Number.parseFloat(item.value.toString()) : 0,
      }));

      newElements = newElements.map((v, index, arr) => {
        const totalValues = arr.reduce((acc, val) => {
          return acc + val.hours;
        }, 0);
        const percentValue = v.hours > 0 ? (v.hours * 100) / totalValues : 0.0;

        return {
          ...v,
          endLabel: `${v.hours}h - ${percentValue.toFixed(1)}%`,
        };
      });

      return newElements;
    }

    return [];
  };

  const getChartLine = (rawData: any[]): any[] => {
    if (!rawData || rawData.length == 0) return [];
    else {
      const data = rawData.map((item: any) => {
        return {
          week: item.week.substring(0, 6) + item.week.substring(8, 10),
          hours: Number.parseFloat(item.hours),
        };
      });

      return data;
    }
  };

  const getChartDataArray = (rawData: any[]): any[] => (rawData && rawData.length > 0 ? rawData : []);

  const getChartData = (rawData: string): string => (rawData && rawData.length > 0 ? rawData.toString() : '0');

  if((monitoredHH.loading))
    return <IconLoading />;

  return (
    <>
      <PreviewHeader period={period} title="HH Monitorado" />
      <S.PreviewContent>
        <S.TitleContainer>
          <S.ContentTitle style={{ fontWeight: 500, fontSize: '1.8rem', margin: 0 }}>
            Acumulado de: 
          </S.ContentTitle>
          <S.ContentTitle style={{ margin: '0 .8rem' }}>
            HH Geral.: {getChartData(monitoredHH.report.hhInformed.toString())}
          </S.ContentTitle>
          <S.ContentTitle style={{ margin: '0 .8rem' }}>
            HH Pago.: {getChartData(monitoredHH.report.hhPago.toString())}
          </S.ContentTitle>
          <S.ContentTitle style={{ margin: '0 .8rem' }}>
            HH Produtivo.: {getChartData(monitoredHH.report.hhTotal.toString())}
          </S.ContentTitle>
        </S.TitleContainer>

        <S.TitleContainer flexDirection="column">
          <S.ContentTitle>Histórico de HH</S.ContentTitle>
          <S.ContentSubtitle style={{ fontStyle: 'italic' }}>HH diário acumulado no período</S.ContentSubtitle>
          <ExampleLineChart data={getChartLine(monitoredHH.report.hhHistory)} className="margin" />
        </S.TitleContainer>

        {profession ? (
          <S.TitleContainer flexDirection="column" className="pagebreak">
            <S.ContentTitle>Tempo médio por recurso</S.ContentTitle>
            <S.ContentSubtitle>Média do HH acumulado, por colaborador, no período</S.ContentSubtitle>
            <ExampleLineChart
              data={getChartLine(monitoredHH.report.hhMedia)}
              className="margin"
              //style={{ marginBottom: '6rem', marginTop: '-1.7rem' }}
            />
          </S.TitleContainer>
        ) : (
          <></>
        )}

        <S.TitleContainer
          flexDirection="column" /*style={{ marginTop: profession ? '10rem' : '0' }}*/
          className={'pagebreak'}
        >
          <S.ContentTitle>HH por função</S.ContentTitle>
          <S.ContentSubtitle>HH acumulado por função - e sua % em relação ao total</S.ContentSubtitle>
          <ExampleBarChart data={attachEndlabel(getChartDataArray(monitoredHH.report.hhFunction))} />
        </S.TitleContainer>

        <S.TitleContainer flexDirection="column">
          <S.ContentTitle>HH por área</S.ContentTitle>
          <S.ContentSubtitle>HH acumulado por área produtiva - e sua % em relação ao total</S.ContentSubtitle>
          <ExampleBarChart /*style={{ marginBottom: '17rem' }}*/
            data={attachEndlabel(getChartDataArray(monitoredHH.report.hhArea))}
          />
        </S.TitleContainer>

        {monitoredHH.report.hhColab.map(
          (collaborator: any, index: number) =>
            collaborator.areas &&
            collaborator.areas.length > 0 && (
              <>
                <S.TitleContainer
                  flexDirection="column"
                  style={
                    {
                      //marginTop: index % 2 ? (profession ? '12.6rem' : '-0.7rem') : profession ? '-0.7rem' : '12.6rem',
                      //height: index % 2 ? (profession ? 'auto' : '55rem') : profession ? '55rem' : 'auto',
                    }
                  }
                  hiddenOnPreview
                  className={index % 2 == 0 ? 'pagebreak' : ''}
                >
                  <S.ContentTitle>{CapitalizeSingleWord(collaborator.name)}</S.ContentTitle>
                  <ExampleBarChart
                    data={attachEndlabel(collaborator.areas)}
                    //style={{ marginBottom: index % 2 ? '0' : 0, marginTop: index % 2 ? '-0.5rem' : 0 }}
                  />
                </S.TitleContainer>
              </>
            )
        )}
      </S.PreviewContent>
    </>
  );
};
