import styled from 'styled-components';
import { CardTitle } from '../../styles';
import { Link } from 'react-router-dom';

export const ScrollChipsContainer = styled.div`
  overflow-x: auto;
  display: flex;
  padding: 0.8rem;
  gap: 2rem;
  width: 92%;
  position: absolute;
  left: 2.4rem;
  right: 2.4rem;
  top: 6.4rem;
`;

export const Chip = styled.div`
  background-color: #e6e6e6;
  box-shadow: 0px 2px 4px 0px #00000026;
  color: #808080;
  border-radius: 10rem;
  padding: 0.2rem 0.8rem;
  text-align: center;
  font-family: Work Sans;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
  }
`;

export const QuestionItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 8px 12px 9px 8px;
  gap: 8px;
  border-radius: 8px;
  border-left: 3px solid var(--State-color-Info_Color, #2f80ed);
  background: rgba(119, 177, 255, 0.15);
  cursor: pointer;
  font-weight: 500;
  font-family: Work Sans;
  margin-bottom: 1.2rem;
  width: 100%;
  max-width: 70rem;
`;
