import React from 'react';

import { usePlanningCollaboratorsCount } from './hooks/usePlanningCollaboratorsCount';
import { useAuth } from '../../../../../hooks';
import { getPlanningCollaboratorsCountTooltipContent } from './SummaryIndicatorsTooltip';
import { Info } from '../../../../../components/Info';

import * as S from './styles';

export const PlanningCollaboratorsCount: React.FC = () => {
  const { indicator } = usePlanningCollaboratorsCount();
  const { getClientId } = useAuth();
  const clientId = getClientId();
  const tooltipContent = getPlanningCollaboratorsCountTooltipContent(clientId);

  return (
    <S.Card>
      <S.CardHeader> Colaboradores Planejados Hoje: </S.CardHeader>
      <S.CardContent>{indicator.value}</S.CardContent>
      <S.IconContent>
        <Info content={tooltipContent} position={'left'} burndown={false}/>
      </S.IconContent>
    </S.Card>
  );
};
