import React from 'react';
import { ToastContainer } from 'react-toastify';

import { AddCompanyContainer } from '../CreateCompany';
import { CompanyTable } from '../CompanyTable';

import * as S from './styles';
import 'react-toastify/dist/ReactToastify.css';
import IconUsers from '../../../../../Icons/Users';
import { Button } from '../../../../../components/Buttons/Button';
import IconAdd from '../../../../../Icons/Add';
import { useCompanyList } from './hooks/useCompanyList';

type CompanyListProps = {
  isAdmin: boolean;
}

export const CompanyList: React.FC<CompanyListProps> = ({ isAdmin }) => {
  const {
    isLoading,
    createdCompany,
    isAdding,
    setIsAdding,
    handleCloseAddCompany,
    handleCloseAddCompanyAndUpdateList,
  } = useCompanyList(isAdmin);

  return (
    <S.CompanyListContainer>
      <ToastContainer />
      <S.CompanyListHeader>
        <S.CompanyListHeaderTitleContainer>
          <S.IconUsersContainer>
            <IconUsers />
          </S.IconUsersContainer>
          <S.CompanyListHeaderLabel>Resumo da empresas existentes</S.CompanyListHeaderLabel>
        </S.CompanyListHeaderTitleContainer>
        <S.NewCompanyButtonContainer>
          <Button
            title="Nova empresa"
            icon={<IconAdd fill="#FFF" height="20px" width="20px" />}
            onClick={() => setIsAdding(true)}
          />
        </S.NewCompanyButtonContainer>
      </S.CompanyListHeader>
      <CompanyTable detailData={createdCompany} loading={isLoading} empty={false} />

      {isAdding && (
        <AddCompanyContainer
          isActive={isAdding}
          onClickToClose={handleCloseAddCompany}
          onClickToSave={handleCloseAddCompanyAndUpdateList}
        />
      )}
    </S.CompanyListContainer>
  );
};
