import React from 'react';

import { FloorsProps } from '../../../types/Monitoring';

import * as S from '../styles';

export const PerspectiveHall: React.FC<FloorsProps> = (props: FloorsProps) => {
  const { productive, color } = props;

  return (
    <>
      <S.FrontWall entrance={true} productive={productive} color={color}>
        <S.HallRowContainer>
          <S.HallHeaderContainer productive={productive} isLeft={true}>
            <S.HallHeader productive={productive}></S.HallHeader>
          </S.HallHeaderContainer>
          <S.HallHeaderContainer productive={productive} isLeft={false}>
            <S.HallHeader productive={productive}></S.HallHeader>
          </S.HallHeaderContainer>
        </S.HallRowContainer>
        <S.HallRowContainer>
          <S.HallContainer productive={productive} isLeft={true}>
            <S.HallEntrance productive={productive}></S.HallEntrance>
          </S.HallContainer>
          <S.HallContainer productive={productive} isLeft={false}>
            <S.HallEntrance productive={productive}></S.HallEntrance>
          </S.HallContainer>
        </S.HallRowContainer>
      </S.FrontWall>
      <S.LeftWall entrance={true} productive={productive} color={color}>
        <S.PerspectiveRowContainer>
          <S.HallWindow productive={productive}></S.HallWindow>
          <S.HallWindow productive={productive}></S.HallWindow>
          <S.HallWindow productive={productive}></S.HallWindow>
        </S.PerspectiveRowContainer>
      </S.LeftWall>
    </>
  );
};
