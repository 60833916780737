import React from 'react';
import { DefaultIconProps } from './types';

export const UserAddIcon: React.FC<DefaultIconProps> = ({ width, height, fill, style, onClick }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M36 36C44.2843 36 51 29.2843 51 21C51 12.7157 44.2843 6 36 6C27.7157 6 21 12.7157 21 21C21 29.2843 27.7157 36 36 36Z"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2305 66C10.2305 54.39 21.7805 45 36.0005 45C38.8805 45 41.6705 45.39 44.2805 46.11"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66 54C66 54.96 65.88 55.89 65.64 56.79C65.37 57.99 64.89 59.16 64.26 60.18C62.19 63.66 58.38 66 54 66C50.91 66 48.12 64.8299 46.02 62.9099C45.12 62.1299 44.34 61.2 43.74 60.18C42.63 58.38 42 56.25 42 54C42 50.76 43.29 47.7901 45.39 45.6301C47.58 43.3801 50.64 42 54 42C57.54 42 60.75 43.5301 62.91 45.9901C64.83 48.1201 66 50.94 66 54Z"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.4702 53.9375H49.5303"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54 49.5586V58.5286"
        stroke={fill || '#666666'}
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
