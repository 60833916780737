import React, { useCallback, useEffect, useState } from 'react';
import { List } from 'antd';
import { AxiosResponse } from 'axios';

import cookieService from '../../../../../../../../services/CookieService';
import { fetchData } from '../../../../../../../../services/requests';
import { useCompanyFilterContext } from '../../../../../../../../stores/CompanyFilterContext';
import { useDetectiveContext } from '../../../context/DetectiveContext';
import { useIsMounted, useAuth } from '../../../../../../../../hooks';
import { IconLoading } from '../../../../../../../../components/IconLoading';
import IconArrowRight from '../../../../../../../../Icons/ArrowRight';
import IconDot from '../../../../../../../../Icons/Dot';

type Historic = {
  color: string;
  area: string;
};

type Collaborator = {
  id: number;
  collaborator: string;
  historic: Historic[];
};

type CollaboratorSimple = {
  id: number;
  collaborator: string;
  color: string;
  area: string;
};

type Props = {
  collaborator: Collaborator;
  onSearch: (value?: string) => any;
  colSimple: CollaboratorSimple;
};

export const ResultList: React.FC<Props> = (props: Props) => {
  const {
    selectedPerson,
    updateSelectedPerson,
    updateDataMonitoredCollaborators,
    initHistory,
    setInitHistory,
  } = useDetectiveContext();

  const { selectedCompany } = useCompanyFilterContext();
  const [loading, setLoading] = useState<boolean>(false);
  const isMounted = useIsMounted();
  const { getClientId } = useAuth();
  const { collaborator, onSearch, colSimple } = props;
  
  const token: any = cookieService.get('token');
  const clientId: number = getClientId();
  const companyId = selectedCompany?.id || '';

  //const user = localStorage.getItem('@trackfy_user_id');
  //const userId = user ? Number.parseInt(user) : 1;
  //const useV2 = (clientId == 11 || clientId == 1 || clientId == 6 || userId == 6);

  const getCollaborators = async () => {
    /*const endpointPath = (useV2) ? //'http://localhost:8083/rest/v2/monitoring/collaborators'
      'https://backend.trackfyapp.com/rest/v2/monitoring/collaborators' 
      : `/monitoring/collaborators`;*/
      
    const endpointPath = 'https://backend.trackfyapp.com/rest/v2/monitoring/collaborators';

    await fetchData(token, endpointPath, { clientId, companyId })
      .then((res: AxiosResponse<any>) => {
        const { data } = res;

        if (!isMounted.current) return;

        updateDataMonitoredCollaborators(data);
      })
      .catch(() => {
        if (!isMounted.current) return;
        updateDataMonitoredCollaborators([]);
      })
      .finally(() => {
        if (!isMounted.current) return;

        onSearch('');
      });
  };

  const handleClickOpenHistory = useCallback(
    (e: any) => {
      e.preventDefault();
      updateSelectedPerson(0);
      setInitHistory(false);
      let { id } = e.target.parentElement;
      id = id || e.target.parentElement.parentElement.id;
      id = id || e.target.parentElement.parentElement.parentElement.id;

      updateSelectedPerson(id);
      getCollaborators();
      setLoading(true);
    },
    [loading, selectedPerson]
  );

  useEffect(() => {
    if (initHistory) return setLoading(false);
  }, [initHistory]);

  return (
    <>
      {collaborator && (
        <List.Item id={collaborator.id.toString()} className="search-list-item">
          <div className="list-label-collaborator">
            {collaborator.collaborator}
            {/* abridgedControl(collaborator.collaborator, 10) */}
          </div>
          { //(useV2) ? 
              <>
                <IconDot fill={colSimple.color} />
                <div className="list-label-area">{colSimple.area}</div> 
              </>
              /*:<>
                <IconDot fill={collaborator.historic[0].color} />
                <div className="list-label-area">{collaborator.historic[0].area}</div>
              </>*/
          }
          {loading ? (
            <IconLoading customStyle={{ width: '20px' }} />
          ) : (
            <div className="list-action-open" onClick={handleClickOpenHistory}>
              <IconArrowRight disabled={loading} />
            </div>
          )}
        </List.Item>
      )}
    </>
  );
};
