import React from 'react';

export interface Props {
  fill?: string;
  height?: number;
  width?: number;
}

export const CircleAlert: React.FC<Props> = ({ fill, height, width }) => {
  return (
    <svg
      width={width || '66'}
      height={height || '66'}
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33 60.5C48.125 60.5 60.5 48.125 60.5 33C60.5 17.875 48.125 5.5 33 5.5C17.875 5.5 5.5 17.875 5.5 33C5.5 48.125 17.875 60.5 33 60.5Z"
        stroke={fill || '#ED3A3A'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 22V35.75"
        stroke={fill || '#ED3A3A'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9849 44H33.0096"
        stroke={fill || '#ED3A3A'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};