import React from 'react';

import { Tooltip as BaseTooltip } from '../../../../../components';
import { OccupationData } from '../../../../../types/MonitoredData';
import * as S from './styles';

export type TooltipTimelineProps = {
  visible: boolean;
  data: OccupationData[];
};

export const Tooltip: React.FC<TooltipTimelineProps> = (props: TooltipTimelineProps) => {
  const { visible, data } = props;

  return (
    <BaseTooltip visible={visible}>
      <S.Container>
        {data &&
          data.map((occupation: OccupationData, idx: number) => (
            <S.OccupationContainer key={idx}>
              <S.OccupationQtdy>{occupation.quantity}</S.OccupationQtdy>
              <S.OccupationTitle>{occupation.occupation}</S.OccupationTitle>
            </S.OccupationContainer>
          ))}
      </S.Container>
    </BaseTooltip>
  );
};