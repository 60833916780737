import React from 'react';

import { Page } from '../../components/PageStructure/Page';
import {ContentContainer} from '../../components/PageStructure/styles';
import { DailySummaryContextProvider } from './context/DailySummaryContext';
import PageContent from './components/PageContent';
import PageOptions from './components/PageOptions';

export const DailySummaryContainer: React.FC = () => (
  <Page title="Sumário do Monitoramento" 
        components={
          <ContentContainer value="">
             <DailySummaryContextProvider>
              <PageOptions />
              <PageContent />
            </DailySummaryContextProvider>
          </ContentContainer>
        } 
  />
);