import React from 'react';
import { DefaultIconProps } from './types';

export const RoleIcon: React.FC<DefaultIconProps> = ({ width, height, style, fill }) => {
  return (
    <svg
      style={style}
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.167 17.5H5.83366C2.50033 17.5 1.66699 16.6667 1.66699 13.3333V6.66667C1.66699 3.33333 2.50033 2.5 5.83366 2.5H14.167C17.5003 2.5 18.3337 3.33333 18.3337 6.66667V13.3333C18.3337 16.6667 17.5003 17.5 14.167 17.5Z"
        stroke={fill || '#808080'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.667 6.66797H15.8337"
        stroke={fill || '#808080'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 10H15.8333"
        stroke={fill || '#808080'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.167 13.332H15.8337"
        stroke={fill || '#808080'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08353 9.40729C7.91656 9.40729 8.59186 8.73199 8.59186 7.89896C8.59186 7.06593 7.91656 6.39062 7.08353 6.39062C6.2505 6.39062 5.5752 7.06593 5.5752 7.89896C5.5752 8.73199 6.2505 9.40729 7.08353 9.40729Z"
        stroke={fill || '#808080'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0003 13.6065C9.88366 12.3982 8.92533 11.4482 7.71699 11.3398C7.30033 11.2982 6.87533 11.2982 6.45033 11.3398C5.24199 11.4565 4.28366 12.3982 4.16699 13.6065"
        stroke={fill || '#808080'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};