import React from 'react';
import { Shadow } from '../../../components';
import styled from 'styled-components';
import { ResourcesBurndownChartHeader } from './ResourcesBurndownChartHeader';
import { ResourcesBurndownChartContent } from './ResourcesBurndownChartContent';

const BurnoutCard = styled(Shadow)`
  width: 100%;
  height: 100%;
  min-height: 400px;
  padding: 2.4rem 2.4rem 1.6rem;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
`;

export const ResourcesBurndownChart: React.FC = () => (
    <BurnoutCard>
        <ResourcesBurndownChartHeader />
        <ResourcesBurndownChartContent />
    </BurnoutCard>
)